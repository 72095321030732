<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmregister())">
        <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="newDutyManager">
            <b-tabs type="is-boxed" position="is-centered">
              <b-tab-item label="Añadir Persona">
                <div class="columns is-centered">
                  <div class="column is-centered">


                    <div class="columns my-0 mb-6">

                      <div class="column">
                        <b-field label="Unidad *">
                          <ValidationProvider name="Unidad" rules="required" v-slot="{ errors }">
                            <multiselect v-model="aux_unit_id" :options="units.map((type) => type.id)"
                              :custom-label="(opt) => units.find((x) => x.id === opt).name_unit"
                              placeholder="Seleccionar Unidad" selectLabel="" selectedLabel="Seleccionado"
                              deselectLabel="" :allow-empty="false">
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>

                      <div class="column">
                        <b-field label="Usuario *">
                          <ValidationProvider name="Usuario" rules="required" v-slot="{ errors }">
                            <multiselect v-model="aux_user_id" :options="users.map((type) => type.id)"
                              :custom-label="(opt) => users.find((x) => x.id === opt).full_name"
                              placeholder="Seleccionar usuario" selectLabel="" selectedLabel="Seleccionado"
                              deselectLabel="" :allow-empty="false" @input="getuser(aux_user_id);">
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>

                    </div>

                  </div>
                </div>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <b-button label="Añadir Persona Asociada" icon-left="save" type="is-success" @click="check()" />
                  </div>

                </div>

              </b-tab-item>

              <b-tab-item label="Subida masiva">

                <div class="content">
                  <h2>Seleccionar archivo</h2>
                </div>

                <b-field class="file">
                  <b-upload v-model="file" expanded>
                    <a class="button is-info is-fullwidth">
                      <b-icon icon="upload"></b-icon>
                      <span>{{ file.name || "Subir Archivo" }}</span>
                    </a>
                  </b-upload>
                </b-field>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <b-button icon-left="upload" type="is-info" @click="import_file" label="Importar" />
                  </div>

                </div>
              </b-tab-item>
            </b-tabs>

          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "new_duty_manager",
  props: [
    'queryType', 'modalHeader'
  ],
  data() {
    return {
      selected: null,
      managers: [],
      units: [],
      users: [],
      aux_name: null,
      aux_rut: null,
      aux_dv: null,
      aux_email: null,
      aux_unit_id: null,
      aux_user_id: null,
      aux_active: true,
      userData: null,
      isLoading: false,

      //IMPORTS
      import_type: "Propiedad Intelectual",
      file: { name: "" },
      dropFiles: [],
      isLoading: false,
      errores: [],
      file_name_errors: "",
      cabeceras: [],
      hojas: [],
      errorFile: { name: "" }
    };
  },
  methods: {
    async getunit() {
      await axios.get("/units/showactiveunit.json")
        .then(response => {
          this.units = response.data
        })
        .catch(error => {
          let errorMsg = ''
          if (error.response) {
            if (error.response.status === 403) {
              errorMsg = 'No tiene permisos para obtener las Unidades'
            } else {
              errorMsg = 'Hubo errores al obtener las Unidades, favor de revisar errores.<br>Errores<br>' + error.response.data
            }
          } else {
            errorMsg = 'Hubo errores al obtener las Unidades, intente más tarde.'
          }

          this.$buefy.dialog.alert({
            title: 'Error',
            message: errorMsg,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading = false
        })
    },


    async getusers() {
      await axios.get("/users.json")
        .then(response => {
          this.users = response.data
        })
        .catch(error => {
          let errorMsg = ''
          if (error.response) {
            if (error.response.status === 403) {
              errorMsg = 'No tiene permisos para obtener los Usuarios'
            } else {
              errorMsg = 'Hubo errores al obtener los Usuarios, favor de revisar errores.<br>Errores<br>' + error.response.data
            }
          } else {
            errorMsg = 'Hubo errores al obtener los Usuarios, intente más tarde.'
          }

          this.$buefy.dialog.alert({
            title: 'Error',
            message: errorMsg,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading = false
        })
    },



    async getuser(item) {
      const response = await axios.get("/users/" + item + ".json");
      this.userData = response.data;
      this.aux_name = this.userData.full_name;
      this.aux_rut = this.userData.rut;
      this.aux_dv = this.userData.dv;
      this.aux_email = this.userData.email;
    },

    async createClick() {
      this.isLoading = true
      const response = await axios.post("/duty_managers.json", {
        unit_id: this.aux_unit_id,
        user_id: this.aux_user_id,
        active_duty_manager: this.aux_active,
        name_duty_manager: this.aux_name,
        rut: this.aux_rut,
        dv: this.aux_dv,
        email: this.aux_email,
      })
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha agregado correctamente la Persona Asociada al sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al ingresar los datos de la Categoría, favor revisar los errores.<br>Errores:<br>' + error.response.data,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async check() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert(
          {
            title: 'Error',
            message: 'Algunos campos presentan errores.', 
            type: 'is-danger',
            hasIcon: true, 
            icon: 'times-circle', 
            iconPack: 'fa', 
            ariaRole: 'alertdialog', 
            ariaModal: true
          }
        )
      }
      else {
        this.$buefy.dialog.confirm({
          title: 'Registra Persona asociada',
          message: `¿Esta seguro de registrar a la persona asociada?`,
          cancelText: 'Cancelar',
          confirmText: 'Registrar',
          type: 'is-info',
          onConfirm: () => this.createClick()
        })
      }
    },


    //IMPORTS


    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    import_file() {
      this.isLoading = true;
      var url = ""
      url = "/duty_managers/administrativo/import_duty_manager.json"
      let formData = new FormData();
      formData.append('file', this.file);
      this.errorFile = this.file;
      axios.post(url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(response => {
        if (response.data["errores"].length > 0) {
          this.errores = response.data["errores"];
          this.$buefy.dialog.alert({
            title: "Error",
            message: 'Algunos datos del archivo "' + this.file.name + '" no han podido ser importados, favor revisar errores <br>Errores:<br>' + this.errores,
            confirmText: 'Ok',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.file_name_errors = this.file.name;
          this.cabeceras = response.data["cabeceras"];
          if (this.import_type == "Convenios") {
            this.hojas = response.data["sheets"]
          }
          else {
            this.hojas = []
          }
        }
        else {
          this.import_type = '';
          this.$buefy.dialog.alert({
            title: 'Logrado',
            message: 'Su archivo "' + this.file.name + '" ha sido importado con éxito',
            confirmText: 'Ok',
            type: 'is-success',
            hasIcon: true,
            icon: 'circle-check',
            iconPack: 'fas',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.file = { name: "" };
          this.errores = [];
          this.file_name_errors = "";
          this.$emit('refresh')
          this.$emit('close')
        }

      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      });
      this.isLoading = false
    },

    importErrors() {
      let vm = this;
      vm.isLoading = true;
      axios
        .post("/load-data/export-errors.xlsx", { cabeceras: vm.cabeceras, errores: vm.errores, hojas: vm.hojas },
          {
            responseType: "blob"
          })
        .then(response => {
          vm.isLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Errores importacion masiva de datos ' + vm.errorFile.name.replace(".xlsx", "") + ' ' + new Date().toLocaleDateString() + '.xlsx');
          document.body.appendChild(link);
          link.click();
        }).catch(error => {
          vm.isLoading = false;
          vm.$buefy.notification.open({
            message: 'Hubo un error en tu solicitud',
            type: 'is-danger'
          })
        })


    },

    async getInformation() {
      this.isLoading = true
      await this.getunit()
      await this.getusers()
      this.isLoading = false
    }


  },
  async created() {
    await this.getInformation()
  },
};
</script>
<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Panel de Administración de Usuarios</h1>
      </div>
      <div>
        <b-modal :active="isModalActive" aria-modal :destroy-on-hide="true" aria-role="dialog" width="90%"
          :can-cancel="canCancel">
          <template>
            <new-user modal-cancel="true" :modal-header="'Registro de Usuario'" query-type="create"
              @close="closeModalCreate" @refresh="getInformation" />
          </template>
        </b-modal>

        <b-modal :active="isModalActiveEdit" aria-modal :destroy-on-hide="true" aria-role="dialog" width="90%"
          :can-cancel="canCancel">
          <template>
            <edit-user modal-cancel="true" :id="selected" :modal-header="'Editar Usuario'" query-type="update"
              @close="closeModalEdit" @refresh="getInformation" />
          </template>
        </b-modal>
      </div>
      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Usuarios Activos">
          <div class="has-text-right">
            <b-button label="Añadir Usuario" type="is-success" icon-left="plus" @click="createUser()"
              v-show="(isAuthorized('all', 'manage')) || (isAuthorized('User', 'create'))" />
          </div>

          <div class="column">
            <b-table id="table" :data="users" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom" default-sort-direction="asc"
              sort-icon="arrow-up" aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
              aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="full_name" label="Nombre Usuario" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.full_name }}
                </template>
              </b-table-column>

              <b-table-column field="email" label="Correo" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Correo..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.email }}
                </template>
              </b-table-column>

              <b-table-column field="rutCompleto" label="RUT" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar RUT..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.rutCompleto }}
                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small"
                :visible="(isAuthorized('all', 'manage')) || (isAuthorized('User', 'update')) || (isAuthorized('User', 'disable_user'))">
                <div class="columns">
                  <div class="column">
                    <b-button pack="fas" icon-right="pen" type="is-primary" @click.native="editUser(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('User', 'update'))" />
                  </div>
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="ban" type="is-danger"
                      @click.native="confirmDisabled(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('User', 'disable_user'))" />
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>
        <b-tab-item label="Usuarios Deshabilitados">
          <div class="column">
            <b-table id="table" :data="nullUsers" :paginated="true" per-page="10" striped hoverable
              :pagination-simple="false" pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom"
              default-sort-direction="asc" sort-icon="arrow-up" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="full_name" label="Nombre Usuario" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.full_name }}
                </template>
              </b-table-column>

              <b-table-column field="email" label="Correo" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Correo..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.email }}
                </template>
              </b-table-column>

              <b-table-column field="rutCompleto" label="RUT" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar RUT..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.rutCompleto }}
                </template>
              </b-table-column>

              <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small"
                :visible="(isAuthorized('all', 'manage')) || (isAuthorized('User', 'activate_user'))">
                <div class="columns">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="trash-arrow-up" type="is-success"
                      @click.native="confirmEnable(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('User', 'activate_user'))" />
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
  </section>
</template>

<script>
import axios from "axios";
import NewUser from "./new_user.vue";
import EditUser from "./edit_user.vue";

export default {
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Panel de Usuarios';
  },
  data() {
    return {
      users: [],
      nullUsers: [],
      null_units: [],
      canCancel: ["escape"],
      isModalActive: false,
      isModalActiveEdit: false,
      selected: null,
      continue: false,
      isLoading: false,
    };
  },
  components: {
    NewUser,
    EditUser,
  },
  props: ['userId', 'permissions'],
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },
    async getUsers() {
      this.isLoading = true
      const response = await axios.get("/users/showusers.json")
        .catch(error => {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al solicitar los datos de los usuarios, favor de recargar la página e inténtelo nuevamente.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
      this.users = response.data.users_enabled
      this.nullUsers = response.data.users_disabled
      this.isLoading = false
    },

    async createUser() {
      this.isModalActive = true;
    },
    async closeModalCreate() {
      this.isModalActive = false;
    },

    async editUser(id) {
      this.selected = id
      this.isModalActiveEdit = true
    },

    async closeModalEdit() {
      this.isModalActiveEdit = false
    },

    async DisableClick(id) {
      this.isLoading = true
      await axios.put("/users/" + id + "/disable.json", {
        disabled: true,
      })
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha deshabilitado correctamente el usuario del sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.getInformation()
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al deshabilitar el usuario.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async confirmDisabled(id) {
      this.$buefy.dialog.confirm({
        title: "Deshabilitar Usuario",
        message: "¿Esta seguro de querer deshabilitar al Usuario?",
        confirmText: "Deshabilitar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.DisableClick(id),
      })
    },

    async enableClick(id) {
      this.isLoading = true
      await axios.put("/users/" + id + "/activate.json", {
        disabled: false,
      })
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha habilitado correctamente el usuario en el sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.getInformation()
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al habilitar el usuario.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async confirmEnable(id) {
      this.$buefy.dialog.confirm({
        title: "Habilitar Usuario",
        message: "¿Esta seguro de querer habilitar el Usuario?",
        confirmText: "Habilitar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.enableClick(id),
      });
    },

    async getInformation() {
      this.isLoading = true
      await this.getUsers()
      this.isLoading = false
    }
  },

  async created() {
    await this.getInformation()
  },
};
</script>

<template>
  <section>
    <br>
    <div class="container box">


      <b-modal :active="isModalActiveEdit" aria-modal :destroy-on-hide="true" aria-role="dialog" width="90%"
        :can-cancel="canCancel">
        <template>
          <edit-good :good-id="goodId" :id="selected" :modal-header="'Editar Bien'" query-type="update"
            @close="closeModalEdit" @refresh="getInformation" />
        </template>
      </b-modal>


      <b-modal :active="isModalnewstatus" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
        width="100%" :can-cancel="canCancel">
        <template>
          <new-condition-good v-if="!(cargoActivo.name === 'SUPERADMIN' || cargoActivo.name === 'ADMINISTRADOR')" 
            :good-id="goodId" :id="selected" :modal-header="'Reportar Bien'" query-type="create"
            @close="closeModalCreateStatus" @refresh="getInformation" />
          <ShowReportConditionGood v-else :id="goodId" :fromConditions="true"
              @close="closeModalCreateStatus" @refresh="getInformation"/>
        </template>
      </b-modal>

      <b-modal :active="isModalEditCharge" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
        width="100%" :can-cancel="canCancel">
        <template>
          <EditChargeGood queryType="update" :modalHeader="'Asignar Nueva Persona Encargada'" :goodId="goodId" 
            :id="goodId" :fromStorage="false" :fromUsed="true" :oldDutyManagerId="good.duty_manager_id" 
            @close="isModalEditCharge = false" @refresh="getInformation" />
        </template>
      </b-modal>


      <div class="content">
        <h1>Información del bien</h1>
      </div>


      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Detalle del bien">



          <div v-if="good">


            <div class="columns">
              <div class="column has-text-right">
                <b-button label="Editar Bien" type="is-primary" icon-left="pen" 
                  :disabled="!(good.condition_good.condition.localeCompare('En Uso') == 0 || good.condition_good.condition.localeCompare('En Bodega') == 0 ||
                  cargoActivo.name === 'SUPERADMIN' || cargoActivo.name === 'ADMINISTRADOR')"
                  @click="editGood(goodId)"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update'))" />
                <b-button label="Reportar Bien" type="is-warning" icon-left="bullhorn"
                  :disabled="!(good.condition_good.condition.localeCompare('En Uso') == 0 || cargoActivo.name === 'SUPERADMIN' || cargoActivo.name === 'ADMINISTRADOR')"
                  @click="createConditionGood(goodId)"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))" />
                <b-button v-if="good.condition_good.condition.localeCompare('En Uso') == 0 || 
                  ((cargoActivo.name === 'SUPERADMIN' || cargoActivo.name === 'ADMINISTRADOR') &&  good.condition_good.condition.localeCompare('En Uso') == 0 )" 
                  label="Anular Bien" type="is-danger" icon-left="trash" 
                  :disabled="!(good.condition_good.condition.localeCompare('En Uso') == 0 || good.condition_good.condition.localeCompare('En Bodega') == 0
                  || cargoActivo.name === 'SUPERADMIN' || cargoActivo.name === 'ADMINISTRADOR')"
                  @click="confirmGoodNull(goodId)"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))" />
                <b-button v-else-if="good.condition_good.condition.localeCompare('Anulado') == 0 ||
                  ((cargoActivo.name === 'SUPERADMIN' || cargoActivo.name === 'ADMINISTRADOR') &&  good.condition_good.condition.localeCompare('Anulado') == 0 )" 
                  label="Restaurar Bien" type="is-success" outlined icon-left="trash-arrow-up"
                  @click="confirmGoodRest(goodId)"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'activate_good'))" />
              </div>
            </div>


            <div class="columns">
              <div class="column">
                <b-field label="Categoria: ">
                  <b-input v-model="good.category.alt_name_category" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Nombre:">
                  <b-input v-model="good.alt_name_good" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Numero interno:">
                  <b-input v-model="good.internal_number" type="text" readonly></b-input>
                </b-field>
              </div>

            </div>

            <div class="columns">
              <div class="column">
                <b-field label="Marca:">
                  <b-input v-model="good.trademark.alt_name_trademark" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Modelo:">
                  <b-input v-model="good.model" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Numero de serie: ">
                  <b-input v-model="good.serie_number" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field label="Fuente de Financiamiento: ">
                  <b-input icon="eye" icon-clickable @icon-click="Clickfinancial(good.financial_source_id)"
                    v-model="good.financial_source.alt_name_financial_source" type="text" readonly></b-input>
                </b-field>
              </div>

              <div class="column">
                <b-field label="Tipo de inversión: ">
                  <b-input icon="eye" icon-clickable @icon-click="Clickshowtipe(good.investment_type_id)"
                    v-model="good.investment_type.name_investment_type" type="text" readonly></b-input>
                </b-field>
              </div>

              <div class="column">
                <b-field label="Fecha de compra:">
                  <b-input v-model="good.date_purchase" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field label="Monto Bruto: ">
                  <b-input v-model="good.amount" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Numero identificación Usach:">
                  <b-input v-model="good.identification_number_good" type="text" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Fecha de ultimo chequeo:">
                  <b-input v-model="good.date_checkup" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field label="Estado:">
                  <b-input v-model="good.operative" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>




            <div class="columns" v-show='!(good.activegood == 3)'>
              <div class="column">
                <b-field label="Unidad: ">
                  <b-input icon="eye" icon-clickable @icon-click="Clickshowunit(good.duty_manager.unit_id)"
                    v-model="good.duty_manager.alt_name_unit" type="text" readonly></b-input>
                </b-field>
              </div>

              <div class="column">
                <b-field label="Edificio: ">
                  <b-input icon="eye" icon-clickable @icon-click="Clickshowbuilding(good.office.building_id)"
                    v-model="good.office.alt_name_building" type="text" readonly></b-input>
                </b-field>
              </div>
            </div>


            <div class="columns" v-show='!(good.activegood == 3)'>


              <div class="column">
                <b-field label="Dependencia: ">
                  <b-input icon="eye" icon-clickable @icon-click="Clickshowoffice(good.office_id)"
                    v-model="good.office.alt_name_office" type="text" readonly></b-input>
                </b-field>
              </div>

              <div class="column">
                <b-field label="Persona encargada: ">
                  <b-input icon="eye" icon-clickable @icon-click="Clickshowdutymanager(good.duty_manager_id)"
                    v-model="good.duty_manager.name_duty_manager" type="text" readonly></b-input>
                </b-field>
              </div>


            </div>



            <div class="columns">
              <div class="column">
                <b-field label="Descripción:">
                  <b-input v-model="good.good_description" type="textarea" readonly></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Comentario:">
                  <b-input v-model="good.good_comment" type="textarea" readonly></b-input>
                </b-field>
              </div>
            </div>

          </div>
          <div v-else>
            <p>Cargando</p>
          </div>

        </b-tab-item>

        <b-tab-item label="Persona Encargada" v-show='!(good.activegood == 2)'>

          <br>

          <b-tabs type="is-toggle" position="is-centered">

            <b-tab-item label="Persona Encargada Actual">
              <div class="columns" v-if="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update_charge'))">
                <div class="column has-text-right">
                  <b-button type="is-purple" label="Asignar Nueva Persona Encargada" icon-left="pen" :disabled="!(good.condition_good.condition.localeCompare('En Uso') == 0 || 
                      good.condition_good.condition.localeCompare('En Bodega de Excluidos') == 0 || cargoActivo.name === 'SUPERADMIN' || cargoActivo.name === 'ADMINISTRADOR')"
                    @click="isModalEditCharge = true"/>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Unidad">
                    <b-input v-model="good.duty_manager.alt_name_unit" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Persona encargada">
                    <b-input v-model="good.duty_manager.name_duty_manager" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Edificio">
                    <b-input v-model="good.office.alt_name_building" type="text" readonly></b-input>
                  </b-field>
                </div>

                <div class="column">
                  <b-field label="Dependencia">
                    <b-input v-model="good.office.alt_name_office" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="Historial de Personas Encargadas">
              <div class="columns">
                <div class="column">

                  <b-table :data="good.record_charges" :paginated="true" per-page="10" striped hoverable sortable: true
                    pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small"
                    aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
                    aria-current-label="Página Actual" pagination-size="is-small">

                    <b-table-column field="name_duty_manager" label="Nombre Persona" sortable searchable>
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.name_duty_manager }}
                      </template>
                    </b-table-column>

                    <b-table-column field="start_formatted_date" label="Fecha Inicio" sortable searchable
                      :custom-search="searchCustomDateStart">
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha Inicio..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.start_formatted_date._isValid ? new
                        Date(props.row.start_formatted_date).toLocaleDateString("es-CL") : '' }}
                      </template>
                    </b-table-column>

                    <b-table-column field="end_formatted_date" label="Fecha Término" sortable searchable
                      :custom-search="searchCustomDateEnd">
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha Término..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.end_formatted_date._isValid ? new
                        Date(props.row.end_formatted_date).toLocaleDateString("es-CL") : 'Actualidad' }}
                      </template>
                    </b-table-column>

                  </b-table>

                </div>
              </div>
            </b-tab-item>

          </b-tabs>

        </b-tab-item>


        <b-tab-item label="Historial">

          <div class="column">
            <b-table :data="good.versions" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              sortable: true pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small"
              aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
              aria-current-label="Página actual">

              <b-table-column field="full_name" label="Nombre Usuario" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre Usuario..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.full_name }}
                </template>
              </b-table-column>

              <b-table-column field="event" label="Acción" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Acción..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.event }}
                </template>
              </b-table-column>

              <b-table-column field="date_event" label="Fecha de Acción" sortable searchable
                :custom-search="searchCustomDate">
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha de Acción..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.date_event._isValid ? new Date(props.row.date_event).toLocaleString("es-CL") : '' }}
                </template>
              </b-table-column>

            </b-table>
          </div>

        </b-tab-item>

      </b-tabs>
      <b-button tag="a" href="/condition_goods" label="Volver">
      </b-button>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>

 
<script>
  import axios from "axios"
  import IndexGoods from "./index_good.vue"
  import EditGood from "./edit_good.vue"
  import NewConditionGood from "../condition_good/new_condition_good.vue"
  import ContactModal from "../ContactModal.vue"
  import moment from "moment"
  import {searchDate} from "../../packs/utilities"
  import EditChargeGood from "./EditChargeGood.vue"
  import ShowReportConditionGood from "../condition_good/show_report_info_condition_good.vue"
  export default {
    name: "show_good",
    components: {
      "index-good":
        IndexGoods,
      NewConditionGood,
      EditGood,
      ContactModal,
      EditChargeGood,
      ShowReportConditionGood,
    },
    props: ["goodId", "id", 'queryType', 'modalHeader', 'cargoActivo', 'permissions'],
    mounted() {
      // Cambiar el título de la pestaña
      document.title = 'SGI - Detalle Bien';
    },

    data() {
      return {
        good: {
          condition_good: {
            condition: "",
          },
          trademark: {},
          category: {},
          financial_source: {},
          investment_type: {},
          duty_manager: {},
          office: {},
        },
        moment: moment,
        null_activegood: false,
        des_activegood: true,
        selected: null,
        oldDutyManagerId: null,
        canCancel: ["escape"],
        isModalActiveEdit: false,
        isModalnewstatus: false,
        isModalEditCharge: false,
        props: ["id"],
        current: null,
        isLoading: false,
      };
    },
    methods: {
      isAuthorized(name, action) {
        var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
        if (objeto !== undefined) {
            return true;
        } else {
            return false;
        }
      },

      async getGood() {
        this.isLoading = true
        await axios
          .get("/goods/" + this.goodId + ".json")
          .then((response) => {
            this.good = response.data
            this.good["record_charges"] = this.good["record_charges"].map(record => {
              var newRecord = record
              newRecord["start_formatted_date"] = moment(newRecord["start_formatted_date"], ["YYYY-MM-DD","DD-MM-YYYY"])
              newRecord["end_formatted_date"] = moment(newRecord["end_formatted_date"], ["YYYY-MM-DD","DD-MM-YYYY"])
              return newRecord
            })
            this.good.date_purchase = moment(this.good.date_purchase, ["YYYY-MM-DD","DD-MM-YYYY"])
            this.good.date_purchase = new Date(this.good.date_purchase).toLocaleDateString("es-CL")

            this.good.versions = this.good.versions.map(version => {
              var newVersion = version
              newVersion.date_event = moment(newVersion.date_event, ["YYYY-MM-DD H:m:s","DD-MM-YYYY H:m:s"])
              return newVersion
            })

            this.good.versions.sort(function (a,b) {
              return new Date(b.date_event) - new Date(a.date_event)
            })

          })
          .catch((error) => {
            this.$buefy.dialog.alert({
              message: "Error en la obtención del Bien",
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
              confirmText: "Ok",
            });
          })
        this.isLoading = false
        
      },

      async Clickshowdutymanager(item) {
        const response = (window.location.href = "/duty_managers/" + item);
      },
      async Clickshowbuilding(item) {
        const response = (window.location.href = "/buildings/" + item);
      },
      async Clickshowoffice(item) {
        const response = (window.location.href = "/offices/" + item);
      },
      async Clickshowunit(item) {
        const response = (window.location.href = "/units/" + item);
      },

      async Clickfinancial(item) {
        const response = (window.location.href = "/financial_sources/" + item);
      },

      async Clickshowtipe(item) {
        const response = (window.location.href = "/investment_types/" + item);
      },

      async Clickeditgood(item) {
        const response = (window.location.href = "/goods/" + item + "/edit");
      },
      async AnulClick(item) {
        this.isLoading = true
        await axios
          .put("/condition_goods/updatebyidgood/" + item + ".json", {
              change_condition_to: "Anulado",
              change_condition: true,
          })
          .then(response => {
              this.getInformation()
              this.$buefy.dialog.alert({
              title: 'Bien Anulado',
              type: 'is-success',
              hasIcon: true,
              icon: 'circle-check',
              iconPack: 'fas',
              message: 'El Bien ha sido anulado exitosamente.',
              confirmText: 'Aceptar'
              })
              this.isLoading = false
          })
          .catch(error => {
            var error_message = error.response ? error.response.data.error : error
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo un error al anular el Bien.<br><br> Error:<br>' + error_message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.isLoading = false
          })
      },


      async get_user_actual() {
        this.isLoading = true
        const response = await axios.get("/duty_managers/currentuser.json")
        this.current = response.data.current_user
        this.isLoading = false
      },

      confirmGoodNull(item) {
        this.$buefy.dialog.confirm({
          title: "Anular Bien",
          message: "¿Esta seguro de querer anular el bien?.",
          confirmText: "Anular",
          cancelText: "Cancelar",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.AnulClick(item),
        });
      },
      async RestClick(item) {
        this.isLoading = true
        await axios
        .put("/condition_goods/updatebyidgood/" + item + ".json", {
              change_condition_to: "En Uso",
              change_condition: true,
          })
          .then(response => {
              this.getInformation()
              this.$buefy.dialog.alert({
              title: 'Bien Restaurado',
              type: 'is-success',
              hasIcon: true,
              icon: 'circle-check',
              iconPack: 'fas',
              message: 'El Bien ha sido restaurado exitosamente.',
              confirmText: 'Aceptar'
              })
              this.isLoading = false
          })
          .catch(error => {
            var error_message = error.response ? error.response.data.error : error
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo un error al restaurar el Bien.<br><br> Error:<br>' + error_message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.isLoading = false
          })
        
      },
      
      confirmGoodRest(item) {
        this.$buefy.dialog.confirm({
          title: "Restaurar Bien",
          message: "¿Esta seguro de querer restaurar el bien?.",
          confirmText: "Restaurar",
          cancelText: "Cancelar",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.RestClick(item),
        });
      },

      async editGood(item) {
        this.selected = item;
        this.isModalActiveEdit = true;
      },
      async closeModalEdit() {
        this.isModalActiveEdit = false;
      },

      async createConditionGood(item) {
        this.selected = item;

        this.isModalnewstatus = true;
      },

      async closeModalCreateStatus() {
        this.isModalnewstatus = false;
      },

      searchCustomDateStart (row, input) {
        return searchDate (row.start_formatted_date, input)
      },

      searchCustomDateEnd (row, input) {
        return searchDate (row.end_formatted_date, input)
      },
      searchCustomDate (row, input) {
        return searchDate (row.date_event, input)
      },

      async getInformation() {
        this.isLoading = true
        await this.getGood()
        await this.get_user_actual()
        this.isLoading = false
      }

    },
    async created() {
      await this.getInformation()
    },
  };
</script>
<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">

      <b-modal :active="isModalEditCharge" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
        width="100%" :can-cancel="canCancel">
        <template>
          <edit-building-charge :oldDutyManagerId="oldDutyManagerId" :id="selected"
            :modal-header="'Editar Persona Encargada'" query-type="update" @close="closeModalEditCharge"
            @refresh="getInformation" />
        </template>
      </b-modal>

      <div class="content">
        <h1>Detalle de mi Edificio</h1>
      </div>
      <b-tabs type="is-boxed" position="is-centered">

        <b-tab-item label="Detalle del Edificio">

          <div v-if="building" class="columns is-centered">


            <div class="column is-centered">

              <div class="columns">
                <div class="column">
                  <b-field label="Nombre Edificio:">
                    <b-input v-model="building.alt_name_building" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Numero identificación del edificio:">
                    <b-input v-model="building.identification_number_building" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Monto bruto total invertido en bienes: ">
                    <b-input v-model="total" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Ubicación:">
                    <b-input v-model="building.location_building" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>


              <div class="columns">
                <div class="column">
                  <b-field label="Unidad: ">
                    <b-input v-model="building.unit.alt_name_unit" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Persona encargada: ">
                    <b-input v-model="building.duty_manager.name_duty_manager" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <p>Cargando</p>
          </div>
        </b-tab-item>

        <b-tab-item label="Dependencias del Edificio">
          <div class="column">
            <b-table :data="offices" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="name_office" label="Nombre dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_office }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_office" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input type="number" v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_office }}
                </template>
              </b-table-column>

              <b-table-column field="location" label="Ubicación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Ubicación..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.location }}
                </template>
              </b-table-column>

              <b-table-column field="id" width="10" label="Acciones" v-slot="props">
                  <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                    tag="a" :href="'/offices/myoffice/' + props.row.id" target="_blank"
                    v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Office', 'show'))"/>
              </b-table-column>
            </b-table>
          </div>

        </b-tab-item>

        <b-tab-item label="Bienes del Edificio">
          <div class="column">
            <b-table :data="goods" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">

              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="namegood" label="Nombre" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_good }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_good" label="N° Inventario" sortable searchable>
                <template #searchable="props">
                  <b-input type="number" v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_good }}
                </template>
              </b-table-column>

              <b-table-column field="category.name_category" label="Categoría" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Categoría..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.category.alt_name_category }}
                </template>
              </b-table-column>

              <b-table-column field="duty_manager.name_duty_manager" label="Nombre Persona asociada" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Persona..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.duty_manager.name_duty_manager }}
                </template>
              </b-table-column>


              <b-table-column field="office.name_office" label="Dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Dependencia..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.office.alt_name_office }}

                </template>
              </b-table-column>

              <b-table-column field="id" width="10" label="Acciones" v-slot="props">
                <div class="columns">
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                      tag="a" :href="'/goods/mygood/' + props.row.id" target="_blank"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show'))"/>
                  </div>
                </div>
              </b-table-column>

            </b-table>
          </div>
        </b-tab-item>

        <b-tab-item label="Persona Encargada">

          <b-tabs type="is-boxed" position="is-centered">

            <b-tab-item label="Persona Encargada Actual">
              <div class="columns">
                <div class="column has-text-right">
                  <b-button type="is-primary" label="Editar Persona Encargada" icon-left="pen"
                    @click="editCharge(buildingId)"
                    v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'update_charge'))"/>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Unidad">
                    <b-input v-model="building.unit.alt_name_unit" type="text" readonly>
                    </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Persona encargada">
                    <b-input v-model="building.duty_manager.name_duty_manager" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="Historial de Personas Encargadas">
              <div class="columns">
                <div class="column">

                  <b-table :data="building.record_charges" :paginated="true" per-page="10" striped hoverable sortable:
                    true pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small"
                    aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
                    aria-current-label="Página Actual" pagination-size="is-small">

                    <b-table-column field="name_duty_manager" label="Nombre Persona" sortable searchable>
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.name_duty_manager }}
                      </template>
                    </b-table-column>

                    <b-table-column field="formatted_start_date" label="Fecha Inicio" sortable searchable
                      :custom-search="searchCustomStartDate">
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha Inicio..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.formatted_start_date._isValid ? new
                          Date(props.row.formatted_start_date).toLocaleDateString("es-CL") : '' }}
                      </template>
                    </b-table-column>

                    <b-table-column field="formatted_end_date" label="Fecha Término" sortable searchable
                      :custom-search="searchCustomEndDate">
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha Término..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.formatted_end_date._isValid ? new
                          Date(props.row.formatted_end_date).toLocaleDateString("es-CL") : 'Actualidad' }}
                      </template>
                    </b-table-column>

                  </b-table>

                </div>
              </div>
            </b-tab-item>

          </b-tabs>
        </b-tab-item>

        <b-tab-item label="Historial">
          <div class="column">
            <b-table :data="records" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">

              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="good.namegood" label="Nombre del bien" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.good.alt_name_good }}
                </template>
              </b-table-column>

              <b-table-column field="user.full_name" label="Nombre usuario" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre Usuario..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.user.full_name }}
                </template>
              </b-table-column>

              <b-table-column field="detail_record" label="Acción" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Acción..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.detail_record }}
                </template>
              </b-table-column>

              <b-table-column field="formatted_created_at" label="Fecha de acción" sortable searchable
                :custom-search="searchCustomDate">
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.formatted_created_at._isValid ? new
                    Date(props.row.formatted_created_at).toLocaleString("es-CL") : '' }}
                </template>
              </b-table-column>

            </b-table>
          </div>

        </b-tab-item>

      </b-tabs>
      <b-button tag="a" href="/duty_managers/mybuildings" label="Volver" />
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
  </div>
</section></template>
  
   
<script>
import axios from "axios";
import moment from "moment";
import EditBuildingCharge from "./edit_building_charge.vue";
import { searchDate } from "../../packs/utilities";
export default {
  name: "show_building",
  props: ["id", 'queryType', 'modalHeader', 'buildingId', 'permissions'],
  components: {
    EditBuildingCharge
  },
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Detalle de Mi Edificio';
  },
  data() {
    return {
      offices: [],
      records: [],
      moment: moment,
      id_goods_list: [],
      id_offices_list: [],
      goods: [],
      goodamounts: [],
      building: {
        unit: {},
        duty_manager: {},
      },
      total: null,
      oldDutyManagerId: null,
      null_activegood: false,
      props: ["id"],
      isModalEditCharge: false,
      canCancel: ["escape"],
      selected: null,
      isLoading: false,
    };
  },
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    async getoffices() {
      const response = await axios.get(
        "/offices/showofficebuilding/" + this.buildingId + ".json"
      );
      this.offices = response.data;
      this.id_offices_list = this.offices.map(o => o['id']);
    },

    async getbuildings() {
      await axios.get("/buildings/" + this.buildingId + ".json")
        .then((response) => {
          this.building = response.data
          this.building["record_charges"] = this.building["record_charges"].map(record => {
            var newRecord = record
            newRecord["formatted_start_date"] = moment(newRecord["formatted_start_date"], ["YYYY-MM-DD", "DD-MM-YYYY"])
            newRecord["formatted_end_date"] = moment(newRecord["formatted_end_date"], ["YYYY-MM-DD", "DD-MM-YYYY"])
            return newRecord
          })
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            message: "Error en la obtención del Edificio",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            confirmText: "Ok",
          });
        })
    },

    async getgoodbybuilding() {
      const response = await axios.get("/goods/showgoodbuilding/" + this.buildingId + ".json");
      this.goods = response.data;
      this.goodamounts = this.goods.map(o => o['amount']);
      this.id_goods_list = this.goods.map(o => o['id']);

      const c = 0;
      this.total = this.goodamounts.reduce((a, b) => a + b, c);
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Bien",
        message: "¿Esta seguro de querer anular el bien?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulGoodClick(item),
      });
    },

    async AnulGoodClick(item) {
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.null_activegood,
      });
      window.location.href = "/buildings/" + this.buildingId;
    },

    async Clickshowunit(item) {
      const response = (window.location.href = "/units/" + item);
    },

    async Clickshowdutymanager(item) {
      const response = (window.location.href = "/duty_managers/" + item);
    },
    async Clickshowbuilding(item) {
      const response = (window.location.href = "/buildings/" + item);
    },

    async editCharge(item) {
      this.selected = item;
      this.oldDutyManagerId = this.building.duty_manager_id;
      this.isModalEditCharge = true;
    },

    async closeModalEditCharge() {
      this.isModalEditCharge = false;
    },

    searchCustomStartDate (row, input) {
      return searchDate (row.formatted_start_date, input)
    },

    searchCustomEndDate (row, input) {
      return searchDate (row.formatted_end_date, input)
    },

    searchCustomDate (row, input) {
      return searchDate (row.formatted_created_at, input)
    },

    async getInformation() {
      this.isLoading = true
      await this.getbuildings();
      await this.getoffices();
      await this.getgoodbybuilding();
      this.isLoading = false
    }

  },
  async created() {
    await this.getInformation()
  },
};
</script>
  
  
  
  
  
<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">

      <div class="content">
        <h1>Edificios a cargo</h1>
      </div>

      <div>
        <b-modal :active="isModalActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog" width="100%"
          :can-cancel="canCancel">
          <template>
            <new-building modal-cancel="true" :modal-header="'Registro de Edificio'" query-type="create"
              @close="closeModalCreate" @refresh="toRefreshActiveBuildings" />
          </template>
        </b-modal>

        <b-modal :active="isModalActiveEdit" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
          width="100%" :can-cancel="canCancel">
          <template>
            <edit-building :building-id="buildingId" :id="selected" :modal-header="'Editar Edificio'" query-type="update"
              @close="closeModalEdit" @refresh="toRefreshActiveBuildings" />
          </template>
        </b-modal>
      </div>

      <div class="column">
        <b-table :data="buildings" :paginated=true per-page="10" striped hoverable :pagination-simple=false
          pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
          default-sort="inventores.name" aria-next-label="Siguiente" aria-previous-label="Anterior"
          aria-page-label="Página" aria-current-label="Página actual">
          <b-table-column :visible=false field="id" label="ID" sortable searchable>

            <template v-slot="props">
              {{ props.row.id }}
            </template>
          </b-table-column>

          <b-table-column field="name_building" label="Nombre Edificio" sortable searchable>
            <template #searchable="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                icon="magnifying-glass" />
            </template>
            <template v-slot="props">
              {{ props.row.alt_name_building }}

            </template>
          </b-table-column>

          <b-table-column field="identification_number_building" label="N° Identificación" sortable searchable>
            <template #searchable="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..." icon="magnifying-glass" />
            </template>
            <template v-slot="props">
              {{ props.row.identification_number_building }}
            </template>
          </b-table-column>

          <b-table-column field="unit.name_unit" label="Nombre Unidad" sortable searchable>
            <template #searchable="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Unidad..."
                icon="magnifying-glass" />
            </template>
            <template v-slot="props">
              {{ props.row.unit.alt_name_unit }}
            </template>
          </b-table-column>

          <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
            <div class="columns is-mobile is-variable is-1-mobile">
              <div class="column">
                <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                  tag="a" :href="'/buildings/mybuilding/' + props.row.id" target="_blank"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'show_my_office'))"/>
              </div>
            </div>
          </b-table-column>

        </b-table>
      </div>
    </div>
  </section>
</template>
  
  
<script>
import axios from "axios";
import NewBuilding from '../building/new_building.vue';
import ShowBuilding from '../building/show_building.vue';
import EditBuilding from "../building/edit_building.vue";
export default {
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Mis Edificios';
  },
  data() {
    return {
      buildings: [],

      offices: [],
      id_goods_list: [],
      id_offices_list: [],
      null_buildings: [],
      offices_building: [],
      goods_buildings: [],
      canCancel: ["escape"],
      isModalActive: false,
      isModalActiveShow: false,
      isModalActiveEdit: false,
      selected: null,
      null_active: false,
      des_active: true,
      pendiing_good: 2,
      current: null,
    };
  },
  components: {
    NewBuilding,
    EditBuilding,
    ShowBuilding
  },
  props: ["buildingId", 'permissions'],
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    async get_user_actual() {
      const response = await axios.get("/duty_managers/currentuser.json");
      this.current = response.data.current_user;
      this.getbuildingactive(this.current);
    },

    async getbuildingactive(item) {
      const response = await axios.get("/buildings/showbuildinguser/" + item + ".json");
      this.buildings = response.data;
    },

    async null_getbuilding() {
      const response = await axios.get("/buildings/shownullbuilding.json");
      this.null_buildings = response.data;
    },

    async AnulClick(item) {
      const response = await axios.put("/buildings/" + item + ".json", {
        active_building: this.null_active,
      });
      // window.location.href = "/buildings";
      this.isLoading = true
      await this.get_user_actual();
      this.isLoading = false;

    },

    async RestClick(item) {
      const response = await axios.put("/buildings/" + item + ".json", {
        active_building: this.des_active,
      });
      // window.location.href = "/buildings";
      this.isLoading = true
      await this.get_user_actual();
      this.isLoading = false;
    },
    confirmGoodRest(item) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Edificio",
        message: "¿Esta seguro de querer restaurar el Edificio?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Edificio",
        message: "¿Esta seguro de querer anular el Edificio?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });
    },


    confirmisempty(item) {
      this.getofficebybuilding(item);
    },


    async getofficebybuilding(item) {
      const response = await axios.get(
        "/offices/showofficebuilding/" + item + ".json"
      );
      this.offices_building = response.data;
      if (this.offices_building.length == 0) {
        this.getgoodbybuilding(item);
      }
      else {
        this.$buefy.dialog.alert({
          title: 'No se puede anular',
          type: 'is-danger',
          message: 'Existen dependencias designados en el edificio',
          confirmText: 'Aceptar'
        })
      }
    },

    async getgoodbybuilding(item) {
      const response = await axios.get("/goods/showgoodbuilding/" + item + ".json");
      this.goods_buildings = response.data;
      if (this.goods_buildings.length == 0) {
        this.confirmGoodNull(item);
      }
      else {
        this.$buefy.dialog.alert({
          title: 'No se puede anular',
          type: 'is-danger',
          message: 'Existen bienes designados en el edificio',
          confirmText: 'Aceptar'
        })
      }
    },



    async Clicknewbuilding() {
      const response = (window.location.href = "/buildings/new");
    },
    async Clickshowbuilding(item) {
      const response = (window.location.href = "/buildings/mybuilding/" + item);
    },
    async Clickshowdutymanager(item) {
      const response = (window.location.href = "/duty_managers/" + item);
    },
    async Clickshowunit(item) {
      const response = (window.location.href = "/units/" + item);
    },
    async createBuilding() {
      this.isModalActive = true;
    },
    async closeModalCreate() {
      this.isModalActive = false;
    },
    async ShowModaleBuilding(item) {
      this.selected = item;
      this.isModalActiveShow = true;
    },
    async closeModalShow() {
      this.isModalActiveShow = false;
    },



    async AnulClickgood(item) {
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.pendiing_good,
      });
    },
    async AnulClickoffice(item) {
      const response = await axios.put("/offices/" + item + ".json", {
        active_office: this.null_active,
      });
    },
    async editBuilding(item) {
      this.selected = item;
      this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },

    async toRefreshActiveBuildings(refrescar){
      
      if (refrescar) {
        this.isLoading = true
        await this.get_user_actual();
        this.isLoading = false
      }

    },

  },
  created() {
    this.get_user_actual();
  },
};
</script>
  
  
<template>
  <section>
    <div class="modal-card" style="width: 100%">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ modalHeader }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body" ref="EditGoodByUser">
        <div v-if="good" class="columns is-centered">
          <div class="column is-centered">

            <ValidationObserver v-slot="{ handleSubmit }" ref="observer1">
              <form @submit.prevent="handleSubmit(checkEditData())">

                <div class="columns">

                  <div class="column">
                    <b-field label="Categoria *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="good.category_id" :options="categories.map((type) => type.id)" :custom-label="(opt) =>
                          categories.find((x) => x.id === opt).name_category
                          " placeholder="Seleccionar categoria" selectedLabel="Seleccionado" selectLabel=""
                          :allow-empty="true">
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Nombre *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="good.alt_name_good" placeholder="Ingrese el nombre del bien"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Numero inventario Usach *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="good.identification_number_good"
                          placeholder="Ingrese el numero inventario del bien"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                </div>


                <div class="columns">

                  <div class="column">
                    <b-field label="Marca *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="good.trademark_id" :options="trademarks.map((type) => type.id)"
                          :custom-label="(opt) =>
                            trademarks.find((x) => x.id === opt).name_trademark
                            " placeholder="Seleccionar Marca" selectedLabel="Seleccionado" selectLabel=""
                          :allow-empty="true">
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Numero de serie">
                      <ValidationProvider v-slot="{ errors }">
                        <b-input v-model="good.serie_number" placeholder="Ingrese el numero de serie del bien"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Modelo">
                      <ValidationProvider v-slot="{ errors }">
                        <b-input v-model="good.model" placeholder="Ingrese el Modelo del bien"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>


                </div>

                <div class="columns">

                  <div class="column">
                    <b-field label="Fecha de Compra *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-datepicker v-model="good.date_purchase" locale="es-ES" placeholder="Seleccionar fecha de compra"
                          trap-focus>
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Fecha Chequeo *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-datepicker v-model="good.date_checkup" locale="es-ES"
                          placeholder="Seleccionar fecha de ultimo Chequeo" trap-focus>
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                </div>

                <div class="columns">

                  <div class="column">
                    <b-field label="Fuente de Financiamiento *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="good.financial_source_id" :options="financial_sources.map((type) => type.id)"
                          :custom-label="(opt) =>
                            financial_sources.find((x) => x.id === opt).alt_name_financial_source
                            " placeholder="Seleccionar fuente de financiamiento" selectedLabel="Seleccionado"
                          selectLabel="" :allow-empty="true">
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Tipo de inversión *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="good.investment_type_id" :options="investment_types.map((type) => type.id)"
                          :custom-label="(opt) =>
                            investment_types.find((x) => x.id === opt).alt_name_investment_type
                            " placeholder="Seleccionar fuente Tipo de inversión" selectedLabel="Seleccionado"
                          selectLabel="" :allow-empty="true">
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Monto bruto">
                      <ValidationProvider v-slot="{ errors }">
                        <b-numberinput min="0" v-model="good.amount" type="is-info"
                          placeholder="Ingrese el monto bruto del bien">
                        </b-numberinput>
                        <span class="validation-alert">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                </div>

                <div class="columns">

                  <div class="column">
                    <b-field label="Ubicación *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="good.location_good" placeholder="Ingrese la ubicación del bien" />
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Numero interno">
                      <ValidationProvider v-slot="{ errors }">
                        <b-input v-model="good.internal_number"
                          placeholder="Ingrese el numero interno del bien"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Descripción">
                      <ValidationProvider v-slot="{ errors }">
                        <b-input v-model="good.good_description"
                          placeholder="Ingrese una descripción del bien o el nombre" type="textarea"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns is-mobile">
                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <p class="control">
                      <b-button label="Editar Bien" icon-left="floppy-disk" type="is-info" @click="checkEditData()" />
                    </p>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>

        <div v-else>
          <p>Cargando</p>
        </div>
      </section>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>
  
  
<script>
import axios from "axios";
import IndexGoods from "./index_good.vue";
export default {
  name: "edit_good",
  props: ["queryType", "modalHeader", "goodId", "id"],
  components: {
    "index-good": IndexGoods,
  },

  data() {
    return {
      good: null,
      goods: [],
      props: ["id"],
      offices: [],
      managers: [],
      buildings: [],
      financial_sources: [],
      investment_types: [],
      trademarks: [],
      categories: [],
      duty_manager_id: null,
      building_id: null,
      office_id: null,
      current: null,
      isLoading: false,
    };
  },
  methods: {
    async getgoods() {
      this.isLoading = true
      await axios.get("/goods/" + this.id + ".json")
      .then(response => {
        this.good = response.data
        this.good.date_purchase = new Date(this.good.date_purchase)
        this.good.date_checkup = new Date(this.good.date_checkup)
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al obtener los datos del bien, favor inténtelo nuevamente más tarde.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('close')
      })

      this.isLoading = false
    },

    async getfinancialsource() {
      const response = await axios.get("/financial_sources/showfinancialsources.json");
      this.financial_sources = response.data.financial_sources_enabled;
      this.getinvestmenttype();

    },

    async getinvestmenttype() {
      const response = await axios.get("/investment_types/showinvestmenttypes.json");
      this.investment_types = response.data.investment_types_enabled;
      this.get_trademarks();
    },

    async get_trademarks() {
      const response = await axios.get("/trademarks/showtrademarks.json");
      this.trademarks = response.data.trademarks_enabled;
      this.get_categories();
    },


    async get_categories() {
      const response = await axios.get("/categories/showcategories.json");
      this.categories = response.data.categories_enabled
      this.getgoods();
    },

    async getofficebybuilding(item) {
      const response = await axios.get("/offices/showofficebuilding/" + item + ".json");
      this.offices = response.data;
      this.getunitidbybuilding();
    },




    async getunitidbybuilding() {
      const response = await axios.get("/buildings/" + this.building_id + ".json");
      this.mybuilding = response.data;
      this.getdutymanagerbyunit();
    },

    async getdutymanagerbyunit() {
      const response = await axios.get(
        "/duty_managers/showdutymanagerunit/" +
        this.mybuilding.unit_id +
        ".json"
      );
      this.managers = response.data;
    },

    /*async getbuildingbyunit(item) {
      const response = await axios.get(
        "http://localhost:3000/buildings/showbuildingunit/" + item + ".json"
      );
      this.buildings = response.data;
    },


    
*/



    async get_user_actual() {
      const response = await axios.get("/duty_managers/currentuser.json");
      this.current = response.data.current_user;
      this.getbuildingactive(this.current);

    },
    async getbuildingactive(item) {
      const response = await axios.get("/buildings/showbuildinguser/" + item + ".json");
      this.buildings = response.data;
    },



    async EditClick() {
      this.isLoading = true
      const response = await axios.put("/goods/" + this.id + ".json", {
        namegood: this.good.alt_name_good,
        good_description: this.good.good_description,
        identification_number_good: this.good.identification_number_good,
        serie_number: this.good.serie_number,
        internal_number: this.good.internal_number,
        trademark: this.good.trademark,
        location_good: this.good.location_good,
        amount: this.good.amount,
        date_purchase: this.good.date_purchase,
        model: this.good.model,
        trademark_id: this.good.trademark_id,
        category_id: this.good.category_id,

        financial_source_id: this.good.financial_source_id,
        investment_type_id: this.good.investment_type_id,
      })
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha editado correctamente el Bien en el sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al editar los datos del Bien, favor revisar los datos e inténtelo nuevamente.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async get_user_actual() {
      const response = await axios.get("/duty_managers/currentuser.json");
      this.current = response.data.current_user;
      this.getbuildingactive(this.current);
    },

    confirmedit() {
      this.$buefy.dialog.confirm({
        title: "Editar el Bien",
        message: `¿Esta seguro de editar esté bien?`,
        cancelText: "Cancelar",
        confirmText: "Guardar",
        type: "is-info",
        onConfirm: () => this.EditClick(),
      });
    },
    confirmeditDes() {
      this.$buefy.dialog.confirm({
        title: "Editar el Bien",
        message: `¿Esta seguro de editar esté bien?`,
        cancelText: "Cancelar",
        confirmText: "Guardar",
        type: "is-info",
        onConfirm: () => this.EditClickDes(),
      });
    },
    async checkEditData() {
      const valid = await this.$refs.observer1.validate()
      if (!valid) {
        this.$buefy.dialog.alert(
          {
            message: 'Algunos campos presentan errores.', type: 'is-danger',
            hasIcon: true, icon: 'times-circle', iconPack: 'fa', ariaRole: 'alertdialog', ariaModal: true
          }
        )
      }
      else {
        this.confirmedit();
      }
    },
    async checkCharge() {
      const valid = await this.$refs.observer2.validate()
      if (!valid) {
        this.$buefy.dialog.alert(
          {
            message: 'Algunos campos presentan errores.', type: 'is-danger',
            hasIcon: true, icon: 'times-circle', iconPack: 'fa', ariaRole: 'alertdialog', ariaModal: true
          }
        )
      }
      else {
        this.confirmeditDes();
      }
    },


    async EditClickDes() {
      this.isLoading = true
      const response = await axios.put("/goods/" + this.id + ".json", {
        duty_manager_id: this.duty_manager_id,
        office_id: this.office_id,
        activegood: true,
      })
      this.$emit('refresh')
      this.$emit('close')
      this.isLoading = false
    },

    confirmeditDes2() {
      this.$buefy.dialog.confirm({
        title: "Editar el Bien",
        message: `¿Esta seguro de editar esté bien?`,
        cancelText: "Cancelar",
        confirmText: "Guardar",
        type: "is-info",
        onConfirm: () => this.EditClickDes(),
      });
    },

    async check() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert(
          {
            message: 'Algunos campos presentan errores.', type: 'is-danger',
            hasIcon: true, icon: 'times-circle', iconPack: 'fa', ariaRole: 'alertdialog', ariaModal: true
          }
        )
      }
      else {
        this.confirmregister();
      }
    },


  },
  created() {
    this.get_user_actual();
    this.getfinancialsource();
  },
};
</script>  
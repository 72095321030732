<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Detalle del Tipo de inversión</h1>
      </div>

      <div v-if="investment_type" class="columns is-centered">
        <div class="column is-centered">

          <div class="columns">
            <div class="column">
              <b-field label="Nombre Tipo de inversión:">
                <b-input v-model="investment_type.alt_name_investment_type" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Descripción:">
                <b-input v-model="investment_type.detail" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>

        </div>
      </div>

      <div v-else>
        <p>Cargando</p>
      </div>
      <b-button tag="a" href="/investment_types" label="Volver">
      </b-button>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
  </section>
</template>


<script>
import axios from "axios";
export default {
  name:"show_investment_types",
  props: ["id",'queryType', 'modalHeader','investment_typeId'],
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Detalle Tipo de Inversión';
  },
  data() {
    return {
      investment_type: null,
      props: ["id"],
      isLoading: false
    };
  },
  methods: {
    async getinvestments() {
      this.isLoading = true
      const response = await axios.get("/investment_types/" + this.investment_typeId + ".json")
      this.investment_type = response.data
      this.isLoading = false
    },

  },
  async created() {
    this.isLoading = true
    await this.getinvestments()
    this.isLoading = false
  },
};
</script>


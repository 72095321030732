<template>
  <section>
    <div class="container is-fullhd my-5">
      <b-loading is-full-page v-model="loading"></b-loading>
      <div class="container box">
        <div class="content">
          <h1>Dashboard de Control</h1>
        </div>

        <div class="columns" v-show="showBotonHideFilters">
            <div class="column has-text-right" v-if="showFilters">
                <b-button label="Ocultar Filtros" type="is-primary is-light" icon-left="filter" @click="showFilters=false"/>
            </div>
            <div class="column has-text-right" v-else>
                <b-button label="Mostrar Filtros" type="is-primary" icon-left="filter" @click="showFilters=true"/>
            </div>
        </div>

        <div v-show="showFilters">
          <div class="columns">

            <div class="column">
              <b-field label="Usuario Autor">
                <multiselect style="is-info" v-model="userId"
                    :options="users.map((type) => type.id)"
                    :custom-label="(opt) => users.find((x) => x.id === opt).full_name" placeholder="Seleccione Usuario"
                    selectLabel="" selectLabelcolor="" selectedLabel="Seleccionado"
                    deselectLabel="Presione para deseleccionar" :allow-empty="true" >
                    <span slot="noResult">No se ha encontrado resultado</span>
                    <span slot="noOptions">No hay elementos</span>
                </multiselect>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Acción">
                <multiselect v-model="action" :options="actions" placeholder="Seleccione una Acción"
                  track-by="es" label="es" selectLabel="Presione enter para seleccionar" selectedLabel="Seleccionado"
                  deselectLabel="Presione enter para deseleccionar">
                </multiselect>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Tipo de Objeto">
                <multiselect v-model="typeObject" :options="typeObjects" placeholder="Seleccione una Acción"
                  track-by="es" label="es" selectLabel="Presione enter para seleccionar" selectedLabel="Seleccionado"
                  deselectLabel="Presione enter para deseleccionar">
                </multiselect>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Fecha desde">
                <b-datepicker placeholder="Click para Seleccionar..." v-model="dateIni"
                  icon="calendar-day" :icon-right="dateIni ? 'circle-xmark' : ''" icon-right-clickable
                  @icon-right-click="dateIni = null"/>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Fecha Hasta">
                <b-datepicker placeholder="Click para Seleccionar..." v-model="dateEnd"
                  icon="calendar-day" :icon-right="dateEnd ? 'circle-xmark' : ''" icon-right-clickable
                  @icon-right-click="dateEnd = null"/>
              </b-field>
            </div>
          </div>

          <div class="columns">
              <div class="column has-text-right">
                  <b-button label="Buscar" type="is-primary" icon-left="magnifying-glass" @click="showBotonHideFilters=true; showTable=true; applyFilters()"/>
              </div>
          </div>
        </div>

        <div v-if="showTable">
          <b-tabs type="is-boxed" position="is-centered">
            <b-tab-item label="Auditoría del Sistema de Inventario">
              <div class="columns">
                <div class="column">
                  <b-table :data="versions" :paginated="true" per-page="10" striped hoverable :current-page="currentPage"
                    :pagination-simple="false" pagination-position="bottom" default-sort-direction="asc"
                    sort-icon="arrow-up" sort-icon-size="is-small" default-sort="project.name" aria-next-label="Siguiente"
                    aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
                    <b-table-column field="id" label="N°" width="100" searchable sortable>
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.id }}
                      </template>
                    </b-table-column>

                    <b-table-column field="formatted_date" label="Fecha" searchable sortable :custom-search="searchCustomDate">
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..."
                          icon="calendar-days" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.formatted_date._isValid ? new Date(props.row.formatted_date).toLocaleString("es-CL") : '' }}
                      </template>
                    </b-table-column>

                    <b-table-column field="email" label="Usuario Autor" searchable sortable>
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Usuario..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.email }}
                      </template>
                    </b-table-column>

                    <b-table-column field="event" label="Acción" searchable sortable>
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Acción..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.event }}
                      </template>
                    </b-table-column>

                    <b-table-column field="item_type" label="Tipo de objeto" searchable sortable>
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Tipo..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.item_type }}
                      </template>
                    </b-table-column>

                    <b-table-column field="acciones" label="Acciones" v-slot="props" width="12em" centered>
                      <div class="columns is-mobile is-variable is-1-mobile">
                        <div class="column">
                          <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                            tag="a" :href="/versions/ + props.row.id" target="_blank" />
                        </div>
                      </div>
                    </b-table-column>

                    <template slot="empty">
                      <div class="columns is-centered">
                        <div class="column is-6 is-centered has-text-centered">
                          <b-message type="is-danger">
                            No se encontraron resultados.
                          </b-message>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import {searchDate} from "../../packs/utilities";
export default {
  name: "IndexPaperTrail",
  props: ["versionId"],
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Dashboard de Control';
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      post_versions: [],
      canCancel: ["escape"],
      selected: null,
      showTable: false,
      showBotonHideFilters: false,
      showFilters: true,
      isLoading: false,
      actions: [
        {es: 'Añadir', en: 'create'}, 
        {es: 'Actualizar', en: 'update'}, 
        {es: 'Destruir', en: 'destroy'}
      ],
      typeObjects: [
        {es: 'Bien', en: 'Good'}, 
        {es: 'Persona Encargada', en: 'DutyManager'}, 
        {es: 'Dependencia', en: 'Office'}, 
        {es: 'Edificio', en: 'Building'},
        {es: 'Cargos', en:'RecordCharge'},
        {es: 'Estado del Bien', en:'ConditionGood'},
      ],
      // Objetos a traer
      versions: [],
      audiroties: [],
      users: [],
      // Atributos a filtrar
      userId: null,
      dateIni: null,
      dateEnd: null,
      action: null,
      typeObject: null,
    };
  },

  methods: {
    async applyFilters() {
      this.isLoading = true;
      await axios
        .get("/versions/showfilterversions.json", {
          params: {
            item_type: this.typeObject ? this.typeObject.en : null,
            event: this.action ? this.action.en : null,
            whodunnit: this.userId ? this.userId.toString() : null,
            start_date: this.dateIni ? moment(this.dateIni).format("YYYY-MM-DD") : null,
            end_date: this.dateEnd ? moment(this.dateEnd).format("YYYY-MM-DD") : null,
          }
        })
        .then((response) => {
          this.versions = response.data;
          this.versions = this.versions.map(version => {
            var newVersion = version
            newVersion["formatted_date"] = moment(newVersion["formatted_date"], ["YYYY-MM-DD H:m:s","DD-MM-YYYY H:m:s"])
            return newVersion
          })
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            message: "Error en la obtención versiones",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            confirmText: "Ok",
          });
        })
        this.isLoading = false
    },
    async getUsers () {
      this.isLoading = true
      await axios
        .get("/users/showusers.json")
        .then((response) => {
          this.users = response.data.users_enabled
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            message: "Error en la obtención de usuarios",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            confirmText: "Ok",
          })
        })
        this.isLoading = false
    },

    searchCustomDate (row, input) {
      return searchDate (row.formatted_date, input)
    }
  },

  created() {
    this.getUsers()
  },

};
</script>

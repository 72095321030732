<template>
	<section>
		<div class="modal-card" style="width: 100%">
			<header class="modal-card-head">
				<p class="modal-card-title">{{ modalHeader }}</p>
				<button type="button" class="delete" @click="$emit('close')" />
			</header>
			<section class="modal-card-body" ref="newGood">
				<b-tabs type="is-boxed" position="is-centered">
					<b-tab-item label="Añadir Bien">
						<div class="columns is-centered">
							<div class="column is-centered">
								<ValidationObserver v-slot="{ handleSubmit }" ref="observer">
									<form @submit.prevent="handleSubmit(check())">
										<div class="columns">
											<div class="column">
												<b-field label="Categoría *">
													<ValidationProvider
														name="Categoría"
														rules="required"
														v-slot="{ errors }"
													>
														<multiselect
															style="is-info"
															v-model="aux_category"
															:options="categories.map((type) => type.id)"
															:custom-label="
																(opt) =>
																	categories.find((x) => x.id === opt)
																		.alt_name_category
															"
															placeholder="Seleccionar categoria"
															selectLabel=""
															selectLabelcolor=""
															selectedLabel="Seleccionado"
															deselectLabel="Presione para deseleccionar"
															:allow-empty="true"
														></multiselect>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>

											<div class="column">
												<b-field label="Nombre *">
													<ValidationProvider
														name="Nombre"
														rules="required|max:255"
														v-slot="{ errors }"
													>
														<b-input
															v-model="aux_namegood"
															placeholder="Ingrese el nombre del bien"
														></b-input>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>

											<div class="column">
												<b-field label="Número Interno *">
													<ValidationProvider
														name="Número Interno"
														rules="required|max:255"
														v-slot="{ errors }"
													>
														<b-input
															v-model="aux_internal_number"
															placeholder="Ingrese el número interno del bien"
														></b-input>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>

										<div class="columns">
											<div class="column">
												<b-field label="Marca *">
													<ValidationProvider
														name="Marca"
														rules="required"
														v-slot="{ errors }"
													>
														<multiselect
															style="is-info"
															v-model="aux_trademark"
															:options="trademarks.map((type) => type.id)"
															:custom-label="
																(opt) =>
																	trademarks.find((x) => x.id === opt)
																		.alt_name_trademark
															"
															placeholder="Seleccionar marca"
															selectLabel=""
															selectLabelcolor=""
															selectedLabel="Seleccionado"
															deselectLabel="Presione para deseleccionar"
															:allow-empty="true"
														></multiselect>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>

											<div class="column">
												<b-field label="Modelo">
													<ValidationProvider
														name="Modelo"
														rules="min:3|max:255"
														v-slot="{ errors }"
													>
														<b-input
															v-model="aux_model"
															placeholder="Ingrese el modelo del bien"
														></b-input>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>

											<div class="column">
												<b-field label="Número de Serie">
													<ValidationProvider
														name="Número de Serie"
														rules="max:255"
														v-slot="{ errors }"
													>
														<b-input
															v-model="aux_serie_number"
															placeholder="Ingrese el número de serie del bien"
														></b-input>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>

										<div class="columns">
											<div class="column">
												<b-field label="Fuente de Financiamiento *">
													<ValidationProvider
														name="Fuente de Financiamiento"
														rules="required"
														v-slot="{ errors }"
													>
														<multiselect
															style="is-info"
															v-model="aux_financial_source_id"
															:options="
																financial_sources.map((type) => type.id)
															"
															:custom-label="
																(opt) =>
																	financial_sources.find((x) => x.id === opt)
																		.alt_name_financial_source
															"
															placeholder="Seleccionar fuente de financiamiento"
															selectLabel=""
															selectLabelcolor=""
															selectedLabel="Seleccionado"
															deselectLabel="Presione para deseleccionar"
															:allow-empty="true"
														></multiselect>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>

											<div class="column">
												<b-field label="Tipo de Inversión *">
													<ValidationProvider
														name="Tipo de Inversión"
														rules="required"
														v-slot="{ errors }"
													>
														<multiselect
															style="is-info"
															v-model="aux_investment_type_id"
															:options="investment_types.map((type) => type.id)"
															:custom-label="
																(opt) =>
																	investment_types.find((x) => x.id === opt)
																		.alt_name_investment_type
															"
															placeholder="Seleccionar Tipo de inversión"
															selectLabel=""
															selectLabelcolor=""
															selectedLabel="Seleccionado"
															deselectLabel="Presione para deseleccionar"
															:allow-empty="true"
														></multiselect>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>

											<div class="column">
												<b-field label="Fecha de Compra *">
													<ValidationProvider
														name="Fecha de Compra"
														rules="required"
														v-slot="{ errors }"
													>
														<b-datepicker
															v-model="aux_date_purchase"
															locale="es-ES"
															placeholder="Seleccionar fecha de compra"
															trap-focus
														>
														</b-datepicker>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>

										<div class="columns">
											<div class="column">
												<b-field label="Monto Bruto">
													<ValidationProvider
														name="Monto Bruto"
														rules="min_value:0|max:255"
														v-slot="{ errors }"
													>
														<b-numberinput
															min="0"
															v-model="aux_amount"
															type="is-info"
															placeholder="Ingrese el monto bruto del bien"
														>
														</b-numberinput>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>
											<div class="column">
												<b-field label="Número Inventario Usach">
													<ValidationProvider
														name="Número Inventario Usach"
														rules="max:255"
														v-slot="{ errors }"
													>
														<b-input
															v-model="aux_identification_number_good"
															type="number"
															placeholder="Ingrese el número inventario del bien"
														></b-input>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>

											<div class="column">
												<b-field label="Fecha Chequeo *">
													<ValidationProvider
														name="Fecha Chequeo"
														rules="required"
														v-slot="{ errors }"
													>
														<b-datepicker
															v-model="aux_date_chekup"
															locale="es-ES"
															placeholder="Seleccionar fecha de ultimo Chequeo"
															trap-focus
														>
														</b-datepicker>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>

										<div class="columns">
											<div class="column">
												<b-field label="Estado *">
													<ValidationProvider
														name="Estado"
														rules="required|max:255"
														v-slot="{ errors }"
													>
														<multiselect
															style="is-info"
															v-model="aux_operative"
															:options="estados"
															placeholder="Seleccionar"
															selectLabel=""
															selectLabelcolor=""
															selectedLabel="Seleccionado"
															deselectLabel="Presione para deseleccionar"
															:allow-empty="true"
														></multiselect>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>

										<div class="columns">
											<div class="column">
												<b-field label="Descripción">
													<ValidationProvider
														name="Descripción"
														rules="min:3|max:255"
														v-slot="{ errors }"
													>
														<b-input
															v-model="aux_good_description"
															placeholder="Ingrese una descripción del bien o el nombre"
															type="textarea"
														></b-input>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>
											<div class="column">
												<b-field label="Comentario">
													<ValidationProvider
														name="Comentario"
														rules="min:3|max:255"
														v-slot="{ errors }"
													>
														<b-input
															v-model="good_comment"
															placeholder="Ingrese una descripción del bien o el nombre"
															type="textarea"
														></b-input>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>
									</form>
								</ValidationObserver>

								<div class="columns">
									<div class="column">
										<b-field>
											<b-radio-button
												v-model="showSelectorPerson"
												native-value=""
												type="is-info"
											>
												<b-icon pack="fas" icon="dolly" />
												<span>Enviar a Bodega</span>
											</b-radio-button>

											<b-radio-button
												v-model="showSelectorPerson"
												native-value="Asignar Persona"
												type="is-info"
											>
												<b-icon pack="fas" icon="user-plus" />
												<span>Asignar Persona Encargada</span>
											</b-radio-button>
										</b-field>
									</div>
								</div>

								<ValidationObserver
									v-slot="{ handleSubmit }"
									ref="observerDutyManager"
								>
									<form @submit.prevent="handleSubmit(check())">
										<div
											class="columns"
											v-show="showSelectorPerson === 'Asignar Persona'"
										>
											<div class="column">
												<b-field label="Unidad *">
													<ValidationProvider
														name="Unidad"
														rules="required"
														v-slot="{ errors }"
													>
														<multiselect
															style="is-info"
															v-model="aux_unit_id"
															:options="units.map((type) => type.id)"
															:custom-label="
																(opt) =>
																	units.find((x) => x.id === opt).alt_name_unit
															"
															placeholder="Seleccionar Unidad"
															selectLabel=""
															selectLabelcolor=""
															selectedLabel="Seleccionado"
															deselectLabel="Presione para deseleccionar"
															:allow-empty="true"
															:disabled="
																aux_duty_manager_id !== null ||
																aux_building_id !== null
															"
															@input="
																getdutymanagerbyunit(aux_unit_id);
																getbuildingbyunit(aux_unit_id);
															"
														>
														</multiselect>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>

											<div class="column">
												<b-field label="Persona Asociada Encargada *">
													<ValidationProvider
														name="Persona Asociada Encargada"
														rules="required"
														v-slot="{ errors }"
													>
														<multiselect
															v-model="aux_duty_manager_id"
															:options="managers.map((type) => type.id)"
															:custom-label="
																(opt) =>
																	managers.find((x) => x.id === opt)
																		.name_duty_manager
															"
															placeholder="Seleccionar Persona encargada"
															selectLabel="Presione para seleccionar"
															selectedLabel="Seleccionado"
															deselectLabel="Presione para deseleccionar"
															:allow-empty="true"
															:disabled="aux_unit_id == null"
														>
														</multiselect>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>

											<div class="column">
												<b-field label="Fecha de Asignación *">
													<ValidationProvider
														name="Fecha de Asignación"
														rules="required"
														v-slot="{ errors }"
													>
														<b-datepicker
															v-model="assignment_date"
															locale="es-ES"
															placeholder="Seleccionar fecha de Asignación"
															trap-focus
														>
														</b-datepicker>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>

										<div
											class="columns"
											v-show="showSelectorPerson === 'Asignar Persona'"
										>
											<div class="column">
												<b-field label="Edificio *">
													<ValidationProvider
														name="Edificio"
														rules="required"
														v-slot="{ errors }"
													>
														<multiselect
															v-model="aux_building_id"
															:options="buildings.map((type) => type.id)"
															:custom-label="
																(opt) =>
																	buildings.find((x) => x.id === opt)
																		.alt_name_building
															"
															placeholder="Seleccionar"
															selectLabel="Presione para seleccionar"
															selectedLabel="Seleccionado"
															deselectLabel="Presione para deseleccionar"
															:allow-empty="true"
															:disabled="
																aux_unit_id == null || aux_office_id !== null
															"
															@input="getofficebybuilding(aux_building_id)"
														>
														</multiselect>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>

											<div class="column">
												<b-field label="Dependencia *">
													<ValidationProvider
														name="Dependencia"
														rules="required"
														v-slot="{ errors }"
													>
														<multiselect
															v-model="aux_office_id"
															:options="offices.map((type) => type.id)"
															:custom-label="
																(opt) =>
																	offices.find((x) => x.id === opt)
																		.alt_name_office
															"
															placeholder="Seleccionar dependencia"
															selectLabel="Presione para seleccionar"
															selectedLabel="Seleccionado"
															deselectLabel="Presione para deseleccionar"
															:allow-empty="true"
															:disabled="aux_building_id == null"
														>
														</multiselect>
														<span class="validation-alert">{{
															errors[0]
														}}</span>
													</ValidationProvider>
												</b-field>
											</div>
										</div>
									</form>
								</ValidationObserver>

								<div class="columns is-mobile">
									<div class="column">
										<b-button label="Cerrar" @click="$emit('close')" />
									</div>

									<div class="column has-text-right">
										<b-button
											label="Añadir Bien"
											icon-left="floppy-disk"
											type="is-success"
											@click="check()"
										/>
									</div>
								</div>
							</div>
						</div>
					</b-tab-item>

					<b-tab-item label="Subida masiva">
						<div class="content">
							<h2>Seleccionar archivo</h2>
						</div>

						<b-field class="file">
							<b-upload v-model="file" expanded>
								<a class="button is-info is-fullwidth">
									<b-icon icon="upload"></b-icon>
									<span>{{ file.name || "Subir Archivo" }}</span>
								</a>
							</b-upload>
						</b-field>

						<div class="columns is-mobile">
							<div class="column">
								<b-button label="Cerrar" @click="$emit('close')" />
							</div>

							<div class="column has-text-right">
								<b-button
									type="is-info"
									icon-left="upload"
									@click="import_file"
									label="Importar"
								/>
							</div>
						</div>
					</b-tab-item>
				</b-tabs>
			</section>
		</div>
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
	</section>
</template>

<script>
import axios from "axios";
export default {
	name: "new_good",
	props: ["queryType", "modalHeader"],
	data() {
		return {
			selected: null,
			goods: [],
			offices: [],
			units: [],
			financial_sources: [],
			investment_types: [],
			managers: [],
			buildings: [],
			dropFiles: [],
			trademarks: [],
			categories: [],
			estados: ["Operativo", "No Operativo"],
			aux_namegood: null,
			aux_good_description: null,
			aux_identification_number_good: null,
			aux_serie_number: null,
			aux_internal_number: null,
			aux_location_good: null,
			aux_duty_manager_id: null,
			aux_office_id: null,
			aux_unit_id: null,
			aux_building_id: null,
			aux_financial_source_id: null,
			aux_investment_type_id: null,
			aux_amount: null,
			aux_date_purchase: new Date(),
			aux_financial_source: null,
			aux_investment_type: null,
			aux_operative: null,
			aux_model: null,
			aux_date_chekup: new Date(),
			aux_trademark: null,
			aux_category: null,
			isLoading: false,
			aux_activegood: true,
			last_good: null,
			actual_id: null,
			current: null,
			showSelectorPerson: "",
			assignment_date: new Date(),
			good_comment: null,

			//IMPORTS
			import_type: "Propiedad Intelectual",
			file: { name: "" },
			dropFiles: [],
			isLoading: false,
			hojas: [],
		};
	},
	methods: {
		async getfinancialsource() {
			const response = await axios.get(
				"/financial_sources/showfinancialsources.json"
			);
			this.financial_sources = response.data.financial_sources_enabled;
		},

		async get_trademarks() {
			const response = await axios.get("/trademarks/showtrademarks.json");
			this.trademarks = response.data.trademarks_enabled;
		},

		async get_categories() {
			const response = await axios.get("/categories/showcategories.json");
			this.categories = response.data.categories_enabled;
		},

		async getinvestmenttype() {
			const response = await axios.get(
				"/investment_types/showinvestmenttypes.json"
			);
			this.investment_types = response.data.investment_types_enabled;
		},

		async getunitactive() {
			const response = await axios.get("/units/showactiveunit.json");
			this.units = response.data;
		},

		async getdutymanagerbyunit(item) {
			this.isLoading = true;
			const response = await axios.get(
				"/duty_managers/showdutymanagerunit/" + item + ".json"
			);
			this.managers = response.data;
			this.isLoading = false;
		},

		async getbuildingbyunit(item) {
			this.isLoading = true;
			const response = await axios.get(
				"/buildings/showbuildingunit/" + item + ".json"
			);
			this.buildings = response.data;
			this.isLoading = false;
		},

		async getofficebybuilding(item) {
			this.isLoading = true;
			const response = await axios.get(
				"/offices/showofficebuilding/" + item + ".json"
			);
			this.offices = response.data;
			this.isLoading = false;
		},

		async createClick() {
			this.isLoading = true;
			if (this.showSelectorPerson === "Asignar Persona") {
				await axios
					.post("/goods.json", {
						namegood: this.aux_namegood,
						good_description: this.aux_good_description,
						identification_number_good: this.aux_identification_number_good,
						serie_number: this.aux_serie_number,
						model: this.aux_model,
						duty_manager_id: this.aux_duty_manager_id,
						office_id: this.aux_office_id,
						activegood: this.aux_activegood,
						amount: this.aux_amount,
						date_purchase: this.aux_date_purchase,
						financial_source_id: this.aux_financial_source_id,
						investment_type_id: this.aux_investment_type_id,
						location_good: this.aux_location_good,
						trademark_id: this.aux_trademark,
						category_id: this.aux_category,
						date_checkup: this.aux_date_chekup,
						operative: this.aux_operative,
						internal_number: this.aux_internal_number,
						hasPersonCharge: true,
						assignment_date: this.assignment_date,
						good_comment: this.good_comment,
					})
					.then((response) => {
						this.$buefy.dialog.alert({
							title: "Logrado",
							message: "Se ha agregado correctamente el Bien al sistema.",
							type: "is-success",
							hasIcon: true,
							icon: "circle-check",
							iconPack: "fas",
							ariaRole: "alertdialog",
							ariaModal: true,
						});
						this.$emit("refresh");
						this.$emit("close");
					})
					.catch((error) => {
						this.$buefy.dialog.alert({
							title: "Error",
							message:
								"Hubo un error al ingresar los datos del Bien, favor revisar los errores. <br>Errores:<br>" +
								error.response.data,
							type: "is-danger",
							hasIcon: true,
							icon: "times-circle",
							iconPack: "fa",
							ariaRole: "alertdialog",
							ariaModal: true,
						});
					});
			} else {
				await axios
					.get("/offices/store.json")
					.then((response) => {
						axios
							.post("/goods.json", {
								namegood: this.aux_namegood,
								good_description: this.aux_good_description,
								identification_number_good: this.aux_identification_number_good,
								serie_number: this.aux_serie_number,
								model: this.aux_model,
								duty_manager_id: response.data.duty_manager.id,
								office_id: response.data.store.id,
								activegood: this.aux_activegood,
								amount: this.aux_amount,
								date_purchase: this.aux_date_purchase,
								financial_source_id: this.aux_financial_source_id,
								investment_type_id: this.aux_investment_type_id,
								location_good: "Portería Bodega",
								trademark_id: this.aux_trademark,
								category_id: this.aux_category,
								date_checkup: this.aux_date_chekup,
								operative: this.aux_operative,
								internal_number: this.aux_internal_number,
								assignment_date: this.assignment_date,
								good_comment: this.good_comment,
							})
							.then((response) => {
								this.$buefy.dialog.alert({
									title: "Logrado",
									message: "Se ha agregado correctamente el Bien al sistema.",
									type: "is-success",
									hasIcon: true,
									icon: "circle-check",
									iconPack: "fas",
									ariaRole: "alertdialog",
									ariaModal: true,
								});
								this.$emit("refresh");
								this.$emit("close");
							})
							.catch((error) => {
								this.$buefy.dialog.alert({
									title: "Error",
									message:
										"Hubo un error al ingresar los datos del Bien, favor revisar los errores. <br>Errores:<br>" +
										error.response.data,
									type: "is-danger",
									hasIcon: true,
									icon: "times-circle",
									iconPack: "fa",
									ariaRole: "alertdialog",
									ariaModal: true,
								});
							});
					})
					.catch((error) => {
						let errorMsg = "";
						if (error.response) {
							if (error.response.status === 403) {
								errorMsg = "No tiene permisos para obtener los datos de Bodega";
							} else {
								errorMsg =
									"Hubo errores al obtener los datos de Bodega, favor de revisar errores.<br>Errores<br>" +
									error.response.data;
							}
						} else {
							errorMsg =
								"Hubo errores al obtener los datos de Bodega, intente más tarde.";
						}

						this.$buefy.dialog.alert({
							title: "Error",
							message: errorMsg,
							type: "is-danger",
							hasIcon: true,
							icon: "times-circle",
							iconPack: "fa",
							ariaRole: "alertdialog",
							ariaModal: true,
						});
					});
			}
			this.isLoading = false;
		},

		async get_user_actual() {
			const response = await axios.get("/duty_managers/currentuser.json");
			this.current = response.data.current_user;
		},

		confirmregister() {
			this.$buefy.dialog.confirm({
				title: "Registrar el Bien",
				message: `¿Esta seguro de registrar este bien?`,
				cancelText: "Cancelar",
				confirmText: "Registrar",
				type: "is-info",
				onConfirm: () => this.createClick(),
			});
		},
		async check() {
			const valid = await this.$refs.observer.validate();
			if (!valid) {
				this.$buefy.dialog.alert({
					title: "Error",
					message: "Algunos campos presentan errores.",
					type: "is-danger",
					hasIcon: true,
					icon: "times-circle",
					iconPack: "fa",
					ariaRole: "alertdialog",
					ariaModal: true,
				});
			} else {
				if (this.showSelectorPerson === "Asignar Persona") {
					const validDutyManager =
						await this.$refs.observerDutyManager.validate();

					if (!validDutyManager) {
						this.$buefy.dialog.alert({
							title: "Error",
							message: "Algunos campos de Persona Encargada presentan errores.",
							type: "is-danger",
							hasIcon: true,
							icon: "times-circle",
							iconPack: "fa",
							ariaRole: "alertdialog",
							ariaModal: true,
						});
					} else {
						this.confirmregister();
					}
				} else {
					this.confirmregister();
				}
			}
		},

		deleteDropFile(index) {
			this.dropFiles.splice(index, 1);
		},

		//IMPORTS

		deleteDropFile(index) {
			this.dropFiles.splice(index, 1);
		},
		async import_file() {
			this.isLoading = true;
			var url = "";
			url = "/goods/administrativo/import_good.json";
			let formData = new FormData();
			formData.append("file", this.file);
			await axios
				.post(url, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((response) => {
					if (response.data["errores"]) {
						this.$buefy.dialog.alert({
							title: "Error",
							message:
								'Algunos datos del archivo "' +
								this.file.name +
								'" no han podido ser importados, por favor, revisar errores en log.',
							confirmText: "Ok",
							type: "is-danger",
							hasIcon: true,
							icon: "times-circle",
							iconPack: "fa",
							ariaRole: "alertdialog",
							ariaModal: true,
						});
						this.$emit("refresh");
						this.$emit("close");
					} else {
						this.import_type = "";
						this.$buefy.dialog.alert({
							title: "Logrado",
							message:
								'Su archivo "' +
								this.file.name +
								'" ha sido importado con éxito.',
							confirmText: "Ok",
							type: "is-success",
							hasIcon: true,
							icon: "circle-check",
							iconPack: "fas",
							ariaRole: "alertdialog",
							ariaModal: true,
						});
						this.file = { name: "" };
						this.isLoading = false;
						this.$emit("refresh");
						this.$emit("close");
					}
				})
				.catch((error) => {
					var showError = " Favor intentarlo más tarde.";
					if (error.response) {
						showError = " Favor revisar errores.<br>Errores:<br>";
						for (const propiedad in error.response.data) {
							if (error.response.data.hasOwnProperty(propiedad)) {
								showError += error.response.data[propiedad].join(", ") + "<br>";
							}
						}
					}
					this.$buefy.dialog.alert({
						title: "Error",
						message:
							"Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato." +
							showError,
						type: "is-danger",
						hasIcon: true,
						icon: "times-circle",
						iconPack: "fa",
						ariaRole: "alertdialog",
						ariaModal: true,
					});
					this.isLoading = false;
				});
		},
	},
	async created() {
		this.isLoading = true;
		await this.getunitactive();
		await this.getfinancialsource();
		await this.getinvestmenttype();
		await this.get_user_actual();
		await this.get_trademarks();
		await this.get_categories();
		this.isLoading = false;
	},
};
</script>

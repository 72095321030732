<template>
    <section>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer1">
            <form @submit.prevent="handleSubmit(checkEditData())">
                <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ modalHeader }}</p>
                        <button type="button" class="delete" @click="$emit('close')" />
                    </header>

                    <section class="modal-card-body" ref="editBuilding">
                        <div v-if="building">
                            <div class="columns is-centered">
                                <div class="column is-centered">

                                    <div class="columns my-0">
                                        <div class="column">
                                            <b-field label="Nombre *">
                                                <ValidationProvider name="Nombre" rules="required|max:255"
                                                    v-slot="{ errors }">
                                                    <b-input v-model="building.alt_name_building"
                                                        placeholder="Ingrese el nombre del edificio" />
                                                    <span class="validation-alert">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>

                                        <div class="column">
                                            <b-field label="Ubicación *">
                                                <ValidationProvider name="Ubicación" rules="required|max:255"
                                                    v-slot="{ errors }">
                                                    <b-input v-model="building.location_building"
                                                        placeholder="Ingrese la ubicación del edificio" />
                                                    <span class="validation-alert">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>

                                        <div class="column">
                                            <b-field label="Número edificio">
                                                <ValidationProvider name="Número edificio" rules="required|max:255"
                                                    v-slot="{ errors }">
                                                    <b-input v-model="building.identification_number_building"
                                                        type="number"
                                                        placeholder="Ingrese el numero de identificación edificio" />
                                                    <span class="validation-alert">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="columns is-mobile">

                                <div class="column">
                                    <b-button label="Cerrar" @click="$emit('close')" />
                                </div>

                                <div class="column has-text-right">
                                    <p class="control">
                                        <b-button label="Editar Edificio" icon-left="save" type="is-info"
                                            @click="checkEditData()" />
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div v-else>
                            <p>Cargando</p>
                        </div>
                    </section>
                    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
                </div>
            </form>
        </ValidationObserver>
    </section>
</template>


<script>
import axios from "axios";
import IndexBuildings from "./index_building.vue";
export default {
    name: "edit_building",
    props: ["queryType", "modalHeader", "buildingId", "id"],
    components: {
        "index-building": IndexBuildings,
    },

    data() {
        return {
            building: null,
            buildings: [],
            props: ["id"],
            units: [],
            managers: [],
            aux_unit_id: null,
            aux_duty_manager_id: null,
            isLoading: false,
        };
    },
    methods: {
        async getbuilding() {
            this.isLoading = true
            const response = await axios.get("/buildings/" + this.id + ".json")
            this.building = response.data
            this.isLoading = false
        },

        async getunitactive() {
            this.isLoading = true
            const response = await axios.get("/units/showactiveunit.json")
            this.units = response.data
            this.isLoading = false
        },

        async getdutymanagerbyunit(item) {
            const response = await axios.get(
                "/duty_managers/showdutymanagerunit/" +
                item +
                ".json"
            );
            this.managers = response.data;
        },

        async getbuildingbyunit(item) {
            const response = await axios.get(
                "/buildings/showbuildingunit/" + item + ".json"
            );
            this.buildings = response.data;
        },

        async getofficebybuilding(item) {
            const response = await axios.get(
                "/offices/showofficebuilding/" + item + ".json"
            );
            this.offices = response.data;
        },


        //Funciones para editar datos del edificio
        async checkEditData() {
            const valid = await this.$refs.observer1.validate()
            if (!valid) {
                this.$buefy.dialog.alert(
                    {
                        title: 'Error',
                        message: 'Algunos campos presentan errores.', 
                        type: 'is-danger',
                        hasIcon: true, 
                        icon: 'times-circle', 
                        iconPack: 'fa', 
                        ariaRole: 'alertdialog', 
                        ariaModal: true
                    }
                )
            }
            else {
                this.$buefy.dialog.confirm({
                    title: "Editar el edificio",
                    message: `¿Esta seguro de editar el edificio?`,
                    cancelText: "Cancelar",
                    confirmText: "Guardar",
                    type: "is-info",
                    onConfirm: () => this.EditClick(),
                });
            }
        },

        async EditClick() {
            this.isLoading = true
            await axios.put("/buildings/" + this.id + ".json", {
                alt_name_building: this.building.alt_name_building,
                identification_number_building: this.building.identification_number_building,
                location_building: this.building.location_building,
            })
            .then(response => {
                this.$buefy.dialog.alert({
                title: 'Logrado',
                message: 'Se ha agregado correctamente el Edificio al sistema.',
                type: 'is-success',
                hasIcon: true,
                icon: 'circle-check',
                iconPack: 'fas',
                ariaRole: 'alertdialog',
                ariaModal: true
                })
                this.$emit('refresh')
                this.$emit('close')
            })
            .catch(error => {
                this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo un error al ingresar los datos del Edificio, favor revisar los errores.<br>Errores:<br>' + error.response.data,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
                })
            })
            
            this.isLoading = false
        },


        //Funciones para editar cargos del edificio

        async checkCharge() {
            const valid = await this.$refs.observer2.validate()
            if (!valid) {
                this.$buefy.dialog.alert(
                    {
                        message: 'Algunos campos presentan errores.', type: 'is-danger',
                        hasIcon: true, icon: 'times-circle', iconPack: 'fa', ariaRole: 'alertdialog', ariaModal: true
                    }
                )
            }
            else {
                this.confirmeditDes();
            }
        },

        confirmeditDes() {
            this.$buefy.dialog.confirm({
                title: "Editar el edificio",
                message: `¿Esta seguro de editar el edificio?`,
                cancelText: "Cancelar",
                confirmText: "Guardar",
                type: "is-info",
                onConfirm: () => this.getofficebybuilding(),
            });
        },

        async getofficebybuilding() {
            const response = await axios.get(
                "/offices/showofficebuilding/" + this.id + ".json"
            );
            this.offices_building = response.data;
            //
            //if (this.offices_building.length == 0) {
            this.EditClickDes();
            //}

            //else {
            //    this.$buefy.dialog.alert({
            //        title: 'No se puede cambiar designación',
            //        type: 'is-danger',
            //        message: 'Existen dependencias designados en el edificio',
            //        confirmText: 'Aceptar'
            //    })
            //}

        },
        async EditClickDes() {
            this.isLoading = true
            const response = await axios.put("/buildings/" + this.id + ".json", {
                duty_manager_id: this.aux_duty_manager_id,
                unit_id: this.aux_unit_id,
                activegood: 1,
            });
            // window.location.href = "/buildings";
            this.$emit('refresh', true)
            this.$emit('close')
            this.isLoading = false
        },


    },
    async created() {
        this.isLoading = true
        await this.getbuilding()
        await this.getunitactive()
        this.isLoading = false
    },
};
</script>





<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmedit())">
        <div class="modal-card" style="width: 62em; height: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="editSupply">
            <b-tabs>
              <div class="box">
                <div v-if="supply" class="columns is-centered">
                  <div class="column is-centered">
                    <div class="center"><h1>Datos del insumo</h1></div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="Nombre de Insumo">
                          <ValidationProvider
                            name="Nombre"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-input
                              v-model="supply.name_supplie"
                              placeholder="Ingrese el nombre del insumo"
                            ></b-input>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="N° Identificación">
                          <ValidationProvider
                            name="Identificación"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-input
                              v-model="supply.identification_number_supplie"
                              placeholder="Ingrese el numero de identificación del insumo"
                            ></b-input>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="Categoria">
                          <ValidationProvider
                            name="nombre"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <multiselect
                              v-model="aux_category_id"
                              :options="categories.map((type) => type.id)"
                              :custom-label="
                                (opt) =>
                                  categories.find((x) => x.id === opt)
                                    .name_category
                              "
                              placeholder="Seleccionar categoria"
                              selectedLabel="Seleccionado"
                              selectLabel=""
                              :allow-empty="true"
                            >
                            </multiselect>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>

                      <div class="column">
                        <b-field label="Marca">
                          <ValidationProvider
                            name="nombre"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <multiselect
                              v-model="aux_trademark_id"
                              :options="trademarks.map((type) => type.id)"
                              :custom-label="
                                (opt) =>
                                  trademarks.find((x) => x.id === opt)
                                    .name_trademark
                              "
                              placeholder="Seleccionar Marca"
                              selectedLabel="Seleccionado"
                              selectLabel=""
                              :allow-empty="true"
                            >
                            </multiselect>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>

                    <b-field grouped position="is-right">
                      <p class="control">
                        <b-button
                          label="Guardar"
                          type="is-info"
                          @click="check()"
                        />
                      </p>
                    </b-field>
                  </div>
                </div>

                <div v-else>
                  <p>Loading</p>
                </div>
              </div>
            </b-tabs>
          </section>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
import IndexSuplies from "./index_supply.vue";
import moment from "moment";
export default {
  name: "edit_unit",
  props: ["queryType", "modalHeader", "supplyId", "id"],
  components: {
    "index-supply": IndexSuplies,
  },

  data() {
    return {
      supply: null,
      supplies: [],
      trademarks: [],
      categories: [],
      aux_category_id: null,
      aux_trademark_id: null,
      props: ["id"],
    };
  },
  methods: {
    async get_trademarks() {
      const response = await axios.get("/trademarks.json");
      this.trademarks = response.data;
      this.get_categories();
    },

    async get_categories() {
      const response = await axios.get("/categories.json");
      this.categories = response.data;
      this.getsupply();
    },

    async getsupply() {
      const response = await axios.get("/supplies/" + this.id + ".json");
      this.loading = true;
      this.supply = response.data;
      this.aux_trademark_id = this.supply.trademark_id;
      this.aux_category_id = this.supply.category_id;
    },

    async EditClick() {
      const response = await axios.put("/supplies/" + this.id + ".json", {
        name_supplie: this.supply.name_supplie,
        identification_number_supplie:
          this.supply.identification_number_supplie,
        trademark_id: this.aux_trademark_id,
        category_id: this.aux_category_id,
      });
      window.location.href = "/supplies/" + this.id;
    },

    confirmedit() {
      this.$buefy.dialog.confirm({
        title: "Editar el insumo",
        message: `¿Esta seguro de editar el insumo?`,
        cancelText: "Cancelar",
        confirmText: "Guardar",
        type: "is-info",
        onConfirm: () => this.EditClick(),
      });
    },
    async check() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.confirmedit();
      }
    },
  },
  created() {
    this.get_trademarks();
  },
};
</script>

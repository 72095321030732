<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmregister())">
        <div class="modal-card" style="width: 100%">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="newOffice">
            <b-tabs type="is-boxed" position="is-centered">
              <b-tab-item label="Añadir Dependencia">
                <div class="columns is-centered">
                  <div class="column is-centered">
                    <div class="columns">
                      <div class="column">
                        <b-field label="Nombre de la Dependencia *">
                          <ValidationProvider name="Nombre de la Dependencia" rules="required|max:255" v-slot="{ errors }">
                            <b-input v-model="aux_name_office"
                              placeholder="Ingrese el nombre de la dependencia o como se identifica"></b-input>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Ubicación *">
                          <ValidationProvider name="Ubicación" rules="required|max:255" v-slot="{ errors }">
                            <b-input v-model="aux_location"
                              placeholder="Ingrese la ubicación de la dependencia (Edificio, en que piso se encuentra..)"></b-input>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="N° Identificación *">
                          <ValidationProvider name="N° Identificación" rules="required|max:255|min_value:1" v-slot="{ errors }">
                            <b-input v-model="aux_identification_number_office" type="number"
                              placeholder="Ingrese el numero de identificación de la dependencia" />
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Identificación Administración Campus *">
                          <ValidationProvider name="Identificación Administración Campus" rules="required|max:255|min_value:1" v-slot="{ errors }">
                            <b-input v-model="aux_campus_identification" type="number"
                              placeholder="Ingrese el numero de identificación de la dependencia" />
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="Horas Semanales Disponibles *">
                          <ValidationProvider name="Horas Semanales Disponibles" rules="required|max:255|min_value:1|max_value:100" v-slot="{ errors }">
                            <b-numberinput min="1" max="100" v-model="aux_hours_available"
                              placeholder="Ingrese la cantidad de horas semanales disponibles de la dependencia">
                            </b-numberinput>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>

                      <div class="column">
                        <b-field label="Metros Cuadrados *">
                          <ValidationProvider name="Metros Cuadrados" rules="required|max:255|min_value:1|max_value:100" v-slot="{ errors }">
                            <b-numberinput min="1" max="100" v-model="aux_square_meter"
                              placeholder="Ingrese cuantos metro cuadrados tiene la dependencia">
                            </b-numberinput>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">

                      <div class="column">
                        <b-field label="Unidad *">
                          <ValidationProvider name="Unidad" rules="required" v-slot="{ errors }">
                            <multiselect v-model="aux_unit_id" :options="units.map((type) => type.id)" :custom-label="(opt) =>
                              units.find((x) => x.id === opt).name_unit" placeholder="Seleccionar Unidad"
                              selectLabel="" selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar"
                              :allow-empty="true" :disabled="aux_duty_manager_id !== null || aux_building_id !== null"
                              @input="getdutymanagerbyunit(aux_unit_id); getbuildingbyunit(aux_unit_id);">
                            </multiselect>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>

                      <div class="column">
                        <b-field label="Persona Encargada *">
                          <ValidationProvider name="Persona Encargada" rules="required" v-slot="{ errors }">
                            <multiselect v-model="aux_duty_manager_id" :options="managers.map((type) => type.id)"
                              :custom-label="(opt) =>
                                managers.find((x) => x.id === opt)
                                  .name_duty_manager
                                " placeholder="Seleccionar Persona encargada" selectLabel="Presione para seleccionar"
                              selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar" :allow-empty="true"
                              :disabled="aux_unit_id == null">
                            </multiselect>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>

                      <div class="column">
                        <b-field label="Edificio *">
                          <ValidationProvider name="Edificio" rules="required" v-slot="{ errors }">
                            <multiselect v-model="aux_building_id" :options="buildings.map((type) => type.id)"
                              :custom-label="(opt) =>
                                buildings.find((x) => x.id === opt)
                                  .name_building
                                " placeholder="Seleccionar Unidad" selectedLabel="Seleccionado"
                              deselectLabel="Presione para deseleccionar" :allow-empty="true"
                              :disabled="aux_unit_id == null">
                            </multiselect>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>

                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="Descripción de la Dependencia *">
                          <ValidationProvider name="Descripción de la Dependencia" rules="required|max:255" v-slot="{ errors }">
                            <b-input v-model="aux_office_description" type="textarea"
                              placeholder="Ingrese la descripción de la dependencia, indicando cual es su uso"></b-input>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns is-mobile">

                      <div class="column">
                        <b-button label="Cerrar" @click="$emit('close')" />
                      </div>

                      <div class="column has-text-right">
                        <b-button label="Añadir Dependencia" icon-left="save" type="is-success" @click="check()" />
                      </div>

                    </div>
                  </div>
                </div>
              </b-tab-item>

              <b-tab-item label="Subida masiva">
                <div class="content">
                  <h2>Seleccionar archivo</h2>
                </div>

                <b-field class="file">
                  <b-upload v-model="file" expanded>
                    <a class="button is-info is-fullwidth">
                      <b-icon icon="upload"></b-icon>
                      <span>{{ file.name || "Subir Archivo" }}</span>
                    </a>
                  </b-upload>
                </b-field>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" type="is-light" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <b-button type="is-info"
                      @click="import_file"
                      icon-left="upload"
                      label="Importar"/>
                  </div>

                </div>
              </b-tab-item>
            </b-tabs>
          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "new_office",
  props: ["queryType", "modalHeader"],
  data() {
    return {
      selected: null,
      units: [],
      buildings: [],
      managers: [],
      offices: [],
      aux_name_office: null,
      aux_identification_number_office: null,
      aux_location: null,
      aux_office_description: null,
      aux_hours_available: null,
      aux_square_meter: null,
      aux_campus_identification: null,
      aux_duty_manager_id: null,
      aux_building_id: null,
      aux_unit_id: null,
      aux_active: true,

      //IMPORTS
      import_type: "Propiedad Intelectual",
      file: { name: "" },
      dropFiles: [],
      isLoading: false,
      errores: [],
      file_name_errors: "",
      cabeceras: [],
      hojas: [],
      errorFile: { name: "" },
    };
  },
  methods: {
    async getunit() {
      this.isLoading = true
      const response = await axios.get("/units/showactiveunit.json")
      this.units = response.data
      this.isLoading = false
    },

    async getdutymanagerbyunit(item) {
      this.isLoading = true
      const response = await axios.get(
        "/duty_managers/showdutymanagerunit/" +
        item +
        ".json"
      )
      this.managers = response.data
      this.isLoading = false
    },

    async getbuildingbyunit(item) {
      this.isLoading = true
      const response = await axios.get(
        "/buildings/showbuildingunit/" + item + ".json"
      )
      this.buildings = response.data
      this.isLoading = false
    },

    async createClick() {
      this.isLoading = true
      await axios.post("/offices.json", {
        name_office: this.aux_name_office,
        identification_number_office: this.aux_identification_number_office,
        location: this.aux_location,
        office_description: this.aux_office_description,
        hours_available: this.aux_hours_available,
        square_meter: this.aux_square_meter,
        campus_identification: this.aux_campus_identification,
        duty_manager_id: this.aux_duty_manager_id,
        building_id: this.aux_building_id,
        active_office: this.aux_active,
      })
      .then(reponse => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha agregado correctamente la Oficina al sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al ingresar los datos de la Oficina, favor revisar los errores.<br>Errores:<br>' + error.response.data,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },
    confirmregister() {
      this.$buefy.dialog.confirm({
        title: "Registrar Dependencia",
        message: `¿Esta seguro de registrar esta dependencia?`,
        cancelText: "Cancelar",
        confirmText: "Registrar",
        type: "is-info",
        onConfirm: () => this.createClick(),
      });
    },
    async check() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.confirmregister();
      }
    },
    //IMPORTS

    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    import_file() {
      this.isLoading = true;
      var url = "";
      url = "/offices/massive_office/import_office.json";
      let formData = new FormData();
      formData.append("file", this.file);
      this.errorFile = this.file;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data["errores"].length > 0) {
            this.errores = response.data["errores"];
            this.$buefy.dialog.alert({
              title: 'Error',
              message:
                'Algunos datos del archivo "' +
                this.file.name +
                '" no han podido ser importados, por favor, revisar errores. <br>Errores:<br>' + this.errores,
              confirmText: "Ok",
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
            this.file_name_errors = this.file.name;
            this.errores = response.data["errores"];
            this.cabeceras = response.data["cabeceras"];
            if (this.import_type == "Convenios") {
              this.hojas = response.data["sheets"];
            } else {
              this.hojas = [];
            }
          } else {
            this.import_type = "";
            this.$buefy.dialog.alert({
              title: 'Logrado',
              message:
                'Su archivo "' +
                this.file.name +
                '" ha sido importado con éxito',
              confirmText: "Ok",
              type: 'is-success',
              hasIcon: true,
              icon: 'circle-check',
              iconPack: 'fas',
              ariaRole: 'alertdialog',
              ariaModal: true
            });
            this.file = { name: "" };
            this.errores = [];
            this.file_name_errors = "";
            this.$emit('refresh')
            this.$emit('close')
          }
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: "Error",
            message:
              "Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          })
        })
        this.isLoading = false
    },
    importErrors() {
      let vm = this;
      vm.isLoading = true;
      axios
        .post(
          "/load-data/export-errors.xlsx",
          { cabeceras: vm.cabeceras, errores: vm.errores, hojas: vm.hojas },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          vm.isLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Errores importacion masiva de datos " +
            vm.errorFile.name.replace(".xlsx", "") +
            " " +
            new Date().toLocaleDateString() +
            ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          vm.isLoading = false;
          vm.$buefy.notification.open({
            message: "Hubo un error en tu solicitud",
            type: "is-danger",
          });
        });
    },
  },
  async created() {
    this.isLoading = true
    await this.getunit()
    this.isLoading = false
  },
};
</script>

<template>
	<section>
		<br />
		<div class="container box">
			<div class="content">
				<h1>Registro de Bien</h1>
			</div>

			<div class="columns is-centered">
				<div class="column is-centered">
					<ValidationObserver v-slot="{ handleSubmit }" ref="observer">
						<form @submit.prevent="handleSubmit(check())">
							<div class="columns">
								<div class="column">
									<b-field label="Categoría *">
										<ValidationProvider
											name="Categoría"
											rules="required"
											v-slot="{ errors }"
										>
											<multiselect
												style="is-info"
												v-model="aux_category"
												:options="categories.map((type) => type.id)"
												:custom-label="
													(opt) =>
														categories.find((x) => x.id === opt)
															.alt_name_category
												"
												placeholder="Seleccionar categoria"
												selectLabel=""
												selectLabelcolor=""
												selectedLabel="Seleccionado"
												deselectLabel="Presione para deseleccionar"
												:allow-empty="true"
											></multiselect>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>

								<div class="column">
									<b-field label="Nombre *">
										<ValidationProvider
											name="Nombre"
											rules="required|max:255"
											v-slot="{ errors }"
										>
											<b-input
												v-model="aux_namegood"
												placeholder="Ingrese el nombre del bien"
											></b-input>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>

								<div class="column">
									<b-field label="Número Interno *">
										<ValidationProvider
											name="Número Interno"
											rules="required|max:255"
											v-slot="{ errors }"
										>
											<b-input
												v-model="aux_internal_number"
												placeholder="Ingrese el número interno del bien"
											></b-input>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<b-field label="Marca *">
										<ValidationProvider
											name="Marca"
											rules="required"
											v-slot="{ errors }"
										>
											<multiselect
												style="is-info"
												v-model="aux_trademark"
												:options="trademarks.map((type) => type.id)"
												:custom-label="
													(opt) =>
														trademarks.find((x) => x.id === opt)
															.alt_name_trademark
												"
												placeholder="Seleccionar marca"
												selectLabel=""
												selectLabelcolor=""
												selectedLabel="Seleccionado"
												deselectLabel="Presione para deseleccionar"
												:allow-empty="true"
											></multiselect>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>

								<div class="column">
									<b-field label="Modelo">
										<ValidationProvider
											name="Modelo"
											rules="min:3|max:255"
											v-slot="{ errors }"
										>
											<b-input
												v-model="aux_model"
												placeholder="Ingrese el modelo del bien"
											></b-input>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>

								<div class="column">
									<b-field label="Número de Serie">
										<ValidationProvider
											name="Número de Serie"
											rules="max:255"
											v-slot="{ errors }"
										>
											<b-input
												v-model="aux_serie_number"
												placeholder="Ingrese el número de serie del bien"
											></b-input>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<b-field label="Fuente de Financiamiento *">
										<ValidationProvider
											name="Fuente de Financiamiento"
											rules="required"
											v-slot="{ errors }"
										>
											<multiselect
												style="is-info"
												v-model="aux_financial_source_id"
												:options="financial_sources.map((type) => type.id)"
												:custom-label="
													(opt) =>
														financial_sources.find((x) => x.id === opt)
															.alt_name_financial_source
												"
												placeholder="Seleccionar fuente de financiamiento"
												selectLabel=""
												selectLabelcolor=""
												selectedLabel="Seleccionado"
												deselectLabel="Presione para deseleccionar"
												:allow-empty="true"
											></multiselect>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>

								<div class="column">
									<b-field label="Tipo de Inversión *">
										<ValidationProvider
											name="Tipo de Inversión"
											rules="required"
											v-slot="{ errors }"
										>
											<multiselect
												style="is-info"
												v-model="aux_investment_type_id"
												:options="investment_types.map((type) => type.id)"
												:custom-label="
													(opt) =>
														investment_types.find((x) => x.id === opt)
															.alt_name_investment_type
												"
												placeholder="Seleccionar Tipo de inversión"
												selectLabel=""
												selectLabelcolor=""
												selectedLabel="Seleccionado"
												deselectLabel="Presione para deseleccionar"
												:allow-empty="true"
											></multiselect>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>

								<div class="column">
									<b-field label="Fecha de Compra *">
										<ValidationProvider
											name="Fecha de Compra"
											rules="required"
											v-slot="{ errors }"
										>
											<b-datepicker
												v-model="aux_date_purchase"
												locale="es-ES"
												placeholder="Seleccionar fecha de compra"
												trap-focus
											>
											</b-datepicker>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<b-field label="Monto Bruto">
										<ValidationProvider
											name="Monto Bruto"
											rules="min_value:0|max:255"
											v-slot="{ errors }"
										>
											<b-numberinput
												min="0"
												v-model="aux_amount"
												type="is-info"
												placeholder="Ingrese el monto bruto del bien"
											>
											</b-numberinput>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>
								<div class="column">
									<b-field label="Número Inventario Usach">
										<ValidationProvider
											name="Número Inventario Usach"
											rules="max:255"
											v-slot="{ errors }"
										>
											<b-input
												v-model="aux_identification_number_good"
												type="number"
												placeholder="Ingrese el número inventario del bien"
											></b-input>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>

								<div class="column">
									<b-field label="Fecha Chequeo *">
										<ValidationProvider
											name="Fecha Chequeo"
											rules="required"
											v-slot="{ errors }"
										>
											<b-datepicker
												v-model="aux_date_chekup"
												locale="es-ES"
												placeholder="Seleccionar fecha de ultimo Chequeo"
												trap-focus
											>
											</b-datepicker>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<b-field label="Estado *">
										<ValidationProvider
											name="Estado"
											rules="required|max:255"
											v-slot="{ errors }"
										>
											<multiselect
												style="is-info"
												v-model="aux_operative"
												:options="estados"
												placeholder="Seleccionar"
												selectLabel=""
												selectLabelcolor=""
												selectedLabel="Seleccionado"
												deselectLabel="Presione para deseleccionar"
												:allow-empty="true"
											></multiselect>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>
							</div>

							<div class="columns">
								<div class="column">
									<b-field label="Descripción">
										<ValidationProvider
											name="Descripción"
											rules="min:3|max:255"
											v-slot="{ errors }"
										>
											<b-input
												v-model="aux_good_description"
												placeholder="Ingrese una descripción del bien o el nombre"
												type="textarea"
											></b-input>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>
								<div class="column">
									<b-field label="Comentario">
										<ValidationProvider
											name="Comentario"
											rules="min:3|max:255"
											v-slot="{ errors }"
										>
											<b-input
												v-model="good_comment"
												placeholder="Ingrese una descripción del bien o el nombre"
												type="textarea"
											></b-input>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>
							</div>
						</form>
					</ValidationObserver>

					<div class="columns">
						<div class="column">
							<b-field label="Seleccione destino del Bien">
								<b-radio-button
									v-model="showSelectorPerson"
									native-value=""
									type="is-info"
								>
									<b-icon pack="fas" icon="dolly" />
									<span>Enviar a Bodega</span>
								</b-radio-button>

								<b-radio-button
									v-model="showSelectorPerson"
									native-value="Asignar Persona"
									type="is-info"
								>
									<b-icon pack="fas" icon="user-plus" />
									<span>Asignar Persona Encargada</span>
								</b-radio-button>
							</b-field>
						</div>
					</div>

					<ValidationObserver
						v-slot="{ handleSubmit }"
						ref="observerDutyManager"
					>
						<form @submit.prevent="handleSubmit(check())">
							<div
								class="columns"
								v-show="showSelectorPerson === 'Asignar Persona'"
							>
								<div class="column">
									<b-field label="Unidad *">
										<ValidationProvider name="Unidad" rules="required" v-slot="{ errors }">
											<multiselect
												style="is-info"
												v-model="aux_unit_id"
												:options="units.map((type) => type.id)"
												:custom-label="
													(opt) => units.find((x) => x.id === opt).alt_name_unit
												"
												placeholder="Seleccionar Unidad"
												selectLabel=""
												selectLabelcolor=""
												selectedLabel="Seleccionado"
												deselectLabel="Presione para deseleccionar"
												:allow-empty="true"
												:disabled="
													aux_duty_manager_id !== null ||
													aux_building_id !== null
												"
												@input="
													getDutymanagerByUnit(aux_unit_id);
													getBuildingByUnit(aux_unit_id);
												"
											>
											</multiselect>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>

								<div class="column">
									<b-field label="Persona Asociada Encargada *">
										<ValidationProvider name="Persona Asociada Encargada" rules="required" v-slot="{ errors }">
											<multiselect
												v-model="aux_duty_manager_id"
												:options="managers.map((type) => type.id)"
												:custom-label="
													(opt) =>
														managers.find((x) => x.id === opt).name_duty_manager
												"
												placeholder="Seleccionar Persona encargada"
												selectLabel="Presione para seleccionar"
												selectedLabel="Seleccionado"
												deselectLabel="Presione para deseleccionar"
												:allow-empty="true"
												:disabled="aux_unit_id == null"
											>
											</multiselect>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>

								<div class="column">
									<b-field label="Fecha de Asignación *">
										<ValidationProvider name="Fecha de Asignación" rules="required" v-slot="{ errors }">
											<b-datepicker
												v-model="assignment_date"
												locale="es-ES"
												placeholder="Seleccionar fecha de Asignación"
												trap-focus
											>
											</b-datepicker>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>
							</div>

							<div
								class="columns"
								v-show="showSelectorPerson === 'Asignar Persona'"
							>
								<div class="column">
									<b-field label="Edificio *">
										<ValidationProvider name="Edificio" rules="required" v-slot="{ errors }">
											<multiselect
												v-model="aux_building_id"
												:options="buildings.map((type) => type.id)"
												:custom-label="
													(opt) =>
														buildings.find((x) => x.id === opt)
															.alt_name_building
												"
												placeholder="Seleccionar"
												selectLabel="Presione para seleccionar"
												selectedLabel="Seleccionado"
												deselectLabel="Presione para deseleccionar"
												:allow-empty="true"
												:disabled="
													aux_unit_id == null || aux_office_id !== null
												"
												@input="getOfficeByBuilding(aux_building_id)"
											>
											</multiselect>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>

								<div class="column">
									<b-field label="Dependencia *">
										<ValidationProvider name="Dependencia" rules="required" v-slot="{ errors }">
											<multiselect
												v-model="aux_office_id"
												:options="offices.map((type) => type.id)"
												:custom-label="
													(opt) =>
														offices.find((x) => x.id === opt).alt_name_office
												"
												placeholder="Seleccionar dependencia"
												selectLabel="Presione para seleccionar"
												selectedLabel="Seleccionado"
												deselectLabel="Presione para deseleccionar"
												:allow-empty="true"
												:disabled="aux_building_id == null"
											>
											</multiselect>
											<span class="validation-alert">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-field>
								</div>
							</div>
						</form>
					</ValidationObserver>

					<div class="columns is-mobile">
						<div class="column">
							<b-button label="Cerrar" @click="$emit('close')" />
						</div>

						<div class="column has-text-right">
							<b-button
								label="Añadir Bien"
								icon-left="floppy-disk"
								type="is-success"
								@click="check()"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
	</section>
</template>

<script>
import axios from "axios";
export default {
	name: "new_good",

	data() {
		return {
			selected: null,
			goods: [],
			offices: [],
			units: [],
			financial_sources: [],
			investment_types: [],
			managers: [],
			buildings: [],
			dropFiles: [],
			trademarks: [],
			categories: [],
			estados: ["Operativo", "No Operativo"],
			aux_namegood: null,
			aux_good_description: null,
			aux_identification_number_good: null,
			aux_serie_number: null,
			aux_internal_number: null,
			aux_location_good: null,
			aux_duty_manager_id: null,
			aux_office_id: null,
			aux_unit_id: null,
			aux_building_id: null,
			aux_financial_source_id: null,
			aux_investment_type_id: null,
			aux_amount: null,
			aux_date_purchase: new Date(),
			aux_financial_source: null,
			aux_investment_type: null,
			aux_operative: null,
			aux_model: null,
			aux_date_chekup: new Date(),
			aux_trademark: null,
			aux_category: null,
			isLoading: false,
			aux_activegood: true,
			last_good: null,
			actual_id: null,
			current: null,
			showSelectorPerson: "",
			assignment_date: new Date(),
			good_comment: null,
		};
	},

	async created() {
		this.isLoading = true;
		await this.getUnitActive();
		await this.getFinancialsource();
		await this.getInvestmentType();
		await this.getActualUser();
		await this.getTrademarks();
		await this.getCategories();
		this.isLoading = false;
	},

	methods: {
		async getFinancialsource() {
			this.isLoading = true;
			await axios
				.get("/financial_sources/showfinancialsources.json")
				.then((response) => {
					this.financial_sources = response.data.financial_sources_enabled;
				})
				.catch((error) => {
					let errorMsg = error.response
						? "favor de revisar errores.<br>Errores<br>" + error.response.data
						: ".";

					this.$buefy.dialog.alert({
						title: "Error",
						message:
							"Hubo un error al obtener las Fuentes de Financiamiento" +
							errorMsg,
						type: "is-danger",
						hasIcon: true,
						icon: "times-circle",
						iconPack: "fa",
						ariaRole: "alertdialog",
						ariaModal: true,
					});
					this.isLoading = false;
				});
		},

		async getTrademarks() {
			this.isLoading = true;
			await axios
				.get("/trademarks/showtrademarks.json")
				.then((response) => {
					this.trademarks = response.data.trademarks_enabled;
				})
				.catch((error) => {
					let errorMsg = error.response
						? "favor de revisar errores.<br>Errores<br>" + error.response.data
						: ".";

					this.$buefy.dialog.alert({
						title: "Error",
						message: "Hubo un error al obtener las Marcas" + errorMsg,
						type: "is-danger",
						hasIcon: true,
						icon: "times-circle",
						iconPack: "fa",
						ariaRole: "alertdialog",
						ariaModal: true,
					});
					this.isLoading = false;
				});
		},

		async getCategories() {
			this.isLoading = true;
			await axios
				.get("/categories/showcategories.json")
				.then((response) => {
					this.categories = response.data.categories_enabled;
				})
				.catch((error) => {
					let errorMsg = error.response
						? "favor de revisar errores.<br>Errores<br>" + error.response.data
						: ".";

					this.$buefy.dialog.alert({
						title: "Error",
						message: "Hubo un error al obtener las Categorías" + errorMsg,
						type: "is-danger",
						hasIcon: true,
						icon: "times-circle",
						iconPack: "fa",
						ariaRole: "alertdialog",
						ariaModal: true,
					});
					this.isLoading = false;
				});
		},

		async getInvestmentType() {
			this.isLoading = true;
			await axios
				.get("/investment_types/showinvestmenttypes.json")
				.then((response) => {
					this.investment_types = response.data.investment_types_enabled;
				})
				.catch((error) => {
					let errorMsg = error.response
						? "favor de revisar errores.<br>Errores<br>" + error.response.data
						: ".";

					this.$buefy.dialog.alert({
						title: "Error",
						message:
							"Hubo un error al obtener los Tipos de Inversión" + errorMsg,
						type: "is-danger",
						hasIcon: true,
						icon: "times-circle",
						iconPack: "fa",
						ariaRole: "alertdialog",
						ariaModal: true,
					});
					this.isLoading = false;
				});
		},

		async getUnitActive() {
			this.isLoading = true;
			await axios
				.get("/units/showactiveunit.json")
				.then((response) => {
					this.units = response.data;
				})
				.catch((error) => {
					let errorMsg = error.response
						? "favor de revisar errores.<br>Errores<br>" + error.response.data
						: ".";

					this.$buefy.dialog.alert({
						title: "Error",
						message: "Hubo un error al obtener las Unidades" + errorMsg,
						type: "is-danger",
						hasIcon: true,
						icon: "times-circle",
						iconPack: "fa",
						ariaRole: "alertdialog",
						ariaModal: true,
					});
					this.isLoading = false;
				});
		},

		async getDutymanagerByUnit(item) {
			this.isLoading = true;
			await axios
				.get("/duty_managers/showdutymanagerunit/" + item + ".json")
				.then((response) => {
					this.managers = response.data;
				})
				.catch((error) => {
					let errorMsg = error.response
						? "favor de revisar errores.<br>Errores<br>" + error.response.data
						: ".";

					this.$buefy.dialog.alert({
						title: "Error",
						message:
							"Hubo un error al obtener las Personas Asociadas por Unidad" +
							errorMsg,
						type: "is-danger",
						hasIcon: true,
						icon: "times-circle",
						iconPack: "fa",
						ariaRole: "alertdialog",
						ariaModal: true,
					});
					this.isLoading = false;
				});
		},

		async getBuildingByUnit(item) {
			this.isLoading = true;
			await axios
				.get("/buildings/showbuildingunit/" + item + ".json")
				.then((response) => {
					this.buildings = response.data;
				})
				.catch((error) => {
					let errorMsg = error.response
						? "favor de revisar errores.<br>Errores<br>" + error.response.data
						: ".";

					this.$buefy.dialog.alert({
						title: "Error",
						message:
							"Hubo un error al obtener los Edificios por Unidad" + errorMsg,
						type: "is-danger",
						hasIcon: true,
						icon: "times-circle",
						iconPack: "fa",
						ariaRole: "alertdialog",
						ariaModal: true,
					});
				});
			this.isLoading = false;
		},

		async getOfficeByBuilding(item) {
			this.isLoading = true;
			await axios
				.get("/offices/showofficebuilding/" + item + ".json")
				.then((response) => {
					this.offices = response.data;
				})
				.catch((error) => {
					let errorMsg = error.response
						? "favor de revisar errores.<br>Errores<br>" + error.response.data
						: ".";

					this.$buefy.dialog.alert({
						title: "Error",
						message:
							"Hubo un error al obtener las Dependencias por Edificio" +
							errorMsg,
						type: "is-danger",
						hasIcon: true,
						icon: "times-circle",
						iconPack: "fa",
						ariaRole: "alertdialog",
						ariaModal: true,
					});
				});
			this.isLoading = false;
		},

		async getActualUser() {
			this.isLoading = true;
			await axios
				.get("/duty_managers/currentuser.json")
				.then((response) => {
					this.current = response.data.current_user;
				})
				.catch((error) => {
					let errorMsg = error.response
						? "favor de revisar errores.<br>Errores<br>" + error.response.data
						: ".";

					this.$buefy.dialog.alert({
						title: "Error",
						message: "Hubo un error al obtener al Usuario Actual" + errorMsg,
						type: "is-danger",
						hasIcon: true,
						icon: "times-circle",
						iconPack: "fa",
						ariaRole: "alertdialog",
						ariaModal: true,
					});
					this.isLoading = false;
				});
		},

		async check() {
			const valid = await this.$refs.observer.validate();
			if (!valid) {
				this.$buefy.dialog.alert({
					title: "Error",
					message: "Algunos campos presentan errores.",
					type: "is-danger",
					hasIcon: true,
					icon: "times-circle",
					iconPack: "fa",
					ariaRole: "alertdialog",
					ariaModal: true,
				});
			} else {
				if (this.showSelectorPerson === "Asignar Persona") {
					const validDutyManager =
						await this.$refs.observerDutyManager.validate();

					if (!validDutyManager) {
						this.$buefy.dialog.alert({
							title: "Error",
							message: "Algunos campos de Persona Encargada presentan errores.",
							type: "is-danger",
							hasIcon: true,
							icon: "times-circle",
							iconPack: "fa",
							ariaRole: "alertdialog",
							ariaModal: true,
						});
					} else {
						this.confirmregister();
					}
				} else {
					this.confirmregister();
				}
			}
		},

		confirmregister() {
			this.$buefy.dialog.confirm({
				title: "Registrar el Bien",
				message: `¿Esta seguro de registrar este bien?`,
				cancelText: "Cancelar",
				confirmText: "Registrar",
				type: "is-info",
				onConfirm: () => this.createClick(),
			});
		},

		async createClick() {
			this.isLoading = true;
			if (this.showSelectorPerson === "Asignar Persona") {
				await axios
					.post("/goods.json", {
						namegood: this.aux_namegood,
						good_description: this.aux_good_description,
						identification_number_good: this.aux_identification_number_good,
						serie_number: this.aux_serie_number,
						model: this.aux_model,
						duty_manager_id: this.aux_duty_manager_id,
						office_id: this.aux_office_id,
						activegood: this.aux_activegood,
						amount: this.aux_amount,
						date_purchase: this.aux_date_purchase,
						financial_source_id: this.aux_financial_source_id,
						investment_type_id: this.aux_investment_type_id,
						location_good: this.aux_location_good,
						trademark_id: this.aux_trademark,
						category_id: this.aux_category,
						date_checkup: this.aux_date_chekup,
						operative: this.aux_operative,
						internal_number: this.aux_internal_number,
						hasPersonCharge: true,
						assignment_date: this.assignment_date,
						good_comment: this.good_comment,
					})
					.then((response) => {
						this.$buefy.dialog.alert({
							title: "Logrado",
							message: "Se ha agregado correctamente el Bien al sistema.",
							type: "is-success",
							hasIcon: true,
							icon: "circle-check",
							iconPack: "fas",
							ariaRole: "alertdialog",
							ariaModal: true,
							onConfirm: () =>
								(window.location.href = "/goods/" + response.data.id),
						});
					})
					.catch((error) => {
						let errorMsg = error.response
							? "favor de revisar errores.<br>Errores<br>" + error.response.data
							: ".";

						this.$buefy.dialog.alert({
							title: "Error",
							message:
								"Hubo un error al ingresar los datos del Bien" + errorMsg,
							type: "is-danger",
							hasIcon: true,
							icon: "times-circle",
							iconPack: "fa",
							ariaRole: "alertdialog",
							ariaModal: true,
						});
					});
			} else {
				await axios
					.get("/offices/store.json")
					.then((response) => {
						axios
							.post("/goods.json", {
								namegood: this.aux_namegood,
								good_description: this.aux_good_description,
								identification_number_good: this.aux_identification_number_good,
								serie_number: this.aux_serie_number,
								model: this.aux_model,
								duty_manager_id: response.data.duty_manager.id,
								office_id: response.data.store.id,
								activegood: this.aux_activegood,
								amount: this.aux_amount,
								date_purchase: this.aux_date_purchase,
								financial_source_id: this.aux_financial_source_id,
								investment_type_id: this.aux_investment_type_id,
								location_good: "Portería Bodega",
								trademark_id: this.aux_trademark,
								category_id: this.aux_category,
								date_checkup: this.aux_date_chekup,
								operative: this.aux_operative,
								internal_number: this.aux_internal_number,
								assignment_date: this.assignment_date,
								good_comment: this.good_comment,
							})
							.then((response) => {
								this.$buefy.dialog.alert({
									title: "Logrado",
									message: "Se ha agregado correctamente el Bien al sistema.",
									type: "is-success",
									hasIcon: true,
									icon: "circle-check",
									iconPack: "fas",
									ariaRole: "alertdialog",
									ariaModal: true,
									onConfirm: () =>
										(window.location.href = "/goods/" + response.data.id),
								});
							})
							.catch((error) => {
								let errorMsg = error.response
									? "favor de revisar errores.<br>Errores<br>" +
									  error.response.data
									: ".";

								this.$buefy.dialog.alert({
									title: "Error",
									message:
										"Hubo un error al ingresar los datos del Bien" + errorMsg,
									type: "is-danger",
									hasIcon: true,
									icon: "times-circle",
									iconPack: "fa",
									ariaRole: "alertdialog",
									ariaModal: true,
								});
							});
					})
					.catch((error) => {
						let errorMsg = error.response
							? "favor de revisar errores.<br>Errores<br>" + error.response.data
							: ".";

						this.$buefy.dialog.alert({
							title: "Error",
							message:
								"Hubo un error al obtener la Información de la Bodega" +
								errorMsg,
							type: "is-danger",
							hasIcon: true,
							icon: "times-circle",
							iconPack: "fa",
							ariaRole: "alertdialog",
							ariaModal: true,
						});
					});
			}
			this.isLoading = false;
		},
	},
};
</script>

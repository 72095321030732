<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmregister())">
        <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="newUnit">
            <b-tabs type="is-boxed" position="is-centered">
              <b-tab-item label="Añadir unidad">
                <div class="columns is-centered">
                  <div class="column is-centered">

                    <div class="my-0">

                      <div class="columns">
                        <div class="column">
                          <b-field label="Nombre de Unidad *">
                            <ValidationProvider name="Nombre de Unidad" rules="required|max:255" v-slot="{ errors }">
                              <b-input v-model="aux_name_unit" placeholder="Ingrese el nombre de la unidad">
                              </b-input>
                              <span class="validation-alert">{{
                                errors[0]
                                }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                        <div class="column">
                          <b-field label="N° Identificación *">
                            <ValidationProvider name="N° Identificación" rules="required|max:255" v-slot="{ errors }">
                              <b-input v-model="aux_identification_number_unit" type="number"
                                placeholder="Ingrese el numero de identificación de la unidad"></b-input>
                              <span class="validation-alert">{{
                                errors[0]
                                }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                      </div>

                      <div class="columns">
                        <div class="column">
                          <b-field label="Descripción *">
                            <ValidationProvider name="Descripción" rules="required|max:255" v-slot="{ errors }">
                              <b-input v-model="aux_description_unit" type="textarea"
                                placeholder="Ingrese la descripción de la unidad"></b-input>
                              <span class="validation-alert">{{
                                errors[0]
                                }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>

                    </div>


                    <div class="columns is-mobile">

                      <div class="column">
                        <b-button label="Cerrar" @click="$emit('close')" />
                      </div>

                      <div class="column has-text-right">
                        <p class="control">
                          <b-button label="Añadir Unidad" type="is-success" icon-left="save" @click="check()" />
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
              </b-tab-item>

              <b-tab-item label="Subida masiva">
                <div class="content">
                  <h2>Seleccionar archivo</h2>
                </div>
                <b-field class="file">
                  <b-upload v-model="file" expanded>
                    <a class="button is-info is-fullwidth">
                      <b-icon icon="upload"></b-icon>
                      <span>{{ file.name || "Subir Archivo" }}</span>
                    </a>
                  </b-upload>
                </b-field>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <b-button label="Importar" icon-left="upload" type="is-info" @click="import_file" />
                  </div>

                </div>

                <div class="has-text-right">

                </div>
              </b-tab-item>
            </b-tabs>
          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "new_unit",
  props: ["queryType", "modalHeader"],
  data() {
    return {
      selected: null,
      units: [],
      aux_name_unit: null,
      aux_description_unit: null,
      aux_identification_number_unit: null,
      aux_active: true,

      //IMPORTS
      import_type: "Propiedad Intelectual",
      file: { name: "" },
      dropFiles: [],
      isLoading: false,
      errores: [],
      file_name_errors: "",
      cabeceras: [],
      hojas: [],
      errorFile: { name: "" },
    };
  },
  methods: {
    async createClick() {
      this.isLoading = true
      await axios.post("/units.json", {
        name_unit: this.aux_name_unit,
        description_unit: this.aux_description_unit,
        identification_number_unit: this.aux_identification_number_unit,
        active_unit: this.aux_active,
      })
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha agregado correctamente la Unidad al sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al ingresar los datos de la Unidad, favor revisar los errores.<br>Errores:<br>' + error.response.data,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async check() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Registrar Unidad",
          message: `¿Esta seguro de registrar esta unidad?`,
          cancelText: "Cancelar",
          confirmText: "Registrar",
          type: "is-info",

          onConfirm: () => this.createClick(),
        });
      }
    },


    //IMPORTS

    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    import_file() {
      this.isLoading = true;
      var url = "";
      url = "/units/massive_unit/import_unit.json";
      let formData = new FormData();
      formData.append("file", this.file);
      this.errorFile = this.file;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data["errores"].length > 0) {
            this.errores = response.data["errores"];
            this.$buefy.dialog.alert({
              title: "Error",
              message:
                'Algunos datos del archivo "' +
                this.file.name +
                '" no han podido ser importados, por favor, revisar errores. <br>Errores:<br>' + this.errores,
              confirmText: "Ok",
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              ariaRole: "alertdialog",
              ariaModal: true,
            });
            this.file_name_errors = this.file.name;
            this.cabeceras = response.data["cabeceras"];
            if (this.import_type == "Convenios") {
              this.hojas = response.data["sheets"];
            } else {
              this.hojas = [];
            }
          } else {
            this.import_type = "";
            this.$buefy.dialog.alert({
              title: 'Logrado',
              message:
                'Su archivo "' +
                this.file.name +
                '" ha sido importado con éxito',
              confirmText: "Ok",
              type: 'is-success',
              hasIcon: true,
              icon: 'circle-check',
              iconPack: 'fas',
              ariaRole: 'alertdialog',
            });
            this.file = { name: "" };
            this.errores = [];
            this.file_name_errors = "";
            this.$emit('refresh')
            this.$emit('close')
          }
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: "Error",
            message:
              "Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          })
        })
        this.isLoading = false
    },
    importErrors() {
      let vm = this;
      vm.isLoading = true;
      axios
        .post(
          "/load-data/export-errors.xlsx",
          { cabeceras: vm.cabeceras, errores: vm.errores, hojas: vm.hojas },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          vm.isLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Errores importacion masiva de datos " +
              vm.errorFile.name.replace(".xlsx", "") +
              " " +
              new Date().toLocaleDateString() +
              ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          vm.isLoading = false;
          vm.$buefy.notification.open({
            message: "Hubo un error en tu solicitud",
            type: "is-danger",
          });
        });
    },
  },
};
</script>

<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmedit())">
        <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="editUnit">
            <div v-if="unit" class="columns is-centered">
              <div class="column is-centered">

                <div class="my-0">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Nombre de Unidad *">
                        <ValidationProvider name="Nombre de Unidad" rules="required|max:255" v-slot="{ errors }">
                          <b-input v-model="unit.alt_name_unit" placeholder="Ingrese el Nombre de Unidad" />
                          <span class="validation-alert">{{
                            errors[0]
                            }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>

                    <div class="column">
                      <b-field label="N° Identificación *">
                        <ValidationProvider name="N° Identificación" rules="required|max:255" v-slot="{ errors }">
                          <b-input v-model="unit.identification_number_unit" type="number"
                            placeholder="Ingrese el numero de identificación de la unidad"></b-input>
                          <span class="validation-alert">{{
                            errors[0]
                            }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>

                  </div>


                  <div class="columns">
                    <div class="column">
                      <b-field label="Descripción *">
                        <ValidationProvider name="Descripción" rules="required|max:255" v-slot="{ errors }">
                          <b-input v-model="unit.description_unit" type="textarea"
                            placeholder="Ingrese la descripción de la unidad"></b-input>
                          <span class="validation-alert">{{
                            errors[0]
                            }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                </div>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <p class="control">
                      <b-button label="Editar Unidad" icon-left="save" type="is-info" @click="check()" />
                    </p>
                  </div>

                </div>
              </div>
            </div>

            <div v-else>
              <p>Cargando</p>
            </div>
          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
import IndexUnits from "./index_unit.vue";
import moment from "moment";
export default {
  name: "edit_unit",
  props: ["queryType", "modalHeader", "unitId", "id"],
  components: {
    "index-unit": IndexUnits,
  },

  data() {
    return {
      unit: null,
      units: [],
      props: ["id"],
      isLoading: false,
    };
  },
  methods: {
    async getunit() {
      this.isLoading = true
      const response = await axios.get("/units/" + this.id + ".json")
      this.unit = response.data
      this.isLoading = false
    },

    async EditClick() {
      this.isLoading = true
      await axios.put("/units/" + this.id + ".json", {
        alt_name_unit: this.unit.alt_name_unit,
        description_unit: this.unit.description_unit,
        identification_number_usach: this.unit.identification_number_usach,
        identification_number_unit: this.unit.identification_number_unit,
      })
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha editado correctamente la Unidad.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al editar los datos de la Unidad, favor revisar los errores.<br>Errores:<br>' + error.response.data,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async check() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Editar la unidad",
          message: `¿Esta seguro de editar esta unidad?`,
          cancelText: "Cancelar",
          confirmText: "Guardar",
          type: "is-info",
          onConfirm: () => this.EditClick(),
        });
      }
    },
  },
  async created() {
    this.isLoading = true
    await this.getunit()
    this.isLoading = false
  },
};
</script>

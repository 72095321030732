<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Edificios de la facultad</h1>
      </div>
      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Edificios">
          <div class="has-text-right">
            <b-button label="Añadir Edificio" type="is-success" icon-left="plus" @click="createBuilding()"
              v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'create'))"/>
          </div>
          <div>
            <b-modal :active="isModalActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <new-building modal-cancel="true" :modal-header="'Registro de Edificio'" query-type="create"
                  @close="closeModalCreate" @refresh="toRefreshActiveBuildings" />
              </template>
            </b-modal>

            <b-modal :active="isModalActiveEdit" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <edit-building :building-id="buildingId" :id="selected" :modal-header="'Editar Edificio'"
                  query-type="update" @close="closeModalEdit" @refresh="toRefreshActiveBuildings" />
              </template>
            </b-modal>

          </div>

          <div class="column">
            <b-table :data="buildings" :paginated=true per-page="10" striped hoverable :pagination-simple=false
              pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
              default-sort="inventores.name" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible=false field="id" label="ID" sortable searchable>

                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="name_building" label="Nombre Edificio" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_building }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_building" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_building }}
                </template>
              </b-table-column>



              <b-table-column field="unit.name_unit" label="Nombre Unidad" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Unidad..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.unit.alt_name_unit }}
                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                      tag="a" :href="/buildings/ + props.row.id" target="_blank"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'show'))"/>
                  </div>
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="pen" type="is-primary"
                      @click.native="editBuilding(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'update'))"/>
                  </div>
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="ban" type="is-danger"
                      @click.native="confirmisempty(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'disable_building'))"/>
                  </div>
                </div>
              </b-table-column>

            </b-table>
          </div>
        </b-tab-item>

        <b-tab-item label="Edificios anulados">
          <div class="column">
            <b-table :data="null_buildings" :paginated=true per-page="10" striped hoverable :pagination-simple=false
              pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
              default-sort="inventores.name" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible=false field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="name_building" label="Nombre Edificio" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                    {{ props.row.alt_name_building }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_building" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_building }}
                </template>
              </b-table-column>


              <b-table-column field="unit.name_unit" label="Nombre Unidad" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Unidad..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.unit.alt_name_unit }}
                </template>
              </b-table-column>



              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">

                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                      tag="a" :href="/buildings/ + props.row.id" target="_blank"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'show'))"/>
                  </div>
                  <div class="column" v-show='!(props.row.unit.active_unit == false)'>
                    <b-button icon-pack="fas" icon-right="trash-arrow-up" type="is-success"
                      @click.native="confirmGoodRest(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'activate_building'))"/>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>


<script>
import axios from "axios";
import NewBuilding from './new_building.vue';
import ShowBuilding from './show_building.vue';
import EditBuilding from "./edit_building.vue";
export default {
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Edificios';
  },
  data() {
    return {
      buildings: [],

      offices: [],
      id_goods_list: [],
      id_offices_list: [],
      null_buildings: [],
      offices_building: [],
      goods_buildings: [],
      canCancel: ["escape"],
      isModalActive: false,
      isModalActiveShow: false,
      isModalActiveEdit: false,
      selected: null,
      null_active: false,
      des_active: true,
      pendiing_good: 2,
      isLoading: false,
    };
  },
  components: {
    NewBuilding,
    EditBuilding,
    ShowBuilding
  },
  props: ["buildingId", 'permissions'],
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    async getbuildingactive() {
      this.isLoading = true
      const response = await axios.get("/buildings/showactivebuilding.json")
      this.buildings = response.data
      this.isLoading = false
    },

    async null_getbuilding() {
      this.isLoading = true
      const response = await axios.get("/buildings/shownullbuilding.json")
      this.null_buildings = response.data
      this.isLoading = false
    },

    async AnulClick(id) {
      this.isLoading = true
      await axios.put('/buildings/' + id + '/disable.json')
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha anulado correctamente el Edificio.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = true
        this.null_getbuilding();
        this.getbuildingactive();
        this.isLoading = false
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al anular el Edificio.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async RestClick(id) {
      this.isLoading = true
      await axios.put('/buildings/' + id + '/activate.json')
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha restaurado correctamente el Edificio.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = true
        this.null_getbuilding();
        this.getbuildingactive();
        this.isLoading = false
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al restaurar el Edificio.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },
    confirmGoodRest(item) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Edificio",
        message: "¿Esta seguro de querer restaurar el Edificio?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Edificio",
        message: "¿Esta seguro de querer anular el Edificio?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });
    },


    confirmisempty(item) {
      this.getofficebybuilding(item);
    },


    async getofficebybuilding(item) {
      const response = await axios.get(
        "/offices/showofficebuilding/" + item + ".json"
      );
      this.offices_building = response.data;
      if (this.offices_building.length == 0) {
        this.getgoodbybuilding(item);
      }
      else {
        this.$buefy.dialog.alert({
          title: 'No se puede anular',
          type: 'is-danger',
          message: 'Existen dependencias designados en el edificio',
          confirmText: 'Aceptar'
        })
      }
    },

    async getgoodbybuilding(item) {
      const response = await axios.get("/goods/showgoodbuilding/" + item + ".json");
      this.goods_buildings = response.data;
      if (this.goods_buildings.length == 0) {
        this.confirmGoodNull(item);
      }
      else {
        this.$buefy.dialog.alert({
          title: 'No se puede anular',
          type: 'is-danger',
          message: 'Existen bienes designados en el edificio',
          confirmText: 'Aceptar'
        })
      }
    },



    async Clicknewbuilding() {
      const response = (window.location.href = "/buildings/new");
    },
    async Clickshowdutymanager(item) {
      const response = (window.location.href = "/duty_managers/" + item);
    },
    async Clickshowunit(item) {
      const response = (window.location.href = "/units/" + item);
    },
    async createBuilding() {
      this.isModalActive = true;
    },
    async closeModalCreate() {
      this.isModalActive = false;
    },
    async ShowModaleBuilding(item) {
      this.selected = item;
      this.isModalActiveShow = true;
    },
    async closeModalShow() {
      this.isModalActiveShow = false;
    },



    async AnulClickgood(item) {
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.pendiing_good,
      });
    },
    async AnulClickoffice(item) {
      const response = await axios.put("/offices/" + item + ".json", {
        active_office: this.null_active,
      });
    },
    async editBuilding(item) {
      this.selected = item;
      this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },

    async toRefreshActiveBuildings(){
      
      this.isLoading = true
      await this.getbuildingactive();
      this.isLoading = false

    },


  },
  async created() {
    this.isLoading = true
    await this.getbuildingactive()
    await this.null_getbuilding()
    this.isLoading = false
  },
};
</script>



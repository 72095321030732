<template>
  <section class="b-tooltips">
    <div
      class="box"
      style="margin-top: 60px; margin-left: 300px; margin-right: 300px"
    >
      <h1>Detalle del insumo</h1>
      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Detalle del insumo">
          <div class="center">
            <h1>Información del insumo</h1>
          </div>
          <div class="box">
            <div v-if="supply" class="columns is-centered">
              <div class="column is-centered">
                <div class="columns">
                  <div class="column">
                    <b-field label="Nombre del Insumo:">
                      <b-input
                        v-model="supply.name_supplie"
                        type="text"
                        readonly
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Numero de identificación del Insumo:">
                      <b-input
                        v-model="supply.identification_number_supplie"
                        type="text"
                        readonly
                      ></b-input>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Cantidad total:">
                      <b-input
                        v-model="supply.total"
                        type="text"
                        readonly
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Cantidad restante:">
                      <b-input
                        v-model="supply.remaining"
                        type="text"
                        readonly
                      ></b-input>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Categoria:">
                      <b-input
                        v-model="supply.category.name_category"
                        type="text"
                        readonly
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Marca:">
                      <b-input
                        v-model="supply.trademark.name_trademark"
                        type="text"
                        readonly
                      ></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>

            <div v-else>
              <p>Loading</p>
            </div>
          </div>
        </b-tab-item>

        <!--

        <b-tab-item label="Historial">
          <div class="center">
            <h1>Historial de bienes de la Unidad</h1>
          </div>


          <div class="column">
            <b-table :data="records" :paginated="true" per-page="80" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small"
              aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">

              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column
                field="good.namegood" label="Nombre del bien" sortable searchable>
                <template v-slot="props">
                  {{ props.row.good.namegood }}
                </template>
              </b-table-column>

              <b-table-column
                field="user.full_name" label="Nombre usuario" sortable searchable>
                <template v-slot="props">
                  {{ props.row.user.full_name }}
                </template>
              </b-table-column>

              <b-table-column
                field="detail_record" label="Acción" sortable searchable>
                <template v-slot="props">
                  {{ props.row.detail_record }}
                </template>
              </b-table-column>

              <b-table-column
                field="date_action" label="Fecha de acción" sortable searchable>
                <template v-slot="props">
                  {{ moment(props.row.created_at).format("DD-MM-YYYY H:m:s") }}
                </template>
              </b-table-column>

            </b-table>
          </div>

        </b-tab-item>

        -->
      </b-tabs>
      <b-button
        tag="a"
        href="/supplies"
        label="Volver"
        type="is-light">
      </b-button>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "show_supply",
  props: ["id", "queryType", "modalHeader", "supplyId"],
  data() {
    return {
      total: null,
      supply: null,
      records: [],
      moment: moment,
      managers: [],
      buildings_unit: [],
      id_buildings_list: [],
      offices_unit: [],
      id_managers_list: [],
      id_offices_list: [],
      id_goods_list: [],
      goods: [],
      goodamounts: [],
      null_activegood: 0,
      props: ["id"],
    };
  },
  methods: {
    async getsupply() {
      const response = await axios.get("/supplies/" + this.supplyId + ".json");
      this.supply = response.data;
    },

    /*
    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Bien",
        message: "¿Esta seguro de querer anular el bien?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulGoodClick(item),
      });
    },

    async AnulGoodClick(item) {
      const response = await axios.put("/supplies/" + item + ".json", {
        activegood: this.null_activegood,
      });
      window.location.href = "/units/" + this.unitId;
    },
*/
  },
  created() {
    this.getsupply();
  },
};
</script>

<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(editCategory())">
        <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="NewCategories">
            <div class="columns is-centered">
              <div class="column is-centered ">

                <div class="columns my-0">
                  <div class="column">
                    <b-field label="Nombre Categoria *">
                      <ValidationProvider name="Nombre" rules="required|max:255" v-slot="{ errors }">
                        <b-input v-model="name_category" placeholder="Ingrese el nombre del Categoría"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

              </div>
            </div>

            <div class="columns is-mobile">

              <div class="column">
                <b-button label="Cerrar" @click="$emit('close')" />
              </div>

              <div class="column has-text-right">
                <p class="control">
                  <b-button label="Editar Categoría de Bien" type="is-info" icon-left="save" @click="check()" />
                </p>
              </div>

            </div>

          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "edit_category",
  props: [
      'queryType', 'modalHeader', 'id'
  ],

  data() {
      return {
          isLoading: false,

          //Información a traer
          name_category: null,
          categories: [],

      }
  },

  methods: {
    async getCategory() {
      this.isLoading = true
        await axios.get('/categories/'+ this.id + '.json')
        .then(response => {
            this.name_category = response.data.alt_name_category
        })
        .catch(error => {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo un error al obtener los datos de la Categoría, favor inténtelo nuevamente más tarde.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
            this.$emit('close')
        })
      this.isLoading = false
    },

    async editCategory() {
        this.isLoading = true
        await axios.put('/categories/' + this.id + '.json', {
          alt_name_category: this.name_category
        })
        .then(response => {
            this.$buefy.dialog.alert({
                title: 'Logrado',
                message: 'Se ha editado correctamente la Categoría.',
                type: 'is-success',
                hasIcon: true,
                icon: 'circle-check',
                iconPack: 'fas',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
            this.$emit('refresh')
            this.$emit('close')
        })
        .catch(error => {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo un error al editar el nombre de la Categoría, favor revisar los errores. <br>Errores:<br>' + error.response.data,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        })
        this.isLoading = false
    },

    async check() {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
            this.$buefy.dialog.alert({
            message: "Debe Ingresar un nombre de la Categoría.",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            });
        }
        else {
            this.$buefy.dialog.confirm({
                title: "Editar Categoría",
                message: `¿Esta seguro de editar la Categoría?`,
                cancelText: "Cancelar",
                confirmText: "Editar",
                type: "is-info",
                onConfirm: () => this.editCategory(),
            });
        }
        
    },
  },

  created() {
      this.getCategory()
  }
}
</script>
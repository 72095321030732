<template>
  <section>
    <br>
    <div class="container box">

      <b-modal :active="isModalnewstatus" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
        width="100%" :can-cancel="canCancel">
        <template>
          <new-condition-good :good-id="goodId" :id="selected" :modal-header="'Reportar Bien'" query-type="create"
            @close="closeModalCreateStatus"></new-condition-good>
        </template>
      </b-modal>

      <b-modal :active="isModalEditCharge" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
        width="100%" :can-cancel="canCancel">
        <template>
          <edit-good-charge :oldDutyManagerId="oldDutyManagerId" :id="selected" :modal-header="'Editar Persona Encargada'"
            query-type="update" @close="closeModalEditCharge" @refresh="getInformation" />
        </template>
      </b-modal>


      <div class="content">
        <h1>Información del bien</h1>
      </div>



      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Detalle del bien">
            <div v-if="good" class="columns is-centered">
              <div class="column is-centered">
                <div class="columns">
                  <div class="column">
                    <b-field label="Nombre:">
                      <b-input v-model="good.alt_name_good" type="text" readonly></b-input>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Numero identificación Usach:">
                      <b-input v-model="good.identification_number_good" type="text" readonly></b-input>
                    </b-field>
                  </div>
                </div>

                <div class="columns">


                  <div class="column">
                    <b-field label="Marca:">
                      <b-input v-model="good.trademark.alt_name_trademark" type="text" readonly></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Categoria: ">
                      <b-input v-model="good.category.alt_name_category" type="text" readonly></b-input>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Modelo:">
                      <b-input v-model="good.model" type="text" readonly></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Numero de serie: ">
                      <b-input v-model="good.serie_number" type="text" readonly></b-input>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Monto Bruto: ">
                      <b-input v-model="good.amount" type="text" readonly></b-input>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Fecha de compra:">
                      <b-input v-model="good.date_purchase" type="text" readonly></b-input>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Fuente de Financiamiento: ">
                      <b-input v-model="good.financial_source.alt_name_financial_source" type="text" readonly></b-input>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Tipo de inversión: ">
                      <b-input v-model="good.investment_type.alt_name_investment_type" type="text" readonly></b-input>
                    </b-field>
                  </div>
                </div>

                <div class="columns">

                  <div class="column">
                    <b-field label="Persona encargada: ">
                      <b-input v-model="good.duty_manager.name_duty_manager" type="text" readonly></b-input>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Unidad: ">
                      <b-input v-model="good.duty_manager.alt_name_unit" type="text" readonly></b-input>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Edificio: ">
                      <b-input v-model="good.office.alt_name_building" type="text" readonly></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Dependencia: ">
                      <b-input v-model="good.office.alt_name_office" type="text" readonly></b-input>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Fecha de ultimo chequeo:">
                      <b-input v-model="good.date_checkup" type="text" readonly></b-input>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Numero interno:">
                      <b-input v-model="good.internal_number" type="text" readonly></b-input>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Estado:">
                      <b-input v-model="good.operative" type="text" readonly></b-input>
                    </b-field>
                  </div>

                </div>


                <div class="columns">
                  <div class="column">
                    <b-field label="Descripción:">
                      <b-input v-model="good.good_description" type="textarea" readonly></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p>Cargando</p>
            </div>
        </b-tab-item>

        <b-tab-item label="Persona Encargada">

          <b-tabs type="is-boxed" position="is-centered">

            <b-tab-item label="Persona Encargada Actual">
              <div class="columns">
                <div class="column has-text-right">
                  <b-button type="is-primary" label="Editar Persona Encargada" icon-left="pen"
                    @click="editCharge(goodId)"
                    v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update_charge'))"/>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Unidad">
                    <b-input v-model="good.duty_manager.alt_name_unit" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Persona encargada">
                    <b-input v-model="good.duty_manager.name_duty_manager" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Edificio">
                    <b-input v-model="good.office.alt_name_building" type="text" readonly></b-input>
                  </b-field>
                </div>

                <div class="column">
                  <b-field label="Dependencia">
                    <b-input v-model="good.office.alt_name_office" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="Historial de Personas Encargadas">
              <div class="columns">
                <div class="column">

                  <b-table :data="good.record_charges" :paginated="true" per-page="10" striped hoverable sortable: true
                    pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small"
                    aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
                    aria-current-label="Página Actual" pagination-size="is-small">

                    <b-table-column field="name_duty_manager" label="Nombre Persona" sortable searchable>
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.name_duty_manager }}
                      </template>
                    </b-table-column>

                    <b-table-column field="start_formatted_date" label="Fecha Inicio" sortable searchable :custom-search="searchCustomDateStart">
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha Inicio..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.start_formatted_date._isValid ? new Date(props.row.start_formatted_date).toLocaleDateString("es-CL") : '' }}
                      </template>
                    </b-table-column>

                    <b-table-column field="end_formatted_date" label="Fecha Término" sortable searchable :custom-search="searchCustomDateEnd">
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha Término..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.end_formatted_date._isValid ? new Date(props.row.end_formatted_date).toLocaleDateString("es-CL") : 'Actualidad' }}
                      </template>
                    </b-table-column>

                  </b-table>

                </div>
              </div>
            </b-tab-item>

          </b-tabs>

        </b-tab-item>

        <b-tab-item label="Historial">

          <div class="column">
            <b-table :data="good.versions" :paginated="true" per-page="80" striped hoverable :pagination-simple="false"
              sortable: true pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small"
              aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
              aria-current-label="Página actual">

              <b-table-column :visible="false" field="id" label="ID" sortable numeric searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar ID..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="full_name" label="Nombre usuario" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre Usuario..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.full_name }}
                </template>
              </b-table-column>

              <b-table-column field="event" label="Acción" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Acción..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.event }}
                </template>
              </b-table-column>

              <b-table-column field="date_event" label="Fecha de acción" sortable searchable :custom-search="searchCustomDate">
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha de Acción..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.date_event._isValid ? new Date(props.row.date_event).toLocaleString("es-CL") : '' }}
                </template>
              </b-table-column>




            </b-table>
          </div>

        </b-tab-item>
      </b-tabs>
      <b-button tag="a" href="/duty_managers/mygoods" label="Volver">
      </b-button>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>
  
   
<script>
import axios from "axios";
import IndexGoods from "./index_good.vue";
import EditGood from "./edit_good.vue";
import EditGoodCharge from "./EditChargeGood.vue"
import NewConditionGood from "../condition_good/new_condition_good.vue";
import moment from "moment";
import {searchDate} from "../../packs/utilities";

export default {
  name: "show_good",
  components: {
    "index-good":
      IndexGoods,
    NewConditionGood,
    EditGood,
    EditGoodCharge,
  },
  props: ["goodId", "id", 'queryType', 'modalHeader','permissions'],
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Detalle de Mi Bien';
  },

  data() {
    return {
      good: {
        condition_good: {
          condition: "",
        },
        trademark: {},
        category: {},
        financial_source: {},
        investment_type: {},
        duty_manager: {},
        office: {},
      },
      selected: null,
      oldDutyManagerId: null,
      auxdatebuy: [],
      auxdatedesign: [],
      canCancel: ["escape"],
      isModalnewstatus: false,
      props: ["id"],
      moment: moment,
      isModalEditCharge: false,
      isLoading: false,
    };
  },
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
          return true;
      } else {
          return false;
      }
    },

    async getGood() {
      await axios
        .get("/goods/" + this.goodId + ".json")
        .then((response) => {
          this.good = response.data
          this.good["record_charges"] = this.good["record_charges"].map(record => {
            var newRecord = record
            newRecord["start_formatted_date"] = moment(newRecord["start_formatted_date"], ["YYYY-MM-DD","DD-MM-YYYY"])
            newRecord["end_formatted_date"] = moment(newRecord["end_formatted_date"], ["YYYY-MM-DD","DD-MM-YYYY"])
            return newRecord
          })
          this.good.date_purchase = moment(this.good.date_purchase, ["YYYY-MM-DD","DD-MM-YYYY"])
          this.good.date_purchase = new Date(this.good.date_purchase).toLocaleDateString("es-CL")

          this.good.versions = this.good.versions.map(version => {
            var newVersion = version
            newVersion.date_event = moment(newVersion.date_event, ["YYYY-MM-DD H:m:s","DD-MM-YYYY H:m:s"])
            return newVersion
          })

          this.good.versions.sort(function (a,b) {
            return new Date(b.date_event) - new Date(a.date_event)
          })

        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            message: "Error en la obtención del Bien",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
            confirmText: "Ok",
          });
        })
      
    },

    async Clickeditgood(item) {
      const response = (window.location.href = "/goods/" + item + "/edit");
    },


    async createConditionGood(item) {
      this.selected = item;

      this.isModalnewstatus = true;
    },

    async closeModalCreateStatus() {
      this.isModalnewstatus = false;
    },

    async editCharge(item) {
      this.selected = item;
      this.oldDutyManagerId = this.good.duty_manager_id;
      this.isModalEditCharge = true;
    },

    async closeModalEditCharge() {
      this.isModalEditCharge = false;
    },

    async getInformation() {
      this.isLoading = true
      await this.getGood();
      this.isLoading = false
    },

    searchCustomDateStart (row, input) {
      return searchDate (row.start_formatted_date, input)
    },

    searchCustomDateEnd (row, input) {
      return searchDate (row.end_formatted_date, input)
    },
    searchCustomDate (row, input) {
      return searchDate (row.date_action_formatted, input)
    },
  },
  created() {
    this.getInformation()
  },
};
</script>
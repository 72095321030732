<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmregister())">
        <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="newBuilding">
            <b-tabs type="is-boxed" position="is-centered">
              <b-tab-item label="Añadir Edificio">
                <div class="columns is-centered">
                  <div class="column is-centered">

                    <div class="my-0 mb-6">

                      <div class="columns">
                        <div class="column">
                          <b-field label="Nombre de edificio *">
                            <ValidationProvider name="Nombre de edificio" rules="required|max:255" v-slot="{ errors }">
                              <b-input v-model="aux_name_building" placeholder="Ingrese nombre"></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                        <div class="column">
                          <b-field label="Ubicación *">
                            <ValidationProvider name="Ubicación" rules="required|max:255" v-slot="{ errors }">
                              <b-input v-model="aux_location_building" placeholder="Ingrese ubicacion"></b-input>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                        <div class="column">
                          <b-field label="N° Identificación *">
                            <ValidationProvider name="N° Identificación" rules="required|max:255" v-slot="{ errors }">
                              <b-input v-model="aux_identification_number_building" type="number"
                                placeholder="Ingrese numero" />
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                      </div>

                      <div class="columns">
                        <div class="column">
                          <b-field label="Unidad *">
                            <ValidationProvider name="Unidad" rules="required" v-slot="{ errors }">
                              <multiselect v-model="aux_unit_id" :options="units.map((type) => type.id)" :custom-label="(opt) => units.find((x) => x.id === opt).name_unit
                              " placeholder="Seleccionar Unidad" selectLabel="Presione para seleccionar"
                                selectedLabel="Seleccionado" :allow-empty="true"
                                :disabled="aux_duty_manager_id !== null" @input="getdutymanagerbyunit(aux_unit_id)">
                              </multiselect>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                        <div class="column">
                          <b-field label="Persona asociada encargada *">
                            <ValidationProvider name=" Persona asociada encargada" rules="required" v-slot="{ errors }">
                              <multiselect v-model="aux_duty_manager_id" :options="managersaux.map((type) => type.id)"
                                :custom-label="(opt) =>
                                managersaux.find((x) => x.id === opt).name_duty_manager
                                " placeholder="Seleccionar persona asociada encargada"
                                selectLabel="Presione para seleccionar" selectedLabel="Seleccionado"
                                deselectLabel="Presione para deseleccionar" :allow-empty="true"
                                :disabled="aux_unit_id == null">
                              </multiselect>
                              <span class="validation-alert">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                      </div>

                    </div>



                  </div>
                </div>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <p class="control">
                      <b-button label="Añadir Edificio" icon-left="save" type="is-success" @click="check()" />
                    </p>
                  </div>

                </div>

              </b-tab-item>

              <b-tab-item label="Subida masiva">

                <div class="content">
                  <h2>Seleccionar archivo</h2>
                </div>


                <b-field class="file">
                  <b-upload v-model="file" expanded>
                    <a class="button is-info is-fullwidth">
                      <b-icon icon="upload"></b-icon>
                      <span>{{ file.name || "Subir Archivo" }}</span>
                    </a>
                  </b-upload>
                </b-field>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <b-button label="Importar" icon-left="upload" type="is-info" @click="import_file" />
                  </div>

                </div>
              </b-tab-item>
            </b-tabs>
          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "new_building",
  props: [
    'queryType', 'modalHeader'
  ],
  data() {
    return {
      selected: null,
      buildings: [],
      managers: [],
      managersaux: [],
      units: [],
      aux_name_building: null,
      aux_identification_number_building: null,
      aux_location_building: null,
      aux_unit_id: null,
      aux_duty_manager_id: null,
      aux_active: true,

      //IMPORTS
      import_type: "Propiedad Intelectual",
      file: { name: "" },
      dropFiles: [],
      isLoading: false,
      errores: [],
      file_name_errors: "",
      cabeceras: [],
      hojas: [],
      errorFile: { name: "" }

    };
  },
  methods: {
    async getdutymanager() {
      this.isLoading = true
      const response = await axios.get("/duty_managers/showactivedutymanager.json")
      this.managers = response.data
      this.isLoading = false
    },

    async getdutymanagerbyunit(item) {
      this.isLoading = true
      const response = await axios.get(
        "/duty_managers/showdutymanagerunit/" +
        item +
        ".json"
      )
      this.managersaux = response.data
      this.isLoading = false
    },

    async getunit() {
      this.isLoading = true
      const response = await axios.get("/units/showactiveunit.json")
      this.units = response.data
      this.isLoading = false
    },

    async createClick() {
      this.isLoading = true
      await axios.post("/buildings.json", {
        name_building: this.aux_name_building,
        identification_number_building: this.aux_identification_number_building,
        location_building: this.aux_location_building,
        unit_id: this.aux_unit_id,
        duty_manager_id: this.aux_duty_manager_id,
        active_building: this.aux_active,
      })
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha agregado correctamente el Edificio al sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al ingresar los datos del Edificio, favor revisar los errores.<br>Errores:<br>' + error.response.data,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async check() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert(
          {
            title: 'Error',
            message: 'Algunos campos presentan errores.', 
            type: 'is-danger',
            hasIcon: true, 
            icon: 'times-circle', 
            iconPack: 'fa', 
            ariaRole: 'alertdialog', 
            ariaModal: true
          }
        )
      }
      else {
        this.$buefy.dialog.confirm({
          title: "Registrar Edificio",
          message: `¿Esta seguro de registrar esté edificio?`,
          cancelText: "Cancelar",
          confirmText: "Registrar",
          type: "is-info",
          onConfirm: () => this.createClick(),
        });
      }
    },



    //IMPORTS


    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    import_file() {
      this.isLoading = true;
      var url = ""
      url = "/buildings/administrativo/import_building.json"
      let formData = new FormData();
      formData.append("file", this.file);
      this.errorFile = this.file;
      axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        if (response.data["errores"].length > 0) {
          this.errores = response.data["errores"];
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'El contenido del archivo "' + this.file.name + '" no han podido ser importado, favor revisar errores. <br>Errores:<br>' + this.errores,
            confirmText: 'Ok',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.file_name_errors = this.file.name;
          this.cabeceras = response.data["cabeceras"];
          if (this.import_type == "Convenios") {
            this.hojas = response.data["sheets"]
          }
          else {
            this.hojas = []
          }
        }
        else {
          this.import_type = '';
          this.$buefy.dialog.alert({
            title: 'Logrado',
            message: 'Su archivo "' + this.file.name + '" ha sido importado con éxito',
            confirmText: 'Ok',
            type: 'is-success',
            hasIcon: true,
            icon: 'circle-check',
            iconPack: 'fas',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.file = { name: "" };
          this.errores = [];
          this.file_name_errors = "";
          this.$emit('refresh')
          this.$emit('close')
        }

      })
        .catch(error => {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
      this.isLoading = false

    },
    importErrors() {
      let vm = this;
      vm.isLoading = true;
      axios
      .post("/load-data/export-errors.xlsx", { cabeceras: vm.cabeceras, errores: vm.errores, hojas: vm.hojas },
        {
          responseType: "blob"
        })
      .then(response => {
        vm.isLoading = false;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Errores importacion masiva de datos ' + vm.errorFile.name.replace(".xlsx", "") + ' ' + new Date().toLocaleDateString() + '.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        vm.isLoading = false;
        vm.$buefy.notification.open({
          message: 'Hubo un error en tu solicitud',
          type: 'is-danger'
        })
      })
    }
  },
  async created() {
    this.isLoading = true
    await this.getdutymanager()
    await this.getunit()
    this.isLoading = false
  },
};
</script>
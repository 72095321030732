<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Dependencias de la facultad</h1>
      </div>
      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Dependencias de la facultad">
          <div class="has-text-right">
            <b-button label="Añadir dependencia" type="is-success" icon-left="plus" @click="createOffice()"
              v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Office', 'create'))"/>
          </div>

          <div>
            <b-modal :active="isModalActive" aria-modal :destroy-on-hide="true" aria-role="dialog"
              width="90%" :can-cancel="canCancel">
              <template>
                <new-office modal-cancel="true" :modal-header="'Registro de Dependencia'" query-type="create"
                  @close="closeModalCreate" @refresh="toRefreshActiveOffices"/>
              </template>
            </b-modal>

            <b-modal :active="isModalActiveEdit" aria-modal :destroy-on-hide="true" aria-role="dialog"
              width="90%" :can-cancel="canCancel">
              <template>
                <edit-office :office-id="officeId" :id="selected" :modal-header="'Editar Dependencia'" query-type="update"
                  @close="closeModalEdit" @refresh="toRefreshActiveOffices"/>
              </template>
            </b-modal>
          </div>

          <div class="column">
            <b-table :data="offices" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
              default-sort="inventores.name" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="name_office" label="Nombre Dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_office }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_office" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_office }}
                </template>
              </b-table-column>

              <b-table-column field="building.name_building" label="Edificio" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Edificio..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.building.alt_name_building }}
                </template>
              </b-table-column>

              <b-table-column field="location" label="Ubicación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Ubicación..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.location }}
                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach" 
                      tag="a" :href="/offices/ + props.row.id" target="_blank"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Office', 'show'))"/>
                  </div>
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="pen" type="is-primary" 
                    @click.native="editOffice(props.row.id)"
                    v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Office', 'update'))"/>
                  </div>
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="ban" type="is-danger" 
                        @click.native="confirmisempty(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Office', 'disable_office'))"/>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>

        <b-tab-item label="Dependencias anuladas">
          <div class="column">
            <b-table :data="null_offices" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
              default-sort="inventores.name" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="name_office" label="Nombre Dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_office }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_office" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_office }}
                </template>
              </b-table-column>

              <b-table-column field="building.name_building" label="Edificio" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Edificio..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.building.alt_name_building }}
                </template>
              </b-table-column>

              <b-table-column field="location" label="Ubicación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Ubicación..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.location }}
                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                        tag="a" :href="/offices/ + props.row.id" target="_blank"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Office', 'show'))"/>
                  </div>
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="trash-arrow-up" type="is-success"
                        @click.native="confirmGoodRest(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Office', 'activate_office'))"/>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"/>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import NewOffice from "./new_office.vue";
import EditOffice from "./edit_office.vue";
export default {
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Dependencias';
  },
  data() {
    return {
      offices: [],
      goods: [],
      goods_office: [],
      id_goods_list: [],
      null_offices: [],
      canCancel: ["escape"],
      isModalActive: false,
      isModalActiveShow: false,
      isModalActiveEdit: false,
      selected: null,
      null_active: false,
      des_active: true,
      pendiing_good: 2,
      isLoading: false,
    };
  },
  components: { NewOffice, EditOffice },
  props: ["officeId", "permissions"],
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },
    async getoffices() {
      this.isLoading = true
      const response = await axios.get("/offices/showactiveoffice.json")
      this.offices = response.data
      this.isLoading = false
    },

    async null_getunit() {
      this.isLoading = true
      const response = await axios.get("/offices/shownulloffice.json")
      this.null_offices = response.data
      this.isLoading = false
    },

    async RestClick(id) {
      await axios.put('/offices/' + id + '/activate.json')
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha restaurado correctamente la Dependencia.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = true
        this.getoffices();
        this.null_getunit();
        this.isLoading = false
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al restaurar la Dependencia.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
    },
    confirmGoodRest(item) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Dependencia",
        message: "¿Esta seguro de querer restaurar la Dependencia?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
    },

    async confirmisempty(item) {
      const response = await axios.get(
        "/goods/showgoodoffice/" + item + ".json"
      );
      this.goods_office = response.data;
      if (this.goods_office.length == 0) {
        this.confirmGoodNull(item);
      } else {
        this.$buefy.dialog.alert({
          title: "No se puede anular",
          type: "is-danger",
          message: "Existen bienes designados en la Dependencia",
          confirmText: "Aceptar",
        });
      }
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Dependencia",
        message: "¿Esta seguro de querer anular la Dependencia?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });
    },

    async AnulClick(id) {
      await axios.put('/offices/' + id + '/disable.json')
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha anulado correctamente la Dependencia.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = true
        this.getoffices();
        this.null_getunit();
        this.isLoading = false
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al anular la Dependencia.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
    },

    async getgoodbyoffice(item) {
      const response = await axios.get(
        "/goods/showgoodoffice/" + item + ".json"
      );
      this.goods = response.data;
    },

    async Clicknewoffice() {
      const response = (window.location.href = "/offices/new");
    },
    async Clickshowunit(item) {
      const response = (window.location.href = "/units/" + item);
    },
    async Clickshowgood(item) {
      const response = (window.location.href = "/goods/" + item);
    },
    async Clickshowdutymanager(item) {
      const response = (window.location.href = "/duty_managers/" + item);
    },
    async Clickshowbuilding(item) {
      const response = (window.location.href = "/buildings/" + item);
    },
    async createOffice() {
      this.isModalActive = true;
    },
    async closeModalCreate() {
      this.isModalActive = false;
    },
    async ShowModalOffice(item) {
      this.selected = item;
      this.isModalActiveShow = true;
    },
    async closeModalShow() {
      this.isModalActiveShow = false;
    },

    async editOffice(item) {
      this.selected = item;
      this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },
    async toRefreshActiveOffices(){
      
      this.isLoading = true
      await this.getoffices();
      this.isLoading = false

    },
  },
  async created() {
    this.isLoading = true
    await this.getoffices()
    await this.null_getunit()
    this.isLoading = false
  },
};
</script>

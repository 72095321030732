<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Auditoría</h1>
      </div>
      <br />
      <br />
      <div v-if="version" class="columns is-centered">
        <div class="column is-centered">
          <div class="columns">
            <div class="column">
              <b-field label="Id:">
                <b-input v-model="version.id" type="text" readonly></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Accion:">
                <b-input v-model="version.event" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Usuario Autor">
                <b-input v-model="version.email" type="text" readonly></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Fecha:">
                <b-input v-model="version.created_at" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="Tipo de objeto:">
                <b-input v-model="version.item_type" type="text" readonly></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Item_id:">
                <b-input v-model="version.item_id" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="URL:">
                <b-input v-model="version.url" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>

          <div v-if="actual_document_info">
            <div v-show="object_type == 'Good'">
              <h1 class="is-size-1 has-text-weight-light">Estado del objeto</h1>
              <div class="columns">
                <div class="column">
                  <b-field label="Id del objeto">
                    <b-input v-model="object_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Id del objeto actual">
                    <b-input v-model="actual_document_info.id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="namegood">
                    <b-input v-model="aux_namegood" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="namegood actual">
                    <b-input v-model="actual_document_info.namegood" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="identification_number_good">
                    <b-input v-model="aux_identification_number_good" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="tag_type_document_id actual">
                    <b-input v-model="actual_document_info.identification_number_good" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="name_trademark">
                    <b-input v-model="aux_trademark" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="name_trademark actual">
                    <b-input v-model="actual_document_info.trademark_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="name_category">
                    <b-input v-model="aux_category" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="name_category actual">
                    <b-input v-model="actual_document_info.category_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="model">
                    <b-input v-model="aux_model" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="model actual">
                    <b-input v-model="actual_document_info.model" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="serie_number">
                    <b-input v-model="aux_serie_number" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="serie_number actual">
                    <b-input v-model="actual_document_info.serie_number" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="amount">
                    <b-input v-model="aux_amount" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="amount actual">
                    <b-input v-model="actual_document_info.amount" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="date_purchase">
                    <b-input v-model="aux_date_purchase" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="date_purchase actual">
                    <b-input v-model="actual_document_info.date_purchase" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="financial_source_id">
                    <b-input v-model="aux_financial_source" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="financial_source_id actual">
                    <b-input v-model="actual_document_info.financial_source_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="investment_type_id">
                    <b-input v-model="aux_investment_type" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="investment_type_id actual">
                    <b-input v-model="actual_document_info.investment_type_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="duty_manager_id">
                    <b-input v-model="aux_duty_manager_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="duty_manager_id actual">
                    <b-input v-model="actual_document_info.duty_manager_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="office_id">
                    <b-input v-model="aux_office_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="office_id actual">
                    <b-input v-model="actual_document_info.office_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="operative">
                    <b-input v-model="aux_operative" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="operative actual">
                    <b-input v-model="actual_document_info.operative" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="date_checkup">
                    <b-input v-model="aux_date_chekup" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="date_checkup actual">
                    <b-input v-model="actual_document_info.date_checkup" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="good_description">
                    <b-input v-model="aux_good_description" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="good_description actual">
                    <b-input v-model="actual_document_info.good_description" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </div>

            <div v-show="object_type == 'DutyManager'">
              <h1 class="is-size-1 has-text-weight-light">Estado del objeto</h1>
              <div class="columns">
                <div class="column">
                  <b-field label="Id del objeto">
                    <b-input v-model="object_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Id del objeto actual">
                    <b-input v-model="actual_document_info.id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="name_duty_manager">
                    <b-input v-model="aux_name" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="name_duty_manager actual">
                    <b-input v-model="actual_document_info.name_duty_manager" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="rut">
                    <b-input v-model="aux_rut" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="rut actual">
                    <b-input v-model="actual_document_info.rutCompleto" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="email">
                    <b-input v-model="aux_email" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="email actual">
                    <b-input v-model="actual_document_info.email" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="unit_id">
                    <b-input v-model="aux_unit_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="unit_id actual">
                    <b-input v-model="actual_document_info.unit_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="user_id">
                    <b-input v-model="aux_user_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="user_id actual">
                    <b-input v-model="actual_document_info.user_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </div>

            <div v-show="object_type == 'Office'">
              <h1 class="is-size-1 has-text-weight-light">Estado del objeto</h1>
              <div class="columns">
                <div class="column">
                  <b-field label="Id del objeto">
                    <b-input v-model="object_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Id del objeto actual">
                    <b-input v-model="actual_document_info.id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="name_office">
                    <b-input v-model="aux_name_office" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="name_office actual">
                    <b-input v-model="actual_document_info.name_office" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="identification_number_office">
                    <b-input v-model="aux_identification_number_office" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="identification_number_office actual">
                    <b-input v-model="actual_document_info.identification_number_office
                      " type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="location">
                    <b-input v-model="aux_location" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="location actual">
                    <b-input v-model="actual_document_info.location" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="office_description">
                    <b-input v-model="aux_office_description" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="office_description actual">
                    <b-input v-model="actual_document_info.office_description" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="hours_available">
                    <b-input v-model="aux_hours_available" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="hours_available actual">
                    <b-input v-model="actual_document_info.hours_available" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="square_meter">
                    <b-input v-model="aux_square_meter" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="square_meter actual">
                    <b-input v-model="actual_document_info.square_meter" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="campus_identification">
                    <b-input v-model="aux_campus_identification" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="campus_identification actual">
                    <b-input v-model="actual_document_info.campus_identification" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="duty_manager_id">
                    <b-input v-model="aux_duty_manager_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="duty_manager_id actual">
                    <b-input v-model="actual_document_info.duty_manager_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="building_id">
                    <b-input v-model="aux_building_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="building_id actual">
                    <b-input v-model="actual_document_info.building_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </div>

            <div v-show="object_type == 'Building'">
              <h1 class="is-size-1 has-text-weight-light">Estado del objeto</h1>

              <div class="columns">
                <div class="column">
                  <b-field label="Id del objeto">
                    <b-input v-model="object_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Id del objeto actual">
                    <b-input v-model="actual_document_info.id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="name_building">
                    <b-input v-model="aux_name_building" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="name_building actual">
                    <b-input v-model="actual_document_info.name_building" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="identification_number_building">
                    <b-input v-model="aux_identification_number_building" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="identification_number_building actual">
                    <b-input v-model="actual_document_info.identification_number_building
                      " type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="location_building">
                    <b-input v-model="aux_location_building" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="location_building actual">
                    <b-input v-model="actual_document_info.location_building" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="unit_id">
                    <b-input v-model="aux_unit_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="unit_id actual">
                    <b-input v-model="actual_document_info.unit_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="duty_manager_id">
                    <b-input v-model="aux_duty_manager_id" type="text" readonly></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="duty_manager_id actual">
                    <b-input v-model="actual_document_info.duty_manager_id" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </div>

            <div v-show="object_type == 'RecordCharge'">
              <h1 class="is-size-1 has-text-weight-light">Estado del objeto</h1>
              <div class="columns">
                <div class="column">
                  <b-field label="ID del Bien a quien se cambio el cargo" v-show="good_id != null">
                    <b-input v-model="good_id" type="number" readonly/>
                  </b-field>
                  <b-field label="ID de la Dependencia a quien se cambio el cargo" v-show="office_id != null">
                    <b-input v-model="office_id" type="number" readonly/>
                  </b-field>
                  <b-field label="ID del Edificio a quien se cambio el cargo" v-show="building_id != null">
                    <b-input v-model="building_id" type="number" readonly/>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ID del Encargado que fue actualizado">
                    <b-input v-model="duty_manager_id" type="number" readonly/>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Fecha Inicio Cargo">
                    <b-input v-model="start_date" type="text" readonly/>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Fecha Inicio Cargo Actual">
                    <b-input v-model="actual_document_info.start_date" type="text" readonly/>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Fecha Término Cargo">
                    <b-input v-model="end_date" type="text" readonly/>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Fecha Término Cargo Actual">
                    <b-input v-model="actual_document_info.end_date" type="text" readonly/>
                  </b-field>
                </div>
              </div>

            </div>
            
          </div>
        </div>
      </div>
      <div v-else>
        <p>Cargando</p>
      </div>
      <b-button tag="a" href="/versions" label="Volver">
      </b-button>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  props: ["id", "queryType", "modalHeader", "versionId", "Id"],
  components: {},
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Detalle Auditoría';
  },
  data() {
    return {
      version: null,
      props: ["id"],
      object_type: null,
      object_id: null,
      actual_document_info: null,
      good: null,
      duty_manager: null,
      office: null,
      building: null,
      no_info: "No disponible",
      //good
      aux_namegood: null,
      aux_good_description: null,
      aux_identification_number_usach: null,
      aux_identification_number_good: null,
      aux_serie_number: null,
      aux_internal_number: null,
      aux_duty_manager_id: null,
      aux_office_id: null,
      aux_unit_id: null,
      aux_building_id: null,
      aux_financial_source_id: null,
      aux_investment_type_id: null,
      aux_amount: null,
      aux_date_purchase: null,
      aux_financial_source: null,
      aux_investment_type: null,
      aux_operative: null,
      aux_model: null,
      aux_date_chekup: null,
      aux_trademark: null,
      aux_category: null,
      //Duty_manager
      aux_name: null,
      aux_rut: null,
      aux_email: null,
      aux_unit_id: null,
      aux_user_id: null,
      //Office
      aux_name_office: null,
      aux_identification_number_office: null,
      aux_location: null,
      aux_office_description: null,
      aux_hours_available: null,
      aux_square_meter: null,
      aux_campus_identification: null,
      aux_duty_manager_id: null,
      aux_building_id: null,

      //Building
      aux_name_building: null,
      aux_identification_number_building: null,
      aux_location_building: null,
      aux_unit_id: null,
      aux_duty_manager_id: null,

      //RecordCharge
      duty_manager_id: null,
      good_id: null,
      start_date: null,
      end_date: null,
      office_id: null,
      building_id: null,
    };
  },
  methods: {
    async get_versions() {
      this.isLoading = true
      await axios.get("/versions/" + this.versionId + ".json")
      .then(response => {
        this.version = response.data;
        this.object_type = this.version.item_type;

        this.version["created_at"] = moment(this.version["created_at"], ["YYYY-MM-DD H:m:s","DD-MM-YYYY H:m:s"])
        this.version["created_at"] = new Date(this.version["created_at"]).toLocaleString("es-CL")

        if (this.version.object != null && this.object_type == "Good") {
          this.object_id = this.version.formatted_object.id;
          this.aux_namegood = this.version.formatted_object.namegood;
          this.aux_good_description =
            this.version.formatted_object.good_description;
          this.aux_identification_number_good =
            this.version.formatted_object.identification_number_good;
          this.aux_serie_number = this.version.formatted_object.serie_number;
          this.aux_internal_number =
            this.version.formatted_object.internal_number;
          this.aux_duty_manager_id =
            this.version.formatted_object.duty_manager_id;
          this.aux_office_id = this.version.formatted_object.office_id;
          this.aux_amount = this.version.formatted_object.amount;
          this.aux_date_purchase = this.version.formatted_object.date_purchase;
          this.aux_financial_source =
            this.version.formatted_object.financial_source_id;
          this.aux_investment_type =
            this.version.formatted_object.investment_type_id;
          this.aux_operative = this.version.formatted_object.operative;
          this.aux_model = this.version.formatted_object.model;
          this.aux_date_chekup = this.version.formatted_object.date_checkup;
          this.aux_trademark = this.version.formatted_object.trademark_id;
          this.aux_category = this.version.formatted_object.category_id;
          this.get_good(this.object_id);
        }
        else if (this.version.object != null && this.object_type == "DutyManager") {
          this.object_id = this.version.formatted_object.id;
          this.aux_name = this.version.formatted_object.name_duty_manager;
          this.aux_rut = this.version.formatted_object.rut;
          this.aux_email = this.version.formatted_object.email;
          this.aux_unit_id = this.version.formatted_object.unit_id;
          this.aux_user_id = this.version.formatted_object.user_id;
          this.get_duty_manager(this.object_id);
        }
        else if (this.version.object != null && this.object_type == "Office") {
          this.object_id = this.version.formatted_object.id;
          this.aux_name_office = this.version.formatted_object.name_office;
          this.aux_identification_number_office =
            this.version.formatted_object.identification_number_office;
          this.aux_location = this.version.formatted_object.location;
          this.aux_office_description =
            this.version.formatted_object.office_description.value;
          this.aux_hours_available =
            this.version.formatted_object.hours_available;
          this.aux_square_meter = this.version.formatted_object.square_meter;
          this.aux_campus_identification =
            this.version.formatted_object.campus_identification;
          this.aux_duty_manager_id =
            this.version.formatted_object.duty_manager_id;
          this.aux_building_id = this.version.formatted_object.building_id;
          this.get_office(this.object_id);
        }
        else if (this.version.object != null && this.object_type == "Building") {
          this.object_id = this.version.formatted_object.id;
          this.aux_name_building = this.version.formatted_object.name_building;
          this.aux_identification_number_building =
            this.version.formatted_object.identification_number_building;
          this.aux_location_building =
            this.version.formatted_object.location_building;
          this.aux_unit_id = this.version.formatted_object.unit_id;
          this.aux_duty_manager_id =
            this.version.formatted_object.duty_manager_id;
          this.get_building(this.object_id);
        }
        else if (this.version.object != null && this.object_type == "RecordCharge"){
          this.object_id = this.version.formatted_object.id
          this.duty_manager_id = this.version.formatted_object.duty_manager_id
          this.good_id = this.version.formatted_object.good_id
          this.start_date = this.version.formatted_object.start_date
          this.end_date = this.version.formatted_object.end_date == null ? "Actualidad" : this.version.formatted_object.end_date
          this.office_id = this.version.formatted_object.office_id
          this.building_id = this.version.formatted_object.building_id
          this.get_record_charge(this.object_id)
        }
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: "Error en la obtención de la Auditoría",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          confirmText: "Ok",
        })
      })
      this.isLoading = false
    },

    async get_good(item) {
      this.isLoading = true
      await axios.get("/goods/" + item + ".json")
      .then(response => {
        this.actual_document_info = response.data
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al obtener el Bien ' + item + '.' ,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async get_duty_manager(item) {
      this.isLoading = true
      await axios.get("/duty_managers/" + item + ".json")
      .then(response => {
        this.actual_document_info = response.data
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al obtener la Persona Encargada ' + item + '.' ,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async get_office(item) {
      this.isLoading = true
      await axios.get("/offices/" + item + ".json")
      .then(response => {
        this.actual_document_info = response.data
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al obtener la Dependencia ' + item + '.' ,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },
    async get_building(item) {
      this.isLoading = true
      await axios.get("/buildings/" + item + ".json")
      .then(response => {
        this.actual_document_info = response.data
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al obtener el Edificio ' + item + '.' ,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },
    async get_record_charge(item) {
      this.isLoading = true
      await axios.get("/record_charges/" + item + ".json")
      .then(response => {
        this.actual_document_info = response.data
      })
      .catch((error) => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: "Error en la obtención del historial de cargo actual",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          confirmText: "Ok",
        })
      })
      this.isLoading = false
    }
  },
  created() {
    this.get_versions();
  },
};
</script>

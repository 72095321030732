<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Fuentes de financiamiento</h1>
      </div>
      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Fuentes de financiamiento de la facultad">
          <div class="has-text-right">
            <b-button label="Añadir Fuente de financiamiento" type="is-success" icon-left="plus"
              @click="createFinancial()"
              v-show="(isAuthorized('all', 'manage')) || (isAuthorized('FinancialSource', 'create'))"/>
          </div>
          <div>
            <b-modal :active="isModalActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <new-financial-sources modal-cancel="true" :modal-header="'Registro de Fuente de financiamiento'"
                  query-type="create" @close="isModalActive = false" @refresh="getInformation"/>
              </template>
            </b-modal>
            <b-modal :active="isModalEditActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <edit-financial-sources modal-cancel="true" :id="selected" :modal-header="'Editar Fuente de financiamiento'"
                  query-type="update" @close="isModalEditActive = false" @refresh="getInformation"/>
              </template>
            </b-modal>
          </div>
          <div class="column">
            <b-table id="table" :data="financial_sources" :paginated="true" per-page="10" striped hoverable
              :pagination-simple="false" pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom"
              default-sort-direction="asc" sort-icon="arrow-up" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>
              <b-table-column field="name_financial_source" label="Nombre Fuente" sortable searchable>
                <template #searchable="props">
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar Nombre..."
                    icon="magnifying-glass"/>
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_financial_source }}
                </template>
              </b-table-column>


              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach" 
                        tag="a" :href="/financial_sources/ + props.row.id" target="_blank"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('FinancialSource', 'show'))"/>
                  </div>
                  <div class="column">
                      <b-button pack="fas" icon-right="pen" type="is-primary"
                        @click.native="showModalEdit(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('FinancialSource', 'update'))"/>
                  </div>
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="ban" type="is-danger" 
                        @click.native="confirmGoodNull(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('FinancialSource', 'disable_financial_source'))"/>
                  </div>
                </div>
              </b-table-column>

            </b-table>
          </div>
        </b-tab-item>


        <b-tab-item label="Fuente de financiamiento anuladas">
          <div class="column">
            <b-table id="table" :data="null_financial_sources" :paginated="true" per-page="10" striped hoverable
              :pagination-simple="false" pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom"
              default-sort-direction="asc" sort-icon="arrow-up" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>
              <b-table-column field="name_financial_source" label="Nombre Fuente" sortable searchable>
                <template #searchable="props">
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar Nombre..."
                    icon="magnifying-glass"/>
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_financial_source }}
                </template>
              </b-table-column>


              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach" 
                        tag="a" :href="/financial_sources/ + props.row.id" target="_blank"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('FinancialSource', 'show'))"/>
                  </div>
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="trash-arrow-up" type="is-success"
                        @click.native="confirmGoodRest(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('FinancialSource', 'activate_financial_source'))"/>
                  </div>
                </div>
              </b-table-column>

            </b-table>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"/>
    </div>
  </section>
</template>


<script>
import axios from "axios";
import NewFinancialSources from "./new_financial_sources.vue";
import ShowFinancialSources from "./show_financial_sources.vue";
import EditFinancialSources from "./edit_financial_sources.vue";
export default {
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Fuentes Financieras';
  },
  data() {
    return {
      financial_sources: [],
      null_financial_sources: [],
      canCancel: ["escape"],
      isModalActive: false,
      isModalEditActive: false,
      selected: null,
      isLoading: false,
    };
  },
  components: {
    NewFinancialSources,
    ShowFinancialSources,
    EditFinancialSources,
  },
  props: ["financial_sourceId", 'permissions'],
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    async getFinancialSources() {
      this.isLoading = true
      await axios.get("/financial_sources/showfinancialsources.json")
      .then(response => {
        this.financial_sources = response.data.financial_sources_enabled
        this.null_financial_sources = response.data.financial_sources_disabled
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al solicitar los datos de las Fuentes de Financieras, favor de recargar la página e inténtelo nuevamente.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async AnulClick(item) {
      this.isLoading = true
      await axios.put("/financial_sources/" + item + "/disable.json")
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha anulado correctamente la Fuente Financiera.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.getInformation()
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al anular la Fuente Financiera.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async RestClick(item) {
      this.isLoading = true
      const response = await axios.put("/financial_sources/" + item + "/activate.json")
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha restaurado correctamente la Fuente Financiera.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.getInformation()
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al restaurar la Fuente Financiera.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },
    confirmGoodRest(item) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Fuente de financiamiento",
        message: "¿Esta seguro de querer restaurar la Fuente de financiamiento?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Fuente de financiamiento",
        message: "¿Esta seguro de querer anular la Fuente de financiamiento?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });
    },

    async Clicknewfinancial() {
      const response = (window.location.href = "/financial_sources/new");
    },
    async createFinancial() {
      this.isModalActive = true;
    },

    async showModalEdit (item) {
      this.selected = item
      this.isModalEditActive = true
    },

    async getInformation(){
      this.isLoading = true
      await this.getFinancialSources();
      this.isLoading = false

    },
  },
  async created() {
    await this.getInformation()
  },
};
</script>



<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmedit())">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('refresh'); $emit('close')" />
          </header>
          <section class="modal-card-body" ref="editConditionGood">

            <div v-if="condition_good" class="columns is-centered">

              <div class="column is-centered">

                <div class="columns">
                  <div class="column">
                    <b-field label="Detalle de la Solicitud">
                      <ValidationProvider name="Detalle" rules="max:255" v-slot="{ errors }">
                        <b-input v-model="condition_good.request_detail" type="textarea"
                          placeholder="Ingrese detalles del motivo de ingreso" />
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column" v-show='(condition_good.repairable != null)'>
                    <b-field label="Reparable">
                      <ValidationProvider name="Reparable" rules="max:255" v-slot="{ errors }">
                        <multiselect v-model="repairable" :options="reparable" placeholder="¿Es reaparable?"
                          selectLabel="Presione para seleccionar" selectedLabel="Seleccionado"
                          deselectLabel="Presione para deseleccionar" :allow-empty="true" />
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="URL del Documento Enlace">
                      <ValidationProvider name="URL" rules="max:255|url_with_protocol" v-slot="{ errors }">
                        <b-input v-model="condition_good.link" type="url"
                          placeholder="Ingrese la URL, por ejemplo 'https://www.google.com'" />
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('refresh'); $emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <p class="control">
                      <b-button label="Guardar" icon-left="floppy-disk" type="is-info" @click="confirmedit()" />
                    </p>
                  </div>

                </div>
              </div>

            </div>

            <div v-else>
              <p>Cargando</p>
            </div>
          </section>
          <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>


<script>
import axios from "axios"
import moment from "moment"
export default {
  props: [
    "queryType", "modalHeader", "id"
  ],
  data() {
    return {
      condition_good: null,
      repairable: null,
      reparable: ['SI', 'NO'],
      isLoading: false,
    };
  },
  methods: {
    async getconditiongoods() {
      this.isLoading = true
      await axios
        .get("/condition_goods/" + this.id + ".json")
        .then(response => {
          this.condition_good = response.data

          this.repairable = this.condition_good.repairable ? 'SI' : 'NO'
          this.isLoading = false
        })
        .catch(error => {
          var showError = null

          if (error.response != null) {
            showError = error.response.data
          } else {
            showError = error
          }
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al obtener los datos, favor revisar errores. <br>Errores:<br>' + showError,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading = false
        })

    },

    async EditClick() {
      this.isLoading = true
      await axios
        .put("/condition_goods/" + this.id + ".json", {
          link: this.condition_good.link,
          request_detail: this.condition_good.request_detail,
          repairable: this.repairable === 'SI',
        })
        .then(response => {
          this.$buefy.dialog.alert({
            title: 'Solicitud',
            message: 'Se ha logrado editar correctamente la Solicitud.',
            type: 'is-success',
            hasIcon: true,
            icon: 'circle-check',
            iconPack: 'fas',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.$emit('refresh', true)
          this.$emit('close')
        })
        .catch(error => {
          var showError = null

          if (error.response != null) {
            showError = error.response.data
          } else {
            showError = error
          }
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al editar la Solicitud, favor de revisar errores. <br>Errores:<br>' + showError,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
      this.isLoading = false
    },

    async confirmedit() {

      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert(
          {
            title: 'Error',
            message: 'Algunos campos presentan errores.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          }
        )
      } else {
        this.$buefy.dialog.confirm({
          title: "Editar el Bien",
          message: `¿Esta seguro de editar el Detalle de la Solicitud?`,
          cancelText: "Cancelar",
          confirmText: "Editar",
          type: "is-info",
          onConfirm: () => this.EditClick(),
        })
      }
    },


  },
  created() {
    this.getconditiongoods();
  },
};
</script>
<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
        <div class="content">
        <h1>Gestión de Bienes</h1>
        </div>

        <div>

            <b-modal :active="isModalNewGood" aria-modal :destroy-on-hide="true" aria-role="dialog"
              width="90%" :can-cancel="canCancel">
              <template>
                <new-good modal-cancel="true" :modal-header="'Registro de Bien'" query-type="create"
                  @close="isModalNewGood = false" @refresh="applyFilters"/>
              </template>
            </b-modal>

            <b-modal :active="isModalActiveEdit" aria-modal :destroy-on-hide="true" aria-role="dialog"
              width="90%" :can-cancel="canCancel">
              <template>
                <edit-good :good-id="goodId" :id="selected" :modal-header="'Editar Bien'" query-type="update"
                  @close="isModalActiveEdit = false" @refresh="applyFilters" />
              </template>
            </b-modal>

            <b-modal :active="isModalEditCharge" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
                width="100%" :can-cancel="canCancel">
                <template>
                <edit-good-charge :oldDutyManagerId="oldDutyManagerId" :id="selected" :modal-header="modalHeaderCharge" query-type="update"
                    :fromStorage="fromStorage" :fromUsed="fromUsed"
                    @close="isModalEditCharge = false" @refresh="applyFilters"/>
                </template>
            </b-modal>

            <b-modal :active="isModalActiveEditRequest" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
                width="100%" :can-cancel="canCancel">
                <template>
                    <edit-condition-good :id="selected"
                    :modal-header="'Editar Solicitud'" query-type="update" @close="isModalActiveEditRequest = false"/>
                </template>
            </b-modal>

            <b-modal :active="isModalActiveShowRequest" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
                width="100%" :can-cancel="canCancel">
                <template>
                    <ShowReportConditionGood :id="selected" :fromConditions="fromConditions"
                        @close="isModalActiveShowRequest = false" @refresh="applyFilters"/>
                </template>
            </b-modal>

        </div>
      
        <div class="columns" v-if="mostrarBotonOcultarFiltro">
            <div class="column has-text-right" v-if="mostrarFiltros">
                <b-button label="Ocultar Filtros" type="is-primary is-light" icon-left="filter" @click="mostrarFiltros=false"/>
            </div>
            <div class="column has-text-right" v-else>
                <b-button label="Añadir Bien" type="is-success" icon-left="plus" @click="isModalNewGood = true"
                    v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'create'))" />
                <b-button label="Mostrar Filtros" type="is-primary" icon-left="filter" @click="mostrarFiltros=true"/>
            </div>
        </div>


        <div v-if="mostrarFiltros">

            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                <div class="columns">

                    <div class="column">
                        <b-field label="Categoría">
                        <multiselect style="is-info" v-model="categoryId"
                            :options="categories.map((type) => type.id)"
                            :custom-label="(opt) => categories.find((x) => x.id === opt).alt_name_category" placeholder="Seleccione categoria"
                            selectLabel="" selectLabelcolor="" selectedLabel="Seleccionado"
                            deselectLabel="Presione para deseleccionar" :allow-empty="true" >
                            <span slot="noResult">No se ha encontrado resultado</span>
                            <span slot="noOptions">No hay elementos</span>
                        </multiselect>
                        </b-field>
                    </div>

                    <div class="column">
                        <ValidationProvider name="Nombre del Bien" rules="max:255" v-slot="{ errors, failedRules }">
                            <b-field label="Nombre del Bien"
                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-input v-model="nameGood" placeholder="Ingrese el nombre del bien" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                    <div class="column">
                        <ValidationProvider name="Número Inventario USACH" rules="max:255|numeric" v-slot="{ errors, failedRules }">
                            <b-field label="Número Inventario USACH"
                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-input v-model="numberUsachGood" placeholder="Ingrese el número de Inventario USACH" type="number" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                    <div class="column">
                        <ValidationProvider name="Número Interno" rules="max:255" v-slot="{ errors, failedRules }">
                            <b-field label="Número Interno"
                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-input v-model="internalNumber" placeholder="Ingrese el número interno" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                </div>

                <div class="columns">

                    <div class="column">
                        <b-field label="Marca">
                        <multiselect style="is-info" v-model="trademarkId" 
                            :options="trademarks.map((type) => type.id)"
                            :custom-label="(opt) => trademarks.find((x) => x.id === opt).name_trademark" placeholder="Seleccione marca"
                            selectLabel="" selectLabelColor="" selectedLabel="Seleccionado"
                            deselectLabel="Presione para deseleccionar" :allow-empty="true"> 
                            <span slot="noResult">No se ha encontrado resultado</span>
                            <span slot="noOptions">No hay elementos</span>
                        </multiselect>
                        </b-field>
                    </div>

                    <div class="column">
                        <ValidationProvider name="Número de Serie" rules="max:255" v-slot="{ errors, failedRules }">
                            <b-field label="Número de Serie"
                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-input v-model="numberSerieGood" placeholder="Ingrese el número de Serie"/>
                            </b-field>
                        </ValidationProvider>
                    </div>

                    <div class="column">
                        <ValidationProvider name="Modelo" rules="max:255" v-slot="{ errors, failedRules }">
                            <b-field label="Modelo"
                                :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                <b-input v-model="modelGood" placeholder="Ingrese Modelo" />
                            </b-field>
                        </ValidationProvider>
                    </div>

                </div>

                <div class="columns">

                    <div class="column">
                        <b-field label="Unidad">
                        <multiselect style="is-info" v-model="unitId"
                            :options="units.map((type) => type.id)"
                            :custom-label="(opt) => units.find((x) => x.id === opt).name_unit" placeholder="Seleccione Unidad"
                            selectLabel="" selectLabelColor="" selectedLabel="Seleccionado"
                            @input="getbuildingbyunit(unitId); getOfficebyunit(unitId)"
                            deselectLabel="Presione para deseleccionar" :allow-empty="true">
                            <span slot="noResult">No se ha encontrado resultado</span>
                            <span slot="noOptions">No hay elementos</span>
                        </multiselect>
                        </b-field>
                    </div>

                    <div class="column">
                        <b-field label="Edificio">
                        <multiselect style="is-info" v-model="buildingId"
                            :options="buildings.map((type) => type.id)"
                            :custom-label="(opt) => buildings.find((x) => x.id === opt).name_building" placeholder="Seleccione Edificio"
                            @input="getOfficebyBuilding(buildingId)"
                            @open="unitId ? buildings = buildings : buildings = buildings_not_filtered"
                            selectLabel="" selectLabelColor="" selectedLabel="Seleccionado"
                            deselectLabel="Presione para deseleccionar" :allow-empty="true">
                            <span slot="noResult">No se ha encontrado resultado</span>
                            <span slot="noOptions">No hay elementos</span>
                        </multiselect>
                        </b-field>
                    </div>

                    <div class="column">
                        <b-field label="Dependencia">
                        <multiselect style="is-info" v-model="officeId"
                            :options="offices.map((type) => type.id)"
                            :custom-label="(opt) => offices.find((x) => x.id === opt).name_office" placeholder="Seleccione Dependencia"
                            @open="(unitId != null) || (buildingId != null) ? offices = offices : offices = offices_not_filtered"
                            selectLabel="" selectLabelColor="" selectedLabel="Seleccionado"
                            deselectLabel="Presione para deseleccionar" :allow-empty="true">
                            <span slot="noResult">No se ha encontrado resultado</span>
                            <span slot="noOptions">No hay elementos</span>
                        </multiselect>
                        </b-field>
                    </div>

                </div>

                <div class="columns">
                    <div class="column has-text-right">
                        <b-button label="Añadir Bien" type="is-success" icon-left="plus" @click="isModalNewGood = true"
                            v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'create'))" />
                        <b-button label="Buscar" type="is-primary" icon-left="magnifying-glass" @click="applyFilters()"/>
                    </div>
                </div>
            </ValidationObserver>


        </div>

        <div v-if="mostrarTablas">

            <br>

            <b-tabs v-if="mostrarFiltros"/>

            <br>

            <b-tabs type="is-boxed" v-model="activeTab" position="is-centered">

                <b-tab-item label="Todos los Bienes" icon="clipboard-list">

                    <div class="columns">
                        <div class="column has-text-right">
                            <b-button label="Descargar Bienes Seleccionados" class="is-excel-color" icon-left="file-excel" @click="downloadAllGoods()"
                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">

                            <b-table :data="allGoods" :checked-rows.sync="allGoodsSelected" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
                                pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" checkable checkbox-position="right">

                                <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                                    <template v-slot="props">
                                        {{ props.row.id }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="namegood" label="Nombre" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Nombre..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.alt_name_good }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="internal_number" label="Número Interno" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Número Interno..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.internal_number }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="identification_number_good" label="Número Inventario USACH" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Número USACH..."
                                        icon="magnifying-glass"
                                        type="number"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.identification_number_good }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="trademark.name_trademark" label="Marca" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Marca..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.trademark.alt_name_trademark }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="model" label="Modelo" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Modelo..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.model }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="operative" label="Estado" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Estado..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.operative }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                                    <div class="columns is-mobile is-variable is-1-mobile">
                                        <div class="column">
                                            <b-button icon-pack="fas" icon-right="eye" tag="a" 
                                                :href="/goods/ + props.row.id"  target="_blank"
                                                type="is-orange-complementary-usach"
                                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show'))"/>
                                        </div>
                                    </div>
                                </b-table-column>

                            </b-table>

                        </div>
                    </div>

                </b-tab-item>


                <b-tab-item label="Bienes en Uso" icon="user-check">

                    <div class="columns">
                        <div class="column has-text-right">
                            <b-button label="Descargar Bienes Seleccionados" class="is-excel-color" icon-left="file-excel" @click="downloadGoodsUsed()"
                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">

                            <b-table :data="usedGoods" :checked-rows.sync="goodsUsedSelected" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
                                pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" checkable checkbox-position="right">

                                <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                                    <template v-slot="props">
                                        {{ props.row.id }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="namegood" label="Nombre" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Nombre..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.alt_name_good }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="internal_number" label="Número Interno" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Número Interno..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.internal_number }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="identification_number_good" label="Número Inventario USACH" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Número USACH..."
                                        icon="magnifying-glass"
                                        type="number"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.identification_number_good }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="duty_manager.name_duty_manager" label="Persona Encargada" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Persona..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{props.row.duty_manager.name_duty_manager}}
                                    </template>
                                </b-table-column>

                                <b-table-column field="location_good" label="Ubicación" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Ubicación..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.location_good }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="category.name_category" label="Categoría" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Categoría..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.category.alt_name_category }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                                    <div class="columns is-mobile is-variable is-1-mobile">
                                        <div class="column">
                                            <b-button icon-right="clipboard-check"
                                                type="is-success" 
                                                @click.native="clickRequest(props.row.id); fromConditions = true"
                                                v-show="((isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))) &&
                                                (cargoActivo === 'SUPERADMIN' || cargoActivo === 'ADMINISTRADOR')"/>
                                        </div>
                                        <div class="column">
                                            <b-button icon-pack="fas" icon-right="repeat"
                                                type="is-purple" @click.native="fromUsed = true; assignGood(props.row.id, props.row.duty_manager_id)"
                                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update_charge'))"/>
                                        </div>
                                        <div class="column">
                                            <b-button icon-pack="fas" icon-right="eye" tag="a" 
                                                :href="/goods/ + props.row.id"  target="_blank"
                                                type="is-orange-complementary-usach"
                                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show'))"/>
                                        </div>
                                        <div class="column">
                                            <b-button icon-pack="fas" icon-right="pen"
                                                type="is-primary" @click.native="editGood(props.row.id)"
                                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update'))"/>
                                        </div>
                                        <div class="column">
                                            <b-button icon-pack="fas" icon-right="ban"
                                                type="is-danger" @click.native="confirmDeleteGood(props.row.id)"
                                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))"/>
                                        </div>
                                    </div>
                                </b-table-column>

                            </b-table>

                        </div>
                    </div>

                </b-tab-item>
                <b-tab-item label="Bienes Reportados" icon="exclamation-triangle">

                    <div class="columns">
                        <div class="column has-text-right">
                            <b-button label="Descargar Bienes Seleccionados" class="is-excel-color" icon-left="file-excel" @click="downloadGoodsRequest()"
                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">

                            <b-table :data="requestGoods" :checked-rows.sync="goodsRequestSelected" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
                                pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" checkable checkbox-position="right">

                                <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                                    <template v-slot="props">
                                        {{ props.row.id }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="namegood" label="Nombre" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Nombre..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.alt_name_good }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="internal_number" label="Número Interno" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Número Interno..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.internal_number }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="duty_manager.name_duty_manager" label="Persona Encargada" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Persona..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{props.row.duty_manager.name_duty_manager}}
                                    </template>
                                </b-table-column>

                                
                                <b-table-column field="office.alt_name_building" label="Edificio" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Edificio..."
                                        icon="magnifying-glass"
                                        type="number"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.office.alt_name_building }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="office.alt_name_office" label="Dependencia" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Dependencia..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.office.alt_name_office }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="condition_good.change_condition_to" label="Estado" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Estado..."
                                        icon="magnifying-glass"
                                        type="number"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.condition_good.change_condition_to }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                                    <div class="columns is-mobile is-variable is-1-mobile">
                                        <div class="column">
                                            <b-button icon-right="clipboard-check"
                                                type="is-success" 
                                                @click.native="clickRequest(props.row.id); fromConditions = false"
                                                v-show="((isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))) &&
                                                (cargoActivo === 'SUPERADMIN' || cargoActivo === 'ADMINISTRADOR')"/>
                                        </div>
                                        <div class="column">
                                            <b-button icon-right="eye" tag="a"
                                                :href="/goods/ + props.row.id" target="_blank"
                                                type="is-orange-complementary-usach"
                                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'show'))"/>
                                        </div>
                                        <div class="column">
                                            <b-button icon-right="pen"
                                                type="is-primary" @click.native="editRequest(props.row.condition_good.id)"
                                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))"/>
                                        </div>
                                        <div class="column">
                                            <b-button icon-pack="fas" icon-right="undo"
                                                type="is-success" @click.native="confirmCancelReport(props.row.id, 'Solicitud')"
                                                v-show="((isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))) &&
                                                (cargoActivo === 'SUPERADMIN' || cargoActivo === 'ADMINISTRADOR')"/>
                                        </div>
                                    </div>
                                </b-table-column>

                            </b-table>

                        </div>
                    </div>

                </b-tab-item>
                <b-tab-item label="Bienes sin Uso" icon="user-times" >

                    <b-tabs class="mt-3" v-model="activeSubTab" type="is-toggle" position="is-centered">

                        <b-tab-item label="Bienes en Bodega" >

                            <div class="columns">
                                <div class="column has-text-right">
                                    <b-button label="Descargar Bienes Seleccionados" class="is-excel-color" icon-left="file-excel" @click="downloadGoodsStore()"
                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">

                                    <b-table :data="storeGoods" :checked-rows.sync="goodsStoreSelected" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
                                        pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" checkable checkbox-position="right">

                                        <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                                            <template v-slot="props">
                                                {{ props.row.id }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="namegood" label="Nombre" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Nombre..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.alt_name_good }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="internal_number" label="Número Interno" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Número Interno..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.internal_number }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="trademark.name_trademark" label="Marca" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Marca..."
                                                icon="magnifying-glass"
                                                type="number"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.trademark.alt_name_trademark }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="model" label="Modelo" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Modelo..."
                                                icon="magnifying-glass"
                                                type="number"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.model }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                                            <div class="columns is-mobile is-variable is-1-mobile">
                                                <div class="column">
                                                    <b-button icon-right="clipboard-check"
                                                        type="is-success" 
                                                        @click.native="clickRequest(props.row.id); fromConditions = true"
                                                        v-show="((isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))) &&
                                                        (cargoActivo === 'SUPERADMIN' || cargoActivo === 'ADMINISTRADOR')"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="repeat"
                                                        type="is-purple" @click.native="fromStorage = true; assignGood(props.row.id, props.row.duty_manager_id);"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update_charge'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="eye" tag="a" 
                                                        :href="/goods/ + props.row.id"  target="_blank"
                                                        type="is-orange-complementary-usach"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="ban"
                                                        type="is-danger" @click.native="confirmDeleteGood(props.row.id)"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))"/>
                                                </div>
                                            </div>
                                        </b-table-column>

                                    </b-table>

                                </div>
                            </div>

                        </b-tab-item>

                        <b-tab-item label="Bienes Dados de Baja" >

                            <div class="columns">
                                <div class="column has-text-right">
                                    <b-button label="Descargar Bienes Seleccionados" class="is-excel-color" icon-left="file-excel" @click="downloadGoodsDischarged()"
                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">

                                    <b-table :data="dischargedGoods" :checked-rows.sync="goodsDischargedSelected" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
                                        pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" checkable checkbox-position="right">

                                        <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                                            <template v-slot="props">
                                                {{ props.row.id }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="namegood" label="Nombre" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Nombre..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.alt_name_good }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="internal_number" label="Número Interno" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Número Interno..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.internal_number }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="trademark.name_trademark" label="Marca" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Marca..."
                                                icon="magnifying-glass"
                                                type="number"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.trademark.alt_name_trademark }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="model" label="Modelo" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Modelo..."
                                                icon="magnifying-glass"
                                                type="number"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.model }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="duty_manager.name_duty_manager" label="Persona Encargada" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Persona..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{props.row.duty_manager.name_duty_manager}}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="office.alt_name_building" label="Edificio" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Edificio..."
                                                icon="magnifying-glass"
                                                type="number"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.office.alt_name_building }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="office.alt_name_office" label="Dependencia" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Dependencia..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.office.alt_name_office }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                                            <div class="columns is-mobile is-variable is-1-mobile">
                                                <div class="column">
                                                    <b-button icon-right="clipboard-check"
                                                        type="is-success" 
                                                        @click.native="clickRequest(props.row.id); fromConditions = true"
                                                        v-show="((isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))) &&
                                                        (cargoActivo === 'SUPERADMIN' || cargoActivo === 'ADMINISTRADOR')"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="repeat"
                                                        type="is-purple" @click.native="fromStorage = true; assignGood(props.row.id, props.row.duty_manager_id);"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update_charge'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="eye" tag="a" 
                                                        :href="/goods/ + props.row.id"  target="_blank"
                                                        type="is-orange-complementary-usach"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="ban"
                                                        type="is-danger" @click.native="confirmDeleteGood(props.row.id)"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))"/>
                                                </div>
                                            </div>
                                        </b-table-column>

                                    </b-table>

                                </div>
                            </div>

                        </b-tab-item>

                        <b-tab-item label="Bienes Perdidos" >

                            <div class="columns">
                                <div class="column has-text-right">
                                    <b-button label="Descargar Bienes Seleccionados" class="is-excel-color" icon-left="file-excel" @click="downloadGoodsLost()"
                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">

                                    <b-table :data="lostGoods" :checked-rows.sync="goodsLostSelected" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
                                        pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" checkable checkbox-position="right">

                                        <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                                            <template v-slot="props">
                                                {{ props.row.id }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="namegood" label="Nombre" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Nombre..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.alt_name_good }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="internal_number" label="Número Interno" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Número Interno..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.internal_number }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="duty_manager.name_duty_manager" label="Persona Encargada" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Persona..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{props.row.duty_manager.name_duty_manager}}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="office.alt_name_building" label="Edificio" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Edificio..."
                                                icon="magnifying-glass"
                                                type="number"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.office.alt_name_building }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="office.alt_name_office" label="Dependencia" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Dependencia..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.office.alt_name_office }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                                            <div class="columns is-mobile is-variable is-1-mobile">
                                                <div class="column">
                                                    <b-button icon-right="clipboard-check"
                                                        type="is-success" 
                                                        @click.native="clickRequest(props.row.id); fromConditions = true"
                                                        v-show="((isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))) &&
                                                        (cargoActivo === 'SUPERADMIN' || cargoActivo === 'ADMINISTRADOR')"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="repeat"
                                                        type="is-purple" @click.native="fromStorage = true; assignGood(props.row.id, props.row.duty_manager_id);"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update_charge'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-right="eye" tag="a"
                                                        :href="/goods/ + props.row.id" target="_blank"
                                                        type="is-orange-complementary-usach"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'show'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="ban"
                                                        type="is-danger" @click.native="confirmDeleteGood(props.row.id)"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))"/>
                                                </div>
                                            </div>
                                        </b-table-column>

                                    </b-table>

                                </div>
                            </div>

                        </b-tab-item>

                        <b-tab-item label="Bienes Dañados" >

                            <div class="columns">
                                <div class="column has-text-right">
                                    <b-button label="Descargar Bienes Seleccionados" class="is-excel-color" icon-left="file-excel" @click="downloadGoodsDestroyed()"
                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">

                                    <b-table :data="destroyedGoods" :checked-rows.sync="goodsDestroyedSelected" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
                                        pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" checkable checkbox-position="right">

                                        <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                                            <template v-slot="props">
                                                {{ props.row.id }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="namegood" label="Nombre" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Nombre..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.alt_name_good }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="internal_number" label="Número Interno" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Número Interno..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.internal_number }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="duty_manager.name_duty_manager" label="Persona Encargada" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Persona..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{props.row.duty_manager.name_duty_manager}}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="office.alt_name_building" label="Edificio" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Edificio..."
                                                icon="magnifying-glass"
                                                type="number"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.office.alt_name_building }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="office.alt_name_office" label="Dependencia" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Dependencia..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.office.alt_name_office }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                                            <div class="columns is-mobile is-variable is-1-mobile">
                                                <div class="column">
                                                    <b-button icon-right="clipboard-check"
                                                        type="is-success" 
                                                        @click.native="clickRequest(props.row.id); fromConditions = true"
                                                        v-show="((isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))) &&
                                                        (cargoActivo === 'SUPERADMIN' || cargoActivo === 'ADMINISTRADOR')"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="repeat"
                                                        type="is-purple" @click.native="fromStorage = true; assignGood(props.row.id, props.row.duty_manager_id);"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update_charge'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-right="eye" tag="a"
                                                        :href="/goods/ + props.row.id" target="_blank"
                                                        type="is-orange-complementary-usach"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'show'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="ban"
                                                        type="is-danger" @click.native="confirmDeleteGood(props.row.id)"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))"/>
                                                </div>
                                            </div>
                                        </b-table-column>

                                    </b-table>

                                </div>
                            </div>

                        </b-tab-item>

                        <b-tab-item label="Bienes No Operativos"  >

                            <div class="columns">
                                <div class="column has-text-right">
                                    <b-button label="Descargar Bienes Seleccionados" class="is-excel-color" icon-left="file-excel" @click="downloadGoodsNoOperative()"
                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">

                                    <b-table :data="noOperativeGoods" :checked-rows.sync="goodsNoOperativeSelected" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
                                        pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" checkable checkbox-position="right">

                                        <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                                            <template v-slot="props">
                                                {{ props.row.id }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="namegood" label="Nombre" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Nombre..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.alt_name_good }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="internal_number" label="Número Interno" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Número Interno..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.internal_number }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="duty_manager.name_duty_manager" label="Persona Encargada" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Persona..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{props.row.duty_manager.name_duty_manager}}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="office.alt_name_building" label="Edificio" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Edificio..."
                                                icon="magnifying-glass"
                                                type="number"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.office.alt_name_building }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="office.alt_name_office" label="Dependencia" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Dependencia..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.office.alt_name_office }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                                            <div class="columns is-mobile is-variable is-1-mobile">
                                                <div class="column">
                                                    <b-button icon-right="clipboard-check"
                                                        type="is-success" 
                                                        @click.native="clickRequest(props.row.id); fromConditions = true"
                                                        v-show="((isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))) &&
                                                        (cargoActivo === 'SUPERADMIN' || cargoActivo === 'ADMINISTRADOR')"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="repeat"
                                                        type="is-purple" @click.native="fromStorage = true; assignGood(props.row.id, props.row.duty_manager_id);"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update_charge'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-right="eye" tag="a"
                                                        :href="/goods/ + props.row.id" target="_blank"
                                                        type="is-orange-complementary-usach"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'show'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="ban"
                                                        type="is-danger" @click.native="confirmDeleteGood(props.row.id)"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))"/>
                                                </div>
                                            </div>
                                        </b-table-column>

                                    </b-table>

                                </div>
                            </div>

                        </b-tab-item>

                        <b-tab-item label="Bienes en Servicio Técnico" >

                            <div class="columns">
                                <div class="column has-text-right">
                                    <b-button label="Descargar Bienes Seleccionados" class="is-excel-color" icon-left="file-excel" @click="downloadGoodsServiceTech()"
                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">

                                    <b-table :data="serviceTechGoods" :checked-rows.sync="goodsServiceTechSelected" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
                                        pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" checkable checkbox-position="right">

                                        <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                                            <template v-slot="props">
                                                {{ props.row.id }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="namegood" label="Nombre" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Nombre..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.alt_name_good }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="internal_number" label="Número Interno" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Número Interno..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.internal_number }}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="duty_manager.name_duty_manager" label="Persona Encargada" sortable searchable>
                                            <template #searchable="props">
                                                <b-input
                                                v-model="props.filters[props.column.field]"
                                                placeholder="Buscar Persona..."
                                                icon="magnifying-glass"/>
                                            </template>
                                            <template v-slot="props">
                                                {{props.row.duty_manager.name_duty_manager}}
                                            </template>
                                        </b-table-column>

                                        <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                                            <div class="columns is-mobile is-variable is-1-mobile">
                                                <div class="column">
                                                    <b-button icon-right="clipboard-check"
                                                        type="is-success" 
                                                        @click.native="clickRequest(props.row.id); fromConditions = true"
                                                        v-show="((isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))) &&
                                                        (cargoActivo === 'SUPERADMIN' || cargoActivo === 'ADMINISTRADOR')"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="repeat"
                                                        type="is-purple" @click.native="fromStorage = true; assignGood(props.row.id, props.row.duty_manager_id);"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update_charge'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-right="eye" tag="a"
                                                        :href="/goods/ + props.row.id" target="_blank"
                                                        type="is-orange-complementary-usach"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'show'))"/>
                                                </div>
                                                <div class="column">
                                                    <b-button icon-pack="fas" icon-right="ban"
                                                        type="is-danger" @click.native="confirmDeleteGood(props.row.id)"
                                                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))"/>
                                                </div>
                                            </div>
                                        </b-table-column>

                                    </b-table>

                                </div>
                            </div>

                        </b-tab-item>

                    </b-tabs>

                </b-tab-item>

                <b-tab-item label="Bienes Anulados" icon="ban">

                    <div class="columns">
                        <div class="column has-text-right">
                            <b-button label="Descargar Bienes Seleccionados" class="is-excel-color" icon-left="file-excel" @click="downloadGoodsDeleted()"
                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">

                            <b-table :data="deletedGoods" :checked-rows.sync="goodsDeletedSelected" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
                                pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" checkable checkbox-position="right">

                                <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                                    <template v-slot="props">
                                        {{ props.row.id }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="namegood" label="Nombre" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Nombre..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.alt_name_good }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="internal_number" label="Número Interno" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Número Interno..."
                                        icon="magnifying-glass"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.internal_number }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="identification_number_good" label="Número Inventario USACH" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Número USACH..."
                                        icon="magnifying-glass"
                                        type="number"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.identification_number_good }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="trademark.name_trademark" label="Marca" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Marca..."
                                        icon="magnifying-glass"
                                        type="number"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.trademark.alt_name_trademark }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="model" label="Modelo" sortable searchable>
                                    <template #searchable="props">
                                        <b-input
                                        v-model="props.filters[props.column.field]"
                                        placeholder="Buscar Modelo..."
                                        icon="magnifying-glass"
                                        type="number"/>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.model }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                                    <div class="columns is-mobile is-variable is-1-mobile">
                                        <div class="column">
                                            <b-button icon-pack="fas" icon-right="eye" tag="a" 
                                                :href="/goods/ + props.row.id"  target="_blank"
                                                type="is-orange-complementary-usach"
                                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show'))"/>
                                        </div>
                                        <div class="column">
                                            <b-button icon-pack="fas" icon-right="trash-arrow-up"
                                                type="is-success" @click.native="confirmRestoreGood(props.row.id)"
                                                v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'activate_good'))"/>
                                        </div>
                                    </div>
                                </b-table-column>

                            </b-table>

                        </div>
                    </div>

                </b-tab-item>

            </b-tabs>
        </div>

    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"/>
  </section>
</template>

<script>
import axios from "axios"
import NewConditionGood from "./new_condition_good.vue"
import ShowConditionGood from "./show_condition_good.vue"
import EditConditionGood from "./edit_condition_good.vue"
import EditGood from '../good/edit_good.vue'
import ShowGood from '../good/show_good.vue'
import EditGoodCharge from "../good/EditChargeGood.vue"
import NewGood from "../good/new_good.vue"
import ShowReportConditionGood from "./show_report_info_condition_good.vue"
export default {
    mounted() {
      // Cambiar el título de la pestaña
      document.title = 'SGI - Gestión de Bienes';
    },
    data () {
        return {
            isLoading: false,
            mostrarFiltros: true,
            mostrarBotonOcultarFiltro: false,
            mostrarTablas: false,
            current: null,
            isModalActiveEdit: false,
            isModalNewGood: false,
            isModalActiveEditRequest: false,
            isModalActiveShowRequest: false,
            canCancel: ["escape"],
            selected: null,
            activeTab: 0,
            activeSubTab: 0,
            isModalEditCharge: false,
            fromStorage: false,
            fromUsed: false,
            modalHeaderCharge: "",
            oldDutyManagerId: null,
            fromConditions: false,

            //Entidades seleccionadas
            allGoodsSelected: [],
            goodsUsedSelected: [],
            goodsRequestSelected: [],
            goodsDischargedSelected: [],
            goodsStoreSelected: [],
            goodsLostSelected: [],
            goodsDestroyedSelected: [],
            goodsNoOperativeSelected: [],
            goodsServiceTechSelected: [],
            goodsDeletedSelected: [],

            //Entidades para la busqueda
            categories: [],
            trademarks: [],
            units: [],
            buildings: [],
            buildings_not_filtered: [],
            offices: [],
            offices_not_filtered: [],

            //Entidades ingresadas por el usuario
            categoryId: null,
            nameGood: null,
            numberUsachGood: null,
            internalNumber: null,
            trademarkId: null,
            numberSerieGood: null,
            modelGood: null,
            unitId: null,
            buildingId: null,
            officeId: null,

            //Entitades para mostrar en tablas
            allGoods: [],
            usedGoods: [],
            dischargedGoods: [],
            storeGoods: [],
            lostGoods: [],
            requestGoods: [],
            destroyedGoods: [],
            noOperativeGoods: [],
            serviceTechGoods:[],
            deletedGoods: [],

        }
    },

    props: ["id", 'queryType', 'modalHeader', 'goodId', 'permissions', 'cargoActivo'],

    components: {
        NewConditionGood,
        ShowConditionGood,
        EditConditionGood,
        EditGood,
        ShowGood,
        EditGoodCharge,
        NewGood,
        ShowReportConditionGood,
    },

    methods: {
        isAuthorized(name, action) {
            var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
            if (objeto !== undefined) {
                return true;
            } else {
                return false;
            }
        },

        async getCategories() {
            this.isLoading = true
            await axios
                .get("/categories/showcategories.json")
                .then(response => {
                    this.categories = response.data.categories_enabled
                })
                .catch(error => {
                    let errorMsg = error.response ? ', favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al obtener las Categorías' + errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async getTrademarks() {
            this.isLoading = true
            await axios
                .get("/trademarks/showtrademarks.json")
                .then(response => {
                    this.trademarks = response.data.trademarks_enabled;
                })
                .catch(error => {
                    let errorMsg = error.response ? ', favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al obtener las Marcas' + errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async getUnits() {
            this.isLoading = true
            await axios
                .get("/units/showactiveunit.json")
                .then(response => {
                    this.units = response.data
                })
                .catch(error => {
                    let errorMsg = error.response ? ', favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al obtener las Unidades' + errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async getBuildings() {
            this.isLoading = true
            await axios
                .get("/buildings/showactivebuilding.json")
                .then(response => {
                    this.buildings = response.data;
                    this.buildings_not_filtered = response.data;
                })
                .catch(error => {
                    let errorMsg = error.response ? ', favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al obtener los Edificios' + errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async getOffices() {
            this.isLoading = true
            await axios
                .get("/offices/showactiveoffice.json")
                .then(response => {
                    this.offices = response.data;
                    this.offices_not_filtered = response.data
                })
                .catch(error => {
                    let errorMsg = error.response ? ', favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al obtener las Dependencias' + errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async getAllGoods() {
            await axios
                    .get("/goods/showallfiltergoods.json", {
                        params: {
                            category_id: this.categoryId,
                            namegood: this.nameGood,
                            identification_number_good: this.numberUsachGood,
                            trademark_id: this.trademarkId,
                            serie_number: this.numberSerieGood,
                            model: this.modelGood,
                            office_id: this.officeId,
                            unit_id: this.unitId,
                            building_id: this.buildingId,
                            internal_number: this.internalNumber
                        }
                    })
                    .then(response => {
                        this.usedGoods = response.data.goods_active
                        this.requestGoods = response.data.goods_request
                        this.dischargedGoods = response.data.goods_discharged
                        this.storeGoods = response.data.goods_excluded
                        this.lostGoods = response.data.goods_lost
                        this.destroyedGoods = response.data.goods_destroyed
                        this.noOperativeGoods = response.data.goods_no_operative
                        this.serviceTechGoods = response.data.goods_service_tech
                        this.deletedGoods = response.data.goods_deleted
                    })
                    .catch(error => {
                        let errorMsg = error.response ? ', favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                        this.$buefy.dialog.alert({
                            title: 'Error',
                            message: 'Hubo un error al obtener los Bienes Filtrados' + errorMsg,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                    })

            
        },

        async applyFilters () {

            let valid = await this.$refs.observer.validate()

            if (!valid) {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Algunos campos del filtro presentan errores.', 
                    type: 'is-danger',
                    hasIcon: true, 
                    icon: 'times-circle', 
                    iconPack: 'fa', 
                    ariaRole: 'alertdialog', 
                    ariaModal: true
                })
                return
            }

            this.mostrarBotonOcultarFiltro=true
            this.mostrarTablas=true

            this.allGoods = []
            this.isLoading = true;
            await this.getAllGoods()

            this.allGoods = this.allGoods.concat(
                this.usedGoods,
                this.requestGoods,
                this.dischargedGoods,
                this.storeGoods,
                this.lostGoods,
                this.destroyedGoods,
                this.noOperativeGoods,
                this.serviceTechGoods,
            )
            this.isLoading = false;
        },

        async getActualUser() {
            this.isLoading = true
            await axios
                .get("/duty_managers/currentuser.json")
                .then(response => {
                    this.current = response.data.current_user;
                })
                .catch(error => {
                    let errorMsg = error.response ? ', favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al obtener el Usuario Actual' + errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async editGood(item) {
            this.selected = item;
            this.isModalActiveEdit = true;
        },

        async editRequest(item) {
            this.selected = item
            this.isModalActiveEditRequest = true
        },

        async assignGood(item, item2) {
            if (this.fromStorage) {
                this.modalHeaderCharge = 'Volver Asignar Persona Encargada'
            } else {
                this.modalHeaderCharge = 'Asignar Nueva Persona Encargada'
            }
            this.selected = item;
            this.oldDutyManagerId = item2
            this.isModalEditCharge = true;
        },

        clickRequest(idGood) {
            this.selected = idGood
            this.isModalActiveShowRequest = true
        },

        async cancelReport(idGood, state) {
            this.isLoading = true
            await axios.put("/condition_goods/updatebyidgood/" + idGood + ".json", {
                change_condition_to: "En Uso",
                change_condition: true,
            })
            .then(response => {
                this.applyFilters()
                this.$buefy.dialog.alert({
                title: 'Revertido',
                type: 'is-success',
                hasIcon: true,
                icon: 'circle-check',
                iconPack: 'fas',
                message: state + ' ha sido revertido exitosamente.',
                confirmText: 'Aceptar'
                })
            })
            .catch(error => {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Hubo un error al revertir el estado ' + sate + '.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            })
            this.isLoading = false
        },

        async confirmCancelReport(idGood, state) {
            this.$buefy.dialog.confirm({
                title: "Revertir Estado " + state,
                message: "¿Esta seguro de querer Revertir estado " + state + "?",
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                confirmText: "Revertir",
                cancelText: "Cancelar",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => this.cancelReport(idGood, state),
            })
        },

        async deleteGood(idGood) {
            this.isLoading = true
            await axios.put("/condition_goods/updatebyidgood/" + idGood + ".json", {
                change_condition_to: "Anulado",
                change_condition: true,
            })
            .then(response => {
                this.applyFilters()
                this.$buefy.dialog.alert({
                title: 'Bien Anulado',
                type: 'is-success',
                hasIcon: true,
                icon: 'circle-check',
                iconPack: 'fas',
                message: 'El Bien ha sido anulado exitosamente.',
                confirmText: 'Aceptar'
                })
            })
            .catch(error => {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Hubo un error al anular el Bien',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            })
            this.isLoading = false
        },

        async confirmDeleteGood(idGood) {
            this.$buefy.dialog.confirm({
                title: "Anular Bien",
                message: "¿Esta seguro de querer anular el Bien?",
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                confirmText: "Anular",
                cancelText: "Cancelar",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => this.deleteGood(idGood),
            })
        },

        async restoreGood(idGood) {
            this.isLoading = true
            await axios.put("/condition_goods/updatebyidgood/" + idGood + ".json", {
                change_condition_to: "En Uso",
                change_condition: true,
            })
            .then(response => {
                this.applyFilters()
                this.$buefy.dialog.alert({
                title: 'Bien Restaurado',
                type: 'is-success',
                hasIcon: true,
                icon: 'circle-check',
                iconPack: 'fas',
                message: 'El Bien ha sido restaurado exitosamente.',
                confirmText: 'Aceptar'
                })
            })
            .catch(error => {
                var showError = null

                if (error.response != null) {
                    showError = error.response.data
                } else {
                    showError = error
                }
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Hubo un error al resturar el Bien, favor de revisar errores.<br>Errores:<br>' + showError,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            })
            this.isLoading = false
        },

        async confirmRestoreGood(idGood) {
            this.$buefy.dialog.confirm({
                title: "Restaurar Bien",
                message: "¿Esta seguro de querer restaurar el Bien?",
                type: 'is-success',
                hasIcon: true,
                icon: 'trash-arrow-up',
                confirmText: "Restaurar",
                cancelText: "Cancelar",
                hasIcon: true,
                onConfirm: () => this.restoreGood(idGood),
            })
        },

        async downloadAllGoods() {
            this.allGoodsSelected = this.allGoodsSelected.map((good) => good.id)
            if(this.allGoodsSelected.length != 0){
                window.location.href = "/goods/downloadgoodsbyids/" + this.activeTab + "/" + this.activeSubTab + "/" + this.allGoodsSelected + ".xlsx"
                this.allGoodsSelected = []
            }
        },
        async downloadGoodsUsed() {
            this.goodsUsedSelected = this.goodsUsedSelected.map((good) => good.id)
            if(this.goodsUsedSelected.length != 0){
                window.location.href = "/goods/downloadgoodsbyids/" + this.activeTab + "/" + this.activeSubTab + "/" + this.goodsUsedSelected + ".xlsx"
                this.goodsUsedSelected = []
            }
        },
        async downloadGoodsRequest() {
            this.goodsRequestSelected = this.goodsRequestSelected.map((good) => good.id)
            if (this.goodsRequestSelected.length != 0) {
                window.location.href = "/goods/downloadgoodsbyids/" + this.activeTab + "/" + this.activeSubTab + "/" + this.goodsRequestSelected + ".xlsx"
                this.goodsRequestSelected = []
            }
        },
        async downloadGoodsDischarged() {
            this.goodsDischargedSelected = this.goodsDischargedSelected.map((good) => good.id)
            if (this.goodsDischargedSelected.length != 0) {
                window.location.href = "/goods/downloadgoodsbyids/" + this.activeTab + "/" + this.activeSubTab + "/" + this.goodsDischargedSelected + ".xlsx"
                this.goodsDischargedSelected = []
            }
        },
        async downloadGoodsStore() {
            this.goodsStoreSelected = this.goodsStoreSelected.map((good) => good.id)
            if (this.goodsStoreSelected.length != 0) {
                window.location.href = "/goods/downloadgoodsbyids/" + this.activeTab + "/" + this.activeSubTab + "/" + this.goodsStoreSelected + ".xlsx"
                this.goodsStoreSelected = []
            }
        },
        async downloadGoodsLost() {
            this.goodsLostSelected = this.goodsLostSelected.map((good) => good.id)
            if (this.goodsLostSelected.length != 0) {
                window.location.href = "/goods/downloadgoodsbyids/" + this.activeTab + "/" + this.activeSubTab + "/" + this.goodsLostSelected + ".xlsx"
                this.goodsLostSelected = []
            }
        },
        async downloadGoodsDestroyed() {
            this.goodsDestroyedSelected = this.goodsDestroyedSelected.map((good) => good.id)
            if (this.goodsDestroyedSelected.length != 0) {
                window.location.href = "/goods/downloadgoodsbyids/" + this.activeTab + "/" + this.activeSubTab + "/" + this.goodsDestroyedSelected + ".xlsx"
                this.goodsDestroyedSelected = []
            }
        },
        async downloadGoodsNoOperative() {
            this.goodsNoOperativeSelected = this.goodsNoOperativeSelected.map((good) => good.id)
            if (this.goodsNoOperativeSelected.length != 0) {
                window.location.href = "/goods/downloadgoodsbyids/" + this.activeTab + "/" + this.activeSubTab + "/" + this.goodsNoOperativeSelected + ".xlsx"
                this.goodsNoOperativeSelected = []
            }
        },
        async downloadGoodsServiceTech() {
            this.goodsServiceTechSelected = this.goodsServiceTechSelected.map((good) => good.id)
            if (this.goodsServiceTechSelected != 0) {
                window.location.href = "/goods/downloadgoodsbyids/" + this.activeTab + "/" + this.activeSubTab + "/" + this.goodsServiceTechSelected + ".xlsx"
                this.goodsServiceTechSelected = []
            }
        },
        async downloadGoodsDeleted() {
            this.goodsDeletedSelected = this.goodsDeletedSelected.map((good) => good.id)
            if (this.goodsDeletedSelected != 0) {
                window.location.href = "/goods/downloadgoodsbyids/" + this.activeTab + "/" + this.activeSubTab + "/" + this.goodsDeletedSelected + ".xlsx"
                this.goodsDeletedSelected = []
            }  
        },

        async getbuildingbyunit(item) {
            if (item != null) {
                this.isLoading = true
                await axios
                    .get(
                        "/buildings/showbuildingunit/" + item + ".json"
                    )
                    .then(response => {
                        this.buildings = response.data
                        this.isLoading = false
                    })
                    .catch(error => {
                        let errorMsg = error.response ? ', favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                        this.$buefy.dialog.alert({
                            title: 'Error',
                            message: 'Hubo un error al obtener los Edificios para el filtro' + errorMsg,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                        this.isLoading = false
                    })
            }
        },

        async getOfficebyunit(item) {
            if (item != null) {
                this.isLoading = true
                await axios
                    .get(
                        "/offices/showofficeunit/" + item + ".json"
                    )
                    .then(response => {
                        this.offices = response.data
                        this.isLoading = false
                    })
                    .catch(error => {
                        let errorMsg = error.response ? ', favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                        this.$buefy.dialog.alert({
                            title: 'Error',
                            message: 'Hubo un error al obtener las Dependencias para el filtro' + errorMsg,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                        this.isLoading = false
                    })
            }
        },

        async getOfficebyBuilding(item) {
            if (item != null) {
                this.isLoading = true
                await axios
                    .get(
                        "/offices/showofficebuilding/" + item + ".json"
                    )
                    .then(response => {
                        this.offices = response.data
                        this.isLoading = false
                    })
                    .catch(error => {
                        let errorMsg = error.response ? ', favor de revisar errores.<br>Errores<br>' + error.response.data : '.'
                        
                        this.$buefy.dialog.alert({
                            title: 'Error',
                            message: 'Hubo un error al obtener las Dependencias para el filtro' + errorMsg,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                        this.isLoading = false
                    })
            }
        },

    },

    async created() {
        this.isLoading = true
        await this.getCategories()
        await this.getTrademarks()
        await this.getUnits()
        await this.getBuildings()
        await this.getOffices()
        await this.getActualUser()
        this.isLoading = false
    }
}
</script>
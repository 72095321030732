<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmregister())">
        <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="NewInvestmentTypes">

            <div class="columns is-centered">
              <div class="column is-centered ">

                <div class="my-0">

                  <div class="columns">
                    <div class="column">
                      <b-field label="Nombre Tipo de Inversión *">
                        <ValidationProvider name="Nombre" rules="required|max:255" v-slot="{ errors }">
                          <b-input v-model="aux_name_investment_type"
                            placeholder="Ingrese el nombre de la Tipo de Inversión"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Descripción *">
                        <ValidationProvider name="Descripción" rules="required|max:255" v-slot="{ errors }">
                          <b-input v-model="aux_detail" type="textarea"
                            placeholder="Ingrese la descripción del Tipo de Inversión">
                          </b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>

                </div>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <p class="control">
                      <b-button label="Añadir Tipo de inversión" type="is-success" icon-left="save" @click="check()" />
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "new_investment_types",
  props: [
    'queryType', 'modalHeader'
  ],
  data() {
    return {
      selected: null,
      investment_types: [],
      aux_name_investment_type: null,
      aux_detail: null,
      aux_active: true,
      isLoading: false,
    };
  },
  methods: {
    async createClick() {
      this.isLoading = true
      const response = await axios.post("/investment_types.json", {
        name_investment_type: this.aux_name_investment_type,
        detail: this.aux_detail,
        active_investment_type: this.aux_active,
      })
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha agregado correctamente el Tipo de Inversión al sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al ingresar los datos del Tipo de Inversión, favor revisar los errores.<br>Errores:<br>' + error.response.data,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async check() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert(
          {
            title: 'Error',
            message: 'Algunos campos presentan errores.', 
            type: 'is-danger',
            hasIcon: true, 
            icon: 'times-circle', 
            iconPack: 'fa', 
            ariaRole: 'alertdialog', 
            ariaModal: true
          }
        )
      }
      else {
        this.$buefy.dialog.confirm({
          title: "Registro Tipo de inversión",
          message: `¿Esta seguro de registrar este tipo de inversión?`,
          cancelText: "Cancelar",
          confirmText: "Registrar",
          type: "is-info",
          onConfirm: () => this.createClick(),
        });
      }
    },
  },
};
</script>
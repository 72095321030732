<template>
  <section>
    <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ modalHeader }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">

        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(check())">

            <div class="columns">

              <div class="column">
                <b-field label="Nombre del Bien">
                  <b-input v-model="name_good" readonly />
                </b-field>
              </div>

              <div class="column">
                <ValidationProvider name="Estado del Bien" vid="condition" rules="required" v-slot="{ errors, failedRules }">
                  <b-field label="Estado del Bien *" :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''" >
                    <b-select v-model="condition" placeholder="Seleccione un Estado" expanded>
                      <option value="Dado de Baja">Dar de Baja</option>
                      <option value="Perdido">Perdido</option>
                      <option value="Dañado">Dañado</option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </div>

            </div>

            <div v-show="condition === 'Dañado'" class="columns">

              <div class="column">
                <ValidationProvider name="Reparable" rules="required_if:condition,Dañado" v-slot="{ errors, failedRules }">
                  <b-field label="¿Es Reparable?" :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''" >
                    <b-select v-model="repairable" placeholder="Seleccione si es Reparable o no" expanded>
                      <option value="SI">Si</option>
                      <option value="NO">No</option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </div>

            </div>

            <div class="columns">

              <div class="column">
                <ValidationProvider name="Motivo del Ingreso" rules="min:3|max:255|required" v-slot="{ errors, failedRules }">
                  <b-field label="Motivo del Ingreso *" 
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''" >
                    <b-input v-model="request_detail" placeholder="Ingrese detalles del Motivo del Ingreso" type="textarea" />
                  </b-field>
                </ValidationProvider>
                
              </div>

            </div>

            <div class="columns">

              <div class="column">
                <ValidationProvider name="URL del Documento" rules="max:255|url_with_protocol" v-slot="{ errors, failedRules }">
                  <b-field label="URL del Documento de Enlace"
                    :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                    :message="Object.keys(failedRules).length > 0 ? errors[0] : ''" >
                    <b-input v-model="link" placeholder="Ingrese URL, por ejemplo 'https://www.drive.com'" type="url" />
                  </b-field>
                </ValidationProvider>
              </div>

            </div>

            <div class="columns is-mobile">

              <div class="column">
                <b-button label="Cerrar" @click="$emit('close')" />
              </div>

              <div class="column has-text-right">
                <p class="control">
                  <b-button label="Reportar Bien" type="is-warning" icon-left="bullhorn" @click="check()" />
                </p>
              </div>

            </div>

          </form>
        </ValidationObserver>

      </section>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "new_good_condition",
  props: [
    'queryType', 'modalHeader', "id", "goodId", "fromDutyManager"
  ],
  data() {
    return {
      name_good: null,

      link: null,
      request_detail: null,
      condition: null,
      repairable: null,

      isLoading: false,
    };
  },
  methods: {
    async getgood() {
      this.isLoading = true
      await axios
              .get("/goods/" + this.id + ".json")
              .then(response => {
                this.good = response.data;
                this.name_good = this.good.alt_name_good;
                this.isLoading = false
              })
              .catch(error => {

                var showError = null

                if (error.response != null) {
                  showError = error.response.data
                } else {
                  showError = error
                }

                this.$buefy.dialog.alert({
                  title: 'Error',
                  message: 'Hubo un error al obtener el Bien, favor de revisar errores.<br>Errores:<br>' + showError,
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
                })
                this.isLoading = false
              })
      
    },

    async check() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert(
          {
            title: 'Error',
            message: 'Algunos campos presentan errores.', 
            type: 'is-danger',
            hasIcon: true, 
            icon: 'times-circle', 
            iconPack: 'fa', 
            ariaRole: 'alertdialog', 
            ariaModal: true
          }
        )
      }
      else {
        this.$buefy.dialog.confirm({
          title: "Reportar el Bien",
          message: `¿Esta seguro de reportar este Bien?`,
          cancelText: "Cancelar",
          confirmText: "Reportar",
          type: "is-info",
          onConfirm: () => this.reportGood(),
        })
      }
    },

    async reportGood() {
      this.isLoading = true
      await axios.put("/condition_goods/updatebyidgood/"+ this.id +".json", {
        request_date: new Date(),
        request_detail: this.request_detail,
        link: this.link,
        repairable: this.repairable === 'SI' ? true : false,
        change_condition_to: this.condition,
      })
      .then(response => {
        this.isLoading = false
        this.$emit('refresh')
        this.$emit('close')
        this.$buefy.dialog.alert({
          title: 'Reporte',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          message: 'El Bien ha sido reportado como: ' + this.condition,
          confirmText: 'Aceptar'
        })
      })
      .catch(error => {

        var showError = error.response ? error.response.data : error

        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al Reportar el Bien, favor revise errores.<br>Errores:<br>' + showError,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false
      })
    },


  },
  created() {
    this.getgood()

  },
};
</script>
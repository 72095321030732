<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(editTrademark())">
        <div class="modal-card" :style="'width: 1080px; max-width: 100%;' ">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="NewTrademarks">
            <div class="columns is-centered">
              <div class="column is-centered">

                <div class="columns my-0">
                  <div class="column">
                    <b-field label="Nombre Marca *">
                      <ValidationProvider name="Nombre" rules="required|max:255" v-slot="{ errors }">
                        <b-input v-model="name_trademark" placeholder="Ingrese el nombre de la marca"></b-input>
                        <span class="validation-alert">{{
                          errors[0]
                          }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

              </div>
            </div>

            <div class="columns is-mobile">

              <div class="column">
                <b-button label="Cerrar" @click="$emit('close')" />
              </div>

              <div class="column has-text-right">
                <p class="control">
                  <b-button label="Editar marca" icon-left="save" type="is-info" @click="check()" />
                </p>
              </div>

            </div>

          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "edit_trademarks",
  props: ["queryType", "modalHeader", "id"],
  data() {
    return {
      isLoading: false,
      //Información a traer
      name_trademark: null,
    }
  },

  methods: {
    async getTrademark() {
      this.isLoading = true
      await axios.get('/trademarks/'+ this.id + '.json')
      .then(response => {
        this.name_trademark = response.data.alt_name_trademark
      })
      .catch(error => {
        this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al obtener los datos de la Marca, favor inténtelo nuevamente más tarde.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
        })
        this.$emit('close')
      })
      this.isLoading = false
    },

    async editTrademark() {
      this.isLoading = true
      await axios.put('/trademarks/' + this.id + '.json', {
        alt_name_trademark: this.name_trademark
      })
      .then(response => {
          this.$buefy.dialog.alert({
              title: 'Logrado',
              message: 'Se ha editado correctamente la Marca.',
              type: 'is-success',
              hasIcon: true,
              icon: 'circle-check',
              iconPack: 'fas',
              ariaRole: 'alertdialog',
              ariaModal: true
          })
          this.$emit('refresh')
          this.$emit('close')
      })
      .catch(error => {
          this.$buefy.dialog.alert({
              title: 'Error',
              message: 'Hubo un error al editar el nombre de la Marca, favor revisar los errores. <br>Errores:<br>' + error.response.data,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
          })
      })
      this.isLoading = false
    },

    async check() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: "Debe Ingresar un nombre de la Marca.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
      else {
        this.$buefy.dialog.confirm({
            title: "Editar Marca",
            message: `¿Esta seguro de editar la Marca?`,
            cancelText: "Cancelar",
            confirmText: "Editar",
            type: "is-info",
            onConfirm: () => this.editTrademark(),
        });
      }
    },
  },


  created() {
    this.getTrademark()
  }

}
</script>
<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmregister())">
        <div class="modal-card" style="width: 45em; height: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="newSupply">
            <div class="box">
              <b-tabs>
                <b-tab-item label="Agregar Insumo">
                  <div class="columns is-centered">
                    <div class="column is-centered">
                      <div class="columns">
                        <div class="column">
                          <b-field label="Nombre del Insumo">
                            <ValidationProvider
                              name="nombre"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-input
                                v-model="aux_name_supplie"
                                placeholder="Ingrese el nombre del insumo"
                              >
                              </b-input>
                              <span class="validation-alert">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                        <div class="column">
                          <b-field label="Numero de identificación del Insumo">
                            <ValidationProvider
                              name="nombre"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-input
                                v-model="aux_identification_number_supplie"
                                placeholder="Ingrese el numero de identificación insumo"
                              >
                              </b-input>
                              <span class="validation-alert">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>

                      <div class="columns">
                        <div class="column">
                          <b-field label="Cantidad total">
                            <ValidationProvider
                              name="nombre"
                              v-slot="{ errors }"
                            >
                              <b-numberinput
                                min="0"
                                v-model="aux_total"
                                type="is-info"
                                placeholder="Ingrese la cantidad total del insumo"
                              >
                              </b-numberinput>
                              <span class="validation-alert">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field label="Cantidad restante">
                            <ValidationProvider
                              name="nombre"
                              v-slot="{ errors }"
                            >
                              <b-numberinput
                                min="0"
                                v-model="aux_remaining"
                                type="is-info"
                                placeholder="Ingrese la cantidad restante del insumo"
                              >
                              </b-numberinput>
                              <span class="validation-alert">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>

                      <div class="columns">
                        <div class="column">
                          <b-field label="Categoria *">
                            <ValidationProvider
                              name="nombre"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <multiselect
                                style="is-info"
                                v-model="aux_category"
                                :options="categories.map((type) => type.id)"
                                :custom-label="
                                  (opt) =>
                                    categories.find((x) => x.id === opt)
                                      .name_category
                                "
                                placeholder="Seleccionar categoria"
                                selectLabel=""
                                selectLabelcolor=""
                                selectedLabel="Seleccionado"
                                deselectLabel="Presione para deseleccionar"
                                :allow-empty="true"
                              ></multiselect>
                              <span class="validation-alert">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>

                        <div class="column">
                          <b-field label="Marca *">
                            <ValidationProvider
                              name="nombre"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <multiselect
                                style="is-info"
                                v-model="aux_trademark"
                                :options="trademarks.map((type) => type.id)"
                                :custom-label="
                                  (opt) =>
                                    trademarks.find((x) => x.id === opt)
                                      .name_trademark
                                "
                                placeholder="Seleccionar marca"
                                selectLabel=""
                                selectLabelcolor=""
                                selectedLabel="Seleccionado"
                                deselectLabel="Presione para deseleccionar"
                                :allow-empty="true"
                              ></multiselect>
                              <span class="validation-alert">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </b-field>
                        </div>
                      </div>

                      <b-field grouped position="is-right">
                        <p class="control">
                          <b-button
                            label="Agregar Insumo"
                            type="is-info"
                            @click="check()"
                          />
                        </p>
                      </b-field>
                    </div>
                  </div>
                </b-tab-item>

                <!---
          <b-tab-item label="Subida masiva">
            

          <h1>Seleccionar archivo</h1>
        
            <b-field class="file">
              <b-upload v-model="file" expanded>
                <a class="button is-info is-fullwidth">
                  <b-icon icon="upload"></b-icon>
                  <span>{{ file.name || "Subir Archivo"}}</span>
                </a>
              </b-upload>
            </b-field>


            <div class="has-text-right">
            <b-button  type="is-info" @click="import_file">Importar</b-button>
          </div>



          </b-tab-item>
          -->
              </b-tabs>
            </div>
          </section>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "new_supply",
  props: ["queryType", "modalHeader"],
  data() {
    return {
      selected: null,
      supplies: [],
      aux_supplies: [],
      trademarks: [],
      categories: [],
      aux_name_supplie: null,
      aux_identification_number_supplie: null,
      aux_total: null,
      aux_remaining: null,
      aux_category: null,
      aux_trademark: null,
      aux_active: 1,

      //IMPORTS
      import_type: "Propiedad Intelectual",
      file: { name: "" },
      dropFiles: [],
      isLoading: false,
      errores: [],
      file_name_errors: "",
      cabeceras: [],
      hojas: [],
      errorFile: { name: "" },
    };
  },
  methods: {
    async get_trademarks() {
      const response = await axios.get("/trademarks.json");
      this.trademarks = response.data;
    },

    async get_categories() {
      const response = await axios.get("/categories.json");
      this.categories = response.data;
    },

    async createClick() {
      const response = await axios.post("/supplies.json", {
        name_supplie: this.aux_name_supplie,
        identification_number_supplie: this.aux_identification_number_supplie,
        total: this.aux_total,
        remaining: this.aux_remaining,
        category_id: this.aux_category,
        trademark_id: this.aux_trademark,
      });
      window.location.href = "/supplies";
    },

    async check() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.confirmregister();
      }
    },

    confirmregister() {
      this.$buefy.dialog.confirm({
        title: "Registrar Insumo",
        message: `¿Esta seguro de registrar el insumo?`,
        cancelText: "Cancelar",
        confirmText: "Registrar",
        type: "is-info",

        onConfirm: () => this.createClick(),
      });
    },

    /*IMPORTS


    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    import_file(){
      this.isLoading = true;
      var url=""
        url = "/units/massive_unit/import_unit.json"
      let formData = new FormData();
      formData.append('file', this.file);
      this.errorFile = this.file;
      axios.post( url,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(response =>{
        if (response.data["errores"].length>0){
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: 'Algunos datos del archivo "'+this.file.name+'" no han podido ser importados, por favor, revisar errores',
            confirmText: 'Ok',
            type: 'is-warning',
            hasIcon: true,
            icon: 'exclamation-triangle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.file_name_errors = this.file.name;
          this.errores = response.data["errores"];
          this.cabeceras = response.data["cabeceras"];
          if (this.import_type == "Convenios"){
            this.hojas = response.data["sheets"]
          }
          else{
            this.hojas = []
          }
        }
        else{
          this.import_type = '';
          this.isLoading = false;
          this.$buefy.dialog.alert({
            message: 'Su archivo "'+this.file.name+'" ha sido importado con éxito',
            confirmText: 'Ok'
          })
          this.file={name:""};
          this.errores = [];
          this.file_name_errors="";
        }

      })
      .catch(error => {
        //console.log('FAILURE!!');
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo errores en la importación de su archivo. Puede ser un archivo no válido o presenta errores de formato',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = false;
      });
      
    },
    importErrors(){
      let vm = this;
      vm.isLoading=true;
      axios
          .post("/load-data/export-errors.xlsx",{cabeceras:vm.cabeceras,errores:vm.errores,hojas:vm.hojas},
              {
                responseType: "blob"
              })
          .then(response =>{
            vm.isLoading=false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Errores importacion masiva de datos '+vm.errorFile.name.replace(".xlsx","")+' '+new Date().toLocaleDateString()+'.xlsx');
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
            vm.isLoading=false;
            vm.$buefy.notification.open({
              message: 'Hubo un error en tu solicitud',
              type: 'is-danger'
            })
          })


    }
    */
  },
  created() {
    this.get_trademarks();
    this.get_categories();
  },
};
</script>

<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">

      <div class="content">
        <h1>Personas asociada a la facultad</h1>
      </div>

      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Personas Asociadas">
          <div class="has-text-right">
            <b-button label="Añadir Persona asociada" type="is-success" icon-left="plus" @click="createDutyManager()"
              v-show="(isAuthorized('all', 'manage')) || (isAuthorized('DutyManager', 'create'))">
            </b-button>
          </div>

          <div>
            <b-modal :active="isModalActiveShow" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <show-duty-manager modal-cancel="true" :id="selected" :modal-header="'Detalle de la persona asociada'"
                  @close="closeModalShow" @refresh="toRefreshActiveDutyManagers" />
              </template>
            </b-modal>

            <b-modal :active="isModalActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <new-duty-manager modal-cancel="true" :modal-header="'Registro de Persona asociada'" query-type="create"
                  @close="closeModalCreate" @refresh="toRefreshActiveDutyManagers" />
              </template>
            </b-modal>

            <b-modal :active="isModalActiveEdit" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <edit-duty-manager :duty_manager-id="duty_managerId" :id="selected"
                  :modal-header="'Editar información de la persona'" query-type="update" @close="closeModalEdit"
                  @refresh="toRefreshActiveDutyManagers" />
              </template>
            </b-modal>

          </div>


          <div class="column">
            <b-table :data="managers" :paginated=true per-page="10" striped hoverable :pagination-simple=false
              pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
              default-sort="inventores.name" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">

              <b-table-column :visible=false field="id" label="ID" sortable searchable>

                <template v-slot="props">
                  {{ props.row.id }}
                </template>



              </b-table-column>

              <b-table-column field="name_duty_manager" label="Nombre del Personal" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.name_duty_manager }}
                </template>
              </b-table-column>

              <b-table-column field="rutCompleto" label="Rut" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Rut..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.rutCompleto }}
                </template>
              </b-table-column>

              <b-table-column field="unit.name_unit" label="Nombre Unidad" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Unidad..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.unit.alt_name_unit }}
                </template>
              </b-table-column>


              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                      tag="a" :href="/duty_managers/ + props.row.id" target="_blank"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('DutyManager', 'show'))"/>
                  </div>
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="pen" type="is-primary"
                      @click.native="editManager(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('DutyManager', 'update'))"/>
                  </div>
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="ban" type="is-danger"
                      @click.native="confirmisempty(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('DutyManager', 'disable_duty_manager'))"/>
                  </div>
                </div>
              </b-table-column>

            </b-table>
          </div>
        </b-tab-item>





        <b-tab-item label="Personas Asociadas Anuladas">
          <div class="column">
            <b-table :data="null_managers" :paginated=true per-page="10" striped hoverable :pagination-simple=false
              pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
              default-sort="inventores.name" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">

              <b-table-column :visible=false field="id" label="ID" sortable searchable>

                <template v-slot="props">
                  {{ props.row.id }}
                </template>



              </b-table-column>

              <b-table-column field="name_duty_manager" label="Nombre del Personal" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.name_duty_manager }}
                </template>
              </b-table-column>

              <b-table-column field="rutCompleto" label="Rut" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Rut..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.rutCompleto }}
                </template>
              </b-table-column>

              <b-table-column field="unit.name_unit" label="Nombre Unidad" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Unidad..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.unit.alt_name_unit }}
                </template>
              </b-table-column>


              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">

                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                      tag="a" :href="/duty_managers/ + props.row.id" target="_blank"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('DutyManager', 'show'))"/>
                  </div>
                  <div class="column" v-show='!(props.row.unit.active_unit == false)'>
                    <b-button icon-pack="fas" icon-right="trash-arrow-up" type="is-success"
                      @click.native="confirmGoodRest(props.row.id, props.row.user_id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('DutyManager', 'activate_duty_manager'))"/>
                  </div>

                  <div class="column" v-show='(props.row.unit.active_unit == false)'>
                    <b-tooltip label="No  se puede Restaurar" type="is-info">
                      <b-icon pack="fas" icon="trash-arrow-up" type="is-danger" size="is-medium" />
                    </b-tooltip>
                  </div>

                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
  </section>
</template>


<script>
import axios from "axios";
import NewDutyManager from './new_duty_manager.vue';
import ShowDutyManager from './show_duty_manager.vue';
import EditDutyManager from "./edit_duty_manager.vue";
export default {
  components: { NewDutyManager, ShowDutyManager, EditDutyManager },
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Personas Asociadas';
  },
  data() {
    return {
      managers: [],
      goods: [],
      id_goods_list: [],
      null_managers: [],
      goods_duty_manager: [],
      offices_duty_manager: [],
      buildings_duty_manager: [],
      canCancel: ["escape"],
      isModalActive: false,
      isModalActiveShow: false,
      isModalActiveEdit: false,
      selected: null,
      null_active: false,
      des_active: true,
      pendiing_good: 2,
      isLoading: false,
    };
  },
  props: ["duty_managerId", 'permissions'],
  methods: {

    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    async getdutymanageractive() {
      this.isLoading = true
      await axios.get("/duty_managers/showactivedutymanager.json")
        .then(response => {
          this.managers = response.data
        })
        .catch(error => {
          let errorMsg = ''
          if (error.response) {
            if (error.response.status === 403) {
              errorMsg = 'No tiene permisos para obtener las Personas Asociadas Activas'
            } else {
              errorMsg = 'Hubo errores al obtener las Personas Asociadas Activas, favor de revisar errores.<br>Errores<br>' + error.response.data
            }
          } else {
            errorMsg = 'Hubo errores al obtener las Personas Asociadas Activas, intente más tarde.'
          }

          this.$buefy.dialog.alert({
            title: 'Error',
            message: errorMsg,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading = false
        })
      
    },

    async null_getdutymanager() {
      this.isLoading = false
      await axios.get("/duty_managers/shownulldutymanager.json")
        .then(response => {
          this.null_managers = response.data
        })
        .catch(error => {
          let errorMsg = ''
          if (error.response) {
            if (error.response.status === 403) {
              errorMsg = 'No tiene permisos para obtener las Personas Asociadas Anuladas'
            } else {
              errorMsg = 'Hubo errores al obtener las Personas Asociadas Anuladas, favor de revisar errores.<br>Errores<br>' + error.response.data
            }
          } else {
            errorMsg = 'Hubo errores al obtener las Personas Asociadas Anuladas, intente más tarde.'
          }

          this.$buefy.dialog.alert({
            title: 'Error',
            message: errorMsg,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.isLoading = false
        })
    },

    confirmisempty(item) {
      this.getgoodbydutymanagernull(item);
    },



    async getgoodbydutymanagernull(item) {
      const response = await axios.get("/goods/showgoodutymanager/" + item + ".json");
      this.goods_duty_manager = response.data;
      if (this.goods_duty_manager.length == 0) {
        this.getofficebydutymanager(item);
      }
      else {
        this.$buefy.dialog.alert({
          title: 'No se puede anular',
          type: 'is-danger',
          message: 'Existen bienes designados a la persona asociada',
          confirmText: 'Aceptar'
        })
      }
    },

    async getofficebydutymanager(item) {
      const response = await axios.get("/offices/showofficedutymanager/" + item + ".json");
      this.offices_duty_manager = response.data;
      if (this.offices_duty_manager.length == 0) {
        this.getobuildingbydutymanager(item);
      }
      else {
        this.$buefy.dialog.alert({
          title: 'No se puede anular',
          type: 'is-danger',
          message: 'Existen dependencias designados a la persona asociada',
          confirmText: 'Aceptar'
        })
      }
    },

    async getobuildingbydutymanager(item) {
      const response = await axios.get("/buildings/showbuildingdutymanager/" + item + ".json");
      this.buildings_duty_manager = response.data;
      if (this.buildings_duty_manager.length == 0) {
        this.confirmGoodNull(item);
      }
      else {
        this.$buefy.dialog.alert({
          title: 'No se puede anular',
          type: 'is-danger',
          message: 'Existen edificios designados a la persona asociada',
          confirmText: 'Aceptar'
        })
      }
    },

    async RestClick(id) {
      await axios.put('/duty_managers/' + id + '/activate.json')
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha restaurado correctamente la Persona Asociada.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.getInformation()
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al restaurar la Persona Asociada.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
    },
    async confirmGoodRest(item, id_user) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Persona Encargada",
        message: "¿Esta seguro de querer restaurar la Persona Encargada?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
      
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Persona Encargada",
        message: "¿Esta seguro de querer anular la Persona Encargada?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });
    },

    async AnulClick(id) {
      await axios.put('/duty_managers/' + id + '/disable.json')
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha anulado correctamente la Persona Asociada.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.getInformation()
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al anular la Persona Asociada.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
    },


    async editManager(item) {
      this.selected = item;
      this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },

    async Clickshowunit(item) {
      const response = (window.location.href = "/units/" + item);
    },
    async Clicknewdutymanager() {
      const response = (window.location.href = "/duty_managers/new");
    },
    async createDutyManager() {
      this.isModalActive = true;
    },
    async closeModalCreate() {
      this.isModalActive = false;
    },
    async ShowModaleDutyManager(item) {
      this.selected = item;
      this.isModalActiveShow = true;
    },
    async closeModalShow() {
      this.isModalActiveShow = false;
    },
    async toRefreshActiveDutyManagers(){
      this.isLoading = true
      await this.getdutymanageractive();
      this.isLoading = false

    },

    async getInformation() {
      this.isLoading = true
      await this.getdutymanageractive()
      await this.null_getdutymanager()
      this.isLoading = false
    }

  },
  async created() {
    await this.getInformation()
  },
};
</script>
<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">

      <b-modal :active="isModalEditCharge" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
        width="100%" :can-cancel="canCancel">
        <template>
          <edit-building-charge :oldDutyManagerId="oldDutyManagerId" :id="selected"
            :modal-header="'Asignar Nueva Persona Encargada'" query-type="update" @close="closeModalEditCharge"
            @refresh="getInformation" />
        </template>
      </b-modal>

      <div class="content">
        <h1>Detalle del Edificio</h1>
      </div>
      <b-tabs type="is-boxed" position="is-centered">

        <b-tab-item label="Detalle del Edificio">

          <div v-if="building" class="columns is-centered">


            <div class="column is-centered">

              <div class="columns">
                <div class="column">
                  <b-field label="Nombre Edificio:">
                    <b-input v-model="building.alt_name_building" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Numero identificación del edificio:">
                    <b-input v-model="building.identification_number_building" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Monto bruto total invertido en bienes: ">
                    <b-input v-model="total" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Ubicación:">
                    <b-input v-model="building.location_building" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>


              <div class="columns">
                <div class="column">
                  <b-field label="Unidad: ">
                    <b-input
                      v-model="building.unit.alt_name_unit" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">


                <div class="column">
                  <b-field label="Persona encargada: ">
                    <b-input v-model="building.duty_manager.name_duty_manager" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <p>Cargando</p>
          </div>
        </b-tab-item>

        <b-tab-item label="Dependencias del Edificio">
          <div class="column">
            <b-table :data="building.offices" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="name_office" label="Nombre Dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Dependencia..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_office }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_office" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_office }}
                </template>
              </b-table-column>

              <b-table-column field="location" label="Ubicación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Ubicación..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.location }}
                </template>
              </b-table-column>

              <b-table-column field="id" width="10" label="Acciones" v-slot="props">
                <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                  tag="a" :href="/offices/ + props.row.id" target="_blank"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Office', 'show'))"/>
              </b-table-column>
            </b-table>
          </div>

        </b-tab-item>

        <b-tab-item label="Bienes del Edificio">
          <div class="column">
            <b-table :data="building.goods" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">

              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_good" label="Nombre" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_good }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_good" label="N° Inventario" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_good }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_category" label="Categoria" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Categoría..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_category }}
                </template>
              </b-table-column>

              <b-table-column field="name_duty_manager" label="Nombre Persona Asociada" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Persona..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.name_duty_manager }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_office" label="Dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Dependencia..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_office }}
                </template>
              </b-table-column>

              <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile">
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                        tag="a" :href="/goods/ + props.row.id" target="_blank"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show'))"/>
                  </div>
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="ban" type="is-danger"
                        @click.native="confirmGoodNull(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'disable_building'))"/>
                  </div>

                </div>
              </b-table-column>

            </b-table>
          </div>
        </b-tab-item>

        <b-tab-item label="Persona Encargada">

          <br>

          <b-tabs type="is-toggle" position="is-centered">

            <b-tab-item label="Persona Encargada Actual">
              <div class="columns" v-if="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'update_charge'))">
                <div class="column has-text-right">
                  <b-button type="is-purple" label="Asignar Nueva Persona Encargada" icon-left="pen"
                    @click="editCharge(buildingId)"/>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Unidad">
                    <b-input v-model="building.unit.alt_name_unit" type="text" readonly>
                    </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Persona encargada">
                    <b-input v-model="building.duty_manager.name_duty_manager" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="Historial de Personas Encargadas">
              <div class="columns">
                <div class="column">

                  <b-table :data="building.record_charges" :paginated="true" per-page="10" striped hoverable sortable:
                    true pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small"
                    aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
                    aria-current-label="Página Actual" pagination-size="is-small">

                    <b-table-column field="name_duty_manager" label="Nombre Persona" sortable searchable>
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.name_duty_manager }}
                      </template>
                    </b-table-column>

                    <b-table-column field="formatted_start_date" label="Fecha Inicio" sortable searchable :custom-search="searchCustomStartDate">
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha Inicio..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.formatted_start_date._isValid ? new Date(props.row.formatted_start_date).toLocaleDateString("es-CL") : '' }}
                      </template>
                    </b-table-column>

                    <b-table-column field="formatted_end_date" label="Fecha Término" sortable searchable :custom-search="searchCustomEndDate">
                      <template #searchable="props">
                        <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha Término..."
                          icon="magnifying-glass" />
                      </template>
                      <template v-slot="props">
                        {{ props.row.formatted_end_date._isValid ? new Date(props.row.formatted_end_date).toLocaleDateString("es-CL") : 'Actualidad' }}
                      </template>
                    </b-table-column>

                  </b-table>

                </div>
              </div>
            </b-tab-item>

          </b-tabs>
        </b-tab-item>


        <b-tab-item label="Historial">

          <div class="column">
            <b-table :data="building.versions_goods" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">

              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_good" label="Nombre del Bien" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_good }}
                </template>
              </b-table-column>

              <b-table-column field="full_name" label="Nombre Usuario" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre U..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.full_name }}
                </template>
              </b-table-column>

              <b-table-column field="event" label="Acción" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Acción..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.event }}
                </template>
              </b-table-column>

              <b-table-column field="date_event" label="Fecha de acción" sortable searchable :custom-search="searchCustomDate">
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.date_event._isValid ? new Date(props.row.date_event).toLocaleString("es-CL") : '' }}
                </template>
              </b-table-column>

            </b-table>
          </div>

        </b-tab-item>

      </b-tabs>

      <b-button tag="a" href="/buildings" label="Volver" />

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    </div>
  </section>
</template>

 
<script>
import axios from "axios";
import moment from "moment";
import EditBuildingCharge from "./edit_building_charge.vue";
import { searchDate } from "../../packs/utilities";
export default {
  name: "show_building",
  props: ["id", 'queryType', 'modalHeader', 'buildingId', 'permissions'],
  components: {
    EditBuildingCharge
  },
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Detalle Edificio';
  },
  data() {
    return {
      records: [],
      building: {
        unit: {},
        duty_manager: {},
      },
      total: null,
      oldDutyManagerId: null,
      null_activegood: false,
      props: ["id"],
      isModalEditCharge: false,
      canCancel: ["escape"],
      selected: null,
      isLoading: false,
    };
  },
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    async getBuilding() {
      await axios.get("/buildings/" + this.buildingId + ".json")
        .then((response) => {
          this.building = response.data
          this.building["record_charges"] = this.building["record_charges"].map(record => {
            var newRecord = record
            newRecord["formatted_start_date"] = moment(newRecord["formatted_start_date"], ["YYYY-MM-DD", "DD-MM-YYYY"])
            newRecord["formatted_end_date"] = moment(newRecord["formatted_end_date"], ["YYYY-MM-DD", "DD-MM-YYYY"])
            return newRecord
          })

          var goodamounts = this.building.goods.map(good => good.amount)
          const c = 0
          this.total = goodamounts.reduce((a,b) => a + b, c)

          if (this.building.versions_goods != null) {

            this.building.versions_goods = this.building.versions_goods.map(version => {
              var newVersion = version
              newVersion.date_event = moment(newVersion.date_event, ["YYYY-MM-DD H:m:s", "DD-MM-YYYY H:m:s"])
              return newVersion
            })

            this.building.versions_goods.sort(function (a, b) {
              return new Date(b.date_event) - new Date(a.date_event)
            })

          }

        })
        .catch((error) => {
          var showError = null
          console.log(error)

          if (error.response != null) {
            showError = error.response.data
          }else{
            showError = error
          }

          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al traer la información del Edificio, favor de revisar errores.<br>Errores<br>' + showError,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Bien",
        message: "¿Esta seguro de querer anular el bien?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulGoodClick(item),
      });
    },

    async AnulGoodClick(item) {
      this.isLoading = true
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.null_activegood,
      })
      await this.getInformation()
      this.isLoading = false
    },

    async Clickshowunit(item) {
      const response = (window.location.href = "/units/" + item);
    },

    async Clickshowdutymanager(item) {
      const response = (window.location.href = "/duty_managers/" + item);
    },
    async Clickshowbuilding(item) {
      const response = (window.location.href = "/buildings/" + item);
    },

    async editCharge(item) {
      this.selected = item;
      this.oldDutyManagerId = this.building.duty_manager_id;
      this.isModalEditCharge = true;
    },

    async closeModalEditCharge() {
      this.isModalEditCharge = false;
    },

    searchCustomStartDate (row, input) {
      return searchDate (row.formatted_start_date, input)
    },

    searchCustomEndDate (row, input) {
      return searchDate (row.formatted_end_date, input)
    },

    searchCustomDate (row, input) {
      return searchDate (row.formatted_created_at, input)
    },

    async getInformation() {
      this.isLoading = true
      await this.getBuilding();
      this.isLoading = false
    }

  },
  async created() {
    await this.getInformation()
  },
};
</script>





<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmedit())">
        <div class="modal-card" style="width: 62em; height: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="restockSupply">
            <b-tabs>
              <div class="box">
                <div v-if="supply" class="columns is-centered">
                  <div class="column is-centered">
                    <div class="center"><h1>Datos del insumo</h1></div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="Nombre del Insumo:">
                          <b-input
                            v-model="supply.name_supplie"
                            type="text"
                            readonly
                          ></b-input>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="Cantidad a reponer">
                          <ValidationProvider name="nombre" v-slot="{ errors }">
                            <b-numberinput
                              min="0"
                              max="supply.total"
                              v-model="aux_consume"
                              type="is-info"
                              placeholder="Ingrese la cantidad a reponer para el insumo"
                            >
                            </b-numberinput>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field label="Unidad *">
                          <ValidationProvider
                            name="nombre"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <multiselect
                              style="is-info"
                              v-model="aux_unit_id"
                              :options="units.map((type) => type.id)"
                              :custom-label="
                                (opt) =>
                                  units.find((x) => x.id === opt).name_unit
                              "
                              placeholder="Seleccionar Unidad"
                              selectLabel=""
                              selectLabelcolor=""
                              selectedLabel="Seleccionado"
                              deselectLabel="Presione para deseleccionar"
                              :allow-empty="true"
                              :disabled="aux_duty_manager_id !== null"
                              @input="getdutymanagerbyunit(aux_unit_id)"
                            >
                            </multiselect>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>

                      <div class="column">
                        <b-field label="Persona asociada encargada *">
                          <ValidationProvider
                            name="nombre"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <multiselect
                              v-model="aux_duty_manager_id"
                              :options="managers.map((type) => type.id)"
                              :custom-label="
                                (opt) =>
                                  managers.find((x) => x.id === opt)
                                    .name_duty_manager
                              "
                              placeholder="Seleccionar Persona encargada"
                              selectLabel="Presione para seleccionar"
                              selectedLabel="Seleccionado"
                              deselectLabel="Presione para deseleccionar"
                              :allow-empty="true"
                              :disabled="aux_unit_id == null"
                            >
                            </multiselect>
                            <span class="validation-alert">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </b-field>
                      </div>
                    </div>

                    <b-field grouped position="is-right">
                      <p class="control">
                        <b-button
                          label="Guardar"
                          type="is-info"
                          @click="check()"
                        />
                      </p>
                    </b-field>
                  </div>
                </div>

                <div v-else>
                  <p>Loading</p>
                </div>
              </div>
            </b-tabs>
          </section>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
import IndexSuplies from "./index_supply.vue";
import moment from "moment";
export default {
  name: "edit_unit",
  props: ["queryType", "modalHeader", "supplyId", "id"],
  components: {
    "index-supply": IndexSuplies,
  },

  data() {
    return {
      supply: null,
      supplies: [],
      trademarks: [],
      categories: [],
      units: [],
      managers: [],
      aux_unit_id: null,
      aux_duty_manager_id: null,
      aux_supply_id: null,
      aux_consume: 0,
      aux_actual: null,
      aux_new_total: null,
      aux_record_restock_supplie: "Reposición de stock",
      aux_date_today: new Date(),

      props: ["id"],
    };
  },
  methods: {
    async getsupply() {
      const response = await axios.get("/supplies/" + this.id + ".json");
      this.loading = true;
      this.supply = response.data;
      this.aux_actual = this.supply.total;
      this.aux_supply_id = this.supply.id;
    },

    async check() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.confirmedit();
      }
    },

    confirmedit() {
      this.$buefy.dialog.confirm({
        title: "Consumo de insumos",
        message: `¿Esta seguro que quiere confirmar el restablecimiento de stock del insumo?`,
        cancelText: "Cancelar",
        confirmText: "Guardar",
        type: "is-info",
        onConfirm: () => this.EditClick(),
      });
    },

    async EditClick() {
      const response = await axios.put("/supplies/" + this.id + ".json", {
        total: this.aux_actual + this.aux_consume,
      });
      this.RecordRestockSupply();
    },

    async getunitactive() {
      const response = await axios.get("/units/showactiveunit.json");
      this.units = response.data;
    },

    async getdutymanagerbyunit(item) {
      const response = await axios.get(
        "/duty_managers/showdutymanagerunit/" + item + ".json"
      );
      this.managers = response.data;
    },

    async RecordRestockSupply() {
      const response = await axios.post("/supplies_records.json", {
        detail_record: this.aux_record_restock_supplie,
        date_action: this.aux_date_today,
        before_total: this.aux_actual,
        after_total: this.aux_actual + this.aux_consume,
        quantity_changed: this.aux_consume,
        supply_id: this.aux_supply_id,
        duty_manager_id: this.aux_duty_manager_id,
      });
      window.location.href = "/supplies";
    },
  },
  created() {
    this.getunitactive();
    this.getsupply();
  },
};
</script>

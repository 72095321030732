<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmregister())">

        <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="newFinancialSources">
            <div class="columns is-centered">
              <div class="column is-centered ">

                <div class="my-0">

                  <div class="columns">
                    <div class="column">
                      <b-field label="Nombre de Fuente financiera *">
                        <ValidationProvider name="Nombre" rules="required|max:255" v-slot="{ errors }">
                          <b-input v-model="name_financial_source"
                            placeholder="Ingrese el nombre de la Fuente financiera"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Descripción *">
                        <ValidationProvider name="Descripción" rules="required|max:255" v-slot="{ errors }">
                          <b-input v-model="detail" type="textarea"
                            placeholder="Ingrese la descripción de la Fuente financiera" />
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>

                </div>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <p class="control">
                      <b-button label="Editar Fuente" icon-left="save" type="is-info" @click="check()" />
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
    name:"edit_financial_sources",
    props:['queryType', 'modalHeader', 'id'],
    data() {
        return {
            isLoading: false,

            //Información a traer
            name_financial_source: null,
            detail: null,
        }
    },

    methods: {
        async getFinancialSource() {
            this.isLoading = true
            await axios.get('/financial_sources/' + this.id + '.json')
            .then(response => {
                this.name_financial_source = response.data.alt_name_financial_source
                this.detail = response.data.detail
            })
            .catch(error => {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Hubo un error al obtener los datos de la Fuente de Financiamiento, favor inténtelo nuevamente más tarde.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
                this.$emit('close')
            })
            this.isLoading = false
        },

        async editFinancialSource() {
            this.isLoading = true
            await axios.put('/financial_sources/' + this.id + '.json', {
                alt_name_financial_source: this.name_financial_source,
                detail: this.detail
            })
            .then(response => {
                this.$buefy.dialog.alert({
                    title: 'Logrado',
                    message: 'Se ha editado correctamente la Fuente de Financiamiento.',
                    type: 'is-success',
                    hasIcon: true,
                    icon: 'circle-check',
                    iconPack: 'fas',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
                this.$emit('refresh')
                this.$emit('close')
            })
            .catch(error => {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Hubo un error al editar la Fuente de Financiamiento, favor revisar los errores.<br>Errores:<br>' + error.response.data,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            })
            this.isLoading = false
        },

        async check() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                this.$buefy.dialog.alert({
                message: "Algunos campos presentan errores.",
                type: "is-danger",
                hasIcon: true,
                icon: "times-circle",
                iconPack: "fa",
                ariaRole: "alertdialog",
                ariaModal: true,
                });
            }
            else {
                this.$buefy.dialog.confirm({
                    title: "Editar Fuente de Financiamiento",
                    message: `¿Esta seguro de editar la Fuente de Financiamiento?`,
                    cancelText: "Cancelar",
                    confirmText: "Editar",
                    type: "is-info",
                    onConfirm: () => this.editFinancialSource(),
                });
            }
            
        },
    },

    async created () {
      this.isLoading = true
      this.getFinancialSource()
      this.isLoading = false
    }
}
</script>

<style>

</style>
<template>
  <section>
    <br>
    <div class="container box">
      <div class="content">
        <h1>Bienes de la facultad</h1>
      </div>
      

      <b-tabs type="is-boxed" position="is-centered">

        <b-tab-item label="Bienes Activos">
          <div>
            <b-modal :active="isModalActive" aria-modal :destroy-on-hide="true" aria-role="dialog"
              width="90%" :can-cancel="canCancel">
              <template>
                <new-good modal-cancel="true" :modal-header="'Registro de Bien'" query-type="create"
                  @close="closeModalCreate" @refresh="toRefreshActiveGoods"/>
              </template>
            </b-modal>

            <b-modal :active="isModalActiveEdit" aria-modal :destroy-on-hide="true" aria-role="dialog"
              width="90%" :can-cancel="canCancel">
              <template>
                <edit-good :good-id="goodId" :id="selected" :modal-header="'Editar Bien'" query-type="update"
                  @close="closeModalEdit" @refresh="toRefreshActiveGoods"></edit-good>
              </template>
            </b-modal>

            <b-modal :active="isModalnewstatus" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <new-condition-good :good-id="goodId" :id="selected" :modal-header="'Reportar Bien'" query-type="create"
                  @close="closeModalCreateStatus" @refresh="toRefreshActiveGoods"></new-condition-good>
              </template>
            </b-modal>
          </div>

          <div class="columns">
            <div class="column has-text-right">
              <b-button label="Añadir Bien" type="is-info" icon-left="plus" @click="createGood()"></b-button>
              <b-button label="Descargar bienes" type="is-success" icon-left="file-excel" @click="exportGood()"></b-button>
            </div>

          </div>
          <div class="columns">
            <div class="column">
              <b-table :data="goods" striped hoverable :paginated="true" per-page="100" :pagination-simple="false"
                pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up">
                <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                  <template v-slot="props">
                    {{ props.row.id }}
                  </template>
                </b-table-column>

                <b-table-column field="namegood" label="Nombre" sortable searchable>
                  <template #searchable="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Buscar Nombre..."
                      icon="magnifying-glass"/>
                  </template>
                  <template v-slot="props">
                    {{ props.row.alt_name_good }}
                  </template>
                </b-table-column>

                <b-table-column field="identification_number_good" label="N° Inventario" sortable searchable>
                  <template #searchable="props">
                    <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Buscar N°..."
                      type="number"
                      icon="magnifying-glass"/>
                  </template>
                  <template v-slot="props">
                    {{ props.row.identification_number_good }}
                  </template>
                </b-table-column>

                <b-table-column field="category.name_category" label="Categoria" sortable searchable>
                  <template #searchable="props">
                    <b-input 
                      v-model="props.filters[props.column.field]"
                      placeholder="Buscar Categoria..."
                      icon="magnifying-glass"/>
                  </template>
                  <template v-slot="props">
                    {{ props.row.category.alt_name_category }}
                  </template>
                </b-table-column>

                <b-table-column field="duty_manager.name_duty_manager" label="Persona encargada" sortable searchable>
                  <template #searchable="props">
                    <b-input 
                      v-model="props.filters[props.column.field]"
                      placeholder="Buscar Encargado/a..."
                      icon="magnifying-glass"/>
                  </template>
                  <template v-slot="props">
                    {{ props.row.duty_manager.name_duty_manager }}
                  </template>
                </b-table-column>

                <b-table-column field="office.name_office" label="Dependencia" sortable searchable>
                  <template #searchable="props">
                    <b-input 
                      v-model="props.filters[props.column.field]"
                      placeholder="Buscar Dependencia..."
                      icon="magnifying-glass"/>
                  </template>
                  <template v-slot="props">
                    {{ props.row.office.alt_name_office }}
                  </template>
                </b-table-column>

                <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                  <div class="columns is-mobile is-variable is-1-mobile">
                    <div class="column">
                      <b-tooltip label="Ver detalle" type="is-info">
                        <b-button icon-pack="fas" icon-right="eye"
                          type="is-primary" @click.native="Clickshowgood(props.row.id)"/>
                      </b-tooltip>
                    </div>
                    <div class="column">
                      <b-tooltip label="Editar" type="is-info">
                        <b-button icon-pack="fas" icon-right="pen"
                          type="is-primary" outlined @click.native="editGood(props.row.id)"/>
                      </b-tooltip>
                    </div>
                    <div class="column">
                      <b-tooltip label="Reportar Bien" type="is-info">
                        <b-button icon-pack="fas" icon-right="bullhorn"
                          type="is-warning" @click.native="createConditionGood(props.row.id)"/>
                      </b-tooltip>
                    </div>
                    <div class="column">
                      <b-tooltip label="Anular" type="is-info">
                        <b-button icon-pack="fas" icon-right="trash"
                          type="is-danger" @click.native="confirmGoodNull(props.row.id)"/>
                      </b-tooltip>
                    </div>
                  </div>
                </b-table-column>

              </b-table>
            </div>
          </div>

        </b-tab-item>



        <b-tab-item label="Bienes Anulados">
          <div>
            <b-modal :active="isModalActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <new-good modal-cancel="true" :modal-header="'Registro de Bien'" query-type="create"
                  @close="closeModalCreate"></new-good>
              </template>
            </b-modal>



            <b-modal :active="isModalActiveEdit" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <edit-good :good-id="goodId" :id="selected" :modal-header="'Editar Bien'" query-type="update"
                  @close="closeModalEdit"></edit-good>
              </template>
            </b-modal>



            <b-modal :active="isModalnewstatus" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <new-condition-good :good-id="goodId" :id="selected" :modal-header="'Reportar Bien'" query-type="create"
                  @close="closeModalCreateStatus"></new-condition-good>
              </template>
            </b-modal>


          </div>
          <div class="column">
            <b-table :data="null_goods" :paginated="true" per-page="100" striped hoverable :pagination-simple="false"
              pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
              type="is-info">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>
              <b-table-column field="name_good" label="Nombre" sortable searchable>
                <template #searchable="props">
                  <b-input 
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar Nombre..."
                    icon="magnifying-glass"/>
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_good }}
                </template>
              </b-table-column>
              <b-table-column field="identification_number_good" label="N° Inventario" sortable searchable>
                <template #searchable="props">
                  <b-input 
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar N°..."
                    type="number"
                    icon="magnifying-glass"/>
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_good }}
                </template>
              </b-table-column>
              <b-table-column field="duty_manager.name_duty_manager" label="Persona encargada" sortable searchable>
                <template #searchable="props">
                  <b-input 
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar Encargado/a..."
                    icon="magnifying-glass"/>
                </template>
                <template v-slot="props">
                  {{ props.row.duty_manager.name_duty_manager }}
                </template>
              </b-table-column>
              <b-table-column field="office.name_building" label="Edificios" sortable searchable>
                <template #searchable="props">
                  <b-input 
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar Edificio..."
                    icon="magnifying-glass"/>
                </template>
                <template v-slot="props">
                  {{ props.row.office.alt_name_building }}
                </template>
              </b-table-column>
              <b-table-column field="office.name_office" label="Dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input 
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar Dependencia..."
                    icon="magnifying-glass"/>
                </template>
                <template v-slot="props">
                  {{ props.row.office.alt_name_office }}
                </template>
              </b-table-column>
              <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-tooltip label="Ver detalle" type="is-info">
                    <b-button icon-pack="fas" icon-right="eye"
                      type="is-primary" @click.native="Clickshowgood(props.row.id)"/>
                    </b-tooltip>
                  </div>
                  <div class="column">
                    <b-tooltip label="Restaurar" type="is-info">
                      <b-button icon-pack="fas" icon-right="trash-arrow-up"
                        type="is-success" outlined @click.native="confirmGoodRest(props.row.id)"/>
                    </b-tooltip>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>




      </b-tabs>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import NewGood from "./new_good.vue";
import EditGood from "./edit_good.vue";
import ShowGood from "./show_good.vue";
import NewConditionGood from "../condition_good/new_condition_good.vue";

export default {
  data() {
    return {
      goods: [],
      null_goods: [],
      pending_goods: [],
      decommissioned_goods: [],
      offices: [],
      units: [],
      managers: [],
      buildings: [],
      selected: null,
      select: null,
      canCancel: ["escape"],
      isModalActive: false,
      isModalActiveEdit: false,
      isModalActiveShow: false,
      isModalnewstatus: false,
      aux_duty_manager_id: null,
      aux_office_id: null,
      aux_unit_id: null,
      aux_building_id: null,
      null_activegood: false,
      des_activegood: true,
      current: null,
      isLoading: false,
    };
  },

  props: ["id", 'queryType', 'modalHeader', 'goodId'],

  components: {
    NewGood,
    EditGood,
    ShowGood,
    NewConditionGood,
  },
  methods: {
    async getgoodsactive() {
      this.isLoading = true;
      const response = await axios.get("/goods/showactive.json");
      this.goods = response.data;
      this.goods.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      this.isLoading = false;
    },



    async exportGood() {
      //const response = await axios.get("/goods/showactive.xlsx");
      window.location.href = "/goods/showactive.xlsx";
    },

    async null_getgoods() {
      const response = await axios.get("/goods/shownull.json");
      this.null_goods = response.data;
    },

    async pending_getgoods() {
      const response = await axios.get("/goods/showgoodpending.json");
      this.pending_goods = response.data;
    },

    async decommissioned_getgoods() {
      const response = await axios.get("/goods/showgooddecommissioned.json");
      this.decommissioned_goods = response.data;
    },

    async Clicknewgood() {
      const response = (window.location.href = "/goods/new");
    },
    async Clickshowgood(item) {
      const response = (window.location.href = "/goods/" + item);
    },
    async Clickshowdutymanager(item) {
      const response = (window.location.href = "/duty_managers/" + item);
    },
    async Clickshowbuilding(item) {
      const response = (window.location.href = "/buildings/" + item);
    },
    async Clickshowoffice(item) {
      const response = (window.location.href = "/offices/" + item);
    },







    async RestClick(item) {
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.des_activegood,
      })
      this.isLoading = true
      this.null_getgoods();
      await this.getgoodsactive();
      this.isLoading = false
    },
    confirmGoodRest(item) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Bien",
        message: "¿Esta seguro de querer restaurar el bien?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Bien",
        message: "¿Esta seguro de querer anular el bien?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });
    },

    async AnulClick(item) {
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.null_activegood,
      })

      this.isLoading = true
      await this.getgoodsactive();
      await this.null_getgoods();
      this.isLoading = false

    },

    async get_user_actual() {
      const response = await axios.get("/duty_managers/currentuser.json");
      this.current = response.data.current_user;
    },

    async getunit() {
      const response = await axios.get("/units.json");
      this.units = response.data;
    },

    async getdutymanagerbyunit(item) {
      const response = await axios.get(
        "/duty_managers/showdutymanagerunit/" +
        item +
        ".json"
      );
      this.managers = response.data;
    },

    async getbuildingbyunit(item) {
      const response = await axios.get(
        "/buildings/showbuildingunit/" + item + ".json"
      );
      this.buildings = response.data;
    },

    async getofficebybuilding(item) {
      const response = await axios.get(
        "/offices/showofficebuilding/" + item + ".json"
      );
      this.offices = response.data;
    },
    async Clickeditgood(item) {
      const response = (window.location.href = "/goods/" + item + "/edit");
    },
    async Clickshowgood(item) {
      window.location.href = "/goods/" + item;
    },

    async createGood() {
      this.isModalActive = true;
    },
    async closeModalCreate() {
      this.isModalActive = false;
    },

    async editGood(item) {
      this.selected = item;
      this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },
    async ShowModalGood(item) {
      this.selected = item;
      this.isModalActiveShow = true;
    },
    async closeModalShow() {
      this.isModalActiveShow = false;
    },
    async createConditionGood(item) {
      this.selected = item;
      this.isModalnewstatus = true;
    },

    async closeModalCreateStatus() {
      this.isModalnewstatus = false;
    },

    async toRefreshActiveGoods(refrescar){
      
      if (refrescar) {
        this.isLoading = true
        await this.getgoodsactive();
        this.isLoading = false
      }

    },

  },
  created() {
    this.getgoodsactive();
    this.null_getgoods();
    this.pending_getgoods();
    this.getunit();
    this.get_user_actual();
  },
};
</script>
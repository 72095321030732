<template>
  <div>

    <b-carousel v-if="userId == null" :arrow="false" :indicator="false" :pause-info="false">
        <b-carousel-item>
          <section :class="`hero is-medium is-${carousels[0].color}`">

            <b-image class="image" :src="carousels[0].image"  />

          </section>
        </b-carousel-item>
    </b-carousel>

    <b-carousel v-if="userId != null" indicator-mode="hover" animated="slide"
        :interval="6000" :pause-info="false">
        <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
          <section :class="`hero is-medium is-${carousel.color}`">
            <div v-if="i == 0">

              <b-image class="image" :src="carousel.image"  />

            </div>
            <div v-else>
              <a :href="carousel.link">
                <b-image class="image" :src="carousel.image"  />
              </a>
              
            </div>
          </section>
        </b-carousel-item>
    </b-carousel>

    <div class="container mt-2"
      v-if="(userId != null) && ((isAuthorized('all', 'manage')) || (isAuthorized('User', 'index')) || (isAuthorized('Good', 'show')) || (isAuthorized('Good', 'create'))) ">
      <section class="hero is-primary welcome is-small">
        <div class="hero-body">
          <p class="title">Accesos Directos</p>
        </div>
      </section>
      <section class="info-tiles box">

        <div class="tile is-ancestor has-text-centered">

          <div v-if="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show'))" class="tile is-parent">
            <article class="tile is-child box">
              <a href="/condition_goods">
                <p class="subtitle is-4">
                  <span class="icon-text">
                    <b-icon pack="fas" icon="people-carry-box" size="is-medium" />
                    <span>Gestión de Bienes</span>
                  </span>
                </p>
              </a>
            </article>
          </div>

          <div v-if="(isAuthorized('all', 'manage')) || (isAuthorized('User', 'index'))" class="tile is-parent">
            <article class="tile is-child box">
              <a href="/users">
                <p class="subtitle is-4">
                  <span class="icon-text">
                    <b-icon pack="fas" icon="users" size="is-medium" />
                    <span>Panel de Administración de Usuarios</span>
                  </span>
                </p>
              </a>
            </article>
          </div>

        </div>

        <div class="tile is-ancestor has-text-centered">

          <div v-if="(isAuthorized('all', 'manage')) || (isAuthorized('Unit', 'index'))" class="tile is-parent">
            <article class="tile is-child box">
              <a href="/units">
                <p class="subtitle is-4">
                  <span class="icon-text">
                    <b-icon pack="fas" icon="people-group" size="is-medium" />
                    <span>Unidades</span>
                  </span>
                </p>
              </a>
            </article>
          </div>

          <div v-if="(isAuthorized('all', 'manage')) || (isAuthorized('DutyManager', 'index'))" class="tile is-parent">
            <article class="tile is-child box">
              <a href="/duty_managers">
                <p class="subtitle is-4">
                  <span class="icon-text">
                    <b-icon pack="fas" icon="id-card" size="is-medium" />
                    <span>Personas Asociadas</span>
                  </span>
                </p>
              </a>
            </article>
          </div>

        </div>

      </section>
    </div>

  </div>
</template>

<script>
export default {
  props: ["nameActivePosition", "userId", "permissions"],
  
  data() {
    return {
      carousels: [
        { image: '/welcome_carousel_images/SGI.png' , color: 'white', link: '' },
        { image: '/welcome_carousel_images/gestion_de_bienes.png' ,color: 'info', link: '/condition_goods' },
        { image: '/welcome_carousel_images/panel_de_administracion_de_usuarios.png' ,color: 'info', link: '/users' },
      ],
    }
  },
  methods: {
    isAuthorized(name, action) {
      if (this.permissions == null) {
        return false;
      }
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>


<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Unidades</h1>
      </div>
      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Unidades">
          <div class="has-text-right">
            <b-button label="Añadir unidad" type="is-success" icon-left="plus" @click="createUnit()"
              v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Unit', 'create'))"/>
          </div>
          <div>
            <b-modal :active="isModalActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <new-unit modal-cancel="true" :modal-header="'Registro de Unidad'" query-type="create"
                  @close="closeModalCreate" @refresh="toRefreshActiveUnits" />
              </template>
            </b-modal>

            <b-modal :active="isModalActiveEdit" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <edit-unit :unit-id="unitId" :id="selected" :modal-header="'Editar Unidad'" query-type="update"
                  @close="closeModalEdit" @refresh="toRefreshActiveUnits" />
              </template>
            </b-modal>
          </div>
          <div class="column">
            <b-table id="table" :data="units" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom" default-sort-direction="asc"
              sort-icon="arrow-up" aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
              aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="name_unit" label="Nombre Unidad" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_unit }}
                </template>
              </b-table-column>

              <b-table-column field="description_unit" label="Descripción" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Descripción..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.description_unit }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_unit" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_unit }}
                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                      tag="a" :href="/units/ + props.row.id" target="_blank"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Unit', 'show'))"/>
                  </div>
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="pen" type="is-primary" @click.native="editUnit(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Unit', 'update'))"/>
                  </div>
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="ban" type="is-danger"
                      @click.native="confirmisempty(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Unit', 'disable_unit'))"/>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>

        <b-tab-item label="Unidades anuladas">
          <div class="column">
            <b-table id="table" :data="null_units" :paginated="true" per-page="10" striped hoverable
              :pagination-simple="false" pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom"
              default-sort-direction="asc" sort-icon="arrow-up" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="name_unit" label="Nombre Unidad" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_unit }}
                </template>
              </b-table-column>

              <b-table-column field="description_unit" label="Descripción" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Descripción..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.description_unit }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_unit" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_unit }}
                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                      tag="a" :href="/units/ + props.row.id" target="_blank"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Unit', 'show'))"/>
                  </div>
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="trash-arrow-up" type="is-success"
                      @click.native="confirmGoodRest(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Unit', 'activate_unit'))"/>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import NewUnit from "./new_unit.vue";
import EditUnit from "./edit_unit.vue";
import ShowUnit from "./show_unit.vue";
import exportXlsFile from "export-from-json";
export default {
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Unidades';
  },
  data() {
    return {
      units: [],
      null_units: [],
      canCancel: ["escape"],
      managers: [],
      goods: [],
      offices_unit: [],
      buildings_unit: [],
      isModalActive: false,
      isModalActiveShow: false,
      isModalActiveEdit: false,
      selected: null,
      continue: false,
      null_active: false,
      des_active: true,
      pendiing_good: 2,
      isLoading: false,
    };
  },
  components: {
    NewUnit,
    ShowUnit,
    EditUnit,
  },
  props: ["unitId", 'permissions'],
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    downloaddocument() {
      const data = this.units;
      const filename = "Unidades";
      const exportType = exportXlsFile.types.xls;
      exportXlsFile({ data, filename, exportType });
    },

    async getunitactive() {
      this.isLoading = true
      const response = await axios.get("/units/showactiveunit.json")
      this.units = response.data
      this.isLoading = false
    },

    async null_getunit() {
      this.isLoading = true
      const response = await axios.get("/units/shownullunit.json")
      this.null_units = response.data
      this.isLoading = false
    },

    async RestClick(id) {
      await axios.put('/units/' + id + '/activate.json')
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha restaurado correctamente la Unidad.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = true
        this.null_getunit();
        this.getunitactive();
        this.isLoading = false
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al restaurar la Unidad.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
    },
    confirmGoodRest(item) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Unidad",
        message: "¿Esta seguro de querer restaurar la Unidad?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
    },

    confirmisempty(item) {
      this.getdutymanagerbyunit(item);
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Unidad",
        message: "¿Esta seguro de querer anular la Unidad?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });
    },

    async Clicknewunit() {
      const response = (window.location.href = "/units/new");
    },
    async createUnit() {
      this.isModalActive = true;
    },
    async closeModalCreate() {
      this.isModalActive = false;
    },
    async ShowModaleUnit(item) {
      this.selected = item;
      this.isModalActiveShow = true;
    },
    async closeModalShow() {
      this.isModalActiveShow = false;
    },

    async AnulClick(id) {
      await axios.put('/units/' + id + '/disable.json')
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha anulado correctamente la Unidad.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.isLoading = true
        this.null_getunit();
        this.getunitactive();
        this.isLoading = false
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al anular la Unidad.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
    },

    async AnulClickgood(item) {
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.pendiing_good,
      });
    },
    async AnulClickoffice(item) {
      const response = await axios.put("/offices/" + item + ".json", {
        active_office: this.null_active,
      });
    },
    async AnulClickbuilding(item) {
      const response = await axios.put("/buildings/" + item + ".json", {
        active_building: this.null_active,
      });
    },
    async AnulClickdutymanager(item) {
      const response = await axios.put("/duty_managers/" + item + ".json", {
        active_duty_manager: this.null_active,
      });
    },

    async getdutymanagerbyunit(item) {
      const response = await axios.get(
        "/duty_managers/showdutymanagerunit/" + item + ".json"
      );
      this.managers = response.data;
      if (this.managers.length == 0) {
        this.getgoodbyunit(item);
      } else {
        this.$buefy.dialog.alert({
          title: "No se puede anular",
          type: "is-danger",
          message: "Existen personas designados a la unidad",
          confirmText: "Aceptar",
        });
        //this.managerempty= false;
      }
    },

    async getgoodbyunit(item) {
      const response = await axios.get("/goods/showgoodunit/" + item + ".json");
      this.goods = response.data;
      if (this.goods.length == 0) {
        this.getofficebyunit(item);
      } else {
        this.$buefy.dialog.alert({
          title: "No se puede anular",
          type: "is-danger",
          message: "Existen bienes designados a la unidad",
          confirmText: "Aceptar",
        });
        //this.goodsempty= false;
      }
    },

    async getofficebyunit(item) {
      const response = await axios.get(
        "/offices/showofficeunit/" + item + ".json"
      );
      this.offices_unit = response.data;
      if (this.offices_unit.length == 0) {
        this.getbuildingbyunit(item);
      } else {
        this.$buefy.dialog.alert({
          title: "No se puede anular",
          type: "is-danger",
          message: "Existen dependencias designados a la unidad",
          confirmText: "Aceptar",
        });
        //this.officeempty= false;
      }
    },

    async getbuildingbyunit(item) {
      const response = await axios.get(
        "/buildings/showbuildingunit/" + item + ".json"
      );
      this.buildings_unit = response.data;
      if (this.buildings_unit.length == 0) {
        this.confirmGoodNull(item);
      } else {
        this.$buefy.dialog.alert({
          title: "No se puede anular",
          type: "is-danger",
          message: "Existen edificios designados a la unidad",
          confirmText: "Aceptar",
        });
        //this.buildingempty= false;
      }
    },
    async editUnit(item) {
      this.selected = item;
      this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },

    async toRefreshActiveUnits(){
      
      this.isLoading = true
      await this.getunitactive();
      this.isLoading = false

    },

  },

  async created() {
    this.isLoading = true
    await this.getunitactive()
    await this.null_getunit()
    this.isLoading = false
  },
};
</script>

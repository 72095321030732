<template>
  <section class="b-tooltips">
    <div
      class="box"
      style="margin-top: 60px; margin-left: 300px; margin-right: 300px"
    >
      <h1>Insumos de la facultad</h1>

      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Insumos">


          <div class="has-text-right">
            <b-button
              label="Añadir Insumo"
              type="is-info"
              icon-left="plus"
              @click="createSupply()"
            ></b-button>
          </div>
    
                    <div class="center">
            <h1>Insumos</h1>
          </div>
          <div> 


            <b-modal
              :active="isModalActive"
              aria-modal
              has-modal-card
              :destroy-on-hide="true"
              aria-role="dialog"
              width="100%"
              :can-cancel="canCancel">
              <template>
                <new-supply
                  modal-cancel="true"
                  :modal-header="'Registro de Insumo'"
                  query-type="create"
                  @close="closeModalCreate"
                ></new-supply>
              </template>
            </b-modal>


            <b-modal :active="isModalActiveEdit" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <edit-supply :supply-id="supplyId" :id="selected" :modal-header="'Editar insumo'" query-type="update"
                  @close="closeModalEdit"></edit-supply>
              </template>
            </b-modal>

            <b-modal :active="isModalActiveConsume" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <consume-supply :supply-id="supplyId" :id="selected" :modal-header="'Consumir insumo'" query-type="update"
                  @close="closeModalconsume"></consume-supply>
              </template>
            </b-modal>


            <b-modal :active="isModalActiveRestock" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
              width="100%" :can-cancel="canCancel">
              <template>
                <restock-supply :supply-id="supplyId" :id="selected" :modal-header="'Reponer insumo'" query-type="update"
                  @close="closeModalrestock"></restock-supply>
              </template>
            </b-modal>


          </div>
          <div class="column">
            <b-table
              id="table"
              :data="supplies"
              :paginated="true"
              per-page="80"
              striped
              hoverable
              :pagination-simple="false"
              pagination-active-color="hsl(0, 0%, 0%)"
              pagination-position="bottom"
              default-sort-direction="asc"
              sort-icon="arrow-up"
              aria-next-label="Siguiente"
              aria-previous-label="Anterior"
              aria-page-label="Página"
              aria-current-label="Página actual"
            >
              <b-table-column
                :visible="false"
                field="id"
                label="ID"
                sortable
                searchable>

                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              

        

              <b-table-column field="name_supplie" label="Nombre insumo" sortable searchable>
                <template v-slot="props">
                  {{ props.row.name_supplie }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_supplie" label="N° Id insumo" sortable searchable>
                <template v-slot="props">
                  {{ props.row.identification_number_supplie }}
                </template>
              </b-table-column>

              <b-table-column field="total" label="Total" sortable searchable>
                <template v-slot="props">
                  {{ props.row.total }}
                </template>
              </b-table-column>

              <b-table-column field="remaining" label="Cantidad actual" sortable searchable>
                <template v-slot="props">
                  {{ props.row.remaining }}
                </template>
              </b-table-column>





 






              <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns">
                  <div class="column">
                   <b-tooltip label="Ver detalle" type="is-info">
                <b-icon pack="fas" icon="eye" size="is-medium"
              @click.native="ClickshowSupply(props.row.id)"/>
              </b-tooltip>
                  </div>

                  <div class="column">
                    <b-tooltip label="Editar" type="is-info">
                      <b-icon pack="fas" icon="pen" size="is-medium" @click.native="editSupply(props.row.id)" />
                    </b-tooltip>
                  </div>

                  <div class="column">
                    <b-tooltip label="Consumir insumo" type="is-info">
                      <b-icon pack="fas" icon="minus" size="is-medium" @click.native="consumeSupply(props.row.id)" />
                    </b-tooltip>
                  </div>

                  <div class="column">
                    <b-tooltip label="Reponer insumo" type="is-info">
                      <b-icon pack="fas" icon="plus" size="is-medium" @click.native="restockSupply(props.row.id)" />
                    </b-tooltip>
                  </div>


                </div>



              </b-table-column>


            </b-table>
          </div>
         </b-tab-item> 




         <b-tab-item label="Historial">
          <div class="center">
            <h1>Historial de insumos</h1>
          </div>


          <div class="column">
            <b-table :data="record_supplies" :paginated="true" per-page="80" striped hoverable :pagination-simple="false" sortable: true
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small"
              aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">

              <b-table-column
                field="supply.name_supplie" label="Nombre del Insumo" sortable searchable>
                <template v-slot="props">
                  {{ props.row.supply.name_supplie }}
                </template>
              </b-table-column>
              
              <b-table-column
                field="record_supplies.full_name" label="Acción" sortable searchable>
                <template v-slot="props">
                  {{ props.row.detail_record }}
                </template>
              </b-table-column>


              <b-table-column
                field="record_supplies.before_total" label="Cantidad original" sortable searchable>
                <template v-slot="props">
                  {{ props.row.before_total }}
                </template>
              </b-table-column>


              <b-table-column
                field="record_supplies.after_total" label="Nueva cantidad" sortable searchable>
                <template v-slot="props">
                  {{ props.row.after_total }}
                </template>
              </b-table-column>


              <b-table-column
                field="record_supplies.quantity_changed" label="Cantidad cambiada" sortable searchable>
                <template v-slot="props">
                  {{ props.row.quantity_changed }}
                </template>
              </b-table-column>
              

              <b-table-column
                field="date_action" label="Fecha de acción" sortable searchable>
                <template v-slot="props">
                  {{ moment(props.row.created_at).format("DD-MM-YYYY H:m:s") }}
                </template>
              </b-table-column>

              <b-table-column
                field="duty_manager.name_duty_manager" label="Responsable" sortable searchable>
                <template v-slot="props">
                  {{ props.row.duty_manager.name_duty_manager }}
                </template>
              </b-table-column>

            
        

            </b-table>
          </div>

        </b-tab-item>



      </b-tabs>
    </div>
  </section>
</template>


<script>
import axios from "axios";
import NewSupply from "./new_supply.vue";
import EditSupply from "./edit_supply.vue";
import ConsumeSupply from "./consume_supply.vue";
import RestockSupply from "./restock_supply.vue";
import moment from "moment";
//import exportXlsFile from "export-from-json";
export default {
  data() {
    return {
      supplies: [],
      record_supplies: [],
      null_supplies: [],
      canCancel: ["escape"],
      moment: moment,
      isModalActive: false,
      isModalActiveConsume: false,
      isModalActiveRestock: false,
      isModalActiveEdit: false,
      selected: null,
      continue: false,
      null_active: 0,
      des_active: 1,
    };
  },
  components: {
    NewSupply, EditSupply, ConsumeSupply, RestockSupply,
  },
  props: ["supplyId"],
  methods: {

    /*
        downloaddocument(){
          const data = this.units;
          const filename = 'Unidades';
          const exportType = exportXlsFile.types.xls;
          exportXlsFile({data, filename, exportType})

        },
    */
        async getsupplies() {
      const response = await axios.get("/supplies.json");
      this.supplies = response.data;
    },

    async getrecordsupplies() {
      const response = await axios.get("/supplies_records.json");
      this.record_supplies = response.data;
    },

        async null_getsupplies() {
      const response = await axios.get("/supplies/shownullsupplies.json");
      this.null_supplies = response.data;
    },

    /*

    async RestClick(item) {
      const response = await axios.put("/units/" + item + ".json", {
        active_unit: this.des_active,
      });
      window.location.href = "/units";
    },

    confirmGoodRest(item) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Unidad",
        message: "¿Esta seguro de querer restaurar la Unidad?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
    },



    confirmGoodNull(item) {
        this.$buefy.dialog.confirm({
        title: "Anular Unidad",
        message: "¿Esta seguro de querer anular la Unidad?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });

      
    },

    */

    async createSupply() {
      this.isModalActive = true;
    },

    async closeModalCreate() {
      this.isModalActive = false;
    },


    async consumeSupply(item) {
      this.selected = item;
      this.isModalActiveConsume = true;
    },

    async closeModalconsume() {
      this.isModalActiveConsume = false;
    },

    async restockSupply(item) {
      this.selected = item;
      this.isModalActiveRestock = true;
    },

    async closeModalrestock() {
      this.isModalActiveRestock = false;
    },

    async ClickshowSupply(item) {
      const response = (window.location.href = "/supplies/" + item);
    },

 




         async AnulClick(item) {
      const response = await axios.put("/supplies/" + item + ".json", {
        active_supply: this.null_active,
      });
      window.location.href = "/supplies";
    },



    async editSupply(item) {
      this.selected = item;
      this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },
  
  },


  created() {
    this.getsupplies();
    this.getrecordsupplies();
  },

};
</script>



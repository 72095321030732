<template>
    <section>
        <div class="modal-card" :style="'width: 1080px; max-width: 100%;' ">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ modalHeader }}</p>
                <button type="button" class="delete" @click="$emit('refresh', false); $emit('close')" />
            </header>
            <section class="modal-card-body" ref="editDutyManager">
                <div v-if="manager" class="columns is-centered">
                    <div class="column is-centered">



                        <b-tabs type="is-boxed" position="is-centered">

                            <b-tab-item label="Datos">
                                <ValidationObserver v-slot="{ handleSubmit }" ref="observer1">
                                    <form @submit.prevent="handleSubmit(confirmedit())">

                                        <div class="my-0">

                                            <div class="columns">

                                                <div class="column">
                                                    <b-field label="Nombre *">
                                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                                            <b-input v-model="manager.name_duty_manager"
                                                                placeholder="Ingrese el nombre del edificio">
                                                            </b-input>
                                                            <span class="validation-alert">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </b-field>
                                                </div>

                                                <!-- <div class="column">
                                                    <b-field label="RUT *">
                                                        <ValidationProvider rules="required"
                                                            v-slot="{ errors }">
                                                            <b-input v-model="manager.rut"
                                                                placeholder="Ingrese el RUT de la persona asociada sin puntos y con guion">
                                                            </b-input>
                                                            <span class="validation-alert">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </b-field>
                                                </div> -->

                                            </div>

                                            <div class="columns">
                                                <div class="column">
                                                    <b-field label="Email *">
                                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                                            <b-input v-model="manager.email"
                                                                placeholder="Ingrese el Email de la persona asociada">
                                                            </b-input>
                                                            <span class="validation-alert">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </b-field>
                                                </div>
                                            </div>

                                        </div>



                                        <div class="columns is-mobile">

                                            <div class="column">
                                                <b-button label="Cerrar" @click="$emit('close')" />
                                            </div>

                                            <div class="column has-text-right">
                                                <b-button label="Editar Persona Encargada" icon-left="save"
                                                    type="is-primary" @click="checkEditData()" />
                                            </div>

                                        </div>
                                    </form>
                                </ValidationObserver>
                            </b-tab-item>


                            <b-tab-item label="Cargo">
                                <ValidationObserver v-slot="{ handleSubmit }" ref="observer2">
                                    <form @submit.prevent="handleSubmit(confirmeditDes())">

                                        <div class="columns">
                                            <div class="column">
                                                <b-field label="Unidad">
                                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                                        <b-input v-model="manager.unit.alt_name_unit" type="text"
                                                            readonly>
                                                        </b-input>
                                                        <span class="validation-alert">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-field>
                                            </div>
                                        </div>


                                        <div class="content">
                                            <h2 class="has-text-left">Nueva designación</h2>



                                            <div class="columns my-0 mb-6">
                                                <div class="column">
                                                    <b-field label="Nueva Unidad *">
                                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                                            <multiselect v-model="aux_unit_id"
                                                                :options="units.map((type) => type.id)" :custom-label="(opt) =>
                                                                            units.find((x) => x.id === opt).name_unit
                                                                            " placeholder="Seleccionar Unidad"
                                                                selectedLabel="Seleccionado" selectLabel=""
                                                                :allow-empty="true">
                                                            </multiselect>
                                                            <span class="validation-alert">
                                                                {{ errors[0] }}
                                                            </span>
                                                        </ValidationProvider>
                                                    </b-field>
                                                </div>
                                            </div>

                                            <div class="columns is-mobile">

                                                <div class="column">
                                                    <b-button label="Cerrar" @click="$emit('close')" />
                                                </div>

                                                <div class="column has-text-right">
                                                    <b-button label="Editar Cargo" icon-left="save" type="is-primary"
                                                        @click="checkCharge()" />
                                                </div>

                                            </div>
                                        </div>

                                    </form>
                                </ValidationObserver>
                            </b-tab-item>
                        </b-tabs>
                    </div>
                </div>
                <div v-else>
                    <p>Cargando</p>
                </div>
            </section>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
        </div>

    </section>
</template>


<script>
import axios from "axios";
import IndexDutyManager from "./index_duty_manager.vue";
export default {
    name: "edit_duty_manager",
    props: ["queryType", "modalHeader", "duty_managerId", "id"],
    components: {
        "index-duty-manager": IndexDutyManager,
    },

    data() {
        return {
            props: ["id"],
            units: [],
            managers: [],
            manager: null,
            aux_unit_id: null,
            isLoading: false,
        };
    },
    methods: {
        async getmanager() {
            await axios.get("/duty_managers/" + this.id + ".json")
                .then(response => {
                    this.manager = response.data
                })
                .catch(error => {
                    let errorMsg = ''
                    if (error.response) {
                        if (error.response.status === 403) {
                        errorMsg = 'No tiene permisos para obtener al Usuario'
                        } else {
                        errorMsg = 'Hubo errores al obtener al Usuario, favor de revisar errores.<br>Errores<br>' + error.response.data
                        }
                    } else {
                        errorMsg = 'Hubo errores al obtener al Usuario, intente más tarde.'
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async getunitactive() {
            await axios.get("/units/showactiveunit.json")
                .then(response => {
                    this.units = response.data
                })
                .catch(error => {
                    let errorMsg = ''
                    if (error.response) {
                        if (error.response.status === 403) {
                        errorMsg = 'No tiene permisos para obtener las Unidades'
                        } else {
                        errorMsg = 'Hubo errores al obtener las Unidades, favor de revisar errores.<br>Errores<br>' + error.response.data
                        }
                    } else {
                        errorMsg = 'Hubo errores al obtener las Unidades, intente más tarde.'
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async EditClick() {
            this.isLoading = true
            const response = await axios.put("/duty_managers/" + this.id + ".json", {
                name_duty_manager: this.manager.name_duty_manager,
                rut: this.manager.rut,
                dv: this.manager.dv,
                email: this.manager.email,
            })
            .then(response => {
                this.$buefy.dialog.alert({
                title: 'Logrado',
                message: 'Se ha editado correctamente la Persona Encargada al sistema.',
                type: 'is-success',
                hasIcon: true,
                icon: 'circle-check',
                iconPack: 'fas',
                ariaRole: 'alertdialog',
                ariaModal: true
                })
                this.$emit('refresh')
                this.$emit('close')
            })
            .catch(error => {
                this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo un error al ingresar los datos de la Persona Encargada, favor revisar los errores.<br>Errores:<br>' + error.response.data,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
                })
            })
            this.isLoading = false
        },

        async checkEditData() {
            const valid = await this.$refs.observer1.validate()
            if (!valid) {
                this.$buefy.dialog.alert(
                    {
                        title: 'Error',
                        message: 'Algunos campos presentan errores.', 
                        type: 'is-danger',
                        hasIcon: true, 
                        icon: 'times-circle', 
                        iconPack: 'fa', 
                        ariaRole: 'alertdialog', 
                        ariaModal: true
                    }
                )
            }
            else {
                this.$buefy.dialog.confirm({
                    title: "Editar la información de la persona",
                    message: `¿Esta seguro de editar la información de la persona?`,
                    cancelText: "Cancelar",
                    confirmText: "Guardar",
                    type: "is-info",
                    onConfirm: () => this.EditClick(),
                });
            }
        },


        async checkCharge() {
            const valid = await this.$refs.observer2.validate()
            if (!valid) {
                this.$buefy.dialog.alert(
                    {
                        title: 'Error',
                        message: 'Algunos campos presentan errores.', 
                        type: 'is-danger',
                        hasIcon: true, 
                        icon: 'times-circle', 
                        iconPack: 'fa', 
                        ariaRole: 'alertdialog', 
                        ariaModal: true
                    }
                )
            }
            else {
                this.$buefy.dialog.confirm({
                    title: "Editar la información de la persona",
                    message: `¿Esta seguro de editar la información de la persona?`,
                    cancelText: "Cancelar",
                    confirmText: "Guardar",
                    type: "is-info",
                    onConfirm: () => this.EditClickDes(),
                });
            }
        },
        async EditClickDes() {
            this.isLoading = true
            const response = await axios.put("/duty_managers/" + this.id + ".json", {
                unit_id: this.aux_unit_id,
                activegood: 1,
            })
            .then(response => {
                this.$buefy.dialog.alert({
                title: 'Logrado',
                message: 'Se ha editado correctamente el cargo de la Persona Encargada.',
                type: 'is-success',
                hasIcon: true,
                icon: 'circle-check',
                iconPack: 'fas',
                ariaRole: 'alertdialog',
                ariaModal: true
                })
                this.$emit('refresh')
                this.$emit('close')
            })
            .catch(error => {
                this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Hubo un error al ingresar los datos del cargo de la Persona Encargada, favor revisar los errores.<br>Errores:<br>' + error.response.data,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
                })
            })
            this.isLoading = false
        },

        async getInformation() {
            this.isLoading = true
            await this.getmanager()
            await this.getunitactive() 
            this.isLoading = false
        }

    },
    async created() {
        await this.getInformation()
    },
};
</script>





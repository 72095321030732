<template>
    <section>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form @submit.prevent="handleSubmit(checkEditData())">
                <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">

                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ modalHeader }}</p>
                        <button type="button" class="delete" @click="$emit('close')" />
                    </header>

                    <section class="modal-card-body" ref="editOfficeCharge">
                        <div class="columns is-centered">
                            <div class="column is-centered">

                                <div class="my-0 mb-6">

                                    <div class="columns">
                                        <div class="column">
                                            <b-field label="Nueva Unidad *">
                                                <ValidationProvider name="Nueva Unidad" rules="required"
                                                    v-slot="{ errors }">
                                                    <multiselect v-model="unit_id"
                                                        :options="units.map((type) => type.id)"
                                                        :custom-label="(opt) => units.find((x) => x.id === opt).name_unit"
                                                        placeholder="Seleccionar Unidad" selectedLabel="Seleccionado"
                                                        selectLabel="" :allow-empty="true"
                                                        :disabled="duty_manager_id !== null || building_id !== null"
                                                        @input="getdutymanagerbyunit(unit_id); getbuildingbyunit(unit_id);"
                                                        :max-height="200">
                                                    </multiselect>
                                                    <span class="validation-alert">
                                                        {{ errors[0] }}
                                                    </span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>

                                    </div>

                                    <div class="columns">

                                        <div class="column">
                                            <b-field label="Nueva persona encargada *">
                                                <ValidationProvider name="Nueva persona encargada" rules="required"
                                                    v-slot="{ errors }">
                                                    <multiselect v-model="duty_manager_id"
                                                        :options="managers.map((type) => type.id)"
                                                        :custom-label="(opt) => managers.find((x) => x.id === opt).name_duty_manager"
                                                        placeholder="Seleccionar persona "
                                                        selectLabel="Presione para seleccionar"
                                                        selectedLabel="Seleccionado"
                                                        deselectLabel="Presione para deseleccionar" :allow-empty="true"
                                                        :disabled="unit_id == null"
                                                        :max-height="200">
                                                    </multiselect>
                                                    <span class="validation-alert">
                                                        {{ errors[0] }}
                                                    </span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>

                                    </div>

                                    <div class="columns">

                                        <div class="column">
                                            <b-field label="Nuevo Edificio *">
                                                <ValidationProvider name="Nuevo Edificio" rules="required"
                                                    v-slot="{ errors }">
                                                    <multiselect v-model="building_id"
                                                        :options="buildings.map((type) => type.id)"
                                                        :custom-label="(opt) => buildings.find((x) => x.id === opt).name_building"
                                                        placeholder="Seleccionar Edificio"
                                                        selectLabel="Presione para seleccionar"
                                                        selectedLabel="Seleccionado"
                                                        deselectLabel="Presione para deseleccionar" :allow-empty="true"
                                                        :disabled="unit_id == null"
                                                        :max-height="200">
                                                    </multiselect>
                                                    <span class="validation-alert">
                                                        {{ errors[0] }}
                                                    </span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>

                        <div class="columns is-mobile" style="margin-top: 7rem">

                            <div class="column">
                                <b-button label="Cerrar" @click="$emit('close')" />
                            </div>

                            <div class="column has-text-right">
                                <p class="control">
                                    <b-button label="Asignar Persona Encargada" icon-left="floppy-disk" type="is-info"
                                        @click="checkEditData()" />
                                </p>
                            </div>

                        </div>

                    </section>
                    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
                </div>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
import axios from "axios";
import IndexOffices from "./index_office.vue";
export default {
    name: "edit_office_charge",
    props: ["queryType", "modalHeader", "officeId", "id", "oldDutyManagerId"],
    components: {
        IndexOffices,
    },
    data () {
        return {
            props: ["id"],
            isLoading: false,
            curent: null,

            //Atributos a editar
            unit_id: null,
            building_id: null,
            duty_manager_id: null,

            // Entidades a guardar
            units: [],
            buildings: [],
            managers: [],
        }
    },
    methods: {
        async getunitactive() {
            this.isLoading = true
            const response = await axios.get("/units/showactiveunit.json")
            this.units = response.data
            this.isLoading = false
        },
        async getbuildingbyunit(item) {
            this.isLoading = true
            const response = await axios.get(
                "/buildings/showbuildingunit/" + item + ".json"
            )
            this.buildings = response.data
            this.isLoading = false
        },
        async getdutymanagerbyunit(item) {
            this.isLoading = true
            const response = await axios.get(
                "/duty_managers/showdutymanagerunit/" +
                item +
                ".json"
            )
            this.managers = response.data
            this.isLoading = false
        },

        async EditClick() {
            this.isLoading = true
            const response = await axios.put("/offices/updatecharge/" + this.id + ".json", {
                duty_manager_id: this.duty_manager_id,
                active_office: true,
                old_duty_manager_id: this.oldDutyManagerId,
            })
            .then(response => {
                this.$buefy.dialog.alert({
                title: 'Logrado',
                message: 'Se ha editado correctamente el cargo de la Dependencia.',
                type: 'is-success',
                hasIcon: true,
                icon: 'circle-check',
                iconPack: 'fas',
                ariaRole: 'alertdialog',
                ariaModal: true
                })
                this.$emit('refresh')
                this.$emit('close')
            })
            .catch ( error => {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Hubo errores al editar el cargo, favor revisar errores.<br>Errores:<br>' + error.response.data,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            })
            this.isLoading = false;
        },

        async checkEditData() {
            const valid = await this.$refs.observer.validate()
            if (!valid) {
                this.$buefy.dialog.alert(
                    {
                        title: 'Error',
                        message: 'Algunos campos presentan errores.', 
                        type: 'is-danger',
                        hasIcon: true, 
                        icon: 'times-circle', 
                        iconPack: 'fa', 
                        ariaRole: 'alertdialog', 
                        ariaModal: true
                    }
                )
            }
            else {
                this.$buefy.dialog.confirm({
                    title: "Editar el Dependencia",
                    message: `¿Esta seguro de editar el cargo de la dependencia?`,
                    cancelText: "Cancelar",
                    confirmText: "Guardar",
                    type: "is-info",
                    onConfirm: () => this.EditClick(),
                });
            }
        },

        async getInformation() {
            this.isLoading = true
            await this.getunitactive()
            this.isLoading = false
        },
    },

    created() {
        this.getInformation()
    }

}
</script>
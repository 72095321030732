<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(editInvestmentType())">
        <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="NewInvestmentTypes">

            <div class="columns is-centered">
              <div class="column is-centered ">


                <div class="my-0">

                  <div class="columns">
                    <div class="column">
                      <b-field label="Nombre Tipo de Inversión *">
                        <ValidationProvider name="Nombre" rules="required|max:255" v-slot="{ errors }">
                          <b-input v-model="name_investment_type"
                            placeholder="Ingrese el nombre de la Tipo de Inversión"></b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Descripción *">
                        <ValidationProvider name="Descripción" rules="required|max:255" v-slot="{ errors }">
                          <b-input v-model="detail" type="textarea"
                            placeholder="Ingrese la descripción del Tipo de Inversión">
                          </b-input>
                          <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </b-field>
                    </div>
                  </div>

                </div>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <p class="control">
                      <b-button label="Editar Tipo de inversión" type="is-info" icon-left="save" @click="check()" />
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "edit_investment_types",
  props: ["queryType", "modalHeader", "id"],
  data() {
    return {
      isLoading: false,

      //Información a traer
      name_investment_type: null,
      detail: null,

    }
  },

  methods: {
      async getInvestmentType() {
        this.isLoading = true
        await axios.get('/investment_types/'+ this.id + '.json')
        .then(response => {
          this.name_investment_type = response.data.alt_name_investment_type
          this.detail = response.data.detail
        })
        .catch(error => {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al obtener los datos del Tipo de Inversión, favor inténtelo nuevamente más tarde.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.$emit('close')
        })
        this.isLoading = false
      },

      async editInvestmentType() {
        this.isLoading = true
        await axios.put('/investment_types/' + this.id + '.json', {
          alt_name_investment_type: this.name_investment_type,
          detail: this.detail
        })
        .then(response => {
          this.$buefy.dialog.alert({
            title: 'Logrado',
            message: 'Se ha editado correctamente el Tipo de Inversión.',
            type: 'is-success',
            hasIcon: true,
            icon: 'circle-check',
            iconPack: 'fas',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.$emit('refresh')
          this.$emit('close')
        })
        .catch(error => {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al editar el Tipo de Inversión, favor revisar los errores.<br>Errores:<br>' + error.response.data,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
        this.isLoading = false
      },

      async check() {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: "Algunos campos presentan errores.",
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
        else {
          this.$buefy.dialog.confirm({
            title: "Editar Tipo de Inversión",
            message: `¿Esta seguro de editar el Tipo de Inversión?`,
            cancelText: "Cancelar",
            confirmText: "Editar",
            type: "is-info",
            onConfirm: () => this.editInvestmentType(),
          });
        }
      },
  },

  async created () {
    this.isLoading = true
    await this.getInvestmentType()
    this.isLoading = false
  }

}
</script>
<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Categorias de bienes</h1>
      </div>

      <div>
        <b-modal :active="isModalActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog" width="100%"
          :can-cancel="canCancel">
          <template>
            <new-category modal-cancel="true" :modal-header="'Agregar Categoría de Bien'" query-type="create"
              @close="isModalActive = false" @refresh="toRefreshActiveCategories" />
          </template>
        </b-modal>

        <b-modal :active="isModalEditActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog" width="100%"
          :can-cancel="canCancel">
          <template>
            <edit-category modal-cancel="true" :id="selected" :modal-header="'Editar Categoría de Bien'" query-type="update"
              @close="isModalEditActive = false" @refresh="toRefreshActiveCategories" />
          </template>
        </b-modal>
      </div>
      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Categorías">
          <div class="has-text-right">
            <b-button label="Añadir categoria de bien" type="is-success" icon-left="plus" @click="createmodel()"
              v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Category', 'create'))"/>
          </div>
          <div class="columns">
            <div class="column">
              <b-table id="table" :data="categories" :paginated="true" per-page="10" striped hoverable
                :pagination-simple="false" pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom"
                default-sort-direction="asc" sort-icon="arrow-up" aria-next-label="Siguiente"
                aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
                <b-table-column :visible="false" field="id" label="ID" sortable searchable>

                  <template v-slot="props">
                    {{ props.row.id }}
                  </template>
                </b-table-column>



                <b-table-column field="name_category" label="Nombre Categoría" sortable searchable>
                  <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                      icon="magnifying-glass" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.alt_name_category }}
                  </template>
                </b-table-column>

                <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                  <div class="columns is-mobile is-variable is-1-mobile">
                    <div class="column">
                      <b-button pack="fas" icon-right="pen" type="is-primary"
                        @click.native="showModalEdit(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Category', 'update'))"  />
                    </div>
                    <div class="column">
                      <b-button icon-pack="fas" icon-right="ban" type="is-danger"
                        @click.native="confirmDisabled(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Category', 'disable_category'))" />
                    </div>
                  </div>
                </b-table-column>
              </b-table>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Categorías Anuladas">
          <div class="columns">
            <div class="column">
              <b-table id="table" :data="null_categories" :paginated="true" per-page="10" striped hoverable
                :pagination-simple="false" pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom"
                default-sort-direction="asc" sort-icon="arrow-up" aria-next-label="Siguiente"
                aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
                <b-table-column :visible="false" field="id" label="ID" sortable searchable>

                  <template v-slot="props">
                    {{ props.row.id }}
                  </template>
                </b-table-column>



                <b-table-column field="name_category" label="Nombre Categoría" sortable searchable>
                  <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                      icon="magnifying-glass" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.alt_name_category }}
                  </template>
                </b-table-column>

                <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                  <div class="columns is-mobile is-variable is-1-mobile">
                    <div class="column">
                      <b-button icon-pack="fas" icon-right="trash-arrow-up" type="is-success"
                        @click.native="confirmEnable(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Category', 'activate_category'))" />
                    </div>
                  </div>
                </b-table-column>
              </b-table>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>


<script>
import axios from "axios";
import NewCategory from "./new_category.vue";
import EditCategory from "./edit_category.vue";
export default {
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Categorías de los Bienes';
  },
  data() {
    return {
      categories: [],
      null_categories: [],
      canCancel: ["escape"],
      isModalActive: false,
      isModalEditActive: false,
      selected: null,
      isLoading: false,
    };
  },
  components: {
    NewCategory,
    EditCategory,
  },
  props: ["CategoriesId", "permissions"],
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    async get_categories() {
      this.isLoading = true
      const response = await axios.get("/categories/showcategories.json")
      .then(response => {
        this.categories = response.data.categories_enabled
        this.null_categories = response.data.categories_disabled
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al solicitar los datos de las Categorías, favor de recargar la página e inténtelo nuevamente.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async disableCategory(id) {
      this.isLoading = true
      await axios.put('/categories/' + id + '/disable.json')
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha anulado correctamente la Categoría.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.toRefreshActiveCategories()
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al anular la Categoría.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async enableCategory(id) {
      this.isLoading = true
      await axios.put('/categories/' + id + '/activate.json')
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha restaurado correctamente la Categoría.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.toRefreshActiveCategories()
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al restaurar la Categoría.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async confirmDisabled(id) {
      this.$buefy.dialog.confirm({
        title: "Anular Categoría",
        message: "¿Esta seguro de querer anular la Categoría?",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.disableCategory(id),
      })
    },

    async confirmEnable(id) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Categoría",
        message: "¿Esta seguro de querer restaurar la Categoría?",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => this.enableCategory(id),
      })
    },

    async createmodel() {
      this.isModalActive = true;
    },
    async showModalEdit(item) {
      this.selected = item;
      this.isModalEditActive = true;
    },
    async toRefreshActiveCategories(){
      this.isLoading = true
      await this.get_categories();
      this.isLoading = false
    },
  },
  created() {
    this.toRefreshActiveCategories()

  },
};
</script>



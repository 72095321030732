<template>
    <section>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form @submit.prevent="handleSubmit(checkEditData())">
                <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">

                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ modalHeader }}</p>
                        <button type="button" class="delete" @click="$emit('close')" />
                    </header>

                    <section class="modal-card-body" ref="editGoodCharge">
                        <div class="columns is-centered">
                            <div class="column is-centered">

                                <div class="my-0 mb-6">
                                    <div class="columns">


                                        <div class="column">
                                            <b-field label="Nueva Unidad *">
                                                <ValidationProvider rules="required" v-slot="{ errors }">
                                                    <multiselect v-model="unit_id"
                                                        :options="units.map((type) => type.id)"
                                                        :custom-label="(opt) =>units.find((x) => x.id === opt).alt_name_unit"
                                                        placeholder="Seleccionar Unidad" selectedLabel="Seleccionado"
                                                        selectLabel="" :allow-empty="true"
                                                        :disabled="duty_manager_id !== null || building_id !== null"
                                                        @input="getInformationByUnit(unit_id)">
                                                    </multiselect>
                                                    <span class="validation-alert">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>


                                        <div class="column">
                                            <b-field label="Nueva persona encargada *">
                                                <ValidationProvider name="Persona encargada" rules="required"
                                                    v-slot="{ errors }">
                                                    <multiselect v-model="duty_manager_id"
                                                        :options="managers.map((type) => type.id)"
                                                        :custom-label="(opt) => managers.find((x) => x.id === opt).name_duty_manager"
                                                        placeholder="Seleccionar persona encargada"
                                                        selectLabel="Presione para seleccionar"
                                                        selectedLabel="Seleccionado"
                                                        deselectLabel="Presione para deseleccionar" :allow-empty="true"
                                                        :disabled="unit_id == null">
                                                    </multiselect>
                                                    <span class="validation-alert">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>

                                        <div class="column">
                                            <b-field label="Fecha de Asignación *">
                                                <ValidationProvider name="Fecha de Asignación" rules="required" v-slot="{ errors }">
                                                    <b-datepicker v-model="assignment_date" locale="es-ES"
                                                        placeholder="Seleccionar fecha de Asignación" trap-focus />
                                                    <span class="validation-alert">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>
                                    </div>
                                    <div class="columns">
                                        <div class="column">
                                            <b-field label="Nuevo Edificio *">
                                                <ValidationProvider name="Edificio" rules="required"
                                                    v-slot="{ errors }">
                                                    <multiselect v-model="building_id"
                                                        :options="buildings.map((type) => type.id)"
                                                        :custom-label="(opt) => buildings.find((x) => x.id === opt).alt_name_building"
                                                        placeholder="Seleccionar Unidad"
                                                        selectLabel="Presione para seleccionar"
                                                        selectedLabel="Seleccionado"
                                                        deselectLabel="Presione para deseleccionar" :allow-empty="true"
                                                        :disabled="unit_id == null || office_id !== null"
                                                        @input="getofficebybuilding(building_id)"
                                                        :max-height="200">
                                                    </multiselect>
                                                    <span class="validation-alert">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>

                                        <div class="column">
                                            <b-field label="Nueva Dependencia *">
                                                <ValidationProvider name="Dependencia" rules="required"
                                                    v-slot="{ errors }">
                                                    <multiselect v-model="office_id"
                                                        :options="offices.map((type) => type.id)"
                                                        :custom-label="(opt) => offices.find((x) => x.id === opt).alt_name_office"
                                                        placeholder="Seleccionar dependencia"
                                                        selectLabel="Presione para seleccionar"
                                                        selectedLabel="Seleccionado"
                                                        deselectLabel="Presione para deseleccionar" :allow-empty="true"
                                                        :disabled="building_id == null"
                                                        :max-height="200">
                                                    </multiselect>
                                                    <span class="validation-alert">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </b-field>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="columns is-mobile" style="margin-top: 7rem">

                            <div class="column">
                                <b-button label="Cerrar" @click="$emit('close')" />
                            </div>

                            <div class="column has-text-right">
                                <p class="control">
                                    <b-button label="Asignar Persona Encargada" type="is-info" icon-left="floppy-disk"
                                        @click="checkEditData()" />
                                </p>
                            </div>

                        </div>

                    </section>
                    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
                </div>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
export default {
    props: [
        "queryType", "modalHeader", "goodId", "id", "fromStorage","fromUsed", "oldDutyManagerId"
    ],

    components: {
        
    },

    data() {
        return {

            isLoading: false,
            curent: null,
            good: null,

            //Atributos a editar
            unit_id: null,
            duty_manager_id: null,
            building_id: null,
            office_id: null,
            condition_good_id: null,
            assignment_date: new Date(),

            // Entidades a guardar
            units: [],
            managers: [],
            offices: [],
            buildings: [],
            date_last_charge: null,
        }
    },

    methods: {
        async getUnitActive() {
            this.isLoading = true
            await axios.get("/units/showactiveunit.json")
                .then(response => {
                    this.units = response.data
                })
                .catch(error => {
                    let errorMsg = error.response ? 'favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al obtener las Unidades Activas' + errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
        },
        async getdutymanagerbyunit(item) {
            this.isLoading = true
            await axios.get(
                "/duty_managers/showdutymanagerunit/" +
                item +
                ".json"
                )
                .then(response => {
                    this.managers = response.data
                })
                .catch(error => {
                    let errorMsg = error.response ? 'favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al obtener las Personas Asociadas de la Unidad seleccionada' + errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
        },
        async getofficebybuilding(item) {
            this.isLoading = true
            await axios.get(
                "/offices/showofficebuilding/" + item + ".json"
                )
                .then(response => {
                    this.offices = response.data
                })
                .catch(error => {
                    let errorMsg = error.response ? 'favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al obtener las Dependencias del Edificio seleccionado' + errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
            this.isLoading = false
        },
        async getbuildingbyunit(item) {
            this.isLoading = true
            await axios.get(
                "/buildings/showbuildingunit/" + item + ".json"
                )
                .then(response => {
                    this.buildings = response.data
                })
                .catch(error => {
                    let errorMsg = error.response ? 'favor de revisar errores.<br>Errores<br>' + error.response.data : '.'

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo errores al obtener los Edificios de la Unidad seleccionada ' + errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.$emit('close')
                    this.isLoading = false
                })
            this.isLoading = false
        },

        async getDateRecordChargeFromGoodDutyManager(){
            this.isLoading = true
            await axios
                    .get('/record_charges/get-date-record-charge.json', {params: {
                        duty_manager_id: this.oldDutyManagerId,
                        good_id: this.id,
                    }})
                    .then(response => {
                        this.date_last_charge = moment(response.data, ["YYYY-MM-DD", "DD-MM-YYYY"])
                    })
                    .catch(error => {
                        let errorMsg = ''
                        if (error.response) {
                            if (error.response.status === 403) {
                                errorMsg = 'No tiene permisos para traer la fecha del último cargo del Bien'
                            } else {
                                errorMsg = 'Hubo un error al traer la fecha del último cargo del Bien, favor de revisar errores.<br>Errores<br>' + error.response.data
                            }
                        } else {
                            errorMsg = 'Hubo un error al traer la fecha del último cargo del Bien, intente más tarde.'
                        }
                        console.log(error)
                        this.$buefy.dialog.alert({
                            title: 'Error',
                            message: errorMsg,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })
                        this.$emit('close')
                        this.isLoading = false
                    })

        },

        async EditClick() {
            this.isLoading = true

            if (this.assignment_date < this.date_last_charge) {
                let fecha = new Date(this.date_last_charge).toLocaleDateString("es-CL")
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'La <b>Fecha de Asignación</b> no puede ser menor a la Fecha de Asignación del ultimo cargo:<br><b>' + fecha,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
                this.isLoading = false
                return
            }

            await axios.put("/goods/updatecharge/" + this.id + ".json", {
                duty_manager_id: this.duty_manager_id,
                office_id: this.office_id,
                activegood: true,
                old_duty_manager_id: this.oldDutyManagerId,
                fromStorage: this.fromStorage,
                start_date: this.assignment_date,
            })
            .then(response => {
                this.$buefy.dialog.alert({
                title: 'Logrado',
                message: 'Se ha editado correctamente el cargo del Bien.',
                type: 'is-success',
                hasIcon: true,
                icon: 'circle-check',
                iconPack: 'fas',
                ariaRole: 'alertdialog',
                ariaModal: true
                })
                this.$emit('refresh')
                this.$emit('close')
            })
            .catch ( error => {

                let errorMsg = ''
                if (error.response) {
                    if (error.response.status === 403) {
                        errorMsg = 'No tiene permisos para editar el Cargo del Bien'
                    } else {
                        errorMsg = 'Hubo errores al editar el Cargo del Bien, favor de revisar errores.<br>Errores<br>' + error.response.data
                    }
                } else {
                    errorMsg = 'Hubo errores al editar el Cargo del Bien, intente más tarde.'
                }

                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: errorMsg,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            })
            this.isLoading = false
        },

        confirmedit() {
            this.$buefy.dialog.confirm({
                title: "Editar el Bien",
                message: `¿Esta seguro de editar el cargo del bien?`,
                cancelText: "Cancelar",
                confirmText: "Guardar",
                type: "is-info",
                onConfirm: () => this.EditClick(),
            });
        },

        async checkEditData() {
            const valid = await this.$refs.observer.validate()
            if (!valid) {
                this.$buefy.dialog.alert(
                    {
                        title: 'Error',
                        message: 'Algunos campos presentan errores.', 
                        type: 'is-danger',
                        hasIcon: true, 
                        icon: 'times-circle', 
                        iconPack: 'fa', 
                        ariaRole: 'alertdialog', 
                        ariaModal: true
                    }
                )
            }
            else {
                this.confirmedit();
            }
        },
        async getUserActual() {
            this.isLoading = true
            await axios.get("/duty_managers/currentuser.json")
                .then(response => {
                    this.current = response.data.current_user
                })
                .catch(error => {
                    let errorMsg = ''
                    if (error.response) {
                        if (error.response.status === 403) {
                            errorMsg = 'No tiene permisos para obtener al Usuario Actual'
                        } else {
                            errorMsg = 'Hubo errores al obtener al Usuario Actual, favor de revisar errores.<br>Errores<br>' + error.response.data
                        }
                    } else {
                        errorMsg = 'Hubo errores al obtener al Usuario Actual, intente más tarde.'
                    }

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: errorMsg,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                    this.$emit('close')
                })
        },

        async getInformationByUnit(id) {
            this.isLoading = true
            await this.getdutymanagerbyunit(id)
            await this.getbuildingbyunit(id)
            this.isLoading = false
        },

        async getInformation() {
            this.isLoading = true
            await this.getUnitActive()
            await this.getUserActual()
            await this.getDateRecordChargeFromGoodDutyManager()
            this.isLoading = false
        },

    },
    async created() {
        await this.getInformation()
    }

}
</script>
<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmregister())">
        <div class="modal-card" :style=" 'width: 1080px; max-width: 100%;' ">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="NewTrademarks">
            <div class="columns is-centered">
              <div class="column is-centered">

                <div class="columns my-0">
                  <div class="column">
                    <b-field label="Nombre Marca *">
                      <ValidationProvider name="Nombre" rules="required|max:255" v-slot="{ errors }">
                        <b-input v-model="aux_name_trademarks" placeholder="Ingrese el nombre de la marca"></b-input>
                        <span class="validation-alert">{{
                          errors[0]
                          }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

              </div>
            </div>

            <div class="columns is-mobile">

              <div class="column">
                <b-button label="Cerrar" @click="$emit('close')" />
              </div>

              <div class="column has-text-right">
                <p class="control">
                  <b-button label="Agregar marca" icon-left="save" type="is-success" @click="check()" />
                </p>
              </div>

            </div>

          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "new_trademarks",
  props: ["queryType", "modalHeader"],
  data() {
    return {
      selected: null,
      aux_name_trademarks: null,
      isLoading: false,
    };
  },
  methods: {
    async createClick() {
      this.isLoading = true
      await axios.post("/trademarks.json", {
        name_trademark: this.aux_name_trademarks,
      })
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha agregado correctamente la Marca al sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al ingresar los datos de la Marca, favor revisar los errores.<br>Errores:<br>' + error.response.data,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    confirmregister() {
      this.$buefy.dialog.confirm({
        title: "Registrar Marca",
        message: `¿Esta seguro de registrar la marca?`,
        cancelText: "Cancelar",
        confirmText: "Registrar",
        type: "is-info",
        onConfirm: () => this.createClick(),
      });
    },

    async check() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }
      else {
        this.confirmregister();
      }
    },
  },
  created() {},
};
</script>

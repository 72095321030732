<template>
  <section class="b-tooltips">
    <b-modal :active="isModalActiveEdit" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog" width="100%"
      :can-cancel="canCancel">
      <template>
        <edit-good :good-id="goodId" :id="selected" :modal-header="'Editar Bien'" query-type="update"
          @close="closeModalEdit"></edit-good>
      </template>
    </b-modal>
    <br>
    <div class="container box">
      <div class="content">
        <h1>Detalle de la persona</h1>
      </div>

      <div>

        <b-modal :active="isModalEditCharge" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
          width="100%" :can-cancel="canCancel">
          <template>
            <edit-good-charge :oldDutyManagerId="duty_managerId" :id="selected" :modal-header="'Asignar Nueva Persona Encargada'" query-type="update"
              :fromStorage="false" :fromUsed="true"
              @close="isModalEditCharge = false" @refresh="getInformation()"/>
          </template>
        </b-modal>

        <b-modal :active="isModalnewstatus" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
          width="100%" :can-cancel="canCancel">
          <template>
            <new-condition-good :good-id="selected" :id="selected" :modal-header="'Enviar a Bodega'" query-type="create" :fromDutyManager="true"
              @close="isModalnewstatus = false" @refresh="getInformation()" />
          </template>
        </b-modal>

      </div>

      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Detalle de la Persona asociada">

          <div v-if="duty_manager" class="columns is-centered">
            <div class="column is-centered">

              <div class="columns">
                <div class="column">
                  <b-field label="Nombre:">
                    <b-input v-model="duty_manager.name_duty_manager" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Rut:">
                    <b-input v-model="duty_manager.rutCompleto" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Email">
                    <b-input v-model="duty_manager.email" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="Unidad:">
                    <b-input
                      v-model="duty_manager.unit.alt_name_unit" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>Cargando</p>
          </div>
        </b-tab-item>






        <b-tab-item label="Bienes a Cargo">
          <div class="column">
            <b-table :data="duty_manager.goods" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_good" label="Nombre" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_good }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_good" label="N° Inventario" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_good }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_category" label="Categoria" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Categoría..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_category }}
                </template>
              </b-table-column>


              <b-table-column field="alt_name_office" label="Dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Dependencia..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_office }}
                </template>
              </b-table-column>

              <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                        tag="a" :href="/goods/ + props.row.id" target="_blank"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show'))"/>
                  </div>
                  <!-- <div class="column">
                      <b-button icon-pack="fas" icon-right="ban" type="is-danger"
                        @click.native="confirmGoodNull(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))"/>
                  </div> -->

                  <div class="column">
                    <b-button icon-pack="fas" icon-right="repeat" type="is-purple" 
                      @click.native = "assignGood(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update_charge'))"/>
                  </div>

                  <div class="column">

                    <b-button icon-pack="fas" icon-right="dolly" type="is-success"
                      @click.native = "sendToStorage(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))" />

                  </div>

                </div>
              </b-table-column>
            </b-table>

          </div>
        </b-tab-item>




        <b-tab-item label="Dependencias a Cargo">
          <div class="column">
            <b-table :data="duty_manager.offices" :paginated="true" per-page="10" striped hoverable
              :pagination-simple="false" pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small"
              aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
              aria-current-label="Página actual">
              <b-table-column :visible=false field="id" label="ID" sortable searchable>

                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_office" label="Nombre Dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_office }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_office" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_office }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_building" label="Edificio" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Edificio..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_building }}
                </template>
              </b-table-column>

              <b-table-column field="location" label="Ubicación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Ubicación..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.location }}
                </template>
              </b-table-column>


              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">

                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                        tag="a" :href="/offices/ + props.row.id" target="_blank"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Office', 'show'))"/>
                  </div>
                </div>
              </b-table-column>


            </b-table>
          </div>
        </b-tab-item>


        <b-tab-item label="Edificios">
          <div class="column">
            <b-table :data="duty_manager.buildings" :paginated="true" per-page="10" striped hoverable
              :pagination-simple="false" pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small"
              aria-next-label="Siguiente" aria-previous-label="Anterior" aria-page-label="Página"
              aria-current-label="Página actual">
              <b-table-column :visible=false field="id" label="ID" sortable searchable>

                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_building" label="Nombre Edificio" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_building }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_building" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_building }}
                </template>
              </b-table-column>



              <b-table-column field="alt_name_unit" label="Nombre Unidad" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Unidad..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_unit }}
                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                        tag="a" :href="/buildings/ + props.row.id" target="_blank"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'show'))"/>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>


        <b-tab-item label="Historial">
          <div class="column">
            <b-table :data="duty_manager.records_charge_goods" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">

              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_good" label="Nombre del Bien" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_good }}
                </template>
              </b-table-column>

              <b-table-column field="start_date" label="Fecha de Inicio" sortable searchable :custom-search="searchCustomDate">
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL") : '' }}
                </template>
              </b-table-column>

              <b-table-column field="end_date" label="Fecha de Inicio" sortable searchable :custom-search="searchCustomDateEnd">
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : 'Actualidad' }}
                </template>
              </b-table-column>

            </b-table>
          </div>

        </b-tab-item>


      </b-tabs>
      <b-button tag="a" href="/duty_managers" label="Volver">
      </b-button>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>


<script>
import axios from "axios"
import moment from "moment"
import {searchDate} from "../../packs/utilities"
import EditGoodCharge from "../good/EditChargeGood.vue"
import NewConditionGood from "../condition_good/new_condition_good.vue"
export default {
  props: ["id", 'queryType', 'modalHeader', 'duty_managerId', 'goodId', 'permissions'],
  components: {
    EditGoodCharge, NewConditionGood,
  },
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Detalle Persona Asociada';
  },
  data() {
    return {
      isLoading: false,
      duty_manager: {
        unit: {
          alt_name_unit: null
        },
      },
      null_activegood: false,
      props: ["id"],
      selected: null,
      canCancel: ["escape"],
      isModalActive: false,
      isModalActiveEdit: false,
      isModalActiveShow: false,
      isModalEditCharge: false,
      isModalnewstatus: false,
    };
  },
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    async getDutyManagers() {
      await axios.get(
        "/duty_managers/" + this.duty_managerId + ".json"
      )
      .then(response => {
        this.duty_manager = response.data;

        this.duty_manager.records_charge_goods = this.duty_manager.records_charge_goods.map(record => {
          var newRecord = record
          newRecord.start_date = moment(newRecord.start_date, ["YYYY-MM-DD","DD-MM-YYYY"])
          newRecord.end_date = moment(newRecord.end_date, ["YYYY-MM-DD","DD-MM-YYYY"])
          return newRecord
        })

      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
          console.log(error)
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al traer la información de la Persona Encargada.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      
    },

    async AnulClick(item) {
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.null_activegood,
      });
      window.location.href = "/duty_managers/" + this.duty_managerId;
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: 'Anular Bien',
        message: '¿Esta seguro de querer anular el bien?.',
        confirmText: 'Anular',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.AnulClick(item)
      })
    },
    async editGood(item) {
      this.selected = item;
      this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },

    searchCustomDate (row, input) {
      return searchDate (row.start_date, input)
    },

    searchCustomDateEnd (row, input) {
      return searchDate (row.end_date, input)
    },

    async getInformation() {
      this.isLoading = true
      await this.getDutyManagers()
      this.isLoading = false
    },

    async assignGood(item) {
      this.selected = item
      this.isModalEditCharge = true
    },

    async sendToStorage(item) {
      this.selected = item
      this.isModalnewstatus = true
    }

  },
  created() {
    this.getInformation()

  },
};
</script>

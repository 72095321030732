<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Marcas</h1>
      </div>
      <div>
        <b-modal :active="isModalActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
          width="100%" :can-cancel="canCancel">
          <template>
            <new-trademarks modal-cancel="true" :modal-header="'Registro de marca'" query-type="create"
              @close="closeModalCreate" @refresh="toRefreshActiveTrademark" />
          </template>
        </b-modal>
        <b-modal :active="isModalActiveEdit" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
          width="100%" :can-cancel="canCancel">
          <template>
            <edit-trademarks modal-cancel="true" :id="selected" :modal-header="'Editar marca'" query-type="update"
              @close="closeModalEdit" @refresh="toRefreshActiveTrademark" />
          </template>
        </b-modal>
      </div>
      <b-tabs type="is-boxed" position="is-centered">

        <b-tab-item label="Marcas">
          <div class="has-text-right">
            <b-button label="Añadir Marca de bien" type="is-success" icon-left="plus" @click="createmodel()"
              v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Trademark', 'create'))" />
          </div>
          <div class="columns">
            <div class="column">
              <b-table id="table" :data="trademarks" :paginated="true" per-page="10" striped hoverable
                :pagination-simple="false" pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom"
                default-sort-direction="asc" sort-icon="arrow-up" aria-next-label="Siguiente"
                aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
                <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                  <template v-slot="props">
                    {{ props.row.id }}
                  </template>
                </b-table-column>

                <b-table-column field="name_trademark" label="Nombre Marca" sortable searchable>
                  <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                      icon="magnifying-glass" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.alt_name_trademark }}
                  </template>
                </b-table-column>

                <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                  <div class="columns is-mobile is-variable is-1-mobile">
                    <div class="column">
                      <b-button pack="fas" icon-right="pen" type="is-primary"
                        @click.native="showModalEdit(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Trademark', 'update'))" />
                    </div>
                    <div class="column">
                      <b-button icon-pack="fas" icon-right="ban" type="is-danger"
                        @click.native="confirmDisabled(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Trademark', 'disable_trademark'))" />
                    </div>
                  </div>
                </b-table-column>
              </b-table>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Marcas Anuladas">
          <div class="columns">
            <div class="column">
              <b-table id="table" :data="null_trademarks" :paginated="true" per-page="10" striped hoverable
                :pagination-simple="false" pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom"
                default-sort-direction="asc" sort-icon="arrow-up" aria-next-label="Siguiente"
                aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
                <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                  <template v-slot="props">
                    {{ props.row.id }}
                  </template>
                </b-table-column>

                <b-table-column field="name_trademark" label="Nombre Marca" sortable searchable>
                  <template #searchable="props">
                    <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                      icon="magnifying-glass" />
                  </template>
                  <template v-slot="props">
                    {{ props.row.alt_name_trademark }}
                  </template>
                </b-table-column>

                <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                  <div class="columns is-mobile is-variable is-1-mobile">
                    <div class="column">
                      <b-button icon-pack="fas" icon-right="trash-arrow-up" type="is-success"
                        @click.native="confirmEnable(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Trademark', 'activate_trademark'))" />
                    </div>
                  </div>
                </b-table-column>
              </b-table>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import NewTrademarks from "./new_trademarks.vue";
import EditTrademarks from "./edit_trademarks.vue";
export default {
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Marcas de los Bienes';
  },
  data() {
    return {
      trademarks: [],
      null_trademarks: [],
      canCancel: ["escape"],
      isModalActive: false,
      isModalActiveEdit: false,
      selected: null,
      isLoading: false,
    };
  },
  components: {
    NewTrademarks,
    EditTrademarks,
  },
  props: ["TrademarkId", "permissions"],
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },
    async getTrademarks() {
      this.isLoading = true
      await axios.get("/trademarks/showtrademarks.json")
        .then(response => {
          this.trademarks = response.data.trademarks_enabled
          this.null_trademarks = response.data.trademarks_disabled
        })
        .catch(error => {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al solicitar los datos de las Marcas, favor de recargar la página e inténtelo nuevamente.',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
      this.isLoading = false
    },

    async disableTrademark(id) {
      await axios.put('/trademarks/' + id + '/disable.json')
        .then(response => {
          this.$buefy.dialog.alert({
            title: 'Logrado',
            message: 'Se ha anulado correctamente la Marca.',
            type: 'is-success',
            hasIcon: true,
            icon: 'circle-check',
            iconPack: 'fas',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.toRefreshActiveTrademark()
        })
        .catch(error => {
          var error_message
          if (error.response != null) {
            error_message = error.response.data.error
          } else {
            error_message = error
          }
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al anular la Marca.<br><br> Error:<br>' + error_message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    },

    async enableTrademark(id) {
      await axios.put('/trademarks/' + id + '/activate.json')
        .then(response => {
          this.$buefy.dialog.alert({
            title: 'Logrado',
            message: 'Se ha restaurado correctamente la Marca.',
            type: 'is-success',
            hasIcon: true,
            icon: 'circle-check',
            iconPack: 'fas',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
          this.toRefreshActiveTrademark()
        })
        .catch(error => {
          var error_message
          if (error.response != null) {
            error_message = error.response.data.error
          } else {
            error_message = error
          }

          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al restaurar la Marca.<br><br> Error:<br>' + error_message,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    },

    async confirmDisabled(id) {
      this.$buefy.dialog.confirm({
        title: "Anular Marca",
        message: "¿Esta seguro de querer anular Marca?",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.disableTrademark(id),
      })
    },

    async confirmEnable(id) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Marca",
        message: "¿Esta seguro de querer restaurar la Marca?",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => this.enableTrademark(id),
      })
    },

    async createmodel() {
      this.isModalActive = true;
    },
    async closeModalCreate() {
      this.isModalActive = false;
    },
    async showModalEdit(item) {
      this.selected = item;
      this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },
    async toRefreshActiveTrademark() {

      this.isLoading = true
      await this.getTrademarks();
      this.isLoading = false

    },
  },
  created() {
    this.toRefreshActiveTrademark();
  },
};
</script>

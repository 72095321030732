<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">

      <div class="content">
        <h1>Bienes a cargo</h1>
      </div>

      <div class="columns">

        <div class="column has-text-right">
          <!-- <b-button label="Añadir Bien" type="is-success" icon-left="plus" @click="createGood()" /> -->
          <b-button label="Descargar bienes" class="is-excel-color" icon-left="file-excel" @click="exportGood()"
            v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'download_goods_by_ids'))"/>
        </div>
      </div>

      <div>
        <b-modal :active="isModalActive" aria-modal :destroy-on-hide="true" aria-role="dialog" width="90%"
          :can-cancel="canCancel">
          <template>
            <new-good-by-user modal-cancel="true" :modal-header="'Registro de Bien'" query-type="create"
              @close="closeModalCreate" @refresh="toRefreshActiveGoods" />
          </template>
        </b-modal>

        <b-modal :active="isModalActiveEdit" aria-modal :destroy-on-hide="true" aria-role="dialog" width="90%"
          :can-cancel="canCancel">
          <template>
            <edit-good-by-user :good-id="goodId" :id="selected" :modal-header="'Editar Bien'" query-type="update"
              @close="closeModalEdit" @refresh="toRefreshActiveGoods" />
          </template>
        </b-modal>

        <b-modal :active="isModalnewstatus" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
          width="100%" :can-cancel="canCancel">
          <template>
            <new-condition-good :good-id="goodId" :id="selected" :modal-header="'Reportar Bien'" query-type="create"
              @close="closeModalCreateStatus" @refresh="toRefreshActiveGoods" />
          </template>
        </b-modal>
      </div>

      <div class="column">

        <b-table :data="goods" striped hoverable :paginated="true" per-page="10" :pagination-simple="false"
          pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up">
          <b-table-column :visible="false" field="id" label="ID" sortable searchable>
            <template v-slot="props">
              {{ props.row.id }}
            </template>
          </b-table-column>

          <b-table-column field="namegood" label="Nombre" sortable searchable>
            <template #searchable="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                icon="magnifying-glass" />
            </template>
            <template v-slot="props">
              {{ props.row.alt_name_good }}
            </template>

          </b-table-column>
          <b-table-column field="identification_number_good" label="N° Inventario" sortable searchable>
            <template #searchable="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..." icon="magnifying-glass" />
            </template>
            <template v-slot="props">
              {{ props.row.identification_number_good }}
            </template>
          </b-table-column>

          <b-table-column field="category.name_category" label="Categoria" sortable searchable>
            <template #searchable="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Categoria..."
                icon="magnifying-glass" />
            </template>
            <template v-slot="props">
              {{ props.row.category.alt_name_category }}
            </template>
          </b-table-column>

          <b-table-column field="duty_manager.name_duty_manager" label="Persona encargada" sortable searchable>
            <template #searchable="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Persona..."
                icon="magnifying-glass" />
            </template>

            <template v-slot="props">
              {{ props.row.duty_manager.name_duty_manager }}
            </template>
          </b-table-column>


          <b-table-column field="office.name_office" label="Dependencia" sortable searchable>
            <template #searchable="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Dependencia..."
                icon="magnifying-glass" />
            </template>
            <template v-slot="props">
              {{ props.row.office.alt_name_office }}

            </template>
          </b-table-column>

          <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
            <div class="columns is-mobile is-variable is-1-mobile">
              <div class="column">
                <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                  tag="a" :href="'/goods/mygood/' + props.row.id" target="_blank"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show_my_good'))"/>
              </div>
              <div class="column">
                <b-button icon-pack="fas" icon-right="pen" type="is-primary" @click.native="editGood(props.row.id)"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'update'))"/>
              </div>
              <div class="column">
                <b-button icon-pack="fas" icon-right="bullhorn" type="is-warning"
                  @click.native="createConditionGood(props.row.id)"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('ConditionGood', 'update'))"/>
              </div>
              <div class="column">
                <b-button icon-pack="fas" icon-right="ban" type="is-danger"
                  @click.native="confirmGoodNull(props.row.id)"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))"/>
              </div>
            </div>
          </b-table-column>

        </b-table>
      </div>

    </div>
  </section>
</template>
  
<script>
import axios from "axios";
import NewGoodByUser from "../good/NewGoodByUser.vue";
import EditGoodByUser from "../good/EditGoodByUser.vue";
import EditGood from "../good/edit_good.vue";
import ShowMyGood from "../good/show_my_good.vue";
import NewConditionGood from "../condition_good/new_condition_good.vue";

export default {
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Mis Bienes';
  },
  data() {
    return {
      goods: [],
      offices: [],
      units: [],
      managers: [],
      buildings: [],
      selected: null,
      select: null,
      canCancel: ["escape"],
      isModalActive: false,
      isModalActiveEdit: false,
      isModalActiveShow: false,
      isModalnewstatus: false,
      aux_duty_manager_id: null,
      aux_office_id: null,
      aux_unit_id: null,
      aux_building_id: null,
      null_activegood: false,
      des_activegood: true,
      usuario_actual: [],
      current: null,
      buildingcharge: false,
    };
  },
  props: ["id", 'queryType', 'modalHeader', 'goodId', 'permissions'],

  components: {
    EditGood,
    NewGoodByUser,
    ShowMyGood,
    NewConditionGood,
    EditGoodByUser,
  },
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
          return true;
      } else {
          return false;
      }
    },

    async get_user_actual() {
      const response = await axios.get("/duty_managers/currentuser.json");
      this.current = response.data.current_user;
      this.getgoodsactive(this.current);
      this.getbuildingactive(this.current);
    },

    async exportGood() {
      //const response = await axios.get("/goods/showactive.xlsx");
      window.location.href = "/goods/showgooduser/" + this.current + ".xlsx";
    },


    async getgoodsactive(item) {
      const response = await axios.get("/goods/showgooduser/" + item + ".json");
      this.goods = response.data;

    },

    async getbuildingactive(item) {
      const response = await axios.get("/buildings/showbuildinguser/" + item + ".json");
      this.buildings = response.data;
      if (this.buildings.length != 0) {
        this.buildingcharge = true;
      }

    },


    async Clicknewgood() {
      const response = (window.location.href = "/goods/new");
    },



    async RestClick(item) {
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.des_activegood,
      });
      // window.location.href = "/duty_managers/mygoods";
      this.isLoading = true
      await this.get_user_actual();
      this.isLoading = false;
    },
    confirmGoodRest(item) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Bien",
        message: "¿Esta seguro de querer restaurar el bien?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Bien",
        message: "¿Esta seguro de querer anular el bien?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });
    },

    async AnulClick(item) {
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.null_activegood,
      });
      // window.location.href = "/duty_managers/mygoods";
      this.isLoading = true
      await this.get_user_actual();
      this.isLoading = false;
    },



    async getdutymanagerbyunit(item) {
      const response = await axios.get(
        "/duty_managers/showdutymanagerunit/" +
        item +
        ".json"
      );
      this.managers = response.data;
    },

    async getbuildingbyunit(item) {
      const response = await axios.get(
        "/buildings/showbuildingunit/" + item + ".json"
      );
      this.buildings = response.data;
    },

    async getofficebybuilding(item) {
      const response = await axios.get(
        "/offices/showofficebuilding/" + item + ".json"
      );
      this.offices = response.data;
    },
    async Clickeditgood(item) {
      const response = (window.location.href = "/goods/" + item + "/edit");
    },

    async createGood() {
      this.isModalActive = true;
    },
    async closeModalCreate() {
      this.isModalActive = false;
    },

    async editGood(item) {
      this.selected = item;
      this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },
    async ShowModalGood(item) {
      this.selected = item;
      this.isModalActiveShow = true;
    },
    async closeModalShow() {
      this.isModalActiveShow = false;
    },
    async createConditionGood(item) {
      this.selected = item;

      this.isModalnewstatus = true;
    },

    async closeModalCreateStatus() {
      this.isModalnewstatus = false;
    },

    async toRefreshActiveGoods(refrescar){
      
      if (refrescar) {
        this.isLoading = true
        await this.get_user_actual();
        this.isLoading = false
      }

    },

    alert() {
      this.$buefy.dialog.alert({
        title: 'Ayuda',
        message: `Aquí el usuario puede ver todas los bienes que tiene a su cargo con la información correspondiente.<br>
                              Dispone de las siguientes acciones: <br>                    
                              Añadir bien: Permite registrar un bien a la plataforma o añadir masivamente los bienes (Solo disponible para personas con edificio a cargo).<br>
                              Descargar bienes: Permite descargar un documento con todos los bienes del usuario con su correspondiente información.<br>
                              Icono Ver detalle: Abre una ventana que permite ver la información del bien. <br>
                              Icono Anular: Permite anular un bien y enviarlo a la papelera. <br>
                              Icono Editar: Permite al usuario editar la información del bien. <br>
                              Icono Añadir estado: Permite reportar un bien si es que este ha sido destruido, se ha perdido, o se quiere enviar a la bodega de excluidos. <br>`,
      })
    },
  },
  created() {
    this.get_user_actual();
  },
};
</script>
  
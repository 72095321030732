<template>
  <section>
    <br>
    <div class="container box">

      <div v-if="condition_good" class="columns is-centered">
        <div class="column is-centered">

          <div class="content">
            <h1>Detalle de la Solicitud</h1>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Nombre:">
                <b-input icon="eye" icon-clickable @icon-click="Clickshowgood(condition_good.good_id)"
                  v-model="condition_good.good.alt_name_good" type="text" readonly></b-input>
              </b-field>
            </div>

          </div>


          <div class="columns">
            <div class="column">
              <b-field label="Estado:">
                <b-input v-if="condition_good.change_condition_to != null" v-model="condition_good.change_condition_to" type="text" readonly/>
                <b-input v-else v-model="condition_good.condition.state_name" type="text" readonly/>
              </b-field>
            </div>

          </div>







          <div class="columns">
            <div class="column">
              <b-field label="Fecha solicitud:">
                <b-input v-model="request_date" type="text" readonly></b-input>
              </b-field>

            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field label="Descripción de la Solicitud">
                <b-input v-model="condition_good.request_detail" type="textarea" readonly></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column" v-show='!(condition_good.repairable == null)'>
              <b-field label="Es reparable:">
                <b-input v-if="condition_good.repairable" value="Es Reparable" type="text" readonly/>
                <b-input v-else type="text" value="No es Reparable" readonly/>
              </b-field>

            </div>
          </div>

          <div class="columns">
            <div class="column" v-show='!(condition_good.good.activegood == 3)'>
              <b-field label="Persona encargada: ">
                <b-input icon="eye" icon-clickable @icon-click="Clickshowdutymanager(condition_good.good.duty_manager_id)"
                  v-model="condition_good.good.name_duty_manager" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>


          <div class="columns">
            <div class="column" v-show='!(condition_good.good.activegood == 3)'>
              <b-field label="Unidad: ">
                <b-input icon="eye" icon-clickable @icon-click="Clickshowunit(condition_good.good.unit_id)"
                  v-model="condition_good.good.alt_name_unit" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column" v-show='!(condition_good.good.activegood == 3)'>
              <b-field label="Edificio: ">
                <b-input icon="eye" icon-clickable @icon-click="Clickshowbuilding(condition_good.good.building_id)"
                  v-model="condition_good.good.alt_name_building" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column" v-show='!(condition_good.good.activegood == 3)'>
              <b-field label="Dependencia: ">
                <b-input icon="eye" icon-clickable @icon-click="Clickshowoffice(condition_good.good.office_id)"
                  v-model="condition_good.good.alt_name_office" type="text" readonly></b-input>
              </b-field>
            </div>
          </div>





          <div class="columns">
            <div class="column">
              <b-field label="Link de documento:">
                <b-input v-model="condition_good.link" type="text" readonly expanded></b-input>
                <p>
                  <b-button label="Ir a URL" type="is-info" @click="Clickshowlink(condition_good.link)" />
                </p>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Cargando</p>
      </div>
      <b-button
        tag="a"
        href="/condition_goods"
        label="Volver">
      </b-button>
    </div>
  </section>
</template>

 
<script>
import axios from "axios";
import moment from "moment";

export default {
  name:"show_good",
  props: ["condition_goodId", "id",'queryType', 'modalHeader'],
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Reporte del Bien';
  },
  
  data() {
    return {
      condition_good: null,
      null_activegood: false,
       des_activegood: true,
      selected: null,
      request_date: null,
      canCancel: ["escape"],
      isModalActiveEdit: false,
      props: ["id"],
    };
  },
  methods: {
    async getconditiongoods() {
      await axios
              .get("/condition_goods/" + this.condition_goodId + ".json")
              .then(response => {
                this.condition_good = response.data

                this.condition_good.request_date = moment(this.condition_good.request_date, ["YYYY-MM-DD","DD-MM-YYYY"])

                this.request_date = this.condition_good.request_date ? new Date(this.condition_good.request_date).toLocaleDateString("es-CL") : ''

              })
              .catch((error) => {

                var showError = null

                if (error.response != null) {
                  showError = error.response.data
                } else {
                  showError = error
                }

                this.$buefy.dialog.alert({
                  title: "Error",
                  message: 'Error en la obtención de la Condicion del Bien. <br>Errores:<br>' + showError,
                  type: "is-danger",
                  hasIcon: true,
                  icon: "times-circle",
                  iconPack: "fa",
                  ariaRole: "alertdialog",
                  ariaModal: true,
                  confirmText: "Ok",
                });
              })
    },
           
        async RestClick(item) {
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.des_activegood,
      });
      window.location.href = "/goods";
    },

async Clickshowlink(item) {
      window.open(item);
    },
        async Clickshowgood(item) {
      const response = (window.location.href = "/goods/" + item );
    },

    async Clickshowdutymanager(item) {
      const response = (window.location.href = "/duty_managers/" + item );
    },
            async Clickshowbuilding(item) {
      const response = (window.location.href = "/buildings/" + item );
    },
      async Clickshowoffice(item) {
      const response = (window.location.href = "/offices/" + item );
    },
        async Clickshowunit(item) {
      const response = (window.location.href = "/units/" + item);
    },
    confirmGoodRest(item) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Bien",
        message: "¿Esta seguro de querer restaurar el bien?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
    },
            async editGood(item) {
          this.selected = item;
          this.isModalActiveEdit = true;
    },
    async closeModalEdit() {
      this.isModalActiveEdit = false;
    },

    formatdate(date){
      if(date!=null && date!="" && date!=undefined){
        var arreglo = date.split("-")
        return arreglo[2]+"-"+arreglo[1]+"-"+arreglo[0]
      }
      else{ 
        return ""
        }
      },

  },
  created() {
    this.getconditiongoods();
  },
};
</script>
 




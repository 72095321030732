<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Tipos de inversiones de la facultad</h1>
      </div>

      <div>
        <b-modal :active="isModalActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
          width="100%" :can-cancel="canCancel">
          <template>
            <new-investment-types modal-cancel="true" :modal-header="'Registro de Tipo de inversión'"
              query-type="create" @close="isModalActive = false" @refresh="toRefreshActiveInvestment"/>
          </template>
        </b-modal>
        <b-modal :active="isModalEditActive" aria-modal has-modal-card :destroy-on-hide="true" aria-role="dialog"
          width="100%" :can-cancel="canCancel">
          <template>
            <edit-investment-types modal-cancel="true" :id="selected" :modal-header="'Editar Tipo de inversión'"
              query-type="create" @close="isModalEditActive = false" @refresh="toRefreshActiveInvestment"/>
          </template>
        </b-modal>
      </div>
      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Inversiones">
          <div class="has-text-right">
            <b-button label="Añadir Tipo de inversión" type="is-success" icon-left="plus"
              @click="createinvestment()"
              v-show="(isAuthorized('all', 'manage')) || (isAuthorized('InvestmentType', 'create'))"/>
          </div>
          <div class="column">
            <b-table id="table" :data="investment_types" :paginated="true" per-page="10" striped hoverable
              :pagination-simple="false" pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom"
              default-sort-direction="asc" sort-icon="arrow-up" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>

                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>



              <b-table-column field="name_investment_type" label="Nombre Inversión" sortable searchable>
                <template #searchable="props">
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar Nombre..."
                    icon="magnifying-glass"/>
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_investment_type }}
                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach" 
                        tag="a" :href="/investment_types/ + props.row.id" target="_blank"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('InvestmentType', 'show'))"/>
                  </div>
                  <div class="column">
                      <b-button pack="fas" icon-right="pen" type="is-primary"
                        @click.native="showModalEdit(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('InvestmentType', 'update'))"/>
                  </div>
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="ban" type="is-danger" 
                        @click.native="confirmGoodNull(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('InvestmentType', 'disable_investment_type'))"/>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>

        <b-tab-item label="Inversiones anuladas">

          <div class="column">
            <b-table id="table" :data="null_investment_types" :paginated="true" per-page="10" striped hoverable
              :pagination-simple="false" pagination-active-color="hsl(0, 0%, 0%)" pagination-position="bottom"
              default-sort-direction="asc" sort-icon="arrow-up" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>

                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>



              <b-table-column field="name_investment_type" label="Nombre Inversión" sortable searchable>
                <template #searchable="props">
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Buscar Nombre..."
                    icon="magnifying-glass"/>
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_investment_type }}

                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach" 
                        tag="a" :href="/investment_types/ + props.row.id" target="_blank"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('InvestmentType', 'show'))"/>
                  </div>
                  <div class="column">
                      <b-button icon-pack="fas" icon-right="trash-arrow-up" type="is-success"
                        @click.native="confirmGoodRest(props.row.id)"
                        v-show="(isAuthorized('all', 'manage')) || (isAuthorized('InvestmentType', 'activate_investment_type'))"/>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"/>
    </div>
  </section>
</template>


<script>
import axios from "axios";
import NewInvestmentTypes from "./new_investment_types.vue";
import ShowInvestmentTypes from "./show_investment_types.vue";
import EditInvestmentTypes from "./edit_investment_types.vue";
export default {
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Tipos de Inversiones';
  },
  data() {
    return {
      investment_types: [],
      null_investment_types: [],
      canCancel: ["escape"],
      isModalActive: false,
      isModalEditActive: false,
      selected: null,
      null_active: false,
      des_active: true,
      isLoading: false,
    };
  },
  components: {
    NewInvestmentTypes,
    ShowInvestmentTypes,
    EditInvestmentTypes,
  },
  props: ["investment_typeId", 'permissions'],
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    async getInvestmentTypes() {
      this.isLoading = true
      const response = await axios.get("/investment_types/showinvestmenttypes.json")
      this.investment_types = response.data.investment_types_enabled
      this.null_investment_types = response.data.investment_types_disabled
      this.isLoading = true
    },

     async AnulClick(item) {
      this.isLoading = true
      const response = await axios.put("/investment_types/" + item + "/disable.json")
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha anulado correctamente el Tipo de Inversión.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.toRefreshActiveInvestment()
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al anular el Tipo de Inversión.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async RestClick(item) {
      this.isLoading = true
      const response = await axios.put("/investment_types/" + item + "/activate.json")
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha restaurado correctamente el Tipo de Inversión.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.toRefreshActiveInvestment()
      })
      .catch(error => {
        var error_message
        if (error.response != null) {
          error_message = error.response.data.error
        }else{
          error_message = error
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al restaurar el Tipo de Inversión.<br><br> Error:<br>' + error_message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },
    confirmGoodRest(item) {
      this.$buefy.dialog.confirm({
        title: "Restaurar Tipo de inversión",
        message: "¿Esta seguro de querer restaurar Tipo de inversión?.",
        confirmText: "Restaurar",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.RestClick(item),
      });
    },

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Tipo de inversión",
        message: "¿Esta seguro de querer anular Tipo de inversión?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });
    },


    async Clicknewinvestment() {
      const response = (window.location.href = "/investment_types/new");
    },
    async createinvestment() {
      this.isModalActive = true;
    },
    async showModalEdit(item) {
      this.selected = item;
      this.isModalEditActive = true;
    },
    async toRefreshActiveInvestment(){
        this.isLoading = true
        await this.getInvestmentTypes();
        this.isLoading = false
    },
  },
  async created() {
    await this.toRefreshActiveInvestment()
  },
};
</script>



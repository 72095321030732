<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Detalle de la Unidad</h1>
      </div>
      <b-tabs type="is-boxed" position="is-centered">
        <b-tab-item label="Detalle de la Unidad">
          <div v-if="unit" class="columns is-centered">
            <div class="column is-centered">
              <div class="columns">
                <div class="column">
                  <b-field label="Nombre de la Unidad:">
                    <b-input v-model="unit.alt_name_unit" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Numero identificación de la unidad:">
                    <b-input v-model="unit.identification_number_unit" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Monto bruto total invertido en bienes: ">
                    <b-input v-model="total" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Descripción:">
                    <b-input v-model="unit.description_unit" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <p>Cargando</p>
          </div>
        </b-tab-item>

        <b-tab-item label="Personas Asociadas a la Unidad">
          <div class="column">
            <b-table :data="unit.duty_managers" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" default-sort-direction="asc" sort-icon="arrow-up" sort-icon-size="is-small"
              default-sort="inventores.name" aria-next-label="Siguiente" aria-previous-label="Anterior"
              aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="name_duty_manager" label="Nombre de la persona asociada" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.name_duty_manager }}
                </template>
              </b-table-column>

              <b-table-column field="all_rut" label="Rut" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar RUT..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.all_rut }}
                </template>
              </b-table-column>

              <b-table-column field="id" width="10" label="Acciones" v-slot="props">
                <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                  tag="a" :href="/duty_managers/ + props.row.id" target="_blank"
                  v-show="(isAuthorized('all', 'manage')) || (isAuthorized('DutyManager', 'show'))"/>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>

        <b-tab-item label="Edificios">
          <div class="column">
            <b-table :data="unit.buildings" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_building" label="Nombre Edificio" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_building }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_building" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_building }}
                </template>
              </b-table-column>

              <b-table-column field="location_building" label="Ubicación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Ubicación..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.location_building }}
                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                      tag="a" :href="/buildings/ + props.row.id" target="_blank"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Building', 'show'))"/>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>

        <b-tab-item label="Dependencias de la Unidad">
          <div class="column">
            <b-table :data="unit.offices" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_office" label="Nombre Dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_office }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_office" label="N° Identificación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_office }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_building" label="Edificio" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Edificio..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_building }}
                </template>
              </b-table-column>

              <b-table-column field="location" label="Ubicación" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Ubicación..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.location }}
                </template>
              </b-table-column>

              <b-table-column ffield="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                      tag="a" :href="/offices/ + props.row.id" target="_blank"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Office', 'show'))"/>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>

        <b-tab-item label="Bienes de la Unidad">
          <div class="column">
            <b-table :data="unit.goods" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_good" label="Nombre" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_good }}
                </template>
              </b-table-column>

              <b-table-column field="identification_number_good" label="N° Inventario" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar N°..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.identification_number_good }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_category" label="Categoría" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Categoría..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_category }}
                </template>
              </b-table-column>

              <b-table-column field="name_duty_manager" label="Nombre Persona Asociada" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Persona..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.name_duty_manager }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_office" label="Dependencia" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Dependencia..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.alt_name_office }}
                </template>
              </b-table-column>

              <b-table-column field="id" width="10" label="Acciones" v-slot="props" size="is-small">
                <div class="columns is-mobile is-variable is-1-mobile">
                  <div class="column">
                    <b-button icon-pack="fas" icon-right="eye" type="is-orange-complementary-usach"
                      tag="a" :href="/goods/ + props.row.id" target="_blank"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'show'))"/>
                  </div>

                  <!-- <div class="column">
                    <b-button icon-pack="fas" icon-right="ban" type="is-danger"
                      @click.native="confirmGoodNull(props.row.id)"
                      v-show="(isAuthorized('all', 'manage')) || (isAuthorized('Good', 'disable_good'))"/>
                  </div> -->
                </div>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>

        <b-tab-item label="Historial">
          <div class="column">
            <b-table :data="unit.versions_goods" :paginated="true" per-page="10" striped hoverable :pagination-simple="false"
              pagination-position="bottom" sort-icon="arrow-up" sort-icon-size="is-small" aria-next-label="Siguiente"
              aria-previous-label="Anterior" aria-page-label="Página" aria-current-label="Página actual">
              <b-table-column :visible="false" field="id" label="ID" sortable searchable>
                <template v-slot="props">
                  {{ props.row.id }}
                </template>
              </b-table-column>

              <b-table-column field="alt_name_good" label="Nombre del Bien" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{  props.row.alt_name_good }}
                </template>
              </b-table-column>

              <b-table-column field="full_name" label="Nombre usuario" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Nombre Usuario..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.full_name }}
                </template>
              </b-table-column>

              <b-table-column field="event" label="Acción" sortable searchable>
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Acción..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.event }}
                </template>
              </b-table-column>

              <b-table-column field="date_event" label="Fecha de acción" sortable searchable
                :custom-search="searchCustomDate">
                <template #searchable="props">
                  <b-input v-model="props.filters[props.column.field]" placeholder="Buscar Fecha..."
                    icon="magnifying-glass" />
                </template>
                <template v-slot="props">
                  {{ props.row.date_event._isValid ? new
                    Date(props.row.date_event).toLocaleString("es-CL") : '' }}
                </template>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-button tag="a" href="/units" label="Volver">
      </b-button>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import {searchDate} from "../../packs/utilities";
export default {
  name: "show_unit",
  props: ["id", "queryType", "modalHeader", "unitId", 'permissions'],
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Detalle Unidad';
  },
  data() {
    return {
      isLoading: false,
      total: null,
      unit: {

      },
      null_activegood: false,
      props: ["id"],
    };
  },
  methods: {
    isAuthorized(name, action) {
      var objeto = this.permissions.find((obj) => obj.subject_class === name && obj.action === action);
      if (objeto !== undefined) {
        return true;
      } else {
        return false;
      }
    },

    async getUnit() {
      this.isLoading = true
      await axios.get("/units/" + this.unitId + ".json")
      .then(response => {
        this.unit = response.data;

        var goodamounts = this.unit.goods.map(good => good.amount)
        const c = 0
        this.total = goodamounts.reduce((a,b) => a + b, c)

        if (this.unit.versions_goods != null) {
          this.unit.versions_goods = this.unit.versions_goods.map(version => {
            var newVersion = version
            newVersion.date_event = moment(newVersion.date_event, ["YYYY-MM-DD H:m:s", "DD-MM-YYYY H:m:s"])
            return newVersion
          })

          this.unit.versions_goods.sort(function (a, b) {
            return new Date(b.date_event) - new Date(a.date_event)
          })
        }
        
      })
      .catch(error => {
        var showError = null
          console.log(error)

          if (error.response != null) {
            showError = error.response.data
          }else{
            showError = error
          }

          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Hubo un error al traer la información del Edificio, favor de revisar errores.<br>Errores<br>' + showError,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
      })
      this.isLoading = false
    },
    //Anular Bienes

    confirmGoodNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Bien",
        message: "¿Esta seguro de querer anular el bien?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulGoodClick(item),
      });
    },

    async AnulGoodClick(item) {
      this.isLoading = true
      const response = await axios.put("/goods/" + item + ".json", {
        activegood: this.null_activegood,
      })
      this.getInformation()
      this.isLoading = false
    },

    //Anular dependencias

    async confirmisempty(item) {
      const response = await axios.get(
        "/goods/showgoodoffice/" + item + ".json"
      );
      this.goods_office = response.data;
      if (this.goods_office.length == 0) {
        this.confirmGoodNull(item);
      } else {
        this.$buefy.dialog.alert({
          title: "No se puede anular",
          type: "is-danger",
          message: "Existen bienes designados en el edificio",
          confirmText: "Aceptar",
        });
      }
    },

    confirmOfficeNull(item) {
      this.$buefy.dialog.confirm({
        title: "Anular Dependencia",
        message: "¿Esta seguro de querer anular la Dependencia?.",
        confirmText: "Anular",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.AnulClick(item),
      });
    },

    async AnulClick(item) {
      const response = await axios.put("/offices/" + item + ".json", {
        active_office: this.null_active,
      });
      window.location.href = "/offices";
    },

    async Clickshowunit(item) {
      const response = (window.location.href = "/units/" + item);
    },
    searchCustomDate (row, input) {
      return searchDate (row.formatted_created_at, input)
    },
    async getInformation() {
      this.isLoading = true
      await this.getUnit()
      this.isLoading = false
    }
  },
  async created() {
    await this.getInformation()
  },
};
</script>

<template>
    <section>
        <div class="modal-card" :style="'width: 1080px; max-width: 100%;'">
            <header class="modal-card-head">
                <p class="modal-card-title">Reporte del Bien</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">

                <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                    <form @submit.prevent="handleSubmit(check())">

                        <div class="columns">

                            <div class="column">
                                <b-field label="Nombre del Bien">
                                    <b-input v-model="good.alt_name_good" readonly />
                                </b-field>
                            </div>

                            <div class="column">
                                <b-field :label="fromConditions ? 'Estado del Bien' : 'Estado del Bien Solicitado'">
                                    <b-input v-model="condition_to" readonly />
                                </b-field>
                            </div>

                        </div>

                        <div class="columns">

                            <div class="column">
                                <b-field label="Número Interno">
                                    <b-input v-model="good.internal_number" readonly />
                                </b-field>
                            </div>

                            <div class="column">
                                <b-field label="Número de Serie">
                                    <b-input v-model="good.serie_number" readonly />
                                </b-field>
                            </div>

                        </div>

                        <div v-show="!fromConditions" class="columns">

                            <div class="column">
                                <b-field label="Fecha del Reporte">
                                    <b-input v-model="good.condition_good.request_date" readonly />
                                </b-field>
                            </div>

                        </div>

                        <div v-show="!fromConditions" class="columns">

                            <div class="column">
                                <b-field label="Motivo del Ingreso">
                                    <b-input v-model="good.condition_good.detail" readonly type="textarea" />
                                </b-field>
                            </div>

                        </div>

                        <div class="title is-6">
                            Acciones
                            <hr class="dropdown-divider" aria-role="menuitem">
                        </div>

                        <div class="columns">
                            <div class="column">

                                <ValidationProvider name="Estado del Bien Final" vid="condition" rules="required"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Estado del Bien Final *"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">

                                        <b-radio-button v-model="condition" native-value="En Bodega" type="is-primary">
                                            <span>Enviar a Bodega</span>
                                        </b-radio-button>

                                        <b-radio-button v-model="condition" native-value="Dado de Baja"
                                            type="is-primary">
                                            <span>Dar de Baja</span>
                                        </b-radio-button>

                                        <b-radio-button v-model="condition" native-value="Perdido" type="is-primary">
                                            <span>Perdido</span>
                                        </b-radio-button>

                                        <b-radio-button v-model="condition" native-value="Dañado" type="is-primary">
                                            <span>Dañado</span>
                                        </b-radio-button>

                                        <b-radio-button v-model="condition" native-value="No Operativo"
                                            type="is-primary">
                                            <span>No Operativo</span>
                                        </b-radio-button>

                                        <b-radio-button v-model="condition" native-value="En Servicio Técnico"
                                            type="is-primary">
                                            <span>A Servicio Técnico</span>
                                        </b-radio-button>

                                    </b-field>
                                </ValidationProvider>

                            </div>
                        </div>

                        <div class="columns">

                            <div class="column" v-show="condition === 'Perdido'">
                                <ValidationProvider name="Fecha de la Perdida" rules="required_if:condition,Perdido"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label='Fecha de la Perdida *'
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-datepicker v-model="report_date" locale="es-ES"
                                            placeholder="Seleccionar Fecha de la Perdida" trap-focus>
                                        </b-datepicker>
                                    </b-field>
                                </ValidationProvider>
                            </div>

                            <div class="column" v-show="condition === 'Dañado'">
                                <ValidationProvider name="Fecha de la Perdida" rules="required_if:condition,Dañado"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label='Fecha del Daño *'
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-datepicker v-model="report_date" locale="es-ES"
                                            placeholder="Seleccionar Fecha del Daño" trap-focus>
                                        </b-datepicker>
                                    </b-field>
                                </ValidationProvider>
                            </div>

                            <div class="column" v-show="condition === 'En Servicio Técnico'">
                                <ValidationProvider name="Fecha de la Perdida"
                                    rules="required_if:condition,En Servicio Técnico" v-slot="{ errors, failedRules }">
                                    <b-field label='Fecha a Servicio Técnico *'
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-datepicker v-model="report_date" locale="es-ES"
                                            placeholder="Seleccionar Fecha a Servicio Técnico" trap-focus>
                                        </b-datepicker>
                                    </b-field>
                                </ValidationProvider>

                            </div>

                            <div class="column" v-show="condition === 'Dañado' || condition === 'En Servicio Técnico'">
                                <ValidationProvider name="Reparable"
                                    rules="required_if:condition,Dañado,En Servicio Técnico"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="¿Reparable? *"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-select v-model="repairable" placeholder="Seleccione si es Reparable o no"
                                            expanded>
                                            <option value="SI">Si</option>
                                            <option value="NO">No</option>
                                        </b-select>
                                    </b-field>
                                </ValidationProvider>

                            </div>

                        </div>

                        <div class="columns">
                            <div class="column">
                                <ValidationProvider name="Comentarios para el Reporte" rules="min:3|max:255"
                                    v-slot="{ errors, failedRules }">
                                    <b-field label="Comentarios para el Reporte"
                                        :type="{ 'is-danger': Object.keys(failedRules).length > 0 }"
                                        :message="Object.keys(failedRules).length > 0 ? errors[0] : ''">
                                        <b-input v-model="request_commentary" type="textarea"
                                            placeholder="Ingrese Comentarios para el Reporte" />
                                    </b-field>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="columns is-mobile">

                            <div class="column">
                                <b-button label="Cerrar" @click="$emit('close')" />
                            </div>

                            <div class="column has-text-right">
                                <p class="control">
                                    <b-button label="Confirmar Reporte" type="is-success" icon-left="check"
                                        @click="check()" />
                                </p>
                            </div>

                        </div>

                    </form>
                </ValidationObserver>
            </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </section>
</template>

<script>
import axios from "axios"
import moment from "moment"
export default {
    name: 'show_report_info_condition_good',

    props: [
        'id', 'fromConditions',
    ],

    data() {
        return {

            isLoading: false,
            good: {
                condition_good: {}
            },
            condition_to: 'En Uso',

            //Variables para aceptar el Reporte
            condition: null,
            request_commentary: null,
            report_date: null,
            repairable: null,

        }
    },

    created() {
        this.getGood()
    },

    methods: {

        async getGood() {
            this.isLoading = true
            await axios
                .get("/goods/" + this.id + ".json")
                .then(response => {
                    this.good = response.data

                    this.condition_to = this.good.condition_good.change_condition_to ? this.good.condition_good.change_condition_to : 'En Uso'
                    this.condition = this.condition_to

                    this.repairable = this.good.condition_good.repairable ? "SI" : "NO"

                    this.request_commentary = this.fromConditions ? this.good.condition_good.request_commentary : null

                    this.good.condition_good.request_date = moment(this.good.condition_good.request_date, ["YYYY-MM-DD","DD-MM-YYYY"])
                    this.good.condition_good.request_date = new Date(this.good.condition_good.request_date).toLocaleDateString("es-CL")

                    this.isLoading = false
                })
                .catch(error => {

                    var showError = error.response ? error.response.data : error

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al obtener el Bien, favor de revisar errores.<br>Errores:<br>' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                    this.isLoading = false
                })
        },

        async check() {
            const valid = await this.$refs.observer.validate()
            if (!valid) {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Algunos campos presentan errores.',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }
            else if (this.fromConditions && (this.good.condition_good.change_condition_to === this.condition)) {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Este Bien ya ha sido reportado como: <b>' + this.condition + '</b>',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            }
            else {
                var msg = this.fromConditions ? '¿Está seguro de cambiar el Estado del Bien?' : '¿Está seguro de Aceptar el Reporte de este Bien?'
                this.$buefy.dialog.confirm({
                    title: "Reportar el Bien",
                    message: msg,
                    cancelText: "Cancelar",
                    confirmText: "Reportar",
                    type: "is-info",
                    onConfirm: () => this.reportGood(),
                })
            }
        },

        async reportGood() {
            this.isLoading = true
            await axios.put("/condition_goods/updatebyidgood/" + this.id + ".json", {
                request_date: this.report_date ,
                repairable: this.repairable === 'SI' ? true : false,
                request_commentary: this.request_commentary,
                change_condition_to: this.condition,
                change_condition: true,
            })
            .then(response => {
                this.isLoading = false
                if (this.condition.localeCompare("En Bodega") == 0) {
                    this.updateChargeGoodStored(this.id)
                } else{
                    this.$emit('refresh')
                    this.$emit('close')
                    this.$buefy.dialog.alert({
                        title: 'Reporte del Bien',
                        type: 'is-success',
                        hasIcon: true,
                        icon: 'circle-check',
                        iconPack: 'fas',
                        message: 'El Bien ha sido reportado como: ' + this.condition,
                        confirmText: 'Aceptar'
                    })
                }
            })
            .catch(error => {

                var showError = error.response ? error.response.data : error

                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Hubo un error al solicitar enviar Bien a Bodega, favor revise errores.<br>Errores:<br>' + showError,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
                this.isLoading = false
            })
        },

        async updateChargeGoodStored(idGood) {
            this.isLoading = true
            let old_duty_manager_id = this.good.duty_manager_id
            await axios.get('/offices/store.json')
            .then(response => {
                axios.put("/goods/updatecharge/" + idGood + ".json", {
                    duty_manager_id: response.data.duty_manager.id,
                    office_id: response.data.store.id,
                    old_duty_manager_id: old_duty_manager_id,
                    location_good: "Portería Bodega",
                    start_date: new Date(),
                })
                .then(response => {
                    this.$emit('refresh')
                    this.$emit('close')
                    this.$buefy.dialog.alert({
                        title: 'Reporte del Bien',
                        type: 'is-success',
                        hasIcon: true,
                        icon: 'circle-check',
                        iconPack: 'fas',
                        message: 'El Bien ha sido reportado como: ' + this.condition,
                        confirmText: 'Aceptar'
                    })
                })
                .catch(error => {
                    var showError = error.response ? error.response.data : error

                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Hubo un error al crear registro del Cambio de Cargo del Bien a Bodega.<br>Errores:<br>' + showError,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                })
            })
            .catch(error => {

                var showError = error.response ? error.response.data : error

                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: 'Hubo un error al crear registro del Cambio de Cargo del Bien a Bodega.<br>Errores:<br>' + showError,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
            })
            this.isLoading = false
        },
    }
}
</script>
<template>
  <section>
    <div class="modal-card" style="width: 100%">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ modalHeader }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body" ref="editOffice">
        <div v-if="office" class="columns is-centered">
          <div class="column is-centered">

            <ValidationObserver v-slot="{ handleSubmit }" ref="observer1">
              <form @submit.prevent="handleSubmit(checkEditData())">

                <div class="columns">
                  <div class="column">
                    <b-field label="Nombre de la Dependencia *">
                      <ValidationProvider name="Nombre de la Dependencia" rules="required|max:255" v-slot="{ errors }">
                        <b-input v-model="office.alt_name_office" placeholder="Ingrese el nombre del edificio"></b-input>
                        <span class="validation-alert">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Ubicación *">
                      <ValidationProvider name="Ubicacion" rules="required|max:255" v-slot="{ errors }">
                        <b-input v-model="office.location"
                          placeholder="Ingrese la ubicación de la dependencia (Edificio, en que piso se encuentra..)">
                        </b-input>
                        <span class="validation-alert">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="N° Identificación *">
                      <ValidationProvider name="N° Identificación" rules="required|max:255|min_value:1" v-slot="{ errors }">
                        <b-input v-model="office.identification_number_office"
                          placeholder="Ingrese el numero de identificación de la dependencia" type="number"></b-input>
                        <span class="validation-alert">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Identificación Administración Campus *">
                      <ValidationProvider name="Identificación Administración Campus" rules="required|max:255|min_value:1"
                        v-slot="{ errors }">
                        <b-input v-model="office.campus_identification"
                          placeholder="Ingrese el numero de identificación de la dependencia" type="number"></b-input>
                        <span class="validation-alert">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Horas Semanales Disponibles *">
                      <ValidationProvider name="Horas Semanales Disponibles" rules="required|max:255|min_value:1|max_value:100" v-slot="{ errors }">
                        <b-numberinput min="1" max="100" v-model="office.hours_available"
                          placeholder="Ingrese la cantidad de horas semanales disponibles de la dependencia">
                        </b-numberinput>
                        <span class="validation-alert">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Metros Cuadrados *">
                      <ValidationProvider name="Metros Cuadrados" rules="required|max:255|min_value:1|max_value:100" v-slot="{ errors }">
                        <b-numberinput min="1" max="100" v-model="office.square_meter"
                          placeholder="Ingrese cuantos metro cuadrados tiene la dependencia">
                        </b-numberinput>
                        <span class="validation-alert">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Descripción de la Dependencia *">
                      <ValidationProvider name="Descripción de la Dependencia" rules="required|max:255" v-slot="{ errors }">
                        <b-input v-model="office.office_description" type="textarea"
                          placeholder="Ingrese la descripción de la dependencia, indicando cual es su uso"></b-input>
                        <span class="validation-alert">
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <p class="control">
                      <b-button label="Editar Dependencia" type="is-info" icon-left="floppy-disk"
                        @click="checkEditData()" />
                    </p>
                  </div>

                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div v-else>
          <p>Cargando</p>
        </div>
      </section>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import IndexOffices from "./index_office.vue";
import moment from "moment";
export default {
  name: "edit_office",
  props: ["queryType", "modalHeader", "officeId", "id"],
  components: {
    "index-office": IndexOffices,
  },

  data() {
    return {
      office: null,
      offices: [],
      props: ["id"],
      units: [],
      managers: [],
      buildings: [],
      aux_unit_id: null,
      aux_building_id: null,
      aux_duty_manager_id: null,
      isLoading: false,
    };
  },
  methods: {
    async getoffice() {
      this.isLoading = true
      const response = await axios.get("/offices/" + this.id + ".json")
      this.office = response.data
      this.isLoading = false
    },

    async getunitactive() {
      this.isLoading = true
      const response = await axios.get("/units/showactiveunit.json")
      this.units = response.data
      this.isLoading = false
    },

    async getdutymanagerbyunit(item) {
      const response = await axios.get(
        "/duty_managers/showdutymanagerunit/" +
        item +
        ".json"
      );
      this.managers = response.data;
    },

    async getbuildingbyunit(item) {
      const response = await axios.get(
        "/buildings/showbuildingunit/" + item + ".json"
      );
      this.buildings = response.data;
    },

    async getofficebybuilding(item) {
      const response = await axios.get(
        "/offices/showofficebuilding/" + item + ".json"
      );
      this.offices = response.data;
    },

    async EditClick() {
      this.isLoading = true
      await axios.put("/offices/" + this.id + ".json", {
        alt_name_office: this.office.alt_name_office,
        location: this.office.location,
        identification_number_office: this.office.identification_number_office,
        campus_identification: this.office.campus_identification,
        hours_available: this.office.hours_available,
        square_meter: this.office.square_meter,
      })
      .then(reponse => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha editado correctamente la Oficina al sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch(error => {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'Hubo un error al ingresar los datos de la Oficina, favor revisar los errores.<br>Errores:<br>' + error.response.data,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.isLoading = false
    },

    async checkEditData() {
      const valid = await this.$refs.observer1.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.confirmedit();
      }
    },

    confirmedit() {
      this.$buefy.dialog.confirm({
        title: "Editar la dependencia",
        message: `¿Esta seguro de editar la dependencia?`,
        cancelText: "Cancelar",
        confirmText: "Guardar",
        type: "is-info",
        onConfirm: () => this.EditClick(),
      });
    },
    async EditClickDes() {
      this.isLoading = true
      const response = await axios.put("/offices/" + this.id + ".json", {
        duty_manager_id: this.aux_duty_manager_id,
        building_id: this.aux_building_id,
        unit_id: this.aux_unit_id,
        activegood: 1,
      })
      this.$emit('refresh')
      this.$emit('close')
      this.isLoading = false
    },

    confirmeditDes() {
      this.$buefy.dialog.confirm({
        title: "Editar la dependencia",
        message: `¿Esta seguro de editar la dependencia?`,
        cancelText: "Cancelar",
        confirmText: "Guardar",
        type: "is-info",
        onConfirm: () => this.EditClickDes(),
      });
    },
    async checkCharge() {
      const valid = await this.$refs.observer2.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.confirmeditDes();
      }
    },

    async check() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.confirmregister();
      }
    },
  },
  async created() {
    this.isLoading = true
    await this.getoffice()
    await this.getunitactive()
    this.isLoading = false
  },
};
</script>

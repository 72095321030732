<template>
  <section class="b-tooltips">
    <br>
    <div class="container box">
      <div class="content">
        <h1>Detalle de la fuente</h1>
      </div>
      <b-tabs type="is-boxed" position="is-centered">

          <div v-if="financial_source" class="columns is-centered">
            <div class="column is-centered">

              <div class="columns">
                <div class="column">
                  <b-field label="Nombre la fuente:">
                    <b-input v-model="financial_source.alt_name_financial_source" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Descripción:">
                    <b-input v-model="financial_source.detail" type="text" readonly></b-input>
                  </b-field>
                </div>
              </div>

            </div>
          </div>

          <div v-else>
            <p>Cargando</p>
          </div>
      </b-tabs>
      <b-button
        tag="a"
        href="/financial_sources"
        label="Volver">
      </b-button>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
  </section>
</template>


<script>
import axios from "axios";
export default {
  name:"show_financial_sources",
  props: ["id",'queryType', 'modalHeader','financial_sourceId'],
  mounted() {
    // Cambiar el título de la pestaña
    document.title = 'SGI - Detalle Fuente Financiera';
  },
  data() {
    return {
      financial_source: null,
      props: ["id"],
      isLoading: false,
    };
  },
  methods: {
    async getfinancials() {
      this.isLoading = true
      const response = await axios.get("/financial_sources/" + this.financial_sourceId + ".json")
      this.financial_source = response.data
      this.isLoading = false
    },

  },
  async created() {
    this.isLoading = true
    await this.getfinancials()
    this.isLoading = false
  },
};
</script>


<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmregister())">
        <div class="modal-card" style="width: 100%">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body" ref="newUser">
            <div class="columns is-centered">
              <div class="column is-centered">
                <div class="columns">
                  <div class="column">
                    <b-field label="Nombre de Usuario *">
                      <ValidationProvider name="Nombre de Usuario" rules="required|max:255" v-slot="{ errors }">
                        <b-input v-model="aux_full_name" placeholder="Ingrese nombre de usuario">
                        </b-input>
                        <span class="validation-alert">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="RUT *">
                      <ValidationProvider name="RUT" rules="required|max:255" v-slot="{ errors }">
                        <b-input v-model="aux_rut" type="number" placeholder="Ingrese Rut del usuario" />
                        <span class="validation-alert">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="DV *">
                      <ValidationProvider name="DV" rules="required" v-slot="{ errors }">
                        <b-input v-model="dv" maxlength="1" placeholder="Ingrese DV del usuario" />
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field >
                      <template #label>
                        Fecha Inicio *
                        <b-tooltip 
                          label="Este rango de fechas indica el periodo que la cuenta del usuario estará activa, fuera de este rango el usuario no podrá ingresar al sistema."
                          multilined>
                          <b-icon icon="lightbulb" type="is-info" size="is-small"/>
                        </b-tooltip>
                      </template>
                      <ValidationProvider name="Fecha Inicio" v-slot="{ errors }"
                        :rules="{start_date_before_end_date: dateEnd, required: true}">
                        <b-datepicker 
                          placeholder="Click para seleccionar..."
                          v-model="dateIni"/>
                        <span class="validation-alert">
                          {{errors[0]}}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Fecha Término *">
                      <ValidationProvider name="Fecha Término" v-slot="{ errors }"
                        :rules="{end_date_not_before_start_date: dateIni, required: true}">
                        <b-datepicker 
                          placeholder="Click para seleccionar..."
                          v-model="dateEnd"/>
                        <span class="validation-alert">
                          {{errors[0]}}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Email *">
                      <ValidationProvider name="Email" rules="required|max:255" v-slot="{ errors }">
                        <b-input v-model="aux_email" type="email" placeholder="Ingrese correo del usuario" />
                        <span class="validation-alert">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field label="Rol *">
                      <ValidationProvider name="Rol" rules="required" v-slot="{ errors }">
                        <multiselect v-model="aux_rol" :options="cargos.map((type) => type.id)" 
                          :custom-label="(opt) => cargos.find((x) => x.id === opt).name" 
                          placeholder="Seleccionar Rol" selectLabel="" selectedLabel="Seleccionado"
                          deselectLabel="" :allow-empty="false"
                          @input="dateIniPosition = new Date, dateEndPosition = new Date">
                        </multiselect>
                        <span class="validation-alert">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Fecha Inicio Rol *">
                      <ValidationProvider name="Fecha Inicio Rol" v-slot="{ errors }"
                        :rules="{start_date_before_end_date: dateEndPosition, required: true}">
                        <b-datepicker
                          :disabled = "(aux_rol === null ? null : (cargos.find((x) => x.id === aux_rol)).name) === 'VISITANTE'" 
                          placeholder="Click para seleccionar..."
                          v-model="dateIniPosition"/>
                        <span class="validation-alert">
                          {{errors[0]}}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Fecha Término Rol">
                      <ValidationProvider name="Fecha Término Rol" v-slot="{ errors }"
                        :rules="{end_date_not_before_start_date: dateIniPosition, required: false}">
                        <b-datepicker
                          :disabled = "(aux_rol === null ? null : (cargos.find((x) => x.id === aux_rol)).name) === 'VISITANTE'"  
                          placeholder="Click para seleccionar..."
                          v-model="dateEndPosition"/>
                        <span class="validation-alert">
                          {{errors[0]}}
                        </span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns is-mobile">
                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('close')" />
                  </div>
                  <div class="column has-text-right">
                    <b-button label="Guardar Datos" icon-left="save" type="is-success" @click="check()" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "new_user",
  props: ["queryType", "modalHeader"],
  data() {
    return {
      selected: null,
      users: [],
      cargos: [],
      aux_full_name: null,
      aux_rut: null,
      dv: null,
      aux_email: null,
      aux_rol: null,
      isLoading: false,
      dateIni: null,
      dateEnd: null,
      dateIniPosition: null,
      dateEndPosition: null,
    };
  },
  methods: {
    async createClick() {
      this.isLoading = true
      await axios.post("/users/create/newuser.json", {
        full_name: this.aux_full_name,
        rut: this.aux_rut,
        dv: this.dv,
        start_date_session: moment(this.dateIni).format("YYYY-MM-DD"),
        end_date_session: moment(this.dateEnd).format("YYYY-MM-DD"),
        email: this.aux_email,
        id_principal_position: this.aux_rol,
        start_date_position: moment(this.dateIniPosition).format("YYYY-MM-DD"),
        end_date_position: moment(this.dateEndPosition).format("YYYY-MM-DD"),
      })
      .then(response => {
        this.$buefy.dialog.alert({
          title: 'Logrado',
          message: 'Se ha agregado correctamente el usuario al sistema.',
          type: 'is-success',
          hasIcon: true,
          icon: 'circle-check',
          iconPack: 'fas',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch(error => {
        let errorMsg = ''
        if (error.response) {
            if (error.response.status === 403) {
                errorMsg = 'No tiene permisos para ingresar los datos del Usuario'
            } else {
                errorMsg = 'Hubo errores al ingresar los datos del Usuario, favor de revisar errores.<br>Errores<br>' + error.response.data
            }
        } else {
            errorMsg = 'Hubo errores al ingresar los datos del Usuario, intente más tarde.'
        }

        this.$buefy.dialog.alert({
            title: 'Error',
            message: errorMsg,
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
        })
      })
      this.isLoading = false
    },

    async getCargos() {
      this.isLoading = true
      await axios.get("/internal_positions.json")
        .then(response => {
            this.cargos = response.data;
        })
        .catch(error => {
          let errorMsg = ''
          if (error.response) {
              if (error.response.status === 403) {
                  errorMsg = 'No tiene permisos para obtener los datos de los Cargos'
              } else {
                  errorMsg = 'Hubo errores al obtener los datos de los Cargos, favor de revisar errores.<br>Errores<br>' + error.response.data
              }
          } else {
              errorMsg = 'Hubo errores al obtener los datos de los Cargos, intente más tarde.'
          }

          this.$buefy.dialog.alert({
              title: 'Error',
              message: errorMsg,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
          })
          this.isLoading = false
          this.$emit('close')
        })
  },


    async check() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: "Algunos campos presentan errores.",
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Registrar Usuario",
          message: `¿Esta seguro de registrar esta usuario?`,
          cancelText: "Cancelar",
          confirmText: "Registrar",
          type: "is-info",
          onConfirm: () => this.createClick(),
        });
      }
    },

  },
  async created() {
    this.isLoading = true
    await this.getCargos()
    this.isLoading = false
  },
};
</script>

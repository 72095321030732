<template>
  <section>


    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(confirmregister())">

        <div class="modal-card" style="width: 100%">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalHeader }}</p>
            <button type="button" class="delete" @click="$emit('refresh', false); $emit('close')" />
          </header>
          <section class="modal-card-body" ref="newGoodByUser">
            <div class="columns is-centered">
              <div class="column is-centered">
                <div class="columns">

                  <div class="column">
                    <b-field label="Categoria *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect style="is-info" v-model="aux_category" :options="categories.map((type) => type.id)"
                          :custom-label="(opt) => categories.find((x) => x.id === opt).name_category"
                          placeholder="Seleccionar categoria" selectLabel="" selectLabelcolor=""
                          selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar" :allow-empty="true">
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Nombre *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="aux_namegood" placeholder="Ingrese el nombre del bien"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Numero inventario Usach *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-input v-model="aux_identification_number_good"
                          placeholder="Ingrese el numero inventario del bien"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                </div>

                <div class="columns">

                  <div class="column">
                    <b-field label="Marca *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect style="is-info" v-model="aux_trademark" :options="trademarks.map((type) => type.id)"
                          :custom-label="(opt) => trademarks.find((x) => x.id === opt).name_trademark"
                          placeholder="Seleccionar marca" selectLabel="" selectLabelcolor="" selectedLabel="Seleccionado"
                          deselectLabel="Presione para deseleccionar" :allow-empty="true"></multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Numero de serie">
                      <ValidationProvider v-slot="{ errors }">
                        <b-input v-model="aux_serie_number" placeholder="Ingrese el numero de serie del bien"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Modelo">
                      <ValidationProvider v-slot="{ errors }">
                        <b-input v-model="aux_model" placeholder="Ingrese el modelo del bien"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">

                  <div class="column">
                    <b-field label="Fecha de Compra *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-datepicker v-model="aux_date_purchase" locale="es-ES" placeholder="Seleccionar fecha de compra"
                          trap-focus>
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Fecha Chequeo *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <b-datepicker v-model="aux_date_chekup" locale="es-ES"
                          placeholder="Seleccionar fecha de ultimo Chequeo" trap-focus>
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">

                  <div class="column">
                    <b-field label="Fuente de Financiamiento *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect style="is-info" v-model="aux_financial_source_id"
                          :options="financial_sources.map((type) => type.id)" :custom-label="(opt) => financial_sources.find((x) => x.id === opt).name_financial_source
                            " placeholder="Seleccionar fuente de financiamiento" selectLabel="" selectLabelcolor=""
                          selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar" :allow-empty="true">
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Tipo de inversión *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect style="is-info" v-model="aux_investment_type_id"
                          :options="investment_types.map((type) => type.id)"
                          :custom-label="(opt) => investment_types.find((x) => x.id === opt).name_investment_type"
                          placeholder="Seleccionar Tipo de inversión" selectLabel="" selectLabelcolor=""
                          selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar" :allow-empty="true">
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Monto Bruto">
                      <ValidationProvider v-slot="{ errors }">
                        <b-numberinput min="0" v-model="aux_amount" type="is-info"
                          placeholder="Ingrese el monto bruto del bien">
                        </b-numberinput>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                </div>



                <div class="columns">
                  <div class="column">
                    <b-field label="Edificio *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="aux_building_id" :options="buildings.map((type) => type.id)" :custom-label="(opt) => buildings.find((x) => x.id === opt).name_building
                          " placeholder="Seleccionar" selectLabel="Presione para seleccionar"
                          selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar" :allow-empty="true"
                          :disabled="aux_office_id !== null" @input="getofficebybuilding(aux_building_id)">
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Persona asociada encargada *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="aux_duty_manager_id" :options="managers.map((type) => type.id)"
                          :custom-label="(opt) =>
                            managers.find((x) => x.id === opt).name_duty_manager
                            " placeholder="Seleccionar Persona encargada" selectLabel="Presione para seleccionar"
                          selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar" :allow-empty="true"
                          :disabled="aux_building_id == null">
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns">


                  <div class="column">
                    <b-field label="Dependencia *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect v-model="aux_office_id" :options="offices.map((type) => type.id)" :custom-label="(opt) => offices.find((x) => x.id === opt).name_office
                          " placeholder="Seleccionar dependencia" selectLabel="Presione para seleccionar"
                          selectedLabel="Seleccionado" deselectLabel="Presione para deseleccionar" :allow-empty="true"
                          :disabled="aux_building_id == null">
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Estado *">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <multiselect style="is-info" v-model="aux_operative" :options="estados" placeholder="Seleccionar"
                          selectLabel="" selectLabelcolor="" selectedLabel="Seleccionado"
                          deselectLabel="Presione para deseleccionar" :allow-empty="true"></multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>




                <div class="columns">

                  <div class="column">
                    <b-field label="Descripción">
                      <ValidationProvider v-slot="{ errors }">
                        <b-input v-model="aux_good_description" placeholder="Ingrese una descripción del bien o el nombre"
                          type="textarea"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>

                <div class="columns is-mobile">

                  <div class="column">
                    <b-button label="Cerrar" @click="$emit('refresh', false); $emit('close')" />
                  </div>

                  <div class="column has-text-right">
                    <p class="control">
                      <b-button label="Añadir Bien" icon-left="save" type="is-success" @click="getgoodsactive()" />
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </form>
    </ValidationObserver>


  </section>
</template>
  
<script>
import axios from "axios";
export default {
  name: "new_good_by_user",
  props: [
    'queryType', 'modalHeader'
  ],
  data() {
    return {
      selected: null,
      goods: [],
      offices: [],
      units: [],
      financial_sources: [],
      investment_types: [],
      managers: [],
      buildings: [],
      dropFiles: [],
      estados: ["Operativo", "No Operativo"],
      mybuilding: null,
      aux_namegood: null,
      aux_good_description: null,
      aux_identification_number_good: null,
      aux_serie_number: null,
      aux_internal_number: null,
      aux_trademark: null,
      aux_category: null,
      trademarks: [],
      categories: [],
      aux_location_good: null,
      aux_duty_manager_id: null,
      aux_office_id: null,
      aux_unit_id: null,
      aux_building_id: null,
      aux_operative: null,
      aux_model: null,
      aux_date_chekup: new Date(),
      aux_financial_source_id: null,
      aux_investment_type_id: null,
      aux_amount: null,
      aux_date_purchase: new Date(),
      aux_financial_source: null,
      aux_investment_type: null,
      aux_activegood: true,
      last_good: null,
      actual_id: null,
      current: null,
      exist: false,
      isLoading: false,
    };
  },
  methods: {


    async get_trademarks() {
      const response = await axios.get("/financial_sources/showfinancialsources.json");
      this.financial_sources = response.data.financial_sources_enabled;
    },


    async get_categories() {
      const response = await axios.get("/categories/showcategories.json");
      this.categories = response.data.categories_enabled
    },


    async getfinancialsource() {
      const response = await axios.get("/financial_sources.json");
      this.financial_sources = response.data;

    },

    async getinvestmenttype() {
      const response = await axios.get("/investment_types/showinvestmenttypes.json");
      this.investment_types = response.data.investment_types_enabled;
    },

    async get_user_actual() {
      const response = await axios.get("/duty_managers/currentuser.json");
      this.current = response.data.current_user;
      this.getbuildingactive(this.current);

    },

    async getbuildingactive(item) {
      const response = await axios.get("/buildings/showbuildinguser/" + item + ".json");
      this.buildings = response.data;
    },



    async getunitactive() {
      const response = await axios.get("/units/showactiveunit.json");
      this.units = response.data;
    },


    async getdutymanagerbyunit() {
      const response = await axios.get(
        "/duty_managers/showdutymanagerunit/" +
        this.mybuilding.unit_id +
        ".json"
      );
      this.managers = response.data;

    },





    async getofficebybuilding(item) {
      const response = await axios.get("/offices/showofficebuilding/" + item + ".json");
      this.offices = response.data;
      this.getunitidbybuilding();
    },

    async getunitidbybuilding() {
      const response = await axios.get("/buildings/" + this.aux_building_id + ".json");
      this.mybuilding = response.data;
      this.getdutymanagerbyunit();
    },

    async createClick() {
      const response = await axios.post("/goods.json", {

        namegood: this.aux_namegood,
        good_description: this.aux_good_description,
        identification_number_good: this.aux_identification_number_good,
        serie_number: this.aux_serie_number,
        model: this.aux_model,
        duty_manager_id: this.aux_duty_manager_id,
        office_id: this.aux_office_id,
        activegood: this.aux_activegood,
        amount: this.aux_amount,
        date_purchase: this.aux_date_purchase,
        financial_source_id: this.aux_financial_source_id,
        investment_type_id: this.aux_investment_type_id,
        trademark_id: this.aux_trademark,
        category_id: this.aux_category,
        date_checkup: this.aux_date_chekup,
        operative: this.aux_operative,
        internal_number: this.aux_internal_number,
      });
      this.getlastgood();
    },

    async getlastgood() {
      const response = await axios.get("/goods.json");
      this.goods = response.data;
      this.goods.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      this.last_good = this.goods.slice(0);
      this.actual_id = this.last_good[0].id;
      this.$emit('refresh')
      this.$emit('close')
    },

    confirmregister() {
      this.$buefy.dialog.confirm({
        title: "Registrar el Bien",
        message: `¿Esta seguro de registrar esté bien?`,
        cancelText: "Cancelar",
        confirmText: "Registrar",
        type: "is-info",
        onConfirm: () => this.createClick(),
      });
    },


    async getgoodsactive() {
      const response = await axios.get("/goods/showactive.json");
      this.goods = response.data;

      for (var i = 0; i < this.goods.length; i++) {
        if (this.goods[i].identification_number_good == this.aux_identification_number_good) {
          if (this.aux_identification_number_good != "-") {
            this.exist = true;
          }
        }
      }
      this.check();
    },



    async check() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert(
          {
            message: 'Algunos campos presentan errores.', type: 'is-danger',
            hasIcon: true, icon: 'times-circle', iconPack: 'fa', ariaRole: 'alertdialog', ariaModal: true
          }
        )
      }
      else {
        if (this.exist == true) {
          this.$buefy.dialog.alert(
            {
              message: 'El Numero de inventario usach ya existe.', type: 'is-danger',
              hasIcon: true, icon: 'times-circle', iconPack: 'fa', ariaRole: 'alertdialog', ariaModal: true
            }
          )
          this.exist = false;
        }
        else {
          this.confirmregister();
        }
      }
      
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
  },
  created() {
    this.getfinancialsource();
    this.getinvestmenttype();
    this.get_user_actual();
    this.get_trademarks();
    this.get_categories();

  },
};
</script>